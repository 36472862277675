/*
 *   File : checkbox-group.js
 *   Author : https://evoqins.com
 *   Description : Checkbox group component for UI
 *   Integrations : null
 *   Version : 1.0.0
*/
/* import components */
import Icon from "../Icon/icon";

const CheckBoxGroup = (props) => {

    let data = props.item;
    // let is_selected = props.selectedType.some(item => item.filter_id === props.filter_id && item.sub_filter_ids.includes(props.sub_filter_id));
    let is_selected = props.selectedType.includes(data.id);
    const array_data = data.label.split('₹');

    return (
        <div className={`d-flex align-items-center gap-8px ${props.className} `}>
            <span>
                <Icon icon={is_selected === true ? "checked" : 'unchecked'}
                    size={24}
                    className="cursor-pointer"
                    onClick={() => props.onHandleClick(data.id)} />
            </span>
            <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer"
                onClick={() => props.onHandleClick(data.id)}>
                {array_data.map((data, index) => (
                    <span key={index}>
                        {index > 0 ? <span className="e-inter-regular">₹</span> : null}
                        {data}
                    </span>
                ))}
            </span>
        </div>
    );

}

CheckBoxGroup.defaultProps = {
    onHandleClick: () => { },
    className: "",
    selectedType: [],
    item: null
}

export default CheckBoxGroup