/*
 *   File : delete.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while removing funds.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { GradientButton } from "../Cta";
import { Icon } from "../Icon";


/* import styles */
import style from "../../Styles/Components/banner.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import { toast } from "react-toastify";

import APIService from "../../Services/api-service";
import { RadioGroup } from "../FormElements";

const TIMING_DATA = [
    {
        id: 1,
        label: '1 Sec'
    },
    {
        id: 2,
        label: '2 Sec'
    },
    {
        id: 4,
        label: '4 Sec'
    },
    {
        id: 5,
        label: '5 Sec'
    },
    {
        id: 6,
        label: '6 Sec'
    }
]

const ManageBannerTimingModal = (props) => {
    const [time, setTime] = useState(props.time_gap);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        setTime(props.time_gap);
    }, [props.time_gap])
    useEffect(() => {
        var my_modal = document.getElementById("manage-banner-timing");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();

        });
        //eslint-disable-next-line
    }, [props.close]);


    function _handleTime(id) {
        setTime(id);
    }



    // API - update time delay for slider
    const _updateTimeGap = () => {
        setApiLoader(true);
        const url = "banner/update-time-gap";

        const data = {
            "time_gap": time
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.updateTiming()
                closeModal("manage-banner-timing");
                toast.dismiss();
                toast.success('Timing updated successfully', {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        });
    }


    return (
        <div className={`modal fade ${style.e_delete}`}
            id="manage-banner-timing"
            tabIndex="-1"
            aria-labelledby="manage-banner-timing"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            Manage time gap
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <p className="color-light-black e-poppins-regular e-font-16 line-height-22px mb-3 padding-12px-top">Adjust your time gap between two
                        banners
                    </p>
                    <div className="d-flex flex-wrap">
                        <RadioGroup id={time}
                            data={TIMING_DATA}
                            radioHandler={_handleTime} />
                    </div>


                    <div className="col-12 pt-4">
                        <GradientButton label="Save changes"
                            loading={apiLoader}
                            className="padding-12px-tb e-font-16 "
                            onPress={_updateTimeGap}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


ManageBannerTimingModal.defaultProps = {
    close: () => { },
}

export default ManageBannerTimingModal