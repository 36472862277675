/*
 *   File : banner-list.js
 *   Author URI : https://evoqins.com
 *   Description : Banner listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import { useEffect, useState } from "react";
import { Modal } from "bootstrap";

/* import components */
import { BannerCard } from "../../../../Components/Cards";
import { GradientButton, LinkText } from "../../../../Components/Cta";
import { SectionTitle } from "../../../../Components/Title";
import { DeleteConfirmationModal, ManageBannerModal, ManageBannerTimingModal } from "../../../../Components/Modal";
import { CustomLoader } from "../../../../Components/Other";

/* import components */
import APIService from "../../../../Services/api-service";


const BannerList = (props) => {

    const [openBanner, setOpenBanner] = useState(false);
    const [deleteBanner, setDeleteBanner] = useState(null);
    const [editBanner, setEditBanner] = useState(null);
    const [pageLoader, setPageLoader] = useState(true);
    const [bannerList, setBannerList] = useState([]);
    const [openTimingModal, setOpenTimingModal] = useState(false);
    const [timeGap, setTimeGap] = useState(null);

    useEffect(() => {
        _bannerList();
    }, []);

    useEffect(() => {
        if (openBanner === true) {
            _openBannerModal();
        }
    }, [openBanner]);

    useEffect(() => {
        if (openTimingModal === true) {
            _openBannerTimingModal();
        }
    }, [openTimingModal]);

    useEffect(() => {
        if (deleteBanner !== null) {
            _openConfirmationModal();
        }
    }, [deleteBanner]);

    function _showManageBannerTimingModal() {
        setOpenTimingModal(true);
    }

    function _openBannerModal() {
        const modal = new Modal(document.getElementById("add-banner"));
        modal.show();
    }

    function _openConfirmationModal() {
        const modal = new Modal(document.getElementById("delete"));
        modal.show();
    }

    function _openBannerTimingModal() {
        const modal = new Modal(document.getElementById("manage-banner-timing"));
        modal.show();
    }

    // API - banner list
    const _bannerList = () => {
        const url = "banner/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerList(response.data.data);
                setTimeGap(response.data.banner_time_gap);
            } else {
                setBannerList([]);
            }
            setPageLoader(false);
        });
    }
    return (
        <div className="row pe-0">
            <div className="col-12 d-flex justify-content-between align-items-center padding-36px-top pe-0">
                <div className="d-flex align-items-center">
                    <SectionTitle title="Recent uploads" />
                </div>

                { <div className="d-flex align-items-center">
                    <LinkText type={2} title="Update time gap"
                        className='e-font-16'
                        onClick={_showManageBannerTimingModal} />
                    <GradientButton label="Add new banner"
                        hide_loader={true}
                        className="ms-3 px-3"
                        onPress={() => {
                            setOpenBanner(true)
                        }} />
                </div>}
            </div>

            {
                pageLoader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <>
                        <div className="col-8 pt-3">
                            {
                                bannerList.length !== 0 ?
                                    bannerList.map((item, index) => (
                                        <BannerCard data={item} key={index}
                                            permissionType={'write'}
                                            editBanner={() => {
                                                setOpenBanner(true)
                                                setEditBanner(item)
                                            }}
                                            removeBanner={() => {
                                                setDeleteBanner(item)
                                            }} />
                                    ))
                                    :
                                    <span className="color-black e-font-14 e-poppins-regular me-2">No banners added</span>

                            }

                        </div>

                    </>
            }
            {
                openBanner ?
                    <ManageBannerModal
                        data={editBanner}
                        close={() => {
                            setOpenBanner(false);
                            setEditBanner(null)
                        }}
                        updateBanner={() => {
                            setOpenBanner(false);
                            setEditBanner(null);
                            _bannerList();
                        }} />
                    :
                    null
            }

            {
                deleteBanner !== null ?
                    <DeleteConfirmationModal content={`Are you sure you want to delete this Banner?`}
                        banner={true}
                        banner_id={deleteBanner.banner_id}
                        close={() => {
                            setDeleteBanner(null);
                        }}
                        confirmDelete={() => {
                            _bannerList();
                            setDeleteBanner(null)
                        }} />
                    :
                    null
            }
            {
                openTimingModal === true ?
                    <ManageBannerTimingModal
                        time_gap={parseInt(timeGap)}
                        close={() => {
                            setOpenTimingModal(false);
                        }}
                        updateTiming={() => {
                            setOpenTimingModal(false);
                            _bannerList();
                        }} />
                    :
                    null
            }
        </div>
    )
}
export default BannerList