/*
 *   File : manage-faq.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for Updating FAQ.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { toast } from "react-toastify";
import { Icon } from "../Icon";
import { CustomTextArea, CustomTextInput, Editor } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Cta";
import { closeModal } from "../../Helper/helper-function";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import APIService from "../../Services/api-service";

const ManageFAQ = (props) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [answerError, setAnswerError] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (props.data !== null) {
            setQuestion(props.data.question);
            setAnswer(props.data.answer);
        }
    }, [props.data])

    useEffect(() => {
        var my_modal = document.getElementById("manage-faq");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setQuestion('');
            setQuestionError('');
            setAnswerError('');
            setAnswer('');
        });
        //eslint-disable-next-line
    }, [props.close]);


    function _handleQuestion(value) {
        setQuestion(value);
        setQuestionError('');
    }

    function _handleAnswer(value) {
        setAnswer(value);
        setAnswerError(null);
    }

    function _handleValidate() {
        let valid = true;
        
        if (question.trim().length === 0) {
            setQuestionError('Question is required');
            valid = false;
        }
        if (answer.trim().length === 0) {
            setAnswerError('Answer is required');
            valid = false;
        }
        if (valid === true) {
            _addFaq();
        }
    }

    // API - add or update FAQ
    const _addFaq = () => {
        setApiLoader(true);
        const url = 'content/add-faq';
        const data = {
            "content_id": props.data !== null ? props.data.id : null,
            "question": question,
            "answer": answer
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                closeModal('manage-faq');
                props.faqAdded();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_approve_kyc} e-manage-info`}
            id="manage-faq"
            tabIndex="-1"
            aria-labelledby="manage-faq"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-answer-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            {props.data !== null ? 'Edit FAQ' : 'Add FAQ'}
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>


                    <div className="col-12 pt-4">
                        <CustomTextInput
                            label="Question"
                            postfix="*"
                            error={questionError}
                            value={question}
                            hide_error_text={true}
                            valueChange={(value) => _handleQuestion(value)} />
                    </div>

                    <div className="col-12 pt-4">
                        <CustomTextArea
                            label="Answer"
                            postfix="*"
                            error={answerError}
                            value={answer}
                            hide_error_text={true}
                            valueChange={(value) => _handleAnswer(value)} />
                    </div>

                    <div className="d-flex justify-content-center w-100 margin-40px-top">
                        <SecondaryButton label="Cancel"
                            cancel="modal" />
                        <PrimaryButton label={props.data !== null ? "Update FAQ" : "Add FAQ"}
                            className='ms-3'
                            loading={apiLoader}
                            onPress={_handleValidate} />

                    </div>

                </div>

            </div>
        </div>
    )
}


ManageFAQ.defaultProps = {
    close: () => { }
}

export default ManageFAQ 