/*
 *   File : search-input.js
 *   Author URI : www.evoqins.com
 *   Description : Search input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";

/*import components */

/*import styles*/
import styles from "../../Styles/Components/search.module.scss";
import { Icon } from "../Icon";
import Color from "../../Styles/color.module.scss";

const SearchInput = (props) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.defaultValue);
    }, [])

    // onchange value
    const _handleChange = (event) => {
        const input_value = event.target.value;
        // Check if acceptNumber prop is true and the input is not a number
        if (props.acceptNumber && isNaN(input_value)) {
            return; // Do nothing if not a number
        }
        setValue(input_value.toLowerCase());
        props.valueChange(input_value.toLowerCase());
    }

    //on presss of enter
    const _handleSearch = (event) => {
        const value = event.target.value;
        if (event.keyCode === 13) {
            props.handleSearch(value);
        }
    }

    return (
        <Fragment>
            <div className={`${styles.e_search_input}  w-100 position-relative ${props.divClass}`}>
                {
                    value.length !== 0 ?
                        <div className="p-2 bg-ocean-boat-blue position-absolute top-0 bottom-0 right-0 d-flex border-tbr-radius-8px cursor-pointer"
                            onClick={() => props.handleSearch(value)}>
                            <Icon icon="arrow-right1"
                                size={24}
                                color={Color.white} />
                        </div>
                        :
                        <Icon icon="search"
                            size={24}
                            className="position-absolute top-0 bottom-0 cursor-pointer" />
                }

                <input type="text"
                    id={props.id}
                    name={props.name}
                    spellCheck="false"
                    placeholder={props.placeholder}
                    value={value}
                    autoComplete="off"
                    onFocus={() => props.onClick()}
                    className={`${props.className} ${value !== '' ? 'padding-42px-right' : null} py-2 border-bright-gray`}
                    onChange={_handleChange}
                    onKeyDown={(e) => _handleSearch(e)}
                />
            </div>
        </Fragment>
    );
};

SearchInput.defaultProps = {
    valueChange: () => { },
    id: 1,
    value: "",
    defaultValue: "",
    acceptNumber: false,
    placeholder: "Search",
    className: '',
    onClick: () => { },
    handleSearch: () => { }
}

export default SearchInput;