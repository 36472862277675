/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Quiz listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* import components */
import { NavigationHeader } from "../../Components/Header";
import { GradientButton, LinkText } from "../../Components/Cta";
import { QuestionnaireList } from "../../Components/Accordion";
import { CustomLoader } from "../../Components/Other";

/* import services */
import APIService from "../../Services/api-service";


const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage Quiz"
]

const QuizListing = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);
    const [pageLoader, setPageLoader] = useState(true);
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [permissionType, setPermissionType] = useState('');
    const [questionIds, setQuestionIds] = useState([]);
    const [orderApiLoader, setOrderApiLoader] = useState(false);

    useEffect(() => {
        _listQuestionnaire();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    //API - list risk questionnaire
    const _listQuestionnaire = () => {
        const url = "risk/list-questions";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setQuestionnaireList(response.data);
            } else {
                setQuestionnaireList([]);
            }
            setPageLoader(false);
        });
    }

    function _updateQuestionIds(ids) {
        setQuestionIds(ids);
    }

    function _updateQuizOrder() {
        setOrderApiLoader(true);
        const url = 'risk/order-questions';
        let payload = JSON.stringify({
            question_ids: questionIds
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Quiz order updated!', {
                    type: 'success'
                })
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setOrderApiLoader(false);
        })
    }

    return (
        <Fragment>
            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage quiz" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-6 ">
                        <h4 class="color-black e-font-16 line-height-16px mb-0 e-poppins-medium">Added Questions</h4>
                    </div>
                    {permissionType !== 'read' &&
                        <div className="col-6 d-flex justify-content-end align-items-center">
                            <LinkText title="View sample logic" type={2}
                                className='e-font-16'
                                onClick={() => {
                                    navigate("/risk-planning-questionnaire/how-it-works");
                                }} />
                            {
                                permissionType === 'write' &&
                                <GradientButton label="Add question"
                                    className="ms-3"
                                    onPress={() => {
                                        navigate("/risk-planning-questionnaire/add");
                                    }} />
                            }
                        </div>
                    }
                </div>
                {
                    pageLoader === true ?
                        <div className="h-100vh">
                            <CustomLoader />
                        </div>
                        :
                        <>
                            <div className="row">
                                <div className="col-9">
                                    <QuestionnaireList data={questionnaireList}
                                        permissionType={permissionType}
                                        updateQuestionIds={_updateQuestionIds}
                                        updateList={_listQuestionnaire} />
                                </div>
                            </div>
                            <div className="row">
                                {
                                    permissionType === 'write' && questionnaireList.length > 0 &&
                                    <div className="col-lg-3 col-md-4 col-12">
                                        <GradientButton label="Save order"
                                            className="ms-3"
                                            loading={orderApiLoader}
                                            onPress={_updateQuizOrder} />
                                    </div>
                                }
                            </div>
                        </>

                }
            </div>

        </Fragment>
    )
})
export default QuizListing;
