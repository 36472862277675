/*
 *   File : reject-kyc.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for rejecting kyc.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

/* import components */
import { GradientButton, SecondaryButton } from "../Cta";
import { Icon } from "../Icon";
import { CustomTextArea, SearchSelect } from "../FormElements";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { useClickOutside } from "../../Helper/helper-function";
import { _manageKyc } from "../../Helper/api";

const RejectKYCModal = (props) => {
    const dropdownRef = useRef(false);
    const [reason, setReason] = useState(false);
    const [information, setInformation] = useState('');
    const [informationError, setInformationError] = useState(null);
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [reasonId, setReasonId] = useState(null);
    const [rejectionReason, setRejectionReason] = useState("");
    const [reasonError, setReasonError] = useState('');
    const [reasons, setReasons] = useState(props.data)

    useEffect(() => {
        var my_modal = document.getElementById("reject-kyc");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setInformation("");
            setSearch("");
            setRejectionReason("");
            setInformationError(null);
            setReasonError("");
            setReason(false);
        });
        //eslint-disable-next-line
    }, [props.close]);


    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
        setReasonId(null);
    });

    // validate form
    const _validateForm = () => {
        let valid_form = true;


        if (reasonId === null) {
            valid_form = false;
            setReasonError('Reason is required');
        }
        if (information.length === 0) {
            valid_form = false;
            setInformationError('Information is required');
        }
        //eslint-disable-next-line
        else if (valid_form = true) {
            _rejectKyc();
        }
    }

    // API - reject kyc 
    const _rejectKyc = () => {
        const data = {
            "customer_id": props.customerId,
            "is_approve": false,
            "reason_id": reasonId,
            "reason": information
        }
        _manageKyc(data).then((response) => {
            setReason(true);
        }).catch((response) => {
            toast.dismiss();
            toast.error(response, {
                type: "error"
            });
        });
    }

    // handle input field
    const _handleInput = (input, type) => {
        switch (type) {
            case 1:
                setReasonError('');
                setSearch(input);
                break;
            case 2:
                setInformationError(null);
                setInformation(input);
                break;

            default:
                break;
        }
    }

    const _copyLink = (type) => {
        switch (type) {
            case 1:
                navigator.clipboard.writeText("Linked copied");
                toast.dismiss();
                toast.success('Linked copied', {
                    type: 'success'
                });
                break;
            case 2:
                navigator.clipboard.writeText("Linked copied");
                toast.dismiss();
                toast.success('Linked copied', {
                    type: 'success'
                });
                // window.location.href = `sms:${props.mobile}`;
                break;
            case 3:
                window.open('https://api.whatsapp.com/send?phone=' + props.mobile, '_blank');
                break;
            case 4:
                window.location.href = `mailto:${props.email}`;
                break;

            default:
                break;
        }
    }

    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="reject-kyc"
            tabIndex="-1"
            aria-labelledby="reject-kyc"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    {
                        reason ?
                            // screen to show reasons
                            <Fragment>

                                <div className="col-12 d-flex justify-content-between">

                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>
                                <img draggable={false} src={require("../../Assets/Images/Modal/kyc-rejected.png")}
                                    alt="Kyc success"
                                    width={72}
                                    height={72}
                                />


                                <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                                    KYC documents rejected
                                </h6>
                                <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-4 px-5">
                                    KYC has been rejected due to {rejectionReason}. {information}
                                </p>

                                {/* <div className="d-flex gap-16px justify-content-center margin-32px-top margin-32px-bottom w-100">

                                    <div className="padding-12px-all cursor-pointer e-share-icon border-all border-bright-gray bg-white border-radius-8px color-outer-space e-font-14 e-poppins-regular letter-spacing-point28"
                                        onClick={() => _copyLink(1)}>
                                        <Icon icon="copy"
                                            size={24} className="me-2" />
                                        Copy link
                                    </div>
                                    <div className="padding-12px-all cursor-pointer e-share-icon border-all border-bright-gray bg-white border-radius-8px color-outer-space e-font-14 e-poppins-regular letter-spacing-point28"
                                        onClick={() => _copyLink(2)}>
                                        <Icon icon="sms"
                                            size={24} className="me-2" />
                                        SMS
                                    </div>
                                    <div className="padding-12px-all cursor-pointer e-share-icon border-all border-bright-gray bg-white border-radius-8px color-outer-space e-font-14 e-poppins-regular letter-spacing-point28"
                                        onClick={() => _copyLink(3)}>
                                        <img draggable={false} src={require('../../Assets/Images/Modal/whatsapp.svg').default}
                                            width={24} className="me-2"
                                            alt="whatsapp" />
                                        WhatsApp
                                    </div>
                                    <div className="padding-12px-all cursor-pointer e-share-icon border-all border-bright-gray bg-white border-radius-8px color-outer-space e-font-14 e-poppins-regular letter-spacing-point28"
                                        onClick={() => _copyLink(4)}>
                                        <Icon icon="email"
                                            size={24} className="me-2" />
                                        Email
                                    </div>
                                </div> */}
                            </Fragment>
                            :
                            // reject message
                            <Fragment>
                                <div className="col-12 d-flex justify-content-between">
                                    <h3 className="color-black e-poppins-semi-bold e-font-18">
                                        KYC rejection reason
                                    </h3>
                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>

                                <div className="col-12 padding-24px-top position-relative">
                                    <SearchSelect placeholder="Reasons"
                                        value={rejectionReason ? rejectionReason : search}
                                        type="text"
                                        error={reasonError}
                                        data={reasons}
                                        showDropdown={showDropdown}
                                        activeId={reasonId}
                                        onClick={() => {
                                            setShowDropdown(true);
                                            setReasonError('');
                                        }}
                                        select={(dropdown, id, label) => {
                                            setShowDropdown(dropdown);
                                            setReasonId(id);
                                            setRejectionReason(label);
                                            setReasonError("")
                                        }}
                                        valueChange={(value) => {
                                            _handleInput(value, 1);

                                            let reason_filter = reasons.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
                                            if (value.length === 0) {
                                                setReasons(props.data)
                                                setShowDropdown(true);
                                            }
                                            else if (reason_filter.length > 0) {
                                                setShowDropdown(true);
                                                setReasons(reason_filter);
                                            }
                                            else {
                                                setShowDropdown(false);
                                            }

                                        }}
                                    />

                                </div>

                                <div className="col-12 mt-4">
                                    <CustomTextArea label="Additional information"
                                        value={information}
                                        type="text"
                                        id="additional-info"
                                        error={informationError}
                                        rows={2}
                                        errorClass={style.e_error_class}
                                        style={{ top: '30%' }}
                                        valueChange={(input_value) => {
                                            _handleInput(input_value, 2);
                                        }} />
                                </div>

                                <div className="col-12 margin-34px-top ">
                                    <SecondaryButton label="Cancel"
                                        className="padding-12px-tb e-font-16 "
                                        cancel="modal" />
                                    <GradientButton label="Reject "
                                        className="padding-12px-tb e-font-16 ms-3"
                                        onPress={_validateForm} />
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}


RejectKYCModal.defaultProps = {
    close: () => { }
}

export default RejectKYCModal