/*
 *   File : tags.js
 *   Author URI : https://evoqins.com
 *   Description : Manage tags
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { Fragment, useEffect, useState, useCallback, useRef } from "react";
import { Modal } from "bootstrap";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from 'immutability-helper';

/* import components */
import { GradientButton, OutlineButton } from "../../../Components/Cta"
import { SectionTitle } from "../../../Components/Title"
import { ManageTag } from "../../../Components/Modal";
import { TagCard } from "../../../Components/Cards";
import { CustomLoader } from "../../../Components/Other";

import APIService from "../../../Services/api-service";
import { toast } from "react-toastify";

const ManageTags = (props) => {
    const [apiLoader, setApiLoader] = useState(true);
    const [tagList, setTagList] = useState({});
    const [selectedTag, setSelectedTag] = useState({});
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [selectedTagId, setSelectedTagId] = useState(0);
    const [quickInvestmentTags, setQuickInvestmentTags] = useState([]);
    const [mutualFundTags, setMutualFundTags] = useState([]);
    const [permission, setPermission] = useState(props.permissionType);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [currentlyLoading, setCurrentlyLoading] = useState(1);

    useEffect(() => {
        _getTagList();
    }, []);


    useEffect(() => {
        if (openUpdateModal === true) {
            _openTagModal();
        }
    }, [openUpdateModal]);

    function _openTagModal() {
        const modal = new Modal(document.getElementById("manage-tag"));
        modal.show();
    }

    //  update modal success call back
    function _updateList() {
        setSelectedTag({});
        setSelectedTagId(0);
        setOpenUpdateModal(false);
        _getTagList();
    }

    // call back modal close
    function _modalClose() {
        setSelectedTag({});
        setSelectedTagId(0);
        setOpenUpdateModal(false);
    }

    function _handleCardClick(tag_type, data) {
        setSelectedTag(data);
        setSelectedTagId(data.id)
        setOpenUpdateModal(true);
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setQuickInvestmentTags((previousState) =>
            update(previousState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, previousState[dragIndex]],
                ],
            }),
        )
    }, []);

    const moveMtTag = useCallback((dragIndex, hoverIndex) => {
        setMutualFundTags((previousState) =>
            update(previousState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, previousState[dragIndex]],
                ],
            }),
        )
    }, []);

    // API - get tag list 
    function _getTagList() {
        let url = 'general-settings/list-tags';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.investment_tags.length !== 0) {
                    setQuickInvestmentTags(response.data.investment_tags);
                }
                if (response.data.fund_tags.length !== 0) {
                    setMutualFundTags(response.data.fund_tags);
                }
            }
            setApiLoader(false);
        });
    }

    // API - to update order of the tags
    function _updateTagOrder(type) {
        setButtonLoader(true);
        setCurrentlyLoading(type);
        let tag_id = [];
        if (type === 1) {
            quickInvestmentTags.map((item) => {
                tag_id.push(item.tag_id)
            });
        } else {
            mutualFundTags.map((item) => {
                tag_id.push(item.tag_id);
            })
        }
        let url = 'general-settings/update-tag-order';
        let payload = JSON.stringify({
            tag_ids: tag_id,
            type
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Sequence updated', {
                    type: 'success'
                })
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setButtonLoader(false);
        })
    }

    const QuickInvestmentTag = (props) => {
        const ref = useRef(null);
        const [show, setShow] = useState(false);

        let key = props.index;
        let row = props.data;
        const [{ isDragging }, drag] = useDrag({
            type: 'ITEM',
            item: { key },

            collect: monitor => ({
                isDragging: monitor.isDragging()
            })

        });

        const [{ handlerId }, drop] = useDrop({
            accept: 'ITEM',
            hover: (item, monitor) => {
                if (!ref.current) {
                    console.log('No ref found');
                    return
                }
                const draggedIndex = item.key;
                const targetIndex = key;
                console.log('targetIndex', targetIndex);
                console.log('draggedIndex', draggedIndex);
                if (draggedIndex === targetIndex) {
                    console.log('Destination adn target are same');
                    return
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                console.log('hoverMiddleY', hoverMiddleY);
                console.log('hoverClientY', hoverClientY);

                // if (draggedIndex < targetIndex && hoverClientY < hoverMiddleY) {
                //     return
                // }
                // // Dragging upwards
                // if (draggedIndex > targetIndex && hoverClientY > hoverMiddleY) {
                //     return
                // }


                moveCard(draggedIndex, targetIndex)
                item.key = targetIndex
            },
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
        });



        const Item_Style = {
            boxShadow: show ? '0px 2px 8px 2px rgba(0, 0, 0, 0.06)' : '',
            backgroundColor: show ? '#EEFAF7' : '',
            opacity: isDragging ? '0' : '1',
            cursor: 'move'
        }

        drag(drop(ref));
        return (
            <div data-handler-id={handlerId} ref={ref} style={Item_Style} className="col-xl-2 e-quick-invest">
                <TagCard data={row}
                    selectedId={selectedTagId} />
                <div className="mt-3 mx-auto d-flex justify-content-center">
                    {
                        row.icon_text === null ?
                            <GradientButton label="Add tag"
                                onPress={() => _handleCardClick('investment_tags', row)} />
                            :
                            <OutlineButton label="Edit tag"
                                onPress={() => _handleCardClick('investment_tags', row)} />
                    }
                </div>
            </div>
        );
    }

    const MutualFundTag = (props) => {
        const ref = useRef(null);
        const [show, setShow] = useState(false);

        let key = props.index;
        let row = props.data;
        const [{ isDragging }, drag] = useDrag({
            type: 'ITEM',
            item: { key },

            collect: monitor => ({
                isDragging: monitor.isDragging()
            })

        });

        const [{ handlerId }, drop] = useDrop({
            accept: 'ITEM',
            hover: (item, monitor) => {
                if (!ref.current) {
                    return
                }
                const draggedIndex = item.key;
                const targetIndex = key;
                if (draggedIndex === targetIndex) {
                    return
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top

                // if (draggedIndex < targetIndex && hoverClientY < hoverMiddleY) {
                //     return
                // }
                // // Dragging upwards
                // if (draggedIndex > targetIndex && hoverClientY > hoverMiddleY) {
                //     return
                // }


                moveMtTag(draggedIndex, targetIndex)
                item.key = targetIndex
            },
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
        });



        const Item_Style = {
            boxShadow: show ? '0px 2px 8px 2px rgba(0, 0, 0, 0.06)' : '',
            backgroundColor: show ? '#EEFAF7' : '',
            opacity: isDragging ? '0' : '1',
            cursor: 'move'
        }

        drag(drop(ref));
        return (
            <div data-handler-id={handlerId} ref={ref} style={Item_Style} className="col-xl-2 e-quick-invest h-100">
                <TagCard data={row} />
                <div className="mt-3 mx-auto d-flex justify-content-center">
                    {
                        row.icon_text === null ?
                            <GradientButton label="Add tag"
                                onPress={() => _handleCardClick('fund_tags', row)} />
                            :
                            <OutlineButton label="Edit tag"
                                onPress={() => _handleCardClick('fund_tags', row)} />
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {
                apiLoader === true ? (
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                ) : (
                    <>
                        {
                            quickInvestmentTags.length !== 0 && (
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-between align-items-center padding-36px-top mb-3">
                                        <SectionTitle title="Manage quick investment tags" />
                                        {
                                            permission !== 'read' && (
                                                <GradientButton label="Save Sequence"
                                                    loading={buttonLoader === true && currentlyLoading === 1 ? true : false}
                                                    onPress={() => _updateTagOrder(1)} />
                                            )
                                        }

                                    </div>
                                    {
                                        permission !== 'read' ? (
                                            <DndProvider backend={HTML5Backend}>
                                                {
                                                    quickInvestmentTags.map((item, key) => {
                                                        return (
                                                            <QuickInvestmentTag data={item}
                                                                index={key} />
                                                        )
                                                    })
                                                }
                                            </DndProvider>
                                        ) : (
                                            <>
                                                {
                                                    quickInvestmentTags.map((item, key) => {
                                                        return (
                                                            <div className="col-xl-2 e-quick-invest h-100"
                                                                key={key}>
                                                                <TagCard data={item} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>

                                        )
                                    }

                                </div>
                            )
                        }
                        {
                            mutualFundTags.length !== 0 && (
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-between align-items-center padding-36px-top mb-3">
                                        <SectionTitle title="Manage mutual fund tags" />
                                        {
                                            permission !== 'read' && (
                                                <GradientButton label="Save Sequence"
                                                    onPress={() => _updateTagOrder(2)} 
                                                    loading={buttonLoader === true && currentlyLoading === 2 ? true : false}/>
                                            )
                                        }

                                    </div>
                                    {
                                        permission !== 'read' ? (
                                            <DndProvider backend={HTML5Backend}>
                                                {
                                                    mutualFundTags.map((item, key) => {
                                                        return (
                                                            <MutualFundTag data={item}
                                                                index={key} />
                                                        )
                                                    })
                                                }
                                            </DndProvider>
                                        ) : (
                                            <>
                                                {
                                                    mutualFundTags.map((item, key) => {
                                                        return (
                                                            <div className="col-xl-2 e-quick-invest h-100"
                                                                key={key}>
                                                                <TagCard data={item} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }

                                </div>
                            )
                        }
                    </>
                )
            }
            {
                openUpdateModal === true ?
                    <ManageTag selectedId={selectedTagId}
                        data={selectedTag}
                        close={_modalClose}
                        successCallBack={_updateList}
                        updateBanner={() => {
                            setOpenUpdateModal(false);
                            setSelectedTag({})
                        }} />
                    :
                    null
            }
        </>

    )
}
export default ManageTags