/*
 *   File : manage-contact-detail.js
 *   Author URI : https://evoqins.com
 *   Description : Manage notification page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";

/* import components */
import { SectionTitle } from "../../../../Components/Title";
import { Toggle } from "../../../../Components/FormElements";
import { CustomLoader } from "../../../../Components/Other";

// import services
import APIService from "../../../../Services/api-service";
import { _getAdminTypes } from "../../../../Helper/api";


const ManageContactDetail = () => {
    const [apiLoader, setApiLoader] = useState(true);
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        _getSettings();
    }, []);

    // API - get settings
    async function _getSettings() {
        let data = await _getAdminTypes();
        setSettingsData(data);
        setApiLoader(false);
    }

    // API - update masked status
    function _updateStatus(item, index) {
        let url = 'general-settings/update-mask';
        let payload = JSON.stringify({
            admin_type_id: item.id,
            mask_status: !item.mask_status
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Status updated', { type: 'success' });
                let contact_details = [...settingsData];
                contact_details[index].mask_status = !item.mask_status;
                setSettingsData(contact_details);
            } else {
                toast.dismiss();
                toast.error(response.message, { type: 'error' });
            }
        })

    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 pt-4">
                    <SectionTitle title="Permission settings" />
                    <p className="e-font-16 e-poppins-regular line-height-24px color-light-black mb-4 mt-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit.
                    </p>
                </div>
                {
                    apiLoader === true ? (
                        <div className="row">
                            <div className="col-12 h-100vh">
                                <CustomLoader />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-lg-7">
                                {
                                    settingsData.map((item, index) => {
                                        return (
                                            <div className="border-all border-bright-gray border-radius-8px p-3 mb-3 d-flex justify-content-between">
                                                <h6 className="e-font-16 e-poppins-medium line-height-24px color-light-black mb-0 ">{item.name}</h6>
                                                <div className="d-flex align-items-center">
                                                    <span className="e-font-16 e-poppins-regular line-height-24px color-light-black me-2">Unmask</span>
                                                    <Toggle check={item.mask_status}
                                                        onChange={() => {
                                                            _updateStatus(item, index)
                                                        }} />
                                                    <span className="e-font-14 e-poppins-regular line-height-24px color-jett-black ms-2">Mask</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <div className="col-12 margin-40px-top">
                                <GradientButton label="Update setting" />
                            </div> */}
                        </div>
                    )
                }

            </div>
        </Fragment>
    )
}

export default ManageContactDetail;