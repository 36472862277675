
/*
 *   File : admin-settings.js
 *   Author URI : https://evoqins.com
 *   Description : Manage admin settings
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

import { NavigationHeader } from "../../../Components/Header";
import { SectionTitle } from "../../../Components/Title";
import { Icon } from "../../../Components/Icon";
import { CustomLoader } from "../../../Components/Other";

import { _getKYCRejectedReason } from "../../../Helper/api";
import { AddFailureReason, DeleteReasonModal } from "../../../Components/Modal";


export default function AdminSettings() {

    const [reasons, setReasons] = useState([]);
    const [hoveredReason, setHoveredReason] = useState(-1);
    const [apiLoader, setApiLoader] = useState(true);
    const [showAddReasonModal, setShowAddReasonModal] = useState(false);
    const [deleteReasonId, setDeleteReasonId] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        _getRejectedReason()
    }, []);

    useEffect(() => {
        if (showAddReasonModal === true) {
            const modal = new Modal(document.getElementById('add-reason'));
            modal.show();
        }
    }, [showAddReasonModal]);

    useEffect(() => {
        if (deleteReasonId !== null) {
            const modal = new Modal(document.getElementById('delete-reason'));
            modal.show();
        }
    }, [deleteReasonId]);

    // API - get reasons for reject kyc application
    function _getRejectedReason() {
        setShowAddReasonModal(false);
        _getKYCRejectedReason().then((response) => {
            setReasons(response);
        }).catch(error => {
            setReasons([]);
        });
        setApiLoader(false);
    };

    function _showAddModal() {
        setShowAddReasonModal(true);
    }

    function _showDeleteModal(id) {
        setDeleteReasonId(id);
        setHoveredReason(-1);
    }

    // Card - Reason
    function Reason({ data, index }) {
        return (
            <div className="border-radius-16px border-all border-bright-gray padding-16px-tb padding-16px-lr d-flex justify-content-between">
                <p className="color-light-black e-poppins-regular e-font-16 mb-0">
                    {data.label}
                </p>
                <span className="e-tool-tip-container position-relative h-fit-content"
                    data-tooltip-content="Delete selected"
                    placement="bottom"
                    onMouseEnter={() => setHoveredReason(index)}
                    onMouseLeave={() => setHoveredReason(-1)}>
                    <Icon icon={hoveredReason === index ? "delete-red" : "delete"}
                        size={24} onClick={() => _showDeleteModal(data.id)}
                    />
                </span>
            </div>
        )
    }

    return (
        <Fragment>
            {/* header */}
            <NavigationHeader type={1}
                title="Manage KYC rejection reasons" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 pt-4">
                <div className="row ">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="KYC rejection reasons" />
                    </div>
                    <div className="col-12 h-initial position-relative mt-4">
                        <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer " onClick={_showAddModal}>
                            <Icon icon="add-circle1" size={24} className='me-2' />
                            Add KYC reason
                        </span>
                    </div>
                </div>
                {
                    apiLoader === true ? (
                        <div className="h-60vh">
                            <CustomLoader />
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    {
                                        reasons.length !== 0 ? (
                                            reasons.map((item, key) => {
                                                return (
                                                    <div className="col-12 mt-4" key={key}>
                                                        <Reason data={item} index={key} />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="col-12 text-center my-5">
                                                <p className="color-light-black e-poppins-regular e-font-16">No data found </p>
                                            </div>
                                        )

                                    }

                                </div>

                            </div>

                        </div>
                    )
                }
                {
                    showAddReasonModal === true ?
                        <AddFailureReason close={() => {
                            setShowAddReasonModal(false);
                        }}
                            reasonAdded={_getRejectedReason} />
                        :
                        null
                }
                {
                    deleteReasonId !== null ?
                        <DeleteReasonModal id={deleteReasonId}
                            close={() => {
                                setDeleteReasonId(null);
                            }}
                            confirmDelete={_getRejectedReason} />
                        :
                        null
                }
            </div>
        </Fragment>
    )
}