/*
 *   File : quiz.js
 *   Author URI : https://evoqins.com
 *   Description : UI component to Quiz accordion
 *   Integrations : null
 *   Version : v1.1
 */

/*import packages*/
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper'
import { CustomSlider } from '../Other';
import { DndProvider, DragLayerMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDrag, useDragLayer } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

/*import components*/
import Icon from "../Icon/icon";
import { RadioGroup } from '../FormElements';

/*import styles*/
import style from '../../Styles/Components/quiz.module.scss';


import { _formatNumber } from '../../Helper/helper-function';

import Colors from '../../Styles/color.module.scss';
import { Modal } from 'bootstrap';
import { DeleteQuestionModal } from '../Modal';


const RISK_SCALE_COLOR_COMPOSITION = [
    Colors.pale_jade,
    Colors.pale_lime,
    Colors.vivid_lime,
    Colors.blonde,
    Colors.sun_lit_yellow,
    Colors.sunflower,
    Colors.canary_yellow,
    Colors.bisque,
    Colors.peachy_pink,
    Colors.apricoat
]


const QuestionnaireList = (props) => {
    const navigate = useNavigate();
    const [init, setInit] = useState(null);
    const [list, setList] = useState(props.data);
    const [questionId, setQuestionId] = useState(null);
    const [showDeletedRed, setShowDeletedRed] = useState(-1);

    useEffect(() => {
        setList(props.data);
    }, [props.data]);

    useEffect(() => {
        if (questionId !== null) {
            _openDeleteModal();
            setShowDeletedRed(-1);
        }
    }, [questionId]);

    useEffect(()=>{
        let ids = list.map((item) => { return item.question_id});
        props.updateQuestionIds(ids);
    },[list]);


    function _handleToggle(index) {
        setInit(index)
    }

    function _handleDeleteQuestion(id) {
        setQuestionId(id);
        
    }

    function _openDeleteModal() {
        const modal = new Modal(document.getElementById("delete-question"));
        modal.show()
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setList((previousState) =>
            update(previousState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, previousState[dragIndex]],
                ],
            }),
        )
    }, []);

    const CustomDragLayer = ({display}) => {

        const { isDragging, currentOffset, item, initialOffset } = useDragLayer((monitor) => {
            return {
                isDragging: monitor.isDragging(),
                currentOffset: monitor.getSourceClientOffset(),
                initialOffset: monitor.getInitialSourceClientOffset(),
                item: monitor.getItem(),
                itemType: monitor.getItemType(),
            };
        });

        function getItemStyles(initialOffset, currentOffset) {
            if (!initialOffset || !currentOffset) {
                return {
                    display: 'none',
                }
            }
            let { x, y } = currentOffset
         
            const transform = `translate(${x}px, ${y}px)`
            return {
                transform,
                WebkitTransform: transform,
                color: 'red'
            }
        }
        if (display === false) {
            // currentOffset = null;
        }
        
        return ( 
            isDragging && currentOffset ? 
            <div style={{
                transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
                border: '1px solid #ccc',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '900px',
                height: '70px',
                zIndex: '-1',
                color: 'red',
                borderRadius: '8px',
                }}
                className='d-flex position-fixed align-items-center justify-content-center'>
                <div
                    style={{backgroundColor: 'yellow'}}
                >
                    {item.id}
                </div>

            </div>
            : null
        );
    }

    const DraggableBox = (props) => {
        const ref = useRef(null);
        const [show, setShow] = useState(false);
        let key = props.index;
        let item = props.data;
        const [{ isDragging,  }, drag] = useDrag({
            type: 'ITEM',
            item: { key },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),

        });

        const [{ isOver }] = useDrop({
            accept: 'YOUR_DRAGGABLE_ITEM_TYPE',
            collect: (monitor) => ({
              isOver: monitor.isOver(),
            }),
          });

        const { currentOffset } = useDragLayer((monitor) => {
            return {
                currentOffset: monitor.getSourceClientOffset(),
            };
        });

        const [{ handlerId }, drop] = useDrop({
            accept: 'ITEM',
            hover: (item, monitor) => {
                if (!ref.current) {
                    return
                }
                const draggedIndex = item.key;
                const targetIndex = key;
                
                if (draggedIndex === targetIndex) {
                    return
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top

                if (draggedIndex < targetIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (draggedIndex > targetIndex && hoverClientY > hoverMiddleY) {
                    return
                }


                moveCard(draggedIndex, targetIndex)
                item.key = targetIndex
            },
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
        });

        console.log('handlerId',handlerId);

        const Item_Style = {
            boxShadow: show ? '0px 2px 8px 2px rgba(0, 0, 0, 0.06)' : '',
            backgroundColor: show ? '#EEFAF7' : '',
            opacity: isDragging ? '0' : '1',
            cursor: 'move'
        }

        drag(drop(ref))
        return (
            <>
            
                {/* <CustomDragLayer display={show }/> */}

                <div className='e-draggable-box e-faq-drag-drop'
                    ref={ref} 
                    // style={Item_Style} 
                    data-dragging={isDragging} 
                    data-handler-id={handlerId}
                    onMouseEnter={()=> setShow(true)}
                    onMouseLeave={()=> setShow(false)}
                    >
                    <div key={key} className='col-12'>
                        {
                            init === key ?

                                <div className={`${style.e_accordion_item} p-3`} >
                                    <h6 className='mb-0' id={`heading-${key}`} >
                                        <button
                                            className={key === 0 && init === key ? `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 color-primary-color text-capitalized line-height-24px ` : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${key}`}
                                            aria-expanded={key === 0 && init === key ? "true" : "false"}
                                            aria-controls={`collapse-${key}`}
                                            onClick={() => {
                                                _handleToggle(null, true)
                                            }}>
                                            {item.question}

                                            <div className={`${style.e_accordion_arrow}`}>
                                                <img draggable={false} src={require("../../Assets/Images/Quiz/minus.svg").default} alt='faqarrow' />
                                            </div>
                                        </button>
                                    </h6>

                                    <div id={`collapse-${key}`} className={`${style.e_answer} col-12`} aria-labelledby={`heading-${key}`}
                                        data-bs-parent="#faq-accordion">
                                        {
                                            item.question_type === 1 || item.question_type === 3 ?

                                                <RadioGroup data={item.answers}
                                                    id={item.config?.default_answer}
                                                    className="e-risk-radio-span pointer-events-none"
                                                    type={2}
                                                    disabled={true}
                                                />

                                                :
                                                <div className="border-radius-16px border-all border-bright-gray px-3 padding-14px-top pb-2 e-risk-range">
                                                    <div className="d-flex gap-16px flex-wrap ">
                                                        <div className="e-range-slider pointer-events-none">
                                                            <CustomSlider defaultValue={item.config.min_max.default}
                                                                min={item.config.min_max.min}
                                                                max={item.config.min_max.max}
                                                                value={item.config.min_max.default}
                                                                disabled={true}
                                                                className='pointer-events-none'
                                                            />
                                                            <div className='d-flex justify-content-between align-items-center e-labels'>
                                                                {
                                                                    item.config.postfix_enabled === false ?
                                                                        <Fragment>
                                                                            <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>
                                                                                <span className="e-inter-regular pe-1">₹</span>{_formatNumber(item.config.min_max.min)}
                                                                            </p>
                                                                            <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>
                                                                                <span className="e-inter-regular pe-1">₹</span>{_formatNumber(item.config.min_max.max)}</p>
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>{item.config.min_max.min} {item.config.singular_postfix}</p>

                                                                            <p className='color-old-silver e-poppins-regular e-font-12 mb-0'>{item.config.min_max.max} {item.config.plural_postfix}</p>
                                                                        </Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-22px mb-0">
                                                            {
                                                                item.config.postfix_enabled === false && <span className="e-inter-medium pe-1">₹</span>
                                                            }
                                                            {
                                                                item.config.postfix_enabled ?
                                                                    `${item.config.min_max.default} ${item.config.min_max.default > 1 ? item.config.plural_postfix : item.config.singular_postfix}` :
                                                                    `${item.config.min_max.default !== null ? item.config.min_max.default?.toLocaleString('en-IN') : 0}`
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            // :
                                            // <div className="d-flex flex-column e-financial">
                                            //     <div className="d-flex gap-8px flex-wrap">
                                            //         {
                                            //             item.answers.map((answer, key) =>
                                            //                 <div className={`border-radius-8px e-financial-risk d-flex justify-content-center align-items-center pointer-events-none
                                            //                 ${item.selected_answer === answer.id ? " border-all border-primary-color" : ""}`}
                                            //                     style={{ backgroundColor: RISK_SCALE_COLOR_COMPOSITION[key] }}
                                            //                     key={key}
                                            //                 >
                                            //                     <span className="color-black e-poppins-regular e-font-16">{answer.label}</span>
                                            //                 </div>
                                            //             )}
                                            //     </div>
                                            //     <div className="d-flex justify-content-between mt-2">
                                            //         <p className="color-green-crayola e-poppins-regular e-font-12 mb-0">Low risk - low returns</p>
                                            //         <p className="color-orange-soda e-poppins-regular e-font-12 mb-0">High risk - high returns</p>
                                            //     </div>
                                            // </div>
                                        }

                                    </div>
                                    {props.permissionType !== 'read' && <div className='d-flex mt-3'>
                                        <p className='color-primary-color line-height-16px e-poppins-regular e-font-14  my-0 cursor-pointer'
                                            onClick={() => {
                                                navigate("/risk-planning-questionnaire/edit", {
                                                    state: {
                                                        question: item
                                                    }
                                                })
                                            }}>
                                            Edit
                                            <Icon icon="Pen-square"
                                                size={24}
                                                className="ms-1" />
                                        </p>
                                        <p className={`${showDeletedRed === item.question_id ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-14  my-0  ms-3 e-tool-tip-container position-relative h-fit-content `}
                                            data-tooltip-content="Delete selected"
                                            onMouseEnter={() => {
                                                setShowDeletedRed(item.question_id);
                                            }}
                                            onMouseLeave={() => {
                                                setShowDeletedRed(-1);
                                            }}
                                            onClick={() => {
                                                _handleDeleteQuestion(item.question_id)
                                            }}>
                                            Delete

                                            {/* <span className="e-tool-tip-container position-relative "
                                            data-tooltip-content="Delete selected"
                                            placement="bottom"
                                        > */}
                                            <Icon icon={showDeletedRed === item.question_id ? "delete-red" : 'delete'}
                                                size={24}
                                                className="ms-1" />
                                            {/* </span> */}
                                        </p>
                                    </div>}
                                </div>
                                :
                                null
                        }
                        {
                            init !== key ?

                                <div className={`${style.e_accordion_item} p-3`}>
                                    <h6 className='mb-0' id={`heading-${key}`}>
                                        <button
                                            className={key === 0 && init === key ? `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 colour-eerie-black text-capitalized line-height-24px ` : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${key}`}
                                            aria-expanded={key === 0 && init === key ? "true" : "false"}
                                            aria-controls={`collapse-${key}`}
                                            onClick={() => {
                                                _handleToggle(key)
                                            }}>
                                            {item.question}

                                            <div className={`${style.e_accordion_arrow}`}>
                                                <img draggable={false} src={require("../../Assets/Images/Quiz/add.svg").default} alt='faqarrow' />
                                            </div>
                                        </button>
                                    </h6>


                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </>
        );
    }


    return (
        <DndProvider backend={HTML5Backend}>

            <div className='row'>
                <div className='col-lg-12 col-12 position-relative'>
                    {
                        list.map((item, key) => {
                            return (
                                <>
                                    <DraggableBox data={item} index={key} permissionType={props.permissionType} />
                                </>
                            )

                        })
                    }
                </div>
            </div>

            {
                questionId !== null ?
                    <DeleteQuestionModal question_id={questionId}
                        close={() => {
                            setQuestionId(null);
                            props.updateList();
                        }}
                        confirmDelete={() => {
                            setQuestionId(null);
                            setInit(null);
                            props.updateList();
                        }} />
                    :
                    null
            }
        </DndProvider>

    )
}

export default QuestionnaireList