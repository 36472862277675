/*
 *   File : manage-permission.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which gives permission to admin
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { CustomSearchSelectBox } from "../FormElements";
import { Icon } from "../Icon";
import { GradientButton, SecondaryButton } from "../Cta";
import { CustomLoader } from "../Other";

import style from "../../Styles/Components/delete.module.scss";

import { _getPermissions } from "../../Helper/api";
import APIService from "../../Services/api-service";


const ManagePermissionModal = (props) => {
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [adminPermissions, setAdminPermissions] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showDeletedRed, setShowDeleteRed] = useState(-1);
    const [apiLoader, setApiLoader] = useState(true);

    useEffect(() => {
        var my_modal = document.getElementById("manage-permission");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    useEffect(() => {
        _fetchPermissions();
    }, []);

    // handle role option change
    function _handleRoleChange(value, index) {
        let roles = [...adminPermissions];
        roles[index] = {
            module: value,
            permission: ''
        };
        let updated_roles_options = [...permissionOptions];
        // removing selected item from role menu option by setting show_option as false, 
        // in component we handle this based on show_option 
        let option_index = updated_roles_options.findIndex(item => item.value === value.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = false;
        }
        setPermissionOptions(updated_roles_options);
        setAdminPermissions(roles);
    }

    // handle action option change
    function _handleActionChange(value, key) {
        let actions = [...adminPermissions];
        actions[key] = {
            module: actions[key].module,
            permission: value
        };
        setAdminPermissions(actions);
    }

    // add new permission to array
    function _handleAddNew() {
        const is_valid = adminPermissions.every(element => element.module !== '' && element.permission !== '');
        // validating before adding new items;
        if (is_valid) {
            setAdminPermissions([...adminPermissions, { module: '', permission: '', error: false }]);
        } else {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
        }
    }

    // Remove permission from array
    function _deletePermission(index) {
        let permission_array = [...adminPermissions];
        let updated_roles_options = [...permissionOptions];
        let dropdown_option = permission_array[index].module;
        console.log('permission', dropdown_option);
        let option_index = updated_roles_options.findIndex(item => item.value === dropdown_option.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = true;
        }
        setPermissionOptions(updated_roles_options);
        permission_array.splice(index, 1);
        setAdminPermissions(permission_array);
    }

    // validate form before submitting 
    function _validate() {
        let valid = true;
        if (adminPermissions.length === 0) {
            return;
        }
        const is_valid_permissions = adminPermissions.every(element => element.module !== '' && element.permission !== '');

        if (is_valid_permissions === false) {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
            valid = false;
        }
        // checking for duplicate
        else {
            const module_labels = adminPermissions.map(entry => entry.module.label);
            const has_duplicates = new Set(module_labels).size !== module_labels.length;
            if (has_duplicates) {
                toast.dismiss();
                toast.error('Please avoid duplicate Roles', {
                    type: 'error'
                });
                valid = false;
            }
        }

        if (valid === true) {
            _updatePermissions();
        }
    }

    // API - get permissions 
    async function _fetchPermissions() {
        try {
            // get all permission data from api
            let permission_data = await _getPermissions();

            // Create a map to store permission data and available permissions
            let permission_map = new Map();

            // Format and add available data to array
            if (props.data) {
                permission_data.modules.forEach(item => {
                    const permission_info = {
                        value: item.id,
                        label: item.name,
                        permissions: item.permissions.map((permission) => ({
                            value: permission.permission_id,
                            label: permission.name,
                        })),
                        short_name: item.short_name,
                        show_option: !props.data.find(data => data.module_id === item.id)
                    };

                    permission_map.set(item.id, permission_info);

                    const data_item = props.data.find(data => data.module_id === item.id);
                    if (data_item) {
                        const available_permission = {
                            module: permission_info,
                            permission: { value: data_item.permission_type_id, label: data_item.action },
                            error: false,
                        };

                        setAdminPermissions(prevPermissions => [...prevPermissions, available_permission]);
                    }
                });
            }

            setPermissionOptions(Array.from(permission_map.values()));
            setApiLoader(false);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    }



    // API - update admin permissions
    function _updatePermissions() {
        setButtonLoader(true);
        let permissions = adminPermissions.map((item) => {
            return { module_id: item.module.value, permission_type_id: item.permission.value }
        });
        let url = "auth/update-permission";
        let payload = JSON.stringify({
            admin_id: props.adminId,
            permissions
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let close_btn = document.getElementById('permission-modal-close');
                close_btn.click();
                toast.dismiss();
                toast.success('Permission updated successfully', {
                    type: 'success'
                });
                props.successCallBack();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setButtonLoader(false);
        })
    }


    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="manage-permission"
            tabIndex="-1"
            aria-labelledby="manage-permission"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between padding-32px-bottom">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            Manage permission
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                        <button className="d-none"
                            id="permission-modal-close"
                            data-bs-dismiss="modal"></button>
                    </div>
                    {
                        apiLoader === true ? (
                            <div className="min-height-144px">
                                <CustomLoader />
                            </div>
                        ) : (
                            <>
                                <div className=" pt-3 ">
                                    {
                                        adminPermissions.length !== 0 ? (
                                            adminPermissions.map((item, index) => {
                                                return (
                                                    <div className="row mb-4">
                                                        <div className="col-12 text-end mb-2">
                                                            <span className={`${showDeletedRed === index ? 'color-red' : 'color-primary-color'} e-font-16 line-height-16px e-poppins-medium cursor-pointer e-tool-tip-container position-relative`}
                                                                data-tooltip-content="Delete selected"
                                                                onClick={() => _deletePermission(index)}
                                                                onMouseEnter={() => {
                                                                    setShowDeleteRed(index);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setShowDeleteRed(-1);
                                                                }}>
                                                                <Icon icon={showDeletedRed === index ? 'delete-red' : "delete"} size={24} className='me-2' />
                                                                Delete
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <CustomSearchSelectBox label="Role"
                                                                value={item.module}
                                                                placeholder=""
                                                                error={item.error === true && item.module === '' ? 'Role required' : ''}
                                                                options={permissionOptions}
                                                                postfix="*"
                                                                onSelectChange={(value) => _handleRoleChange(value, index)} />
                                                        </div>
                                                        <div className="col-sm-6"
                                                            data-disabled={item.module === '' ? 'true' : 'false'}>
                                                            <CustomSearchSelectBox label="Action"
                                                                value={item.permission}
                                                                placeholder=""
                                                                error={item.error === true && item.permission === '' ? 'Action required' : ''}
                                                                options={item.module.permissions}
                                                                postfix="*"
                                                                onSelectChange={(value) => _handleActionChange(value, index)} />
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="col-12 d-flex justify-content-center mt-1 mb-5">
                                                {/* <span className="color-black e-font-14 e-poppins-regular me-2">No permissions added,</span> */}
                                                <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer" onClick={_handleAddNew}>
                                                    <Icon icon="add-circle1" size={24} className='me-2' />
                                                    Add new
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    adminPermissions.length !== 0 && adminPermissions.length < permissionOptions.length &&(
                                        <div className="col-12 d-flex justify-content-end mt-1">
                                            <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer" onClick={_handleAddNew}>
                                                <Icon icon="add-circle1" size={24} className='me-2' />
                                                Add new
                                            </span>
                                        </div>
                                    )
                                }

                                <div className="d-flex  justify-content-center mt-3 w-100">

                                    <SecondaryButton label="Cancel"
                                        className="padding-12px-tb e-font-16 "
                                        cancel="modal"
                                    />
                                    <GradientButton label="Update Permission"
                                        className="padding-12px-tb e-font-16 ms-3"
                                        loading={buttonLoader}
                                        onPress={_validate}
                                    />
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )

}

ManagePermissionModal.defaultProps = {

}

export default ManagePermissionModal;
