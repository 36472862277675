
/*
 *   File : title.js
 *   Author URI : https://evoqins.com
 *   Description : section title
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment } from "react";


export default function SectionTitle(props) {

    return (
        <Fragment>
            <h4 className={`color-black e-font-16 e-poppins-semi-bold line-height-24px mb-0 ${props.className}`}>
                {props.title}
            </h4>
        </Fragment>
    )
}

SectionTitle.defaultProps = {
    title: "",
    className: ""
}