/*
 *   File : quiz.js
 *   Author URI : https://evoqins.com
 *   Description : UI component to Quiz accordion
 *   Integrations : null
 *   Version : v1.1
 */

/*import packages*/
import { Fragment, useState } from 'react';

/*import components*/
import Icon from "../Icon/icon";

/*import styles*/
import style from '../../Styles/Components/quiz.module.scss';


const QuizAccordion = (props) => {
    const [init, setInit] = useState(-1);
    const [showDeletedRed, setShowDeletedRed] = useState(-1);

    const _handleToggle = (index) => {
        setInit(index)
    }
    return (
        <Fragment>

            <div className={`${style.e_faq_accordion}`}>
                <div className="accordion" id="accordionDescription">
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 === 0) {
                                        return (
                                            <div key={key} className='col-12'>
                                                {
                                                    init === key ?

                                                        <div className={`${style.e_faq_accordion_item_active}`} >
                                                            <h6 className='mb-0' id={`heading-${key}`} onClick={() => {
                                                                _handleToggle(null, true)
                                                            }}>
                                                                <button
                                                                    className={key === 0 && init === key ?
                                                                        `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 color-primary-color text-capitalized line-height-24px `
                                                                        : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse-${key}`}
                                                                    aria-expanded={key === 0 && init === key ? "true" : "false"}
                                                                    aria-controls={`collapse-${key}`}>
                                                                    {item.question}

                                                                    <div className={`${style.e_accordion_arrow}`}>
                                                                        <img draggable={false} src={require("../../Assets/Images/Quiz/minus.svg").default} alt='faqarrow' />
                                                                    </div>
                                                                </button>
                                                            </h6>

                                                            <div id={`collapse-${key}`} aria-labelledby={`heading-${key}`}
                                                                data-bs-parent="#faq-accordion">


                                                                <p className='color-outer-space line-height-24px e-poppins-regular e-font-16  mt-3'>
                                                                    {item.answer}
                                                                </p>
                                                                {props.permissionType !== 'read' && <div className='d-flex'>
                                                                    <p className='color-primary-color line-height-16px e-poppins-regular e-font-16  my-0 cursor-pointer'
                                                                        onClick={() => props.updateFaq(item)}>
                                                                        Edit
                                                                        <Icon icon="Pen-square"
                                                                            size={24}
                                                                            className="ms-1" />
                                                                    </p>
                                                                    <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                                                                        onClick={() => props.removeData(item)}
                                                                        data-tooltip-content="Delete selected"
                                                                        placement="bottom"
                                                                        onMouseEnter={() => {
                                                                            setShowDeletedRed(key);
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setShowDeletedRed(-1);
                                                                        }}>
                                                                        Remove


                                                                        <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                                                                            size={24}
                                                                        />

                                                                    </p>


                                                                </div>}

                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    init !== key ?

                                                        <div className={`${style.e_faq_accordion_item} p-3`} onClick={() => {
                                                            _handleToggle(key)
                                                        }}>
                                                            <h6 className='mb-0' id={`heading-${key}`}>
                                                                <button
                                                                    className={key === 0 && init === key ? `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 colour-eerie-black text-capitalized line-height-24px ` : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse-${key}`}
                                                                    aria-expanded={key === 0 && init === key ? "true" : "false"}
                                                                    aria-controls={`collapse-${key}`}
                                                                >
                                                                    {item.question}

                                                                    <div className={`${style.e_accordion_arrow}`}>
                                                                        <img draggable={false} src={require("../../Assets/Images/Quiz/add.svg").default} alt='faqarrow' />
                                                                    </div>
                                                                </button>
                                                            </h6>


                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 !== 0) {
                                        return (
                                            <div key={key} className='col-12'>
                                                {
                                                    init === key ?

                                                        <div className={`${style.e_faq_accordion_item_active} p-3`} >
                                                            <h6 className='mb-0' id={`heading-${key}`} onClick={() => {
                                                                _handleToggle(null, true)
                                                            }}>
                                                                <button
                                                                    className={key === 0 && init === key ? `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 colour-eerie-black text-capitalized line-height-24px ` : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse-${key}`}
                                                                    aria-expanded={key === 0 && init === key ? "true" : "false"}
                                                                    aria-controls={`collapse-${key}`}
                                                                >
                                                                    {item.question}

                                                                    <div className={`${style.e_accordion_arrow}`}>
                                                                        <img draggable={false} src={require("../../Assets/Images/Quiz/minus.svg").default} alt='faqarrow' />
                                                                    </div>
                                                                </button>
                                                            </h6>

                                                            <div id={`collapse-${key}`} aria-labelledby={`heading-${key}`}
                                                                data-bs-parent="#faq-accordion">


                                                                <p className='color-outer-space line-height-24px e-poppins-regular e-font-16  mt-3'>
                                                                    {item.answer}
                                                                </p>
                                                                {
                                                                    props.permissionType !== 'read' && (
                                                                        <div className='d-flex'>
                                                                            <p className='color-primary-color line-height-16px e-poppins-regular e-font-16  my-0 cursor-pointer' onClick={() => props.updateFaq(item)}>
                                                                                Edit
                                                                                <Icon icon="Pen-square"
                                                                                    size={24}
                                                                                    className="ms-1" />
                                                                            </p>
                                                                            <p className={`${showDeletedRed === key ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-16  my-0 ps-3 e-tool-tip-container position-relative h-fit-content`}
                                                                                onClick={() => props.removeData(item)}
                                                                                data-tooltip-content="Delete selected"
                                                                                placement="bottom"
                                                                                onMouseEnter={() => {
                                                                                    setShowDeletedRed(key);
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setShowDeletedRed(-1);
                                                                                }}>
                                                                                Remove


                                                                                <Icon icon={showDeletedRed === key ? "delete-red" : "delete"}
                                                                                    size={24}
                                                                                />

                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    init !== key ?

                                                        <div className={`${style.e_faq_accordion_item} p-3`} onClick={() => {
                                                            _handleToggle(key)
                                                        }}>
                                                            <h6 className='mb-0' id={`heading-${key}`}>
                                                                <button
                                                                    className={key === 0 && init === key ? `${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-font-16 e-xs-font-14 colour-eerie-black text-capitalized line-height-24px ` : `collapsed ${style.e_button} ${props.headingClass} pe-3 e-poppins-semi-bold e-xs-font-14 e-font-16 colour-eerie-black text-capitalized line-height-24px `}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse-${key}`}
                                                                    aria-expanded={key === 0 && init === key ? "true" : "false"}
                                                                    aria-controls={`collapse-${key}`}
                                                                >
                                                                    {item.question}

                                                                    <div className={`${style.e_accordion_arrow}`}>
                                                                        <img draggable={false} src={require("../../Assets/Images/Quiz/add.svg").default} alt='faqarrow' />
                                                                    </div>
                                                                </button>
                                                            </h6>


                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>



                {/* <DeleteConfirmationModal

                            content="Are you sure, you want to delete this faq?"

                            close={() => {

                            }}
                        /> */}
            </div>

        </Fragment>
    )
}

QuizAccordion.defaultProps = {
    data: []
}

export default QuizAccordion