/*
 *   File : goal-order-details.js
 *   Author URI : www.evoqins.com
 *   Description : Order details for Goal.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 12-12-2023
 */

import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { OrderFunds } from "../../../Components/Accordion"
import { NavigationHeader } from "../../../Components/Header"
import { Icon } from "../../../Components/Icon";
import { CustomLoader } from "../../../Components/Other";
import { BreadCrumb } from "../../../Components/Breadcrumb";

import Color from "../../../Styles/color.module.scss";

import { _getOrderDetail } from "../../../Helper/api";

const PAGE_NAVIGATION = [
    'Dashboard',
    'Orders',
    'Order details'
];

const AdminGoalOrderDetail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [orderDetail, setOrderDetail] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetail(location.state.customer_id, location.state.order_id).then((response) => {
                setOrderDetail(response)
                setLoader(false);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
                navigate(-1)
            });
        }
        //eslint-disable-next-line
    }, [location])

    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../../Assets/Images/Orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../../Assets/Images/Orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../../Assets/Images/Orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../../Assets/Images/Orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>
        )
    }

    return (
        loader === true ?
            <div className="row h-80vh">
                <CustomLoader />
            </div>
            :
            <Fragment>
                {/* Header */}
                <NavigationHeader type={1}
                    data={PAGE_NAVIGATION}
                    title="Order details" />

                <div className="e-goal-order-summary ps-4 pe-lg-5 pe-md-2 pe-2 pt-2">
                    <BreadCrumb data={PAGE_NAVIGATION}/>
                    {/* <div className="d-flex align-items-center gap-8px padding-12px-all mb-3 cursor-pointer w-fit-content"
                        onClick={() => navigate(-1)}>
                        <Icon icon="arrow-right"
                            size={24}
                            color={Color.black}
                            className="e-arrow-right-180" />
                        <p className="color-black e-poppins-regular e-font-16 line-height-14px mb-0">Back</p>
                    </div> */}
                    <div className="row">
                        <div className="col-xl-9 ">
                            <div className="border-radius-16px border-all border-bright-gray p-4" >
                                <div className="d-flex justify-content-between align-items-center padding-18px-bottom border-bottom-1px border-bright-gray">
                                    <div className="d-flex align-items-center gap-12px">
                                        <div className="border-all border-bright-gray border-radius-8px py-2 padding-20px-lr">
                                            <img draggable={false} src={orderDetail.summary.image}
                                                alt="Quant"
                                                width={40}
                                                height={40}
                                                className="object-fit-contain " />
                                        </div>
                                        <div>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">
                                                {orderDetail.summary.name}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="color-black e-poppins-medium e-font-24 line-height-26px mb-0">
                                        <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString('en-IN')}
                                    </p>
                                </div>

                                <div className="row my-3">
                                    <div className="col-xl-4 col-lg-6">
                                        <Description title="Order id:" description={orderDetail.summary.order_no} />
                                    </div>
                                    <div className="col-xl-8 col-lg-6">
                                        <Description title="Order type:" description={orderDetail.summary.type} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-6">
                                        <Description title="Placed on:" description={orderDetail.summary.order_date+' '+orderDetail.summary.order_time} />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-xl-4 col-lg-6">
                                        <Description title="Order status:" description={orderDetail.summary.order_status}
                                            status={true} />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-11">
                                        <OrderFunds data={orderDetail}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default AdminGoalOrderDetail