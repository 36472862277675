
/*
 *   File : user-filter.js 
 *   Author : https://evoqins.com
 *   Description : UI element which is used to do the user filter.
 *   Integrations : null
 *   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react";
import { GradientButton, OutlineButton } from "../Cta"
import { CheckBoxGroup } from "../FormElements"
import { Icon } from "../Icon";

const UserFilter = (props) => {

    const [accountType, setAccountType] = useState([]);
    const [status, setStatus] = useState([]);

    useEffect(() => {
        setAccountType(props.accountType);
        setStatus(props.status)

    }, [props.accountType, props.status])

    // clearing all filter
    const _handleClose = () => {
        props.close()
    }

    const _handleClearFilter = () => {
        setAccountType([props.data.account_type[0].id]);
        setStatus([props.data.status[0].id]);
    }

    // handler for all checkboxes
    const _handlerCheckBoxIcon = (type, selected_item) => {
        switch (type) {
            case 1:
                _manageCheckBox(accountType, selected_item, type);
                break;
            case 2:
                _manageCheckBox(status, selected_item, type);
                break;
        }
    }
    // helper function for managing the filtered data
    const _manageCheckBox = (array_to_be_modified, selected_filter_type, type) => {
        let filter_type = [...array_to_be_modified];


        let selected_type_index = filter_type.findIndex(item => item === selected_filter_type);
        if (selected_type_index !== -1) {
            filter_type.splice(selected_type_index, 1);
        } else {
            filter_type.push(selected_filter_type);
            if (selected_filter_type !== null) {
                if (filter_type.includes(null) && filter_type.length > 1) {
                    filter_type = filter_type.filter((item) => item !== null)
                }
            } else {
                filter_type = [null]
            }
        }


        switch (type) {
            case 1:
                if (filter_type.length === props.data.account_type.length - 1) {
                    filter_type = [null]
                }
                setAccountType(filter_type);
                break;
            case 2:
                if (filter_type.length === props.data.status.length - 1) {
                    filter_type = [null]
                }
                setStatus(filter_type);
                break;
        }



    };

    const _applyFilter = () => {
        props.applyFilter(accountType, status)
    }

    return (
        <div className="border-radius-16px z-index-9 border-all border-bright-gray box-shadow-filter padding-24px-all pe-1 bg-white position-absolute right-0 top-8px w-max-content">

            <div className="position-relative e-filter-popup-height pe-4">
                <Icon icon="close"
                    size={24}
                    className="position-absolute e-close top-0 right-20px cursor-pointer e-modal-close-btn"
                    onClick={_handleClose} />
                <div className="container-fluid">
                    <div className="row">

                        <Fragment>
                            {/* admin user */}
                            <div className="bg-transparent">
                                {/* Account type */}
                                <div className="border-bottom-1px border-bright-gray pb-3">
                                    <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Account type</p>
                                    <div className="d-flex flex-column gap-12px e-fund-category">

                                        {
                                            props.data.account_type.map((item, key) =>
                                                <CheckBoxGroup item={item}
                                                    key={key}
                                                    selectedType={accountType}
                                                    onHandleClick={(id) => _handlerCheckBoxIcon(1, id)} />

                                            )
                                        }

                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Status</p>
                                    <div className="d-flex flex-column gap-12px e-fund-category">

                                        {
                                            props.data.status.map((item, key) =>
                                                <CheckBoxGroup item={item}
                                                    key={key}
                                                    selectedType={status}
                                                    onHandleClick={(id) => _handlerCheckBoxIcon(2, id)} />

                                            )
                                        }

                                    </div>
                                </div>

                            </div>
                        </Fragment>

                    </div>
                </div>
            </div>

            {/* CTA buttons */}
            <div className="d-flex gap-16px justify-content-end  mt-3 pt-3 border-top-1px border-bright-gray pe-4">
                <GradientButton label="Apply filters"
                    className="e-font-16 padding-12px-tb px-3"
                    hide_loader={true}
                    onPress={_applyFilter} />
                <OutlineButton label="Clear filters"
                    className="e-font-16"
                    onPress={_handleClearFilter} />
            </div>

        </div>
    )
}

export default UserFilter