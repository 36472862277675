/*
 *   File : block-admin.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while blocking admin.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { GradientButton, SecondaryButton } from "../Cta";
import { Icon } from "../Icon";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";

const BlockAdminModal = (props) => {
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("block-admin");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, []);





    const _handleConfirm = () => {
        setLoader(true);
        const url = "auth/toggle-active";

        const data = {
            admin_id: props.admin_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.confirm();
                closeModal('block-admin');
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="block-admin"
            tabIndex="-1"
            aria-labelledby="block-admin"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <img draggable={false} src={require("../../Assets/Images/Modal/block.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                    />


                    <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        Are you sure you want to {props.block_status === true ? 'un block' : 'block'} sub-admin?
                    </h6>
                    {/* <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </p> */}

                    <div className="d-flex  justify-content-center margin-32px-top margin-32px-bottom w-100">

                        <SecondaryButton label="Cancel"
                            className="padding-12px-tb e-font-16 "
                            cancel="modal"
                        />
                        <GradientButton label={props.block_status ?
                            "Yes, unblock" :
                            "Yes, block"}
                            className="padding-12px-tb e-font-16 ms-3"
                            loading={loader}
                            onPress={_handleConfirm} />
                    </div>
                </div>
            </div>
        </div>
    )
}


BlockAdminModal.defaultProps = {
    close: () => { }
}

export default BlockAdminModal