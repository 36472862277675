/*
 *   File : manage-fund.js
 *   Author URI : https://evoqins.com
 *   Description : Manage Fund Modal
 *   Integrations : null
 *   Version : v1.1
 */
/* import packags */
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

/* import components */
import Icon from "../Icon/icon";
import { GradientButton, OutlineButton, SecondaryButton } from "../Cta";
import { SearchInput, CustomTextInput } from "../FormElements";


/* import styles */
import style from "../../Styles/Components/fund.module.scss"

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import { _searchMF } from "../../Helper/api";
import APIService from "../../Services/api-service";


const ManageFundModal = (props) => {
    const [showList, setShowList] = useState(false);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [fundsSelected, setFundsSelected] = useState(false);
    const [showRedDeleteIcon, setShowRedDeleteIcon] = useState(null);
    const [fund, setFund] = useState({});
    const [fundList, setFundList] = useState([]);
    const [fundName, setFundName] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [deleteIds, setDeleteIds] = useState([]);
    const [errorIndex, setErrorIndex] = useState([]);

    useEffect(() => {
        if (props.fund !== null && props.fund.length > 0) {
            setFundsSelected(true);
            setShowList(true);
            setSelectedFunds(props.fund);
        } else {
            setFundsSelected(false);
            setShowList(false);
        }
    }, [props.fund])

    useEffect(() => {
        var my_modal = document.getElementById("manage-fund");
        my_modal.addEventListener("hidden.bs.modal", () => {
            setShowList(false);
            setSelectedFunds([]);
            setFundsSelected(false);
            props.closeModal()
        });
        //eslint-disable-next-line
    }, []);

    const _handleSelectFunds = (fund_obj) => {

        let selected_funds = [...selectedFunds];
        const fund_index = selected_funds.findIndex((item) => item.id === fund_obj.id);

        if (fund_index < 0) {
            // Create a new object for the fund to be added
            let newFund = { ...fund_obj, allocation: '' };
            selected_funds.push(newFund);
        } else {
            // Remove the fund from the array
            selected_funds.splice(fund_index, 1);
        }

        setSelectedFunds(selected_funds);
        setFund({});

    };

    const _handleDeleteFunds = (fund_obj) => {
        let selected_funds = [...selectedFunds];
        const fund_index = selected_funds.findIndex((item) => item.id === fund_obj.id);
        let delete_ids = [...deleteIds];
        let data_is_listed = props.fund.findIndex((item) => item.id === fund_obj.id);
        if (data_is_listed > -1) {
            delete_ids.push(fund_obj.id);
            setDeleteIds(delete_ids);
        }
        

        if (fund_index < 0) {
            // Create a new object for the fund to be added
            let newFund = { ...fund_obj };
            selected_funds.push(newFund);
        } else {
            // Remove the fund from the array
            selected_funds.splice(fund_index, 1);
        }

        setSelectedFunds(selected_funds);
        setFund({});


    };


    const _handleMouseEnter = (id) => {
        setShowRedDeleteIcon(id);
    }

    const _handleDeleteEnter = () => {
        setShowRedDeleteIcon(null);
    }


    const _handleAllocation = (index, input_value) => {
        let selected_funds = [...selectedFunds];
        let error_field = [...errorIndex];
        let error_index = error_field.findIndex(item => item === index);
        error_field.splice(error_index, 1);
        setErrorIndex(error_field);
        // Create a new object by spreading the properties of the original object
        let updatedFund = { ...selected_funds[index] };
        // Update the allocation property of the new object
        if (input_value <= 100) {
            updatedFund.allocation = parseFloat(input_value);
        }
        // Replace the old object with the new one in the array
        selected_funds[index] = updatedFund;
        setSelectedFunds(selected_funds);
    };


    const _getTotalAllocation = () => {
        let allocation = `${selectedFunds.filter((item) => !isNaN(item.allocation)).reduce((a, b) => a + b.allocation, 0)}`;
        return allocation;
    }

    const _handleAddFund = () => {
        let error_indices = selectedFunds
            .map((item, index) => ((isNaN(item.allocation) || item.allocation === '') ? index : -1))
            .filter(index => index !== -1);
        if (error_indices.length !== 0) {
            setErrorIndex(error_indices);
            return;
        }

        let allocation = _getTotalAllocation();
        if (props.total_allocation === 100 && props.fund === null) {
            toast.dismiss();
            toast.error('Total Asset allocation is 100%', {
                type: "error"
            });
        } else {
            if (parseInt(allocation) === 100) {
                _addFund();
            } else {
                toast.dismiss();
                toast.error('Asset allocation should be 100%', {
                    type: "error"
                });


            }
        }
    }
    const _addFund = () => {
        const url = "fund/add";

        setApiLoader(true);
        let selected_mfs = []
        selectedFunds.map((item) => {
            return (
                selected_mfs.push({
                    "mf_id": item.mf_id ? item.mf_id : item.id,
                    "allocation": item.allocation,
                    "investment_style_id": props.investment_id
                })
            )
        })
        const request = {
            "funds": selected_mfs,
            "deleted_funds": props.fund !== null ? deleteIds.length > 0 ? deleteIds : [] : []
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                closeModal("manage-fund");
                props.addFund();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false)
        });

    }


    const _handleOpenConfirmation = (fund_obj) => {
        setFund(fund_obj);
    }

    // API - fund list
    const _showFundList = (query) => {
        const data = {
            query: query,
            page_num: 1
        }
        _searchMF(data).then((response) => {
            setFundList(response);
            setShowList(true);
        })

    }
    return (
        <>
            <div className={`modal fade ${style.e_fund_modal}`}
                id="manage-fund"
                tabIndex="-1"
                aria-labelledby="manage-fund"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content bg-white position-relative text-center d-flex align-items-center padding-32px-all ${style.e_content} w-100 `}>
                        <div className="col-12 d-flex justify-content-between">
                            <h3 className="color-black e-poppins-semi-bold e-font-18">
                                {
                                    fundsSelected ?
                                        Object.keys(fund).length > 0 ?
                                            null
                                            :
                                            props.fund === null
                                                ?
                                                "Add allocation"
                                                :
                                                "Manage allocations"
                                        :
                                        "Add funds"
                                }
                            </h3>
                            <Icon icon="close"
                                size={24}
                                className="cursor-pointer ms-auto e-modal-close-btn"
                                data-bs-dismiss="modal" />
                        </div>
                        {
                            fundsSelected ?
                                null
                                :
                                <div className="col-12 padding-32px-top">
                                    <SearchInput
                                        valueChange={(value) => {
                                            setFundName(value);
                                            if (value === "") {
                                                _showFundList("")
                                            }
                                        }}
                                        handleSearch={() => _showFundList(fundName)}
                                        onClick={() => _showFundList("")} />
                                </div>
                        }
                        {
                            showList ?
                                !fundsSelected ?
                                    <div className="col-12 pt-2">
                                        {
                                            fundList.length > 0 ?
                                                <div className={style.e_fund_section}>
                                                    {

                                                        fundList.map((item, index) => {
                                                            return (
                                                                <div key={index}
                                                                    className={selectedFunds.some((fund) => fund.id === item.id) ?
                                                                        `${style.e_fund_list} row d-flex justify-content-between py-2 padding-14px-lr cursor-pointer mb-2 bg-alice-blue`
                                                                        :
                                                                        `${style.e_fund_list} row d-flex justify-content-between py-2 padding-14px-lr cursor-pointer mb-2`
                                                                    }
                                                                    onClick={() => _handleSelectFunds(item)}>
                                                                    <div className="d-flex align-items-center col-10 pe-0">
                                                                        <img draggable={false} src={require("../../Assets/Images/Funds/line.svg").default}
                                                                            alt="fundimage"
                                                                            className="" />
                                                                        <span className={selectedFunds.some((fund) => fund.id === item.id) ? `color-primary-color e-font14 e-poppins-regular line-height-24px margin-10px-left text-start` : `${style.e_fund_name} e-font14 e-poppins-regular line-height-24px margin-10px-left text-start`}>
                                                                            {item.name}
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-end col-2 pe-0">
                                                                        {
                                                                            selectedFunds.some((fund) => fund.id === item.id) ?
                                                                                <>
                                                                                    <Icon icon="close-without-circle"
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                        className="me-1" />
                                                                                    <span className={`e-font-12 e-poppins-semi-bold line-height-12px`}>
                                                                                        Remove
                                                                                    </span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Icon icon="add-circle1"
                                                                                        width="16px"
                                                                                        height="16px"
                                                                                        className="me-1" />
                                                                                    <span className={`${style.e_add_text} e-font-12 e-poppins-regular line-height-12px`}>
                                                                                        Add fund
                                                                                    </span>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </div>
                                                :

                                                <div className={`${style.e_fund_section} overflow-hidden`}>
                                                    <img draggable={false} src={require("../../Assets/Images/Funds/search.png")}
                                                        alt="search funds"
                                                        className={style.e_placeholder} />
                                                </div>
                                        }
                                        {
                                            selectedFunds.length !== 0 &&
                                            <GradientButton label="Continue"
                                                className="d-flex padding-12px-tb padding-24px-lr ms-auto"
                                                onPress={() => {
                                                    if (props.manage_scheme) {
                                                        closeModal("manage-fund");
                                                        props.closeModal();
                                                        props.updateFeatureFund(selectedFunds);
                                                    } else {
                                                        setFundsSelected(true);
                                                    }
                                                }} />
                                        }
                                    </div>
                                    :
                                    <div className="w-100">
                                        {
                                            Object.keys(fund).length > 0 ?
                                                <>
                                                    <img draggable={false} src={require("../../Assets/Images/Modal/delete-icon.png")}
                                                        alt="Delete account"
                                                        width={72}
                                                        height={72} />

                                                    <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                                                        Are you sure you want to remove this fund. {fund.name}
                                                    </p>

                                                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  justify-content-center px-5">
                                                        <OutlineButton label="No, cancel"
                                                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                                                            onPress={() => setFund({})} />
                                                        <GradientButton label="Yes, delete"
                                                            className="padding-12px-tb e-font-16  "
                                                            loading={deleteLoader}
                                                            onPress={() => {
                                                                _handleDeleteFunds(fund);
                                                            }} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={style.e_fund_section}>
                                                        <div className="col-12 text-start padding-28px-top">
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <p className="e-font-12 e-poppins-regular line-height-14px color-outer-space my-0">
                                                                        Investment type
                                                                    </p>
                                                                    <h6 className="e-poppins-bold e-font-14 line-height-18px color-light-black mb-0 mt-2">
                                                                        {props.investment_type}
                                                                    </h6>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="e-font-12 e-poppins-regular line-height-14px color-outer-space my-0">
                                                                        Total asset class allocation
                                                                    </p>
                                                                    <h6 className="e-poppins-bold e-font-14 line-height-18px color-light-black mb-0 mt-2">
                                                                        {_getTotalAllocation()}%
                                                                        {/* {props.totalAllocation}% */}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            {
                                                                selectedFunds.length > 0 &&
                                                                <>
                                                                    <div className="row padding-24px-top mb-2">
                                                                        <div className="col-7">
                                                                            <p className="e-font-14 e-poppins-regular line-height-18px color-outer-space my-0">
                                                                                Selected Funds
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <p className="e-font-14 e-poppins-regular line-height-18px color-outer-space my-0">
                                                                                Allocation
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-2">

                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        selectedFunds.map((item, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                    className="row pt-3 d-flex align-items-center mb-2">
                                                                                    <div className="col-7">
                                                                                        <p className="e-font-14 e-poppins-semi-bold line-height-24px color-black my-0">
                                                                                            {item.name}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <p className="e-font-14 e-poppins-regular line-height-18px color-outer-space my-0">
                                                                                            <CustomTextInput
                                                                                                value={item.allocation}
                                                                                                placeholder="allocation"
                                                                                                type="number"
                                                                                                is_decimal={true}
                                                                                                suffix="%"
                                                                                                error={errorIndex.indexOf(index) > -1 ? 'Required' : null}
                                                                                                valueChange={(input_value) => _handleAllocation(index, input_value)} />
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-2 text-center">
                                                                                        <span className=" position-relative e-tool-tip-container"
                                                                                            data-tooltip-content="Delete fund">
                                                                                            <Icon icon={showRedDeleteIcon === item.id ? "delete-red" : "delete"}
                                                                                                size={24}
                                                                                                className="cursor-pointer"
                                                                                                onClick={() => _handleOpenConfirmation(item)}
                                                                                                onMouseEnter={() => _handleMouseEnter(item.id)}

                                                                                                onMouseLeave={_handleDeleteEnter} />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`d-flex ${props.fund !== null ? "justify-content-between gap-16px mt-3" : "justify-content-between mt-3"}`}>
                                                        {

                                                            props.fund === null ?
                                                                <Fragment>
                                                                    <OutlineButton label="Add funds"
                                                                        className="padding-12px-tb padding-24px-lr "
                                                                        onPress={() => {
                                                                            setFundsSelected(false);

                                                                        }} />
                                                                    <div>
                                                                        <SecondaryButton label="Cancel"
                                                                            cancel="modal" />
                                                                        <GradientButton label="Add"
                                                                            className="padding-12px-tb padding-24px-lr ms-3"
                                                                            loading={apiLoader}
                                                                            onPress={() => {
                                                                                _handleAddFund()
                                                                                setFundsSelected(true);
                                                                            }} />
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    <OutlineButton label="Add funds"
                                                                        className="padding-12px-tb padding-24px-lr "
                                                                        onPress={() => setFundsSelected(false)} />
                                                                    <div className="d-flex">
                                                                        <SecondaryButton label="Cancel"
                                                                            cancel="modal" />

                                                                        <GradientButton label="Update"
                                                                            className="padding-12px-tb padding-24px-lr ms-3"
                                                                            loading={apiLoader}
                                                                            onPress={() => {
                                                                                _handleAddFund()
                                                                                setFundsSelected(true);
                                                                            }} />
                                                                    </div>
                                                                </Fragment>
                                                        }
                                                    </div>
                                                    {/* <div className="row" >
                                                        <div className="col-6 d-flex justify-content-start">
                                                            {

                                                                props.fund === null ?
                                                                    <OutlineButton label="Add funds"
                                                                        className="padding-12px-tb padding-24px-lr "
                                                                        onPress={() => {
                                                                            setFundsSelected(false);

                                                                        }} />
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-6 d-flex justify-content-end gap-16px">
                                                            <SecondaryButton label="Cancel"
                                                                cancel="modal" />
                                                            <OutlineButton label="Add funds"
                                                                className="padding-12px-tb padding-24px-lr "
                                                                onPress={() => setFundsSelected(false)} />
                                                            <GradientButton label="Update"
                                                                className="padding-12px-tb padding-24px-lr"
                                                                onPress={() => {
                                                                    _handleAddFund()
                                                                    setFundsSelected(true);
                                                                }} />
                                                        </div>
                                                    </div> */}
                                                </>
                                        }

                                    </div>
                                :
                                <div className={`${style.e_fund_section} overflow-hidden`}>
                                    <img draggable={false} src={require("../../Assets/Images/Funds/search.png")}
                                        alt="search funds"
                                        className={style.e_placeholder} />
                                </div>
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

ManageFundModal.defaultProps = {
    closeModal: () => { },
    investment_type: ""
}

export default ManageFundModal;