/*
 *   File : manage-info.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks adding Info.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { Icon } from "../Icon";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";
import { CustomTextInput, Editor } from "../FormElements";
import { GradientButton, PrimaryButton, SecondaryButton } from "../Cta";
import { closeModal } from "../../Helper/helper-function";
import { _addContent } from "../../Helper/api";
/* import helper */

const ManageInfo = (props) => {
    const [content, setContent] = useState('');
    const [contentError, setContentError] = useState('');
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (props.data !== null) {
            setContent(props.data.content);
        }
    }, [props.data])

    useEffect(() => {
        var my_modal = document.getElementById("manage-info");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setContentError('');
            setContent('');
        });
        //eslint-disable-next-line
    }, [props.close]);


    function _handleContent(value) {
        setContent(value);
        setContentError('');
    }

    function _handleValidate() {
        if (content.length === 0) {
            setContentError('Content is required');
        }
        else if (content.length > 200) {
            setContentError('Content length should be less than 200 chars.');
        }
        else  {
            _addInfo()
        }
    }
    const _addInfo = () => {
        setApiLoader(true);
        const data = {
            "content_id": props.data !== null ? props.data.id : null,
            "content_type_id": 1,
            "heading": "",
            "content": content,
            "page": props.data.page,
            "section": props.data.section

        }
        _addContent(data).then((response) => {
            setApiLoader(false);
            closeModal('manage-info');
            props.contentAdded()
        })

    }

    return (
        <div className={`modal fade ${style.e_approve_kyc} e-manage-info`}
            id="manage-info"
            tabIndex="-1"
            aria-labelledby="manage-info"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            {props.data !== null ? 'Edit content' : 'Add Content'}
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>



                    <div className="col-12 pt-4">
                        {
                            contentError.length === 0 ? 
                                <label className={`color-light-black e-font-14 e-poppins-regular line-height-14px pb-3`}>Content*</label>
                            :
                                <label className={`color-red e-font-14 e-poppins-regular line-height-14px pb-3`}>{contentError}</label>
                        }
                        
                        <Editor value={content}
                            editorChange={(editor_data) => _handleContent(editor_data)} />
                    </div>

                    <div className="d-flex justify-content-center w-100 margin-40px-top">
                        <SecondaryButton label="Cancel"
                            cancel="modal" />
                        <GradientButton label="Update content"
                            className='ms-3'
                            loading={apiLoader}
                            onPress={_handleValidate} />

                    </div>

                </div>

            </div>
        </div>
    )
}


ManageInfo.defaultProps = {
    close: () => { }
}

export default ManageInfo 