
/*
 *   File : create-kyc.js
 *   Author URI : www.evoqins.com
 *   Description : Create KYC request
 *   Integrations : null
 *   Version : v1.0
 *   Created : 02-07-2024
 */

// import package
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { GradientButton, OutlineButton } from "../../Components/Cta";
import { NavigationHeader } from "../../Components/Header";
import { SectionTitle } from "../../Components/Title";
import { CustomSearchSelectBox, CustomTextInput, CustomPhoneInput } from "../../Components/FormElements";
import { Icon } from "../../Components/Icon";
import { KYCRequestSuccess } from "../../Components/Modal";

// import api services
import { _getAdminTypes, _getPermissions, _getZone } from "../../Helper/api";
import APIService from "../../Services/api-service";

export default function CreateKYCRequest(props) {

    const navigate = useNavigate();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [zoneOptions, setZoneOptions] = useState([]);
    const [selectedZone, setSelectedZone] = useState({});
    const [selectedZoneError, setSelectedZoneError] = useState("");
    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedRegionError, setSelectedRegionError] = useState("");
    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedBranchError, setSelectedBranchError] = useState("");
    const [managerOptions, setManagerOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState({});
    const [selectedManagerError, setSelectedManagerError] = useState("");
    const [KYCLink, setKYCLink] = useState("");
    const [openLinkModal, setOpenLinkModal] = useState(false);

    useEffect(() => {
        if (ADMIN_PROFILE_DATA.admin_type_id === 2) {
            _fetchZoneList();
        }

        if (ADMIN_PROFILE_DATA.admin_type_id === 6) {
            _getManagerList();
        }
    }, [ADMIN_PROFILE_DATA]);

    useEffect(()=>{
        if (openLinkModal === true ) {
            const modal = new Modal(document.getElementById("kyc-request-success"));
            modal.show();
        }
    },[openLinkModal]);


    // navigate to listing
    function _navigateToListing() {
        navigate('/kyc-request');
    }

    // handle name input change
    function _handleNameInput(value) {
        setName(value);
        setNameError("");
    }

    // handle phone input change
    function _handlePhoneInput(value) {
        setPhoneNumber(value);
        setPhoneNumberError("");
    }

    // handle email input change
    function _handleEmailInput(value) {
        setEmail(value);
        setEmailError("");
    }

    // selected zone dropdown handler
    function _selectZone(zone) {
        setSelectedZone(zone);
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedManager({});
        setSelectedZoneError('');
        const url = "client_mapping/region/list";
        const request_data = { "id": zone.value };
         // API - get region
        _fetchAndSetOptions(url, request_data, setRegionOptions);
    };

    // handle function region dropdown
    function _selectRegion (region) {
        setSelectedRegion(region);
        setSelectedBranch({});
        setSelectedManager({});
        setSelectedRegionError('');
        const url = "client_mapping/branch/list";
        const request_data = { "id": region.value };
         // API - get region
        _fetchAndSetOptions(url, request_data, setBranchOptions);
    }

    // handle function branch dropdown
    function _selectBranch (branch) {
        setSelectedBranch(branch);
        setSelectedManager({});
        setSelectedBranchError('');
        const url = "client_mapping/rm/list";
        const request_data = { "id": branch.value };
        _fetchAndSetOptions(url, request_data, setManagerOptions);
    }

    // handle function rm dropdown
    function _selectManager (rm) {
        setSelectedManager(rm);
        setSelectedManagerError('');
    }

    // Reset form 
    function _clearForm () {
        setName("");
        setPhoneNumber("");
        setEmail("");
        setSelectedZone({});
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedManager({});
    }

    // Call back function success modal close
    function _callBackURLModalClose () {
        setKYCLink("");
        setOpenLinkModal(false);
        _navigateToListing();
    }

    // API- helper function to fetch and set data for region, branch and manager
    async function _fetchAndSetOptions(url, request_data, setOptionsCallback){
        try {
            const response = await APIService(true, url, request_data);

            if (response.status_code === 200) {
                const mappedData = response.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                    code: item.code
                }));
                setOptionsCallback(mappedData);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
        }
    };

    // form validation
    function _validateForm() {
        let valid_form = true;
        if (name === "") {
            setNameError("Name is required");
            valid_form = false;
        }
        if (phoneNumber === "") {
            setPhoneNumberError('Phone number is required');
            valid_form = false;
        }
        else if (phoneNumber.length !== 10) {
            setPhoneNumberError("Invalid phone number");
            valid_form = false;
        }
        if (email === "") {
            setEmailError("Email is required");
            valid_form = false;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setEmailError("Invalid Email");
            valid_form = false;
        }
        if ((ADMIN_PROFILE_DATA.admin_type_id === 2 || ADMIN_PROFILE_DATA.admin_type_id === 6) && Object.keys(selectedManager).length === 0) {
            setSelectedManagerError('RM is required');
            valid_form = false;
        }
        if (valid_form === true) {
            _createKYCRequest();
        }
    }


    // API - get admin type list
    function _getManagerList() {
        const url = "client_mapping/rm/list";
        const request_data = { "id": ADMIN_PROFILE_DATA.admin_id };
        _fetchAndSetOptions(url, request_data, setManagerOptions);
    };

    // API - get zone list
    async function _fetchZoneList() {
        let data = await _getZone();
        const modified_data = data.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
        setZoneOptions(modified_data);
    }

    function _createKYCRequest () {
        setApiLoader(true);
        let url = "user/generate-ekyc-link";
        let payload = JSON.stringify({
            email,
            mobile: phoneNumber,
            name,
            code: ADMIN_PROFILE_DATA.admin_type_id <= 6 ? selectedManager.code: null,
            branch_id: selectedBranch?.value
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _clearForm();
                setKYCLink(response.data.url);
                setOpenLinkModal(true);
            }else{
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        })
    }
    return (
        <>
            {/* header */}
            <NavigationHeader type={1}
                title="Create new KYC request" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 ">
                <div className="row padding-32px-bottom">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="Create request" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <CustomTextInput label="Name"
                                    value={name}
                                    postfix="*"
                                    id="name-input"
                                    type="text"
                                    error={nameError}
                                    valueChange={_handleNameInput} />
                            </div>

                            <div className="col-12 mt-4 pt-3">
                                <CustomPhoneInput label="Phone"
                                    value={phoneNumber}
                                    postfix="*"
                                    id="phone-input"
                                    type="text"
                                    error={phoneNumberError}
                                    handleChange={_handlePhoneInput} />
                            </div>

                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Email"
                                    value={email}
                                    postfix="*"
                                    id="email-input"
                                    type="text"
                                    error={emailError}
                                    valueChange={_handleEmailInput} />
                            </div>
                            {/* Allowing select RM if branch admin */}
                            {
                                ADMIN_PROFILE_DATA.admin_type_id === 6 &&
                                <div className="col-12 mt-4 pt-3">
                                    <CustomSearchSelectBox label="RM"
                                        value={Object.keys(selectedManager).length === 0 ? "" : selectedManager}
                                        placeholder=""
                                        id="rm-input"
                                        error={selectedManagerError}
                                        options={managerOptions}
                                        postfix="*"
                                        onSelectChange={_selectManager} />
                                </div>
                            }

                            {/* Mapping user to RM if admin type is sub-admin */}
                            {
                                ADMIN_PROFILE_DATA.admin_type_id < 6 &&
                                <>

                                    <div className="col-12 mt-4 pt-3">
                                        <CustomSearchSelectBox options={zoneOptions}
                                            value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                            label="Select zone"
                                            error={selectedZoneError}
                                            id="admin-zone"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectZone} />
                                    </div>

                                    <div className="col-12 mt-4 pt-3">
                                        <CustomSearchSelectBox options={regionOptions}
                                            value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                            label="Select Region"
                                            error={selectedRegionError}
                                            hide_error_text={false}
                                            id="admin-region-input"
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectRegion} />
                                    </div>

                                    <div className="col-12 mt-4 pt-3">
                                        <CustomSearchSelectBox options={branchOptions}
                                            value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                            label="Select Branch"
                                            error={selectedBranchError}
                                            id="admin-branch-input"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectBranch} />
                                    </div>

                                    <div className="col-12 mt-4 pt-3">
                                        <CustomSearchSelectBox options={managerOptions}
                                            value={Object.keys(selectedManager).length === 0 ? "" : selectedManager}
                                            label="RM"
                                            error={selectedManagerError}
                                            id="admin-manager-input"
                                            hide_error_text={false}
                                            placeholder=""
                                            postfix="*"
                                            onSelectChange={_selectManager} />
                                    </div>
                                </>
                            }
                        </div>

                        <div className="row">
                            <div className="col-12 mt-4 pt-3">
                                <OutlineButton label="Cancel"
                                    onPress={_navigateToListing}
                                    className="me-3 color-black" />
                                <GradientButton label="Create & share"
                                    className="padding-12px-lr"
                                    loading={apiLoader}
                                    onPress={_validateForm} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                openLinkModal === true &&
                <KYCRequestSuccess url={KYCLink}
                    newRequest={true}
                    close={_callBackURLModalClose} />
            }
        </>
    )
}