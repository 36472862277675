/*
 *   File : fund-order.js
 *   Author URI : www.evoqins.com
 *   Description : Fund order data table
 *   Integrations : null
 *   Version : v1.0
 *   Created : 11-09-2023
 */

/* import packages */
import { useState } from "react";

/* import components */
import { DataTableContainer } from "../FormElements";
import { LinkText } from "../Cta";


/* import styles */
import Colors from '../../Styles/color.module.scss';


const SUMMARY_DATA = [{
    id: 1,
    admin: "Paul Walker",
    orders: 5,
    aum: "100Cr",
    users: 4,
    last_login_date: "09 Sep 2023"
},
{
    id: 2,
    admin: "Jack Nicholson",
    orders: 5,
    aum: "100Cr",
    users: 4,
    last_login_date: "09 Sep 2023"
},
{
    id: 3,
    admin: "Robert De Niro",
    orders: 5,
    aum: "100Cr",
    users: 4,
    last_login_date: "09 Sep 2023"
},
{
    id: 4,
    admin: "Daniel Day Lewis",
    orders: 5,
    aum: "100Cr",
    users: 4,
    last_login_date: "09 Sep 2023"
}]

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0px",
            borderRadius: "16px",
            background: Colors.white,
            border: `1px solid ${Colors.bright_gray}`,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: Colors.alice_blue,
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            padding: "16px 32px",
            color: Colors.black,
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            lineHeight: "24px",
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: "0 !important",
            borderTop: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "0",
            color: Colors.black,
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            lineHeight: "24px",
            padding: "16px 32px",
        },
    },
    cells: {
        style: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
};

const SummaryTable = () => {

    const [showArrow, setShowArrow] = useState(0);

    const SUMMARY_COLUMNS = [
        {
            name: 'Sl.no',
            selector: row => row['id'],
            sortable: false,
            left: true,
            // width: "576px",
            width: "90px",
            cell: row =>
                <div className="d-flex align-items-center e-fund-name">
                    <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 bg-white">{row['id']}</p>
                </div>

        },
        {
            name: 'Sub admins',
            selector: row => row['admin'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row => <span className="color-primary-color"> {row['admin']}</span>,
            style: {
                color: Colors.primary_color,
                fontFamily: "Poppins-Regular",
                fontSize: "16px",
                lineHeight: "24px",
            }
        },
        {
            name: 'No. of orders',
            selector: row => row['orders'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['orders']}
                </p>
        },
        {
            name: 'AUM',
            selector: row => row['aum'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['aum']}
                </p>
        },
        {
            name: 'Users',
            selector: row => row['users'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['users']}
                </p>
        },
        {
            name: 'Last login',
            selector: row => row['last_login_date'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['last_login_date']}
                </p>
        },

        {
            name: '',
            sortable: false,
            right: true,
            maxWidth: "100%",
            cell: row =>

                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}>
                    <LinkText title="View details"
                        icon="arrow-right"
                        className="d-flex" />
                </div>

        }

    ];


    const _handleCardClick = (row) => {
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    return (

        <DataTableContainer columns={SUMMARY_COLUMNS}
            data={SUMMARY_DATA}
            pagination={false}
            customStyles={TABLE_STYLE}
            onRowMouseEnter={_handleRowHover}
            onRowMouseLeave={_handleRowRemoveHover}
            rowClick={_handleCardClick} />

    )
}

export default SummaryTable