
/*
*   File : risk-assessment.js
*   Author URI : https://evoqins.com
*   Description : Risk assessment page
*   Integrations : null
*   Version : v1.1
*/

import React, { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { _getColorForInvestmentStyle } from "../../../../Helper/helper-function"
import { CustomLoader } from "../../../../Components/Other";
import APIService from "../../../../Services/api-service";

const RiskAssessment = React.memo((props) => {

    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [riskData, setRiskData] = useState({});

    useEffect(() => {
        _getRiskAssessment();
    }, [])

    const _getRiskAssessment = () => {
        let url = 'user/profile/get-risk';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setRiskData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        })
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="row padding-24px-top">
                <div className="col-8">
                    <div className="border-radius-16px border-all border-bright-gray bg-minty-cream p-4">
                        {
                            riskData.assessment_completed === false ? (
                                <p className="color-black e-poppins-regular e-font-16 line-height-22px mb-3">Risk assessment pending</p>
                            ) : (
                                <>
                                    <p className="color-black e-poppins-regular e-font-16 line-height-22px mb-3">Based on the risk analysis, user is currently using:</p>
                                    <div className="d-flex align-items-center gap-8px">
                                        <img draggable={false} src={riskData.image}
                                            alt={riskData.investment_style}
                                            width={48}
                                            height={24}
                                            className="object-fit-contain" />
                                        <p className="e-poppins-medium e-font-16 line-height-22px mb-0 mt-0"
                                            style={{ color: _getColorForInvestmentStyle(riskData.investment_style) }}>{riskData.investment_style}</p>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
    )
})

export default RiskAssessment