
/*
 *   File : preview.js
 *   Author URI : https://evoqins.com
 *   Description : File preview *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { Fragment, React, useState } from "react";

/*import components*/
import { Icon } from "../Icon";


const BannerCard = (props) => {
    const [showDeletedRed, setShowDeletedRed] = useState(false);

    return (
        <Fragment>
            <div className="border-radius-24px bg-snow mb-3 position-relative">
                {/* <Icon icon="Pen-square"
                    size={24}
                    className='position-absolute top-24px right-80 cursor-pointer'
                    onClick={() => {
                        props.editBanner()
                    }} /> */}
                <div className="row d-flex align-items-center">
                    <div className="col-12 pe-0">
                        {props.permissionType !== 'read' && <Icon icon={showDeletedRed ? "delete-red" : "delete"}
                            size={24}
                            className='position-absolute top-24px right-24 cursor-pointer'
                            onMouseEnter={() => {
                                setShowDeletedRed(true);
                            }}
                            onMouseLeave={() => {
                                setShowDeletedRed(false);
                            }}
                            onClick={() => {
                                props.removeBanner()
                            }} />}
                        <img draggable={false} src={props.data.image_url}
                            alt="preview"
                            height={'100%'}
                            width={"100%"} />
                        {
                            showDeletedRed === true ?
                                <span className='position-absolute top--10 end-0 cursor-pointer bg-white e-font-12 padding-6px-all'>Delete selected</span>
                                :
                                null
                        }
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

BannerCard.defaultProps = {
    data: {
        thumbnail: null,
        heading: "",
        content: "",
        banner: null,
    }
}

export default BannerCard;