/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";

import { GradientButton, SecondaryButton } from "../Cta";
import Icon from "../Icon/icon";
import { OTPInput } from "../FormElements";

import style from "../../Styles/Components/unlock.module.scss"

const UnlockSIPModal = (props) => {

    const firstPINref = useRef(null);

    const [pinString, setPinString] = useState("");

    const [errorMessage, setErrorMessage] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);

    // Focus the first input 
    // useEffect(() => {
    //     const my_modal = document.getElementById("unlock");
    //     my_modal.addEventListener("shown.bs.modal", () => {
    //         firstPINref.current.click();
    //     });
    // }, []);

    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("unlock");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setPinString('');
        });
    }, []);

    // update button loading status 
    useEffect(() => {
        setButtonLoader(props.loader);
    }, [props.loader]);

    useEffect(() => {
        const _handleKeyPress = (event) => {
            // Check if Ctrl key (or Command key on Mac) is pressed and 'a' key is pressed
            if (event.ctrlKey && event.key === 'a') {
                event.preventDefault(); // Prevent the default behavior (text selection)
            }
        };

        // Attach the event listener to the modal content
        const modalContent = document.getElementById('unlock');

        modalContent.addEventListener('keydown', _handleKeyPress);

        // Clean up the event listener when the component is unmounted
        return () => {
            modalContent.removeEventListener('keydown', _handleKeyPress);
        };
    }, []);

    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    // submit button click 
    async function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Please enter OTP");
            return;
        }
        try {
            props.handleOTPSubmission(pinString);
            const close_button = document.getElementById("close-modal");
            close_button.click();
        } catch (error) {

        }
    }

    return (
        <div id="unlock"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="unlock"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all text-center ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>

                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                        className="mx-auto" />


                    <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        Two factor authentication
                    </h6>
                    <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5 letter-spacing-point28">
                        To download the selected detail you need to confirm the verification code that sent to your email
                    </p>



                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <div className="d-flex flex-column align-items-center text-start mt-4">
                        <OTPInput pinString={pinString}
                            id="unlock-otp"
                            autoFocus={true}
                            pinError={errorMessage}
                            disabled={buttonLoader}
                            handleChange={_handlePin} />

                        <div className="d-flex  justify-content-center mt-4 w-100">

                            <SecondaryButton label="Cancel"
                                className="padding-12px e-font-16 "
                                cancel="modal"
                            />
                            <GradientButton label="Verify"
                                hide_loader={true}
                                className="padding-12px e-font-16 ms-3"
                                onPress={_verifyPin}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

UnlockSIPModal.defaultProps = {
    close: () => { },
    handleOTPSubmission: () => { }
}

export default UnlockSIPModal;