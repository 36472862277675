/*
 *   File : add-ip.js
 *   Author URI : https://evoqins.com
 *   Description : Modal element to add or update ip address
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { Icon } from "../Icon";
import { CustomTextInput } from "../FormElements";
import { GradientButton, SecondaryButton } from "../Cta";
import { toast } from "react-toastify";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";

const AddIPModal = (props) => {
    const [ipAddress, setIpAddress] = useState('');
    const [ipError, setIpError] = useState(null);
    const [id, setId] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("add-ip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setIpError('');
            setIpAddress('');
        });
        //eslint-disable-next-line
    }, [props.close]);

    useEffect(() =>{
        if (Object.keys(props.data).length !== 0) {
            setId(props.data.id);
            setIpAddress(props.data.ip_address);
        }
    },[props.data]);
    
    function _handleReason(value) {
        setIpAddress(value);
        setIpError(null);
    }

    function _handleValidate() {
        const ipv4_regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (ipAddress.length === 0) {
            setIpError('IP address is required');
        }else if (!ipv4_regex.test(ipAddress)) {
            setIpError('Invalid IP Address');
        }
        else {
            _addIp()
        }
    }


    // API - Add reason
    const _addIp = () => {
        setButtonLoader(true);
        const url = "ip-validation/upsert";

        const data = {
            id,
            ip_address: ipAddress
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                closeModal('add-ip');
                props.successCallBack()
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setButtonLoader(false);
        })
    }


    return (
        <div className={`modal fade ${style.e_approve_kyc} e-add-reason`}
            id="add-ip"
            tabIndex="-1"
            aria-labelledby="add-ip"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            {
                                Object.keys(props.data).length === 0 ?
                                    'Add IP address'
                                :
                                    'Update IP address'
                            }
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>



                    <div className="col-12 pt-4">
                        <CustomTextInput value={ipAddress}
                            error={ipError}
                            label="IP address"
                            postfix='*'
                            valueChange={(input_value) => _handleReason(input_value)} />
                    </div>

                    <div className="d-flex justify-content-center w-100 margin-40px-top">
                        <SecondaryButton label="Cancel"
                            cancel="modal" />
                        <GradientButton label={ Object.keys(props.data).length === 0 ? "Add IP" : "Update IP"}
                            className='ms-3'
                            loading={buttonLoader}
                            onPress={_handleValidate} />

                    </div>

                </div>

            </div>
        </div>
    )
}


AddIPModal.defaultProps = {
    close: () => { }
}

export default AddIPModal 