/*
 *   File : manage-email.js
 *   Author URI : https://evoqins.com
 *   Description : ManageEmail modal
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";


import { closeModal } from "../../Helper/helper-function";
import { CustomTextInput, OTPInput } from "../FormElements";

import style from "../../Styles/Components/manage.module.scss"
import { Icon } from "../Icon";
import { GradientButton, SecondaryButton } from "../Cta";

const ManageEmail = (props) => {


    const [pinString, setPinString] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [verifyPin, setVerifyPin] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState("");

    // event listener action to be performed after modal close 
    useEffect(() => {
        var my_modal = document.getElementById("manage-email");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setEmail('');
            setEmailError('');
            setOtp('');
            setOtpError('');
            setPinString("");
        });
        //eslint-disable-next-line
    }, [props.close]);



    // state for PIn
    function _handlePin(value) {
        setPinString(value);
        setErrorMessage("");
    }

    // handle for email
    function _handleEmail(value) {
        setEmail(value);
        setEmailError("");
    }

    // handler for OTP
    function _handleOtp(value) {
        if (value.length <= 4) {
            setOtp(value);
        }
        setOtpError("");
    }

    // validation submit to verify PIN
    function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Enter valid pin");
        } else {
            setVerifyPin(true);
        }
    }

    // validation submit for email and OTP
    function _validate() {
        let valid = true;
        // eslint-disable-next-line
        const email_validator = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/; // email validator regex
        if (!email_validator.test(email)) {
            valid = false;
            setEmailError("Invalid email");
        }

        if (otp.length !== 4) {
            valid = false;
            setOtpError('Invalid OTP');
        }
        if (valid === true) {
            props.close();
            closeModal('manage-email');
        }
    }

    return (
        <div id="manage-email"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="manage-email"
            aria-hidden="true"
            className={`modal  ${style.e_unlock}`}>
            <div className={`modal-dialog modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white ${style.e_content}`}>
                    {
                        !verifyPin ?
                            <Fragment >
                                {/* =================== Two factor screen =================*/}
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                                        alt="Unlock icon"
                                        width={72}
                                        height={72} />
                                    <h6 className="color-eerie-black e-poppins-medium e-font-18  line-height-24px mb-2">Two factor authentication</h6>

                                    <p className="color-outer-space e-poppins-regular e-font-14 letter-space-point28 line-height-21px mb-3 px-5">
                                        To download the selected detail you need to confirm the verification code that sent to your mail
                                    </p>

                                    <OTPInput pinString={pinString}
                                        id="mt-unlock-pin"
                                        autoFocus={true}
                                        pinError={errorMessage}
                                        handleChange={_handlePin} />
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal" />
                                        <GradientButton label="Verify"
                                            className="ms-3"
                                            onPress={_verifyPin} />
                                    </div>


                                </div>
                            </Fragment>
                            :

                            <Fragment >
                                {/* ================Email and OTP screen ============== */}
                                <div className="col-12 d-flex justify-content-between">
                                    <h3 className="color-black e-poppins-semi-bold e-font-18">
                                        Change email
                                    </h3>
                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>
                                <div className="row padding-32px-top">
                                    <div className="col-sm-6">
                                        <CustomTextInput label="Add new email"
                                            postfix="*"
                                            type='email'
                                            value={email}
                                            error={emailError}
                                            valueChange={(value) => _handleEmail(value)} />
                                    </div>
                                    <div className="col-sm-6">
                                        <CustomTextInput label="Verify email"
                                            postfix="*"
                                            type={'password'}
                                            error={otpError}
                                            value={otp}
                                            valueChange={(value) => _handleOtp(value)} />
                                    </div>
                                </div>
                                <p className="color-jett-black e-poppins-regular e-font-16 margin-40px-bottom mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                <div className="w-100 d-flex justify-content-center mt-4">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal" />
                                    <GradientButton label="Update email"
                                        className="ms-3"
                                        onPress={_validate} />
                                </div>
                            </Fragment>
                    }

                </div>
            </div>
        </div >
    )
}

export default ManageEmail;