/*
 *   File : manage-info.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks adding Info.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { Icon } from "../Icon";
import { CustomTextArea } from "../FormElements";
import { GradientButton, SecondaryButton } from "../Cta";
import { toast } from "react-toastify";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";

const AddFailureReason = (props) => {
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("add-reason");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setReasonError('');
            setReason('');
        });
        //eslint-disable-next-line
    }, [props.close]);


    function _handleReason(value) {
        setReason(value);
        setReasonError(null);
    }

    function _handleValidate() {
        let valid = true;
        if (reason.trim().length === 0) {
            setReasonError('Content is required');
        }
        else if (valid === true) {
            _addReason()
        }
    }


    // API - Add reason
    const _addReason = () => {
        setApiLoader(true);
        const url = "onboarding/kyc/manage-reason";

        const data = {
            reason: reason
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                closeModal('add-reason');
                props.reasonAdded()
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }


    return (
        <div className={`modal fade ${style.e_approve_kyc} e-add-reason`}
            id="add-reason"
            tabIndex="-1"
            aria-labelledby="add-reason"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            {'Add Reason'}
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>



                    <div className="col-12 pt-4">
                        <CustomTextArea value={reason}
                            error={reasonError}
                            label="Reason"
                            postfix='*'
                            hide_error_text={true}
                            valueChange={(input_value) => _handleReason(input_value)} />
                    </div>

                    <div className="d-flex justify-content-center w-100 margin-40px-top">
                        <SecondaryButton label="Cancel"
                            cancel="modal" />
                        <GradientButton label="Add content"
                            className='ms-3'
                            loading={apiLoader}
                            onPress={_handleValidate} />

                    </div>

                </div>

            </div>
        </div>
    )
}


AddFailureReason.defaultProps = {
    close: () => { }
}

export default AddFailureReason 