/*
 *   File : mandates.js
 *   Author URI : https://evoqins.com
 *   Description : Mandate listing page
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import { DataTableContainer } from "../../../../Components/FormElements";
import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { GradientButton } from "../../../../Components/Cta";
import { CreateMandateModal } from "../../../../Components/Modal";
import APIService from "../../../../Services/api-service";

import Colors from "../../../../Styles/color.module.scss";


const MandateList = React.memo((props) => {

    const location = useLocation();
    const [mandateList, setMandateList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showMandateModal, setShowMandateModal] = useState(false);

    useEffect(() => {
        _getMandateList();
    }, []);

    useEffect(() => {
        if (showMandateModal === true) {
            const modal = new Modal(document.getElementById("show-mandate"), {});
            modal.show();
        }
    }, [showMandateModal]);

    const _handleRowHover = (row) => {

    }

    const _handleRowRemoveHover = () => {

    }

    const _handleCardClick = (row) => {

    }

    const MANDATE_COLUMNS = [
        {
            name: 'Mandate bank',
            selector: row => row['bank_name'],
            sortable: true,
            left: true,
            // width: "576px",
            maxWidth: "200px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['bank_name']}</p>
                </div>
        },
        {
            name: 'Mandate ID',
            selector: row => row['mandate_id'],
            sortable: false,
            left: true,
            maxWidth: "160px",
            cell: row => <span onClick={() => _handleCardClick(row)}> {row['mandate_id']}</span>,
        },
        {
            name: 'Mandate created on',
            selector: row => row['created'],
            sortable: true,
            left: true,
            maxWidth: "180px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Mandate expiry date',
            selector: row => row['end_date'],
            sortable: true,
            left: true,
            maxWidth: "180px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                {row['end_date']}</span>,
        },
        {
            name: 'Mandate limit',
            selector: row => row['mandate_limit'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                <span className="e-inter-regular">₹</span>
                {row['mandate_limit']}</span>,
        },

        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    <p className={` ${row.status_str === 'SUCCESS' ? 'color-sea-green' : 'color-vivid-orange'} e-poppins-medium e-font-16 line-height-24px mb-0 mt-0 e-mandate-status`}>
                        {row.status_str}
                    </p>
                </div>
        },
        {
            name: 'Remarks',
            selector: row => row['remarks'],
            sortable: false,
            left: true,
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                        {row['remarks'] ? row['remarks'] : '-'}
                    </p>
                </div>
        },

    ];

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "0 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "14px",
                fontFamily: "Poppins-Medium",
                lineHeight: "24px"
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Regular",
                lineHeight: "24px",
                cursor: "pointer",
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "24px 0px 24px 24px",
                }
            },
        },
        pagination: {
            style: {
                borderTop: `none `,
                color: Colors.outer_space,
                fontSize: '16px',
                fontFamily: "Poppins-Regular",
            },
        }
    }

    // API - Get user data
    function _getMandateList() {
        let url = 'user/mandates/list';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
            bank_id: null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            }
            else {
                setMandateList([]);
            }
            setLoader(false);
        })
    }

    // handle create mandate button click
    function _handleMandateButton() {
        setShowMandateModal(true);
    }


    return (
        <>
            {
                loader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :

                    mandateList.length !== 0 ?
                        <div className="col-12">
                            <DataTableContainer columns={MANDATE_COLUMNS}
                                data={mandateList}
                                selectableRows={false}
                                pagination={false}
                                customStyles={TABLE_STYLE}
                                onRowMouseEnter={_handleRowHover}
                                defaultSortFieldId={1}
                                onRowMouseLeave={_handleRowRemoveHover}

                                rowClick={(row) => {
                                    _handleCardClick(row)
                                }} />
                            <GradientButton label="Create mandate"
                                className="mt-3"
                                onPress={_handleMandateButton} />
                        </div>
                        :
                        <EmptyScreen title={props.userBank.length === 0 ? "User don't have linked any bank" : "Empty mandate data!"}
                            buttonLabel={props.userBank.length === 0 ? "" : "Create mandate"}
                            onPress={(_handleMandateButton)} />
            }
            {
                showMandateModal === true &&
                <CreateMandateModal userId={location.state.user_id}
                    bankData={props.userBank}
                    updateList={_getMandateList}
                    close={() => setShowMandateModal(false)} />
            }
        </>
    )
})

export default MandateList