
/*
 *   File : sip-history.js
 *   Author URI : https://evoqins.com
 *   Description : Listing sip history data
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";

import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { DataTableContainer } from "../../../../Components/FormElements";

import APIService from "../../../../Services/api-service";

import Colors from '../../../../Styles/color.module.scss';

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: Colors.alice_blue,
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-regular",
            lineHeight: "24px",
            color: Colors.jet_black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

export default function SipHistory(props) {

    const [apiLoader, setApiLoader] = useState(true);
    const [historyData, setHistoryData] = useState([]);

    const USER_COLUMNS = [

        {
            name: 'User name',
            selector: row => row['customer_name'],
            sortable: false,
            left: true,
            // width: "576px",
            width: "200px",
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['customer_name']}</p>
        },
        {
            name: 'User email',
            selector: row => row['customer_email'],
            sortable: false,
            left: true,
            width: "300px",
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['customer_email']}</p>
        },

        {
            name: 'User mobile',
            selector: row => row['customer_mobile'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-black e-poppins-regular e-font-16 mb-0">{row['customer_mobile']}</p>
        },
        {
            name: 'Activity type',
            selector: row => row['activity_type'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-black e-poppins-regular e-font-16 mb-0">{row['activity_type']}</p>
        },
        {
            name: 'Created at',
            selector: row => row['created'],
            sortable: false,
            left: true,
            width: '200px',
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['created']}</p>
        },
        {
            name: 'Created by',
            selector: row => row['created_by'],
            sortable: false,
            left: true,
            width: '130px',
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">
                {row['created_by'] ? row['created_by'] : '-'}</p>
        },
        {
            name: 'Admin name',
            selector: row => row['admin_name'],
            sortable: false,
            left: true,
            width: '200px',
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">
                {row['admin_name'] ? row['admin_name'] : '-'}
                </p>
        },
        {
            name: 'Admin type',
            selector: row => row['admin_type'],
            sortable: false,
            left: true,
            width: '200px',
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">
                {row['admin_type'] ? row['admin_type'] : '-'}
            </p>
        },

    ];

    useEffect(() => {
        _getHistory();
    }, []);

    // API - history data
    function _getHistory() {
        let url = 'activity-logs/get';
        let payload = JSON.stringify({
            customer_id: props.userId,
            start_date: null,
            end_date: null,
            page_num: null,
            page_size: null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setHistoryData(response.data.data);
            }
            else {
                setHistoryData([]);
            }
            setApiLoader(false);
        })

    }
    return (
        <>
            {
                apiLoader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div>
                        {
                            historyData.length !== 0 ?
                                <div className="col-12 mt-3 px-0">
                                    <DataTableContainer columns={USER_COLUMNS}
                                        data={historyData}
                                        selectableRows={false}
                                        pagination={false}
                                        customStyles={TABLE_STYLE}
                                        // rowClick={(row) => {
                                        //     _handleCardClick(row)
                                        // }} 
                                        />
                                </div>
                                :
                                <EmptyScreen title="Empty login data!" />
                        }
                    </div>
            }
        </>
    );
}