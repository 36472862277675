/*
 *   File : delete-ip.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while removing ip.
 *   Integrations : null
 *   Version : v1.1
*/

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../Cta";
import { Icon } from "../Icon";

import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";

import style from "../../Styles/Components/delete.module.scss";


const DeleteIPModal = (props) => {
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("delete-ip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    const _handleCancel = () => {
        props.close();
    }

    function _handleSubmit () {
        setApiLoader(true);
        let url = 'ip-validation/delete';
        let data = {
            id: props.data.id
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                // closeModal('delete-ip');
                props.successCallBack();
                toast.dismiss();
                toast.success('Removed IP address', {
                    type: 'success'
                });
            }else{
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    };
    

    return (
        <div className={`modal fade ${style.e_delete}`}
            id="delete-ip"
            tabIndex="-1"
            aria-labelledby="delete-ip"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto"
                            data-bs-dismiss="modal" />
                    </div>
                    <img draggable={false} src={require("../../Assets/Images/Modal/delete-icon.png")}
                        alt="Delete account"
                        width={72}
                        height={72}
                    />


                    <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        Are you sure want to remove this IP address 
                    </p>

                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  w-100 px-5">
                        <OutlineButton label="No, cancel"
                            cancel="modal"
                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                            handleClick={_handleCancel} />
                        <GradientButton label="Yes, delete"
                            className="padding-12px-tb e-font-16 w-100 "
                            cancel="modal"
                            loading={apiLoader}
                            onPress={_handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}


DeleteIPModal.defaultProps = {
    close: () => { },
}

export default DeleteIPModal;