/*
 *   File : link-text.js
 *   Author URI : https://evoqins.com
 *   Description : Link text button
 *   Integrations : null
 *   Version : v1.1
 */

/*import components*/
import Icon from "../Icon/icon";

/*import styles*/
import style from "../../Styles/Components/link-text.module.scss";
import { Fragment } from "react";

// type - 2 for blue text and type 1 for green 

const LinkText = (props) => {
    return (
        <Fragment>
            {props.type === 2 ?
                <p className={
                    props.disabled ?
                        `color-primary-color e-poppins-medium e-font-14 line-height-24px mb-0 ${style.e_see_more_blue} ${props.className} ${style.e_disable}`
                        :
                        `color-primary-color e-poppins-medium e-font-14 line-height-24px mb-0 ${style.e_see_more_blue} ${props.className}`}
                    data-bs-toggle={props.toggle}
                    data-bs-target={props.target}
                    onClick={() => props.onClick()}>
                    {
                        props.iconPosition === 1 && (
                            <Icon icon={props.icon}
                                alt="See more"
                                width="24px"
                                height="24px"
                                className={`${style.e_icon} me-3 position-relative`} />
                        )
                    }
                    <span >{props.title ? props.title : "See more"}</span>
                    {
                        props.iconPosition === 2 && (
                            <Icon icon={props.icon}
                                alt="See more"
                                width="24px"
                                height="24px"
                                className={`${style.e_icon} ms-3 position-relative `} />
                        )
                    }
                </p>
                :
                <p className={`color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 ${style.e_see_more} ${props.className}`}
                    data-bs-toggle={props.toggle}
                    data-bs-target={props.target}
                    onClick={() => props.onClick()}>
                    {
                        props.iconPosition === 1 && (
                            <Icon icon={props.icon}
                                alt="See more"
                                width="24px"
                                height="24px"
                                className={`${style.e_icon} me-3 position-relative`} />
                        )
                    }
                    <span >{props.title ? props.title : "See more"}</span>
                    {
                        props.iconPosition === 2 && (
                            <Icon icon={props.icon}
                                alt="See more"
                                width="24px"
                                height="24px"
                                className={`${style.e_icon} ms-3 position-relative `} />
                        )
                    }
                </p>}
        </Fragment>
    )
}

LinkText.defaultProps = {
    onClick: () => null,
    iconPosition: 2
}

export default LinkText