/*
 *   File : social-share-success.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which show success after social share
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";
import { toast } from "react-toastify";

import { Icon } from "../Icon";

import style from "../../Styles/Components/order-execution-success.module.scss"

const SocialShareSuccess = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("social-share-success");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);


    return (
        <div className={`modal fade ${style.e_order_execution} e-order-execution-sucess`}
            id="social-share-success"
            tabIndex="-1"
            aria-labelledby="social-share-success"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        className={`cursor-pointer position-absolute ${style.e_close} e-modal-close-btn`}
                        data-bs-dismiss="modal" />
                    <img draggable={false} src={require("../../Assets/Images/Order-execution/order-execution.png")}
                        alt="Order execution"
                        width={72}
                        height={72} 
                        className="mt-5"/>

                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">
                        Successfully sent to user
                    </p>
                    <p className="color-outer-space e-poppins-regular e-font-14 margin-32px-bottom mx-4 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                </div>
            </div>
        </div>
    )
}

export default SocialShareSuccess