/*
 *   File : toast.js
 *   Author URI : https://evoqins.com
 *   Description : Alert messages
 *   Integrations : null
 *   Version : v1.1
 */

import { ToastContainer } from "react-toastify";
import Icon from "../Icon/icon";

import "react-toastify/dist/ReactToastify.css";

function Toast() {

  function CloseButton () {
    return (
      <Icon icon='close'
        size={20}
        className="position-absolute right-10px"/>
    )
  }
  return (
    <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        closeButton={CloseButton}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  );
}

export default Toast;
