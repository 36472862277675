
/*
 *   File : loader.js
 *   Author URI : https://evoqins.com
 *   Description : UI element for api loading
 *   Integrations : null
 *   Version : v1.1
 */

import LoaderGif from '../../Assets/Images/loader.gif';

export default function CustomLoader(props) {
    return (
        <div className={`h-100 w-100  d-flex justify-content-center align-items-center ${props.className}`}>
            <img draggable={false} src={LoaderGif}
                alt="data loader"
                width={80} />
        </div>
    );
}

CustomLoader.defaultProps = {
    className: 'bg-white'
};