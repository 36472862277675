/*
 *   File : manage-allocation.js
 *   Author URI : https://evoqins.com
 *   Description : Fund listing page
 *   Integrations : null
 *   Version : v1.1
 */
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { CustomTab } from "../../../Components/Tab";
import { NavigationHeader } from "../../../Components/Header";
import { GradientButton, OutlineButton } from "../../../Components/Cta";
import { ManageFundModal } from "../../../Components/Modal";
import { DataTableContainer } from "../../../Components/FormElements";
import { CustomLoader } from "../../../Components/Other";

import APIService from "../../../Services/api-service";

import Colors from '../../../Styles/color.module.scss';

const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage funds"
]

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px"
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
        },
    },
    cells: {
        style: {
            justifyContent: "center",
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            '&:first-child': {
                padding: "16px 0px 16px 16px",
                justifyContent: "start"
            }
        },
    },
    headCells: {
        style: {
            color: Colors.black,
            justifyContent: "center",
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            '&:first-child': {
                justifyContent: "start",
                padding: "16px 0px 16px 16px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Medium",
        },
    }
}


const AdminAllocation = React.memo(() => {

    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [tabIndex, setTabIndex] = useState(null);
    const [investmentType, setInvestmentType] = useState('');
    const [fundList, setFundList] = useState({});
    const [fund, setFund] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    const [riskTypeDetail, setRiskTypeDetail] = useState({});
    const [investmentStyles, setInvestmentStyles] = useState([]);
    const [permissionType, setPermissionType] = useState('');

    useEffect(() => {
        _getFunds(true);
    }, [])

    useEffect(() => {
        if (openModal === true) {
            _openFundModal();
        }
    }, [openModal]);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);



    const _handleTabIndex = (tab_index, type) => {
        setTabIndex(tab_index);
        setInvestmentType(type);
        setRiskTypeDetail({ ...fundList[tab_index - 1] });
    }

    const _editFund = (fund_list) => {
        console.log('fund_list', fund_list);
        setFund(fund_list.funds);
        setOpenModal(true);
    }

    const _openFundModal = () => {
        const modal = new Modal(document.getElementById("manage-fund"));
        modal.show();
    }

    // API - get fund list
    function _getFunds(is_first) {
        let url = 'fund/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setApiLoader(false);
                if (is_first) {
                    let list_data = [];
                    Object.keys(response.data).map((item) => {
                        return (
                            list_data.push({
                                type: item,
                                id: response.data[`${item}`].data.id
                            })
                        )
                    });

                    setTabIndex(list_data[0].id);
                    setInvestmentType(list_data[0].type);
                    setInvestmentStyles(list_data);
                }
                setFundList(response.data);
            }
        })
    }

    const FUND_COLUMNS = [

        {
            name: 'Fund',
            selector: row => row['name'],
            sortable: true,
            left: true,
            maxWidth: "500px",
            cell: row => <div className="d-flex align-items-center">
                <div className="p-2 border-radius-8px border-all border-bright-gray d-flex justify-content-center align-items-center">
                    <img draggable={false} src={row.fund_image}
                        width={36}
                        height={36}
                        alt="logo"
                        className="object-fit-contain" />
                </div>
                <div className="color-light-black e-font-16 line-height-20px e-poppins-medium padding-12px-left ">
                    {row['name']}
                    <div className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px  mt-2 w-max-content">
                        {row.class_name}
                    </div>
                </div>
            </div>,
        },

        {
            name: 'Min SIP',
            selector: row => row['min_sip_investment'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span className="color-black">
                {
                    row['min_sip_investment'] ?
                        <>
                            <span className="e-inter-regular">₹</span>
                            {row['min_sip_investment'].toLocaleString('en-IN')}
                        </>
                        :
                        '-'
                }

            </span>,
        },

        {
            name: 'Min Lumpsum',
            selector: row => row['min_lumpsum_investment'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span className="color-black">
                {
                    row['min_lumpsum_investment'] ?
                        <>
                            <span className="e-inter-regular">₹</span>
                            {row['min_lumpsum_investment'].toLocaleString('en-IN')}
                        </>
                        :
                        '-'
                }

            </span>,
        },
        {
            name: 'Allocation',
            selector: row => row['allocation'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <span className="color-black">
                    {row['allocation']}
                    %
                </span>,
        },



    ];


    return (
        <Fragment>
            <NavigationHeader
                type={1}
                data={PAGE_NAVIGATION}
                title="Manage funds" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                {
                    apiLoader === true ? (
                        <div className="row">
                            <div className="col-12 h-100vh">
                                <CustomLoader />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-10">
                                <CustomTab type={2}
                                    index={tabIndex}
                                    data={investmentStyles}
                                    onSelectTab={_handleTabIndex} />

                            </div>
                            <div className="col-10 d-flex justify-content-between align-items-center padding-24px-top">
                                <div className="d-flex align-items-center">
                                    <img draggable={false} src={fundList[`${investmentType}`].data.image}
                                        alt="chart"
                                        width={48}
                                        height={40}
                                        className="object-fit-contain" />
                                    <span className="ms-2 e-font-16 e-poppins-medium line-height-16px color-black">
                                        {investmentType} investment option selected
                                    </span>
                                    <span className="e-font-16 color-old-silver e-poppins-medium line-height-18px  ps-1">
                                        Total allocation:
                                    </span>
                                    <span className="e-font-16 e-poppins-semi-bold line-height-18px color-black ps-1">
                                        {fundList[`${investmentType}`].funds.reduce((a, b) => a + b.allocation, 0)}%
                                    </span>

                                </div>
                                {
                                    // permissionType !== 'read' && (
                                        <div className="d-flex">
                                            <OutlineButton label="Update funds"
                                                onPress={() => {
                                                    _editFund(fundList[`${investmentType}`])
                                                }} />
                                        </div>
                                    // )
                                }

                            </div>
                            <div className="col-10 margin-30px-top">
                                <div className="border-radius-16px bg-light-grayish-white p-3">
                                    <p className="color-old-silver e-poppins-regular e-font-16 line-height-18px mb-2">
                                        Total minimum SIP amount <span className="color-light-black e-poppins-semi-bold">
                                            <span className="e-inter-regular">₹</span>
                                            {fundList[`${investmentType}`].data.min_sip_investment.toLocaleString('en-IN')}
                                        </span>
                                    </p>
                                    <p className="color-old-silver e-poppins-regular e-font-16 line-height-18px mb-0">
                                        Total minimum Lumpsum amount <span className="color-light-black e-poppins-semi-bold">
                                            <span className="e-inter-regular">₹</span>
                                            {fundList[`${investmentType}`].data.min_lumpsum_investment.toLocaleString('en-IN')}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-10 padding-24px-top">
                                <DataTableContainer columns={FUND_COLUMNS}
                                    data={fundList[`${investmentType}`].funds}
                                    selectableRows={false}
                                    pagination={false}
                                    customStyles={TABLE_STYLE}
                                    defaultSortFieldId={1} />
                            </div>
                        </div>
                    )
                }

            </div>
            {
                openModal && apiLoader === false?
                    <ManageFundModal investment_type={investmentType}
                        investment_id={tabIndex}
                        fund={fund}
                        total_allocation={parseInt(fundList[`${investmentType}`].funds.reduce((a, b) => a + b.allocation, 0))}
                        closeModal={() => {
                            setFund(null);
                            setOpenModal(false);
                        }}
                        totalAllocation={fundList[`${investmentType}`].funds.reduce((a, b) => a + b.allocation, 0)}
                        addFund={() => {
                            setFund(null);
                            setOpenModal(false);
                            _getFunds();
                        }} />
                    :
                    null
            }
        </Fragment>
    )
})

export default AdminAllocation;