/*
 *   File : profile-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment } from "react";

/* import components */
import { UserInfo } from "../../../../Components/Cards";

const CustomerProfile = React.memo((props) => {


    return (
        <Fragment>
            <div className="row padding-24px-top">
                <div className="col-12">
                    <UserInfo
                        basicInfo={props.customer_info.profile}
                        type={1} />
                </div>
                <div className="col-12 pt-3">
                    <UserInfo
                        bankInfo={props.customer_info.profile.banks}
                        type={2} />
                </div>
                <div className="col-12 pt-3">
                    <UserInfo
                        nomineeInfo={props.customer_info.nominees}
                        type={3} />
                </div>

            </div>
        </Fragment>
    )
})

CustomerProfile.defaultProps = {
    customer_info: {
        profile: {
            banks: [],
            nominees: []
        }
    }
}

export default CustomerProfile;