/*
 *   File : import-admin.js
 *   Author URI : https://evoqins.com
 *   Description : UI element for adding admin from xl
 *   Integrations : react-toastify
 *   Version : v1.1
 */


/* import packages */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

/* import components */
import { GradientButton } from "../Cta";
import { Icon } from "../Icon";
import { FilePreview } from "../Cards";
import { CustomSearchSelectBox, FileUpload } from "../FormElements";

/* import styles */
import style from "../../Styles/Components/banner.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import APIService from "../../Services/api-service";

const ImportAdminModal = (props) => {
    const [fileName, setFileName] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [selectedAdminType, setSelectedAdminType] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [adminError, setAdminError] = useState("");
    const [adminOptions, setAdminOptions] = useState([]);

    useEffect(() => {
        var my_modal = document.getElementById("import-admin");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setFileError('');
            setFileName('');
            setFileUrl('');
            setSelectedAdminType("");
        });
    }, [props.close]);

    useEffect(()=>{
        _getAdminTypes();

    },[]);
    // update admin type 
    function _handleAdminTypeChange(value) {
        setSelectedAdminType(value);
        setAdminError('');
    }

    function _fileHandler(path, file) {
        var file_formatted = path.replace(/^.*[\\\/]/, '').split('/');
        file_formatted = file_formatted[0].replace(/[\(\)\s]/g, ''); //returning path
        const file_type = file.type;

        if (_verifyFileType(file_type)) {
            _fileUpload(file_formatted, file);
            setFileError("");
        }
        else {
            toast.dismiss();
            toast.error("File format should be in xls, xlsx or csv", {
                type: "error",
            });
        }
    }

    function _verifyFileType(fileType) {
        const valid_mime_type = [
            "application/vnd.ms-excel", // .xls
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
            "text/csv", // .csv
        ];
        return valid_mime_type.includes(fileType);
    }

    // form validation 
    function _validateForm() {
        let valid_form = true;
        if (fileUrl === '') {
            valid_form = false;
            setFileError("File is require");
        }
        if (selectedAdminType === "") {
            valid_form = false;
            setAdminError('Admin type is require');
        }
        //eslint-disable-next-line
        if (valid_form === true) {
            _importAdmin();
        }
    }

    // API - get admin type
    function _getAdminTypes () {
        let url = 'auth/admin-types-filter';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let admin_types = response.data.map((item)=>{
                    return {value: item.id, label: item.name}
                });
                console.log('admin', admin_types);
                setAdminOptions(admin_types);
            }
        })
    }
    // API - file upload
    const _fileUpload = (key, file) => {
        setFileLoader(true);
        let url = "general/file-upload";
        let body = {
            "key": `banner/${key}`,
            is_public: true
        }

        APIService(true, url, body).then(async (response) => {
            if (response.status_code === 200) {
                setFileLoader(true)

                // s3 helper
                await _uploadDocumentToS3(response.data, key, file).then((result) => {
                    console.log('result', result);
                    console.log('response', response.data.view_info);
                    setFileUrl(response.data.view_info); // file got uploaded successfully
                    setFileLoader(false);
                    setFileName(key);
                }).catch(error => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                    setFileLoader(false);
                });
            } else {
                setFileLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }


    // API - Add banner
    const _importAdmin = () => {
        setApiLoader(true);
        const url = "auth/add-from-file";
        const data = {
            url: fileUrl,
            admin_type_id: selectedAdminType.value
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                closeModal("import-admin");
                toast.dismiss();
                toast.success('Imported successfully', {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        })
    }


    return (
        <div className={`modal fade ${style.e_delete}`}
            id="import-admin"
            tabIndex="-1"
            aria-labelledby="import-admin"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            Import admins
                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <div className="col-12 text-start ">
                        <label className="pb-3 color-light-black e-font-14 line-height-14px e-poppins-regular text-start">
                            Add CSV/Excel
                        </label>
                        {/* <span className="d-block mb-2 e-font-12">Preferred file dimensions: 1096 x 270.</span> */}


                        {/* file picker */}
                        <FileUpload id="e-upload"
                            error={fileError}
                            loading={fileLoader}
                            accept=".xlx, .xlsx, .csv"
                            hide_error_text={true}
                            title="Select a file"
                            content="Supports only xl, xlsx and csv"
                            onSelectFile={(path, file) => {
                                _fileHandler(path, file)
                            }} />
                        {
                            fileUrl ?
                                <FilePreview file={fileName}
                                    hidePreviewIcon={true}
                                    clearFile={() => {
                                        setFileName("");
                                        setFileUrl("");
                                    }} />

                                :
                                null
                        }
                    </div>

                    {/* redirection URL */}
                    <div className="col-12 padding-24px-top">
                        <CustomSearchSelectBox label="Admin type"
                            value={selectedAdminType}
                            placeholder=""
                            id="admin-type-input"
                            error={adminError}
                            options={adminOptions}
                            postfix="*"
                            onSelectChange={_handleAdminTypeChange} />
                    </div>

                    {/* save banner button */}
                    <div className="col-12 padding-24px-top mt-2">
                        <GradientButton label="Import admins"
                            loading={apiLoader}
                            className="padding-12px-tb e-font-16 "
                            onPress={_validateForm} />
                    </div>
                </div>
            </div>
        </div>
    )
}


ImportAdminModal.defaultProps = {
    close: () => { },
}

export default ImportAdminModal