
const initialState = {
    path: '/',
    toggle: false,
    ADMIN_PROFILE: {},
    OF_FILTERS: {}
};

export default function adminReducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_NAVIGATION":
            return {
                ...state,
                path: action.payload
            };
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                toggle: action.payload
            };

        case "GET_ADMIN_PROFILE":
            return {
                ...state,
                ADMIN_PROFILE: action.payload
            };
        case 'SELECTED_OF_FILTERS':
            return {
                ...state,
                OF_FILTERS: action.payload
            }
        default:
            return state
    }
}