
/*
 *   File : tool-tip.js
 *   Author URI : https://evoqins.com
 *   Description : Component for tool-tip
 *   Integrations : null
 *   Version : v1.1
 */

import style from '../../Styles/Components/tool-tip.module.scss'

export default function CustomToolTip(props) {
    return (
        <div className={`${style.e_popup_tooltip} position-absolute bg-white color-light-black border-all border-bright-gray e-poppins-medium border-radius-4px e-font-12 d-flex`}>
            <span>{props.content}</span>
            <div className={style.e_popup_arrow} />
        </div>
    )
} 