/*
 *   File : fund-order-details.js
 *   Author URI : www.evoqins.com
 *   Description : Order details for Fund.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 12-12-2023
 */

/* import packages */
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import Icon from "../../../Components/Icon/icon";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { _getOrderDetail } from "../../../Helper/api";

import Color from "../../../Styles/color.module.scss"
import { CustomLoader } from "../../../Components/Other";

const PAGE_NAVIGATION = [
    'Home',
    'Orders',
    'Order details'
];

const DETAIL_DATA = {
    summary: {
        order_id: 588,
        order_no: "MT-PN-1576",
        name: "Mirae Asset Ultra Short Duration Fund - Reg (G)",
        order_date: "11 Dec 2023",
        amount: 30000,
        amount_text: "30000.0",
        is_purchase: true,
        type: "Lumpsum",
        payment_status: "Failed",
        order_status: "Failed",
        image: "https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/amcs/mirae.png",
        is_goal: false,
        failure_reason: null
    },
    funds: {
        order_detail_id: 661,
        mt_order_id: "MT-MFPN-1734",
        folio_no: "8239",
        fund_name: "Mirae Asset Ultra Short Duration Fund - Reg (G)",
        mf_id: 960,
        category: "Ultra Short Term Funds",
        units: 0,
        purchase_nav: 0,
        nav_date: null,
        amount: 30000,
        amount_text: "30000.0",
        order_status: "Failed",
        order_time: "03:23:36 PM",
        order_date: "11 Dec 2023",
        payment_date: "11 Dec 2023",
        payment_received: null,
        order_accepted: null,
        portfolio_updated: null
    }
}


const AdminFundOrderdetail = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [orderDetail, setOrderDetail] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetail(location.state.customer_id, location.state.order_id).then((response) => {
                setOrderDetail(response)
                setLoader(false)
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
                navigate(-1)
            });
        }
        //eslint-disable-next-line
    }, [location])

    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../../Assets/Images/Orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../../Assets/Images/Orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../../Assets/Images/Orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../../Assets/Images/Orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>

        )
    }

    const Progress = (props) => {
        return (
            <div className={`d-flex flex-column align-items-center text-center ${props.index !== 3 && "margin-64px-right"}`}>
                <Icon icon={`${props.progress === 3 ? 'check-green' : props.progress === 2 ? 'pending-yellow' : props.progress === 0 ? 'info-circle' : 'pending'}`}
                    size={24}
                    className="z-index-1" />
                <span className={`e-poppins-medium e-font-12 line-height-12px my-1 w-max-content ${props.progress === 3 ? 'color-sea-green' : props.progress === 2 ? 'color-yellow-orange' : props.progress === 0 ? 'color-red' : 'color-gray'}`}>{props.title}</span>
                <span className={`e-poppins-medium e-font-10 line-height-10px ${props.data !== null ? 'color-outer-space' : 'color-gray'}`}>{props.data}</span>
            </div>
        )
    }

    return (
        loader === true ?
            <div className="row h-80vh">
                <CustomLoader />
            </div>
            :
            <Fragment>

                {/* Header */}
                <NavigationHeader type={1}
                    title="Order details" />

                {/* Breadcrumb */}
                <div className="e-fund-order-summary ps-4 pe-lg-5 pe-md-2 pe-2 pt-2">
                    {/* <div className="d-flex align-items-center gap-8px padding-12px-all mb-3 cursor-pointer w-fit-content"
                        onClick={() => navigate(-1)}>
                        <Icon icon="arrow-right"
                            size={24}
                            color={Color.black}
                            className="e-arrow-right-180" />
                        <p className="color-black e-poppins-regular e-font-16 line-height-14px mb-0">Back</p>
                    </div> */}
                    <BreadCrumb data={PAGE_NAVIGATION} />
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="border-all border-bright-gray padding-24px-all border-radius-16px">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <div className="bg-bright-navy-blue color-white e-poppins-semi-bold e-font-16 d-flex justify-content-center align-items-center w-40px h-40px border-radius-100 text-uppder-case">
                                                {orderDetail.summary.name ? orderDetail.summary.name?.charAt(0) : orderDetail.funds[0].fund_name.charAt(0)}
                                            </div>
                                            <div className="ps-2">
                                                <h6 className="color-charleston-green e-font-16 e-poppins-semi-bold line-height-24px mb-2">
                                                    {orderDetail.summary.name ? orderDetail.summary.name : orderDetail.funds[0].fund_name}
                                                </h6>
                                                <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                                    Placed on:
                                                    <span className="color-outer-space ps-2">
                                                        {orderDetail.summary.order_date}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 text-end">
                                        <p className="e-font-14 e-poppins-regular line-height-14px color-charleston-green my-0">
                                            Order status
                                        </p>
                                        <p className={`${orderDetail.summary.order_status === 'Failed' ?
                                            "color-red"
                                            :
                                            orderDetail.summary.order_status === 'Success' ?
                                                "color-sea-green"
                                                :
                                                orderDetail.summary.order_status === 'Payment pending' ?
                                                    "color-sunset-blaze"
                                                    :
                                                    "color-yellow-orange"} e-poppins-medium e-font-16 line-height-24px mt-2`}>
                                            {orderDetail.summary.order_status}
                                            {
                                                orderDetail.summary.order_status === 'Failed' ?
                                                    <img draggable={false} src={require("../../../Assets/Images/Orders/failed.png")} alt="Processing"
                                                        width={24}
                                                        height={24}
                                                    />
                                                    : orderDetail.summary.order_status === 'Success' ?
                                                        <img draggable={false} src={require("../../../Assets/Images/Orders/success.png")} alt="Success"
                                                            width={24}
                                                            height={24}
                                                        />
                                                        :
                                                        orderDetail.summary.order_status === 'Payment pending' ?
                                                            <img draggable={false} src={require("../../../Assets/Images/Orders/payment-pending.svg").default} alt="Success"
                                                                width={24}
                                                                height={24}
                                                            /> :
                                                            <img draggable={false} src={require("../../../Assets/Images/Orders/processing.png")} alt="Failed"
                                                                width={24}
                                                                height={24}
                                                            />

                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        orderDetail.summary.type === 'STP' || orderDetail.summary.type === 'Switch' ? (
                            <div className="row padding-24px-top">
                                <div className="col-xl-9 ">
                                    <div className="border-radius-16px border-all border-bright-gray p-4" >

                                        <div className="d-flex gap-16px padding-18px-bottom border-bottom-1px border-bright-gray">
                                            <div className="col-1">
                                                <div className="border-all border-bright-gray border-radius-8px p-2">
                                                    <img src={orderDetail.summary.image}
                                                        alt="mf-image"
                                                        width={60}
                                                        height={40}
                                                        className="object-fit-contain " />
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                {
                                                    orderDetail.summary.type === 'STP' ?
                                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                            Transfer Out
                                                        </p>
                                                        :
                                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                            Switch Out
                                                        </p>
                                                }

                                                <div >
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-2">
                                                        {orderDetail.funds[0].fund_name}
                                                    </p>
                                                    <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                        {orderDetail.funds[0].category}
                                                    </span>
                                                </div>
                                                <img src={require('../../../Assets/Images/Orders/stp-arrow.png')}
                                                    className="e-arrow-down my-3 w-35px" />
                                                {
                                                    orderDetail.summary.type === 'STP' ?
                                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                            Transfer In
                                                        </p>
                                                        :
                                                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                            Switch In
                                                        </p>
                                                }
                                                <div className="d-flex align-items-center gap-12px">
                                                    <div>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-2">
                                                            {orderDetail.funds[0].switch_in_fund_name}
                                                        </p>
                                                        <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                            {orderDetail.funds[0].switch_in_fund_category}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                {/* invested amount */}
                                                <p className="color-black e-poppins-medium e-font-24 line-height-26px mb-0">
                                                    <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString("en-IN")}
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            orderDetail.funds.map((fund, key) => {
                                                return (
                                                    <div key={key} className="mt-4">
                                                        <div className="row my-3">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title="Order id:" description={fund.mt_order_id} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title="Order type:" description={orderDetail.summary.type} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title="Placed on:" description={`${orderDetail.summary.order_date} ${fund.order_time}`} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title="Folio:"
                                                                    description={fund.folio_no} symbol={false} />
                                                                {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title={orderDetail.summary.type === 'STP' ? "Transfer out units:" : "Switch out units:"}
                                                                    description={fund.units} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title={orderDetail.summary.type === 'STP' ? "Transfer out NAV:" : "Switch out NAV:"}
                                                                    description={fund.purchase_nav} />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title={orderDetail.summary.type === 'STP' ? "Transfer in units:" : "Switch in units:"}
                                                                    description={fund.switch_in_units} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title={orderDetail.summary.type === 'STP' ? "Transfer in NAV:" : "Switch in NAV:"}
                                                                    description={fund.switch_in_nav} />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-xl-5 col-lg-6 pe-0">
                                                                <Description title="Order status:"
                                                                    description={orderDetail.summary.order_status}
                                                                    status={true} />
                                                            </div>
                                                        </div>

                                                        <div className=" w-100 overflowX-auto overflowY-auto e-order-milestone-wrapper">
                                                            <div className={`position-relative margin-26px-top e-line-progress-status ps-3 border-bottom pb-4 `}>
                                                                <div className={`position-absolute bg-platinum e-progress-line ms-1 left-47`}></div>
                                                                <div className="d-flex ">
                                                                    <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Payment created" : "Order created"}
                                                                        data={fund.order_date}
                                                                        progress={3}
                                                                        index={0}
                                                                        status={fund.order_status} />
                                                                    <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Payment confirmed" : orderDetail.summary.order_status === 'Payment pending' ? orderDetail.summary.order_status : "2FA completed"}
                                                                        data={orderDetail.summary.type === 'Withdrawal' ? fund.payment_date : fund.payment_received}
                                                                        progress={
                                                                            orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                fund.payment_received !== null ? 3 : (fund.order_date !== null && fund.payment_received === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                :
                                                                                fund.payment_date !== null ? 3 : (fund.order_date !== null && fund.payment_date === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                        }
                                                                        index={1}
                                                                        status={fund.order_status} />
                                                                    <Progress title="Accepted by Exchange/RTA"
                                                                        data={fund.order_accepted}
                                                                        progress={
                                                                            orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                fund.order_accepted !== null ? 3 : (fund.payment_received !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                :
                                                                                fund.order_accepted !== null ? 3 : (fund.payment_date !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                        }
                                                                        index={2}
                                                                        status={fund.order_status} />
                                                                    <Progress title={`${orderDetail.summary.type} Out status`}
                                                                        data={fund.switch_out_status}
                                                                        progress={fund.switch_out_status !== null ? 3 : (fund.order_accepted !== null && fund.switch_out_status === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                        index={2}
                                                                        status={fund.switch_out_status} />
                                                                    <Progress title={`${orderDetail.summary.type} In status`}
                                                                        data={fund.switch_in_status}
                                                                        progress={fund.switch_in_status !== null ? 3 : (fund.switch_out_status !== null && fund.switch_in_status === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                        index={2}
                                                                        status={fund.switch_in_status} />
                                                                 
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            orderDetail.summary.order_status === 'Failed' && fund.failed_reason &&
                                                            <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink  mt-4 p-3 border-radius-24px ">
                                                                <img src={require('../../../Assets/Images/Orders/clock-failed.png')}
                                                                    className="me-2" /> <span className="mt-1">{fund.failed_reason}</span>
                                                            </div>
                                                        }
                                                    </div>

                                                )
                                            })
                                        }


                                    </div>
                                </div>
                            </div>
                        ) :
                            <div className="row padding-24px-top">
                                <div className="col-xl-9 ">
                                    <div className="border-radius-16px border-all border-bright-gray p-4" >
                                        <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px padding-18px-bottom border-bottom-1px border-bright-gray">
                                            <div className="d-flex align-items-center gap-12px">
                                                {/* mf image */}
                                                <div className="border-all border-bright-gray border-radius-8px p-2">
                                                    <img src={orderDetail.summary.image}
                                                        alt="mf-image"
                                                        width={60}
                                                        height={40}
                                                        className="object-fit-contain " />
                                                </div>

                                                {
                                                    orderDetail.funds.map((fund, key) => {
                                                        return (
                                                            <div key={key}>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {/* fund name and category */}
                                                <div>
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-2">
                                                        {orderDetail.funds[0].fund_name}
                                                    </p>
                                                    <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                        {orderDetail.funds[0].category}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* invested amount */}
                                            <p className="color-black e-poppins-medium e-font-24 line-height-26px mb-0">
                                                <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString("en-IN")}
                                            </p>
                                        </div>

                                        {
                                            orderDetail.funds.map((fund, key) => {
                                                return (
                                                    <div key={key} className="mt-4">
                                                        <div className="row my-3">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title="Order id:" description={fund.mt_order_id} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title="Order type:" description={orderDetail.summary.type} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title="Placed on:" description={`${orderDetail.summary.order_date} ${fund.order_time}`} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title={orderDetail.summary.type === 'Withdrawal' ? "Redemption NAV: " : "Purchased NAV: "}
                                                                    description={fund.purchase_nav} symbol={false} />
                                                                {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-xl-5 col-sm-6 pe-0">
                                                                <Description title="Units:" description={fund.units} />
                                                            </div>
                                                            <div className="col-xl-7 col-sm-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                <Description title="Folio:" description={fund.folio_no} />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-xl-5 col-lg-6 pe-0">
                                                                <Description title="Order status:"
                                                                    description={orderDetail.summary.order_status}
                                                                    status={true} />
                                                            </div>

                                                        </div>

                                                        <div className=" w-100 overflowX-auto overflowY-auto ">
                                                            <div className={`position-relative margin-26px-top e-line-progress-status ps-3 border-bottom   pb-4 `}>
                                                                <div className={`position-absolute bg-platinum e-progress-line ms-1`}></div>
                                                                <div className="d-flex ">
                                                                    <Progress title={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? "Order created" : orderDetail.summary.type === 'Lumpsum' ? "Payment created" : orderDetail.summary.generate_today === true ? "Payment created" : "Order created"}
                                                                        data={fund.order_date}
                                                                        progress={3}
                                                                        index={0}
                                                                        status={fund.order_status} />
                                                                    <Progress title={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? "2FA completed" : orderDetail.summary.order_status === 'Payment pending' ? orderDetail.summary.order_status : "Payment confirmed"}
                                                                        data={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? fund.payment_date : fund.payment_received}
                                                                        progress={
                                                                            orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ?
                                                                                fund.payment_date !== null ? 3 : (fund.order_date !== null && fund.payment_date === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                :
                                                                                fund.payment_received !== null ? 3 : (fund.order_date !== null && fund.payment_received === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                        }
                                                                        index={1}
                                                                        status={fund.order_status} />
                                                                    <Progress title="Accepted by Exchange/RTA"
                                                                        data={fund.order_accepted}
                                                                        progress={
                                                                            orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ?
                                                                                fund.order_accepted !== null ? 3 : (fund.payment_date !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                :
                                                                                fund.order_accepted !== null ? 3 : (fund.payment_received !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                        }
                                                                        index={2}
                                                                        status={fund.order_status} />
                                                                    <Progress title={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? "Amount transferred" : "Unit(s) allocated "}
                                                                        data={fund.portfolio_updated}
                                                                        progress={fund.portfolio_updated !== null ? 3 : (fund.order_accepted !== null && fund.portfolio_updated === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                        index={3}
                                                                        status={fund.order_status} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            orderDetail.summary.order_status === 'Failed' && fund.failed_reason &&
                                                            <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink  mt-4 p-3 border-radius-24px ">
                                                                <img src={require('../../../Assets/Images/Orders/clock-failed.png')}
                                                                    className="me-2" /> <span className="mt-1">{fund.failed_reason}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* {
                                DETAIL_DATA.summary.is_purchase === true &&
                                <div className="d-flex justify-content-end margin-32px-top">
                                    <GradientButton label="Repeat order"
                                        className="px-3 padding-12px-tb e-font-16" />
                                </div>
                            } */}
                                    </div>
                                </div>
                            </div>
                    }


                </div>


            </Fragment>
    )
}

export default AdminFundOrderdetail 