/*
 *   File :  portfolio-funds.js
 *   Author URI : www.evoqins.com
 *   Description : Popup component which allows the user to make any transactions from the invested funds or goals.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 09-11-2023
 */
/* import packages */

/* import components */
import { DataTableContainer } from "../FormElements";

/* import styles */
import Colors from '../../Styles/color.module.scss';

const PortfolioFundsTable = (props) => {

    const MF_COLUMNS = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: "450px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="padding-6px-all border-radius-8px border-all border-bright-gray">
                        <img draggable={false} src={row['image']} alt={row['fund_name']}
                            width={46}
                            height={46}
                            className="object-fit-contain" />
                    </div>
                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                        <div className="d-flex gap-8px ">
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px">
                                {row['category']}
                            </span>

                            <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise white-space-nowrap">{row['holding_type']}</span>
                            <span className="border-radius-16px color-primary-color e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-primary-color border-all bg-alice-blue w-50px white-space-nowrap text-center">{row['type']}</span>
                        </div>
                    </div>

                </div>
        },
        {
            name: 'Invested',
            selector: row => row['invested_amount'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current',
            selector: row => row['current_amount'],
            sortable: true,
            // width: "150px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Total units',
            selector: row => row['units'],
            sortable: true,
            cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0"
                onClick={() => _handleCardClick(row)}>{row['units']}</p>
        },
        {
            name: 'Returns',
            selector: row => row['returns'],
            sortable: true,
            cell: row => <p className={` ${row['returns_percentage'] < 0 ? 'color-red' : 'color-sea-green'} e-poppins-medium e-font-16 line-height-18px mb-0`}>
                {row['returns'].toLocaleString('en-IN')}({row['returns_percentage']}%)
            </p>

        },

    ];

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "0 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "16px",
                fontFamily: "Poppins-Medium",
                lineHeight: "18px",
                color: Colors.black
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Medium",
                lineHeight: "18px",
                cursor: "pointer",
                color: Colors.black,
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "16px 0px 16px 16px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "16px 0px 16px 16px",
                }
            },
        },
    }


    const _handleRowHover = (row) => {

    }

    const _handleRowRemoveHover = () => {

    }


    const _handleCardClick = (row) => {
        console.log("row", row)
        props.onClick(row.id);
    }

    return (
        <div className="e-portfolio-funds">
            <DataTableContainer columns={MF_COLUMNS}
                data={props.data}
                pagination={false}
                customStyles={TABLE_STYLE}
                onRowMouseEnter={_handleRowHover}
                onRowMouseLeave={_handleRowRemoveHover}
                rowClick={(row) => _handleCardClick(row)} />
        </div>

    )
}

export default PortfolioFundsTable