/*
 *   File :  portfolio.js
 *   Author URI : https://evoqins.com
 *   Description : Container file which shows investments summary.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../../../../Components/Cta";

import { Icon } from "../../../../Components/Icon";
import { CustomLoader } from "../../../../Components/Other";
import { NavigationHeader } from "../../../../Components/Header";
import { DataTableContainer } from "../../../../Components/FormElements";

import Colors from "../../../../Styles/color.module.scss";
import { _getPortFolioDetail } from "../../../../Helper/api";


const PAGE_NAVIGATION = [
    "Dashboard",
    "Users",
    "Portfolio details"
]


const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            color: Colors.black,
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
                justifyContent: `start !important`
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
                justifyContent: `start !important`
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const FundPortfolioDetail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showArrow, setShowArrow] = useState(0);
    const [portFolioDetail, setPortFolioDetail] = useState({});
    const [loader, setLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            // API - get portfolio details
            console.log("LOcation", location)
            _getPortFolioDetail(location.state.user_id, location.state.id, location.state.is_goal)
                .then((response) => {
                    console.log('response :: ', response)
                    setPortFolioDetail(response);
                    setLoader(false);
                }).catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error"
                    });
                });
        } else {
            navigate("/portfolio");
        }
    }, [location]);


    const INVESTMENT_COLUMN = [
        {
            name: 'Date',
            selector: row => row['created'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Trans. type',
            selector: row => row['transaction_type'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['transaction_type']}
                </p>
        },
        {
            name: 'Type',
            selector: row => row['order_type'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    {row['order_type']}
                </p>
        },
        {
            name: 'Units',
            selector: row => row['units'],
            sortable: true,
            cell: row => <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                onClick={() => _handleCardClick(row)}>
                {row['units'] > 0 ? row['units'] : "-"}
            </p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            cell: row => row.amount > 0 ?
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>
                    {row['amount'].toLocaleString('en-IN')}</p> :
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>-</p>
        },
        {
            name: 'NAV',
            selector: row => row['nav'],
            sortable: true,

            cell: row => row.nav > 0 ?
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium">₹</span>
                    {row['nav'].toLocaleString('en-IN')}</p> :
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 "
                    onClick={() => _handleCardClick(row)}>-</p>
        },
        {
            name: 'Status',
            selector: row => row['order_status'],
            sortable: true,
            cell: (row) => {
                // define colouur and select icon from here
                return (
                    row['order_status'] === "Processing" ?
                        <div className="d-flex align-items-center justify-content-center"
                            onClick={() => _handleCardClick(row)}>
                            <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['order_status']}
                            </p>
                            <img draggable={false} src={require("../../../../Assets/Images/Orders/processing.png")} alt="Processing"
                                width={24}
                                height={24}
                            />
                        </div>
                        :
                        row['order_status'] === "Success" ?
                            <div className="d-flex align-items-center justify-content-center "
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img draggable={false} src={require("../../../../Assets/Images/Orders/success.png")}
                                    alt="Success"
                                    width={24}
                                    height={24}
                                />
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center"
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img draggable={false} src={require("../../../../Assets/Images/Orders/failed.png")}
                                    alt="Failed"
                                    width={24}
                                    height={24}
                                />
                            </div>
                )
            }
        },
        {
            name: '',
            sortable: true,
            width: "56px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _handleCardClick = (selected_order) => {

    }

    const _handleRedeem = () => {

    }

    const FundDetail = (props) => {
        return (
            <div className="d-flex align-items-center">
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0 me-1">{props.heading}:</p>
                {
                    !props.returns ? (
                        <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">
                            {props.rupee && <span className="e-inter-medium">₹</span>}{props.description.toLocaleString("en-IN")}
                        </p>
                    ) : (
                        <p className={`${props.returns < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-16 line-height-16px mb-0`}>({props.returns}%)</p>
                    )
                }

            </div>
        )
    }


    return (
        <>
            {
                loader === true ?
                    <CustomLoader />
                    :
                    <Fragment>
                        <NavigationHeader
                            type={2}
                            data={PAGE_NAVIGATION}
                            title="Investments details" />
                        <div className="e-fund-portfolio-detail ps-4 pe-lg-5 pe-md-2 pe-2 pt-2">

                            <div className="border-radius-24px border-all border-bright-gray p-4">
                                <div className="d-flex align-items-center justify-content-between padding-26px-bottom border-bottom-1px border-bright-gray">
                                    {/* amc image and fund name */}
                                    <div className="d-flex align-items-center gap-12px">
                                        <div className="p-2 border-radius-8px border-all border-bright-gray">
                                            <img draggable={false} src={portFolioDetail.investment_details.image}
                                                alt={portFolioDetail.investment_details.fund_name}
                                                width={40}
                                                height={40} />
                                        </div>
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{portFolioDetail.investment_details.fund_name}</p>
                                    </div>

                                    {/* nav, units and total invested amount */}
                                    <div className="d-flex gap-24px">
                                        <FundDetail heading="Avg. NAV"
                                            description={portFolioDetail.investment_details.avg_nav}
                                            rupee={true} />
                                        <FundDetail heading="Units"
                                            description={portFolioDetail.investment_details.units}
                                            rupee={false} />
                                        <FundDetail heading="Total invested"
                                            description={portFolioDetail.investment_details.invested_amount}
                                            rupee={true} />
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="d-flex gap-36px">
                                        <FundDetail heading="Current amount"
                                            description={portFolioDetail.investment_details.current_amount}
                                            rupee={true} />

                                        <FundDetail heading="Returns"
                                            description={portFolioDetail.investment_details.returns}
                                            rupee={false}
                                            returns={portFolioDetail.investment_details.returns_percentage} />
                                    </div>

                                    <div className="d-flex gap-16px align-items-center position-relative">
                                        <OutlineButton label="Start new SIP"
                                            disabled={!portFolioDetail.investment_details.sip_enabled}
                                            className="py-2 px-4 e-font-16"
                                            handleClick={_handleRedeem} />
                                        <OutlineButton label="Redeem"
                                            className="padding-12px-all e-font-16 color-black"
                                            handleClick={_handleRedeem} />
                                        <GradientButton label="Invest more"
                                            disabled={!portFolioDetail.investment_details.lumpsum_enabled}
                                            className="px-3 py-2 e-font-16"
                                            handleClick={_handleRedeem} />
                                    </div>
                                </div>
                            </div>

                            <p className="color-black e-poppins-medium e-font-16 line-height-34px mt-3 mb-4">Transaction history</p>
                            <DataTableContainer columns={INVESTMENT_COLUMN}
                                data={portFolioDetail.orders}
                                selectableRows={false}
                                pagination={false}
                                customStyles={TABLE_STYLE}
                                onRowMouseEnter={_handleRowHover}
                                onRowMouseLeave={_handleRowRemoveHover}
                                rowClick={_handleCardClick} />
                        </div>

                    </Fragment>

            }
        </>
    )
}

export default FundPortfolioDetail