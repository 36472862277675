/*
 *   File : text-input.js
 *   Author URI : www.evoqins.com
 *   Description : Input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";

/*import components */
import Icon from '../Icon/icon';

/*import styles*/
import style from "../../Styles/Components/input.module.scss";

const CustomTextArea = React.memo(function CustomTextArea(props) {
    //eslint-disable-next-line
    const [value, setValue] = useState(props.value);

    const INVALID_CHARS = [
        "-",
        "+",
        "e",
        ","
    ];

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    /* ---- func to be implemented on press of enter ----*/
    const _keyPress = (e) => {
        if (props.type === "number") {
            if (INVALID_CHARS.includes(e.key)) {
                e.preventDefault();
            }
        }
    }


    return (
        <Fragment>

            <div className={props.error !== null ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
                {/* Text area */}
                <textarea value={props.value}
                    id={props.id}
                    type='text'
                    className={`${style.e_textarea} color-charleston-green e-poppins-regular e-font-14 w-100 no-outline`}
                    onChange={(e) => props.valueChange(e.target.value)}
                    required
                    onKeyDown={_keyPress}
                    rows={props.rows ? props.rows : 6}>
                </textarea>
                {/* Label */}
                <label className={`position-absolute left-15px e-poppins-regular cursor-pointer e-font-14 ${style.e_textarea_label}`}
                    for={props.id}
                    style={props.style}>
                    {props.label}
                    <span className="color-red">
                        {props.postfix}
                    </span>
                </label>

                {
                    props.hide_error_text ?
                        null
                        :
                        props.error &&
                        <div className={`position-absolute d-flex align-items-center bottom-0 ${props.errorClass}`}>
                            <Icon icon="info-circle"
                                size={16}
                                className="me-1" />
                            <p className='color-red mb-0 e-font-12 my-0 '>
                                {props.error}
                            </p>
                        </div>
                }
            </div>
        </Fragment>
    );
});

CustomTextArea.defaultProps = {
    valueChange: () => { },
    id: 1,
    value: "",
    error: null,
    is_number: false,
    placeholder: "",
    title: "",
    className: "",
    labelClass: 'bg-white',
    errorClass: ''
}

export default CustomTextArea;