
/*
 *   File : list-ips.js
 *   Author URI : https://evoqins.com
 *   Description : List all sub admin ip address
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { Modal } from "bootstrap";

import { NavigationHeader } from "../../../Components/Header";
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { Icon } from "../../../Components/Icon";
import { DataTableContainer } from "../../../Components/FormElements";
import { GradientButton } from "../../../Components/Cta";
import { SectionTitle } from "../../../Components/Title";
import { AddIPModal, DeleteIPModal } from "../../../Components/Modal";

import APIService from "../../../Services/api-service";

import Colors from '../../../Styles/color.module.scss';

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 0px 16px 0px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "18px",
        },
    },
    cells: {
        style: {
            justifyContent: "start !important",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            justifyContent: "start !important",
            color: Colors.black,
            '&:first-child': {
                paddingLeft: "0px",
            },
            '&:nth-child(2)': {
                paddingLeft: "14px !important",
                paddingRight: "0px !important",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

export default function IPListing() {

    const [ipList, setIpList] = useState([]);
    const [apiLoader, setApiLoader] = useState(true);
    const [showDeletedRed, setShowDeleteRed] = useState(-1);
    const [openIdModal, setOpenIpModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const IP_COLUMN = [
        {
            name: 'Action',
            selector: row => row['id'],
            sortable: false,
            left: true,
            cell: (row, index) => <div className="d-flex">
                <p className='color-primary-color line-height-16px e-poppins-regular e-font-14  my-0 cursor-pointer e-tool-tip-container position-relative'
                    data-tooltip-content="Edit selected"
                    onClick={()=> _handleEditButtonClick(row)}>
                    Edit
                    <Icon icon="Pen-square"
                        size={24}
                        className="ms-1" />
                </p>
                <span className={`ms-4 e-font-16 line-height-16px e-poppins-regular cursor-pointer e-tool-tip-container position-relative`}
                    data-tooltip-content="Delete selected"
                    onClick={()=> _handleDeleteButtonClick(row)}
                    onMouseEnter={() => {
                        setShowDeleteRed(index);
                    }}
                    onMouseLeave={() => {
                        setShowDeleteRed(-1);
                    }}>
                    <Icon icon={showDeletedRed === index ? 'delete-red' : "delete"} size={24} className='me-2' />
                </span>
            </div>,
        },
        {
            name: 'IP address',
            selector: row => row['ip_address'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" > {row['ip_address']}</span>,
        },
        {
            name: 'Created by',
            selector: row => row['modified_by'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" > {row['modified_by']}</span>,
        },
        {
            name: 'Created date',
            selector: row => row['created'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" > {row['created']}</span>,
        },
    ]

    useEffect(() => {
        window.scrollTo({
            top: '0',
            behavior: 'smooth'
        });
       
    }, []);

    useEffect(() => {
        _getIpData();
    }, []);

    useEffect(()=>{
        if (openIdModal === true) {
            const modal = new Modal(document.getElementById('add-ip'));
            modal.show();
        } 
    },[openIdModal]);

    useEffect(()=> {
        if (openDeleteModal === true) {
            const modal = new Modal(document.getElementById('delete-ip'));
            modal.show();
        }
    },[openDeleteModal]);

    // handle add new button
    function _handleButton () {
        setOpenIpModal(true);
    }

    // handle edit button
    function _handleEditButtonClick (row) {
        setSelectedRow(row);
        setOpenIpModal(true);
    }

    // handle delete button
    function _handleDeleteButtonClick (row) {
        setSelectedRow(row);
        setOpenDeleteModal(true);
    }

    // callback close modal
    function _callBackCloseModal () {
        setSelectedRow({});
        setOpenIpModal(false);
    }

    // modal success callback
    function _successCallBack() {
        setOpenIpModal(false);
        setSelectedRow({});
        _getIpData();
    }

    // callback close modal delete
    function _callBackDeleteModal () {
        setSelectedRow({});
        setOpenDeleteModal(false);
    }

    // function callback success delete
    function _successCallBackDeleteModal () {
        setSelectedRow({});
        setOpenDeleteModal(false);
        _getIpData();
    } 

    // API - get ip list
    function _getIpData() {
        let url = 'ip-validation/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setIpList(response.data);
            }else{
                setIpList([]);
            }
            setApiLoader(false);
        })
    };


    return (
        <>
            <NavigationHeader type={1}
                title="Manage IP address" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 pt-2">
                <div className="row pt-1">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="Whitelist IP Address" 
                            className="mt-3"/>
                        <GradientButton label="Add new IP"
                            onPress={_handleButton} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3" >
                        {
                            apiLoader === true ?
                                <div className="h-80vh">
                                    <CustomLoader />
                                </div>
                                :
                                ipList.length !== 0 ?
                                    (<div className="min-height-50vh">
                                        <div id="e-table-body" className="position-relative">
                                            <DataTableContainer columns={IP_COLUMN}
                                                data={ipList}
                                                selectableRows={false}
                                                pagination={false}
                                                customStyles={TABLE_STYLE}
                                                defaultSortFieldId={1}
                                                rowClick={(row) => {
                                                    // _handleCardClick(row)
                                                }} />
                                        </div>

                                    </div>)
                                    :
                                    <EmptyScreen title="No Whitelisted IP found!" />
                        }

                    </div>
                </div>
            </div>
            {
                openIdModal === true && (
                    <AddIPModal close={_callBackCloseModal}
                        successCallBack={_successCallBack}
                        data={selectedRow}/>
                )
            }
            {
                openDeleteModal === true && (
                    <DeleteIPModal close={_callBackDeleteModal}
                        successCallBack={_successCallBackDeleteModal}
                        data={selectedRow}/>
                )
            }
        </>
    )
}