/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";

import { GradientButton, SecondaryButton } from "../Cta";
import Icon from "../Icon/icon";
import { OTPInput, CustomSearchSelectBox, CustomTextInput } from "../FormElements";

import style from "../../Styles/Components/unlock.module.scss";

import { closeModal } from "../../Helper/helper-function";

import APIService from "../../Services/api-service";

const ActivateSIPModal = (props) => {

    const firstPINref = useRef(null);

    const [pinString, setPinString] = useState("");

    const [errorMessage, setErrorMessage] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState("");
    const [remark, setRemark] = useState("");
    const [reasonError, setReasonError] = useState('');
    const [remarkError, setRemarkError] = useState('');

    // Focus the first input 
    // useEffect(() => {
    //     const my_modal = document.getElementById("active-sip");
    //     my_modal.addEventListener("shown.bs.modal", () => {
    //         firstPINref.current.click();
    //     });
    // }, []);

    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("active-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setPinString('');
        });
    }, []);

    // update button loading status 
    useEffect(() => {
        setButtonLoader(props.loader);
    }, [props.loader]);

    useEffect(() => {
        const _handleKeyPress = (event) => {
            // Check if Ctrl key (or Command key on Mac) is pressed and 'a' key is pressed
            if (event.ctrlKey && event.key === 'a') {
                event.preventDefault(); // Prevent the default behavior (text selection)
            }
        };

        // Attach the event listener to the modal content
        const modalContent = document.getElementById('active-sip');

        modalContent.addEventListener('keydown', _handleKeyPress);

        // Clean up the event listener when the component is unmounted
        return () => {
            modalContent.removeEventListener('keydown', _handleKeyPress);
        };
    }, []);

    useEffect(() => {
        if (props.cancelSip === true) {
            _getReasons();
        }
    }, [props.cancelSip]);

    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    // submit button click 
    async function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Please enter OTP");
            return;
        }
        if (selectedReason === "") {
            setReasonError("Reason is required");
            return;
        } else if (selectedReason.value == 13 && remark === "") {
            setRemarkError("Please specify reason");
            return;
        }
        try {
            const result = await props.handleOTPSubmission(pinString, selectedReason.value, remark);
            // closing modal after api call;
            if (result === true) {
                const close_button = document.getElementById("close-modal");
                close_button.click();
            }

        } catch (error) {

        }
    }

    function _getReasons() {
        let url = 'sip/reason-list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let reasons = response.data.map((item) => {
                    return { value: item.id, label: item.name }
                })
                setReasons(reasons);
            }
        })
    }

    return (
        <div id="active-sip"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="active-sip"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all  ${style.e_content} `}>
                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>

                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                        className="mx-auto" />


                    <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0 text-center">
                        User Consent OTP
                    </h6>
                    <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5 letter-spacing-point28 text-center">
                        We have sent a consent OTP to the user's email and phone. Please collect it and verify to proceed with the update.
                    </p>




                    <div className="d-flex flex-column align-items-center text-start mt-3 mb-2">
                        <OTPInput pinString={pinString}
                            id="sip-otp"
                            autoFocus={true}
                            pinError={errorMessage}
                            disabled={buttonLoader}
                            handleChange={_handlePin} />
                    </div>

                    <div className="col-6 offset-3 mt-4 mb-2">
                        <CustomSearchSelectBox options={reasons}
                            value={selectedReason}
                            label="Reason"
                            error={reasonError}
                            hide_error_text={false}
                            placeholder=""
                            postfix="*"
                            onSelectChange={(value) => {
                                setSelectedReason(value);
                                setReasonError("");
                            }} />
                    </div>

                    {
                        selectedReason !== null && selectedReason.value == 13 &&
                        <div className="col-6 offset-3 mt-4">
                            <CustomTextInput value={remark}
                                error={remarkError}
                                label="Specify reason"
                                postfix='*'
                                valueChange={(input_value) => {setRemark(input_value); setRemarkError("")}} />
                        </div>
                    }


                    <div className="d-flex  justify-content-center mt-4 w-100">

                        <SecondaryButton label="Cancel"
                            className="padding-12px-tb e-font-16 "
                            cancel="modal"
                        />
                        <GradientButton label={props.label}
                            loading={props.loading}
                            className="padding-12px-tb e-font-16 ms-3"
                            onPress={_verifyPin}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

ActivateSIPModal.defaultProps = {
    label: "Resume SIP",
    updateList: () => { },
    handleOTPSubmission: () => { }
}

export default ActivateSIPModal;