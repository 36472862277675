/*
 *   File : login.js
 *   Author URI : www.evoqins.com
 *   Description : Login screen.
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 28-12-2023
 */

/* import packages */
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

/* import components */
import { CustomTextInput } from "../../Components/FormElements";
import { GradientButton, LinkText } from "../../Components/Cta";
import { Icon } from "../../Components/Icon";
import { toast } from "react-toastify";

/* import services */
import APIService from "../../Services/api-service";
import { _resendOTP } from "../../Helper/api";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [timer, setTimer] = useState(30);
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (location.state !== null) {
            setEmail(location.state.email);
        }
    }, [location])

    useEffect(() => {
        if (otpSent === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [otpSent, timer]);

    // Calculate whether the Resend OTP button should be disabled
    const isResendButtonDisabled = timer > 0 || loader === true;

    function _handleEmailInput(input) {
        setEmail(input);
        setEmailError('');
    }

    function _handlePasswordInput(input) {
        setPassword(input);
        setPasswordError('');
    }

    // handle otp input
    function _handleOTPInput(value) {
        if (value.length <= 4) {
            setOtp(value);
        }
        setOtpError('');
    }

    // navigate to forgot password page
    function _navigateForgotPassword() {
        navigate('/forgot-password', {
            state: {
                email: email
            }
        });
    }

    // Validate OTP
    function _validateOTP(e) {
        e.preventDefault();
        if (otp.length !== 4) {
            setOtpError('OTP must be 4 digits');
            return;
        }
        if (!/^\d+$/.test(otp)) {
            setOtpError('Invalid OTP');
            return;
        }
        _verifyOTP();
    }


    function _prevStep() {
        setOtpSent(false);
    }


    /*---- validation for password and email ----*/
    function _validate(e) {
        e.preventDefault();
        let valid = true;
        // eslint-disable-next-line
        const email_validator = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/; // email validator regex

        // eslint-disable-next-line
        // const password_validator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; //password validator regex
        if (email.length === 0) {
            valid = false;
            setEmailError("Email is required");
        }
        if (email.length !== 0 && !email_validator.test(email)) {
            valid = false;
            setEmailError("Invalid email");
        }


        if (password === '') {
            valid = false;
            setPasswordError("Password is required");
        }

        if (valid === true) {
            _login()
        }

    }

    // API - login 
    const _login = () => {
        setLoader(true);
        const url = "auth/login";
        const data = {
            "email": email,
            "password": password
        }
        APIService(true, url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setOtpSent(true);
                toast.success(`OTP has been sent to ${email}`, {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
                setPassword('');
            }
            setLoader(false);
        });
    }

    //API - verify otp
    const _verifyOTP = () => {
        setLoader(true);
        const url = "auth/verify-otp";
        const data = {
            "email": email,
            "otp": otp,
            "type": "LOGIN"
        }
        APIService(true, url, data).then((response) => {

            if (response.status_code === 200) {
                Cookies.set("admin_session", response.data.temp_token);
                setTimeout(() => {
                    window.location.href = "/"
                }, 20);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                setOtp("");
            }
            setLoader(false);
        });
    }

    // API - resend OTP
    const _resend = () => {
        setTimer(30);
        const data = {
            email: email,
            type: 'LOGIN'
        }

        _resendOTP(data).then((response) => {
            toast.dismiss();
            toast.success('OTP resent', {
                type: 'success'
            })
        });
    }

    return (
        <Fragment>
            <div className="bg-light-pastel-blue-gray e-login-bg padding-32px-all w-100vw h-100vh overflowY-scroll">
                <Link to="/">
                    <img draggable={false} src={require('../../Assets/Images/Sidebar/master-trust-logo.svg').default} alt="MT_logo"
                        className="sticky-top e-cover-image" />
                </Link>
                <div className="w-100 d-flex justify-content-center pt-5">
                    {
                        otpSent ?
                            <div className="bg-white py-5 padding-72px-lr border-radius-16px w-42vw position-relative">

                                <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">
                                    <Icon icon="pagination-previous"
                                        size={24}
                                        className="position-absolute left-24px cursor-pointer top-12"
                                        onClick={_prevStep} />
                                    Confirm OTP</h3>
                                <p className="color-light-black e-poppins-regular e-font-16 mt-4 mb-0">
                                    Enter 4 digit OTP sent to your registered email ID
                                </p>
                                <form onSubmit={(e) => _validateOTP(e)}>
                                    <div className="row pt-4">
                                        <div className="col-12">
                                            <CustomTextInput label="Enter OTP"
                                                postfix="*"
                                                type={showPassword ? "text" : "otp"}
                                                max={6}
                                                name="OTP"
                                                mandatory={true}
                                                error={otpError}
                                                value={otp}
                                                password_icon={true}
                                                showPassword={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                                valueChange={_handleOTPInput} />
                                        </div>
                                        <div className="col-12">
                                            <p className="color-light-black e-poppins-regular e-font-14 mt-4 d-flex justify-content-center align-items-center">
                                                Didn't get OTP? <LinkText type={2}
                                                    disabled={isResendButtonDisabled}
                                                    title="Send again"
                                                    className='mx-2'
                                                    onClick={_resend} /> in {timer >= 10 ? timer : `0${timer}`}s
                                            </p>

                                        </div>

                                        <div className="w-100 d-flex justify-content-center margin-118px-bottom padding-32px-top">
                                            <GradientButton label="Verify & proceed"
                                                className='padding-128px-lr'
                                                loading={loader}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="bg-white py-5 padding-72px-lr border-radius-16px w-38vw">
                                <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">Login</h3>
                                <form onSubmit={(e) => _validate(e)}
                                >
                                    <div className="row pt-5">
                                        <div className="col-12 mb-4">
                                            <CustomTextInput type="text"
                                                label="Email"
                                                postfix="*"
                                                error={emailError}
                                                value={email}
                                                isAuthInput={true}
                                                name="email"
                                                // hide_error_text={true}
                                                valueChange={_handleEmailInput} />
                                        </div>

                                        <div className="col-12 pb-3 mt-3">
                                            <CustomTextInput label="Password"
                                                postfix="*"
                                                type={showPassword ? "text" : "password"}
                                                // hide_error_text={true}
                                                value={password}
                                                error={passwordError}
                                                password_icon={true}
                                                isAuthInput={true}
                                                showPassword={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                                valueChange={_handlePasswordInput} />
                                        </div>

                                        <div className="text-center">
                                            <LinkText title="Forgot password?"
                                                type={2}
                                                onClick={_navigateForgotPassword} />
                                        </div>

                                        <div className="w-100 d-flex justify-content-center margin-40px-bottom padding-32px-top">
                                            <GradientButton label="Login"
                                                className='padding-128px-lr'
                                                loading={loader} />
                                        </div>
                                    </div>
                                </form>
                            </div>}
                </div>
            </div>

        </Fragment>
    )
}

export default Login;