/*
 *   File : report.js
 *   Author URI : https://evoqins.com
 *   Description : Report page
 *   Integrations : NA
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { GradientButton, OutlineButton } from "../../../../Components/Cta";
import { DateRangeFilterPopup } from "../../../../Components/Popups";
import { DatePicker, CustomSearchSelectBox } from "../../../../Components/FormElements";
import { CustomTab } from "../../../../Components/Tab";
import { Icon } from "../../../../Components/Icon";

import APIService from "../../../../Services/api-service";
import { CustomLoader } from "../../../../Components/Other";



const TAB_DATA = ['Transactions', 'Portfolio summary', 'Capital gain'];

export default function Report(props) {

    const [transactionStartDate, setTransactionStartDate] = useState(null);
    const [transactionsEndDate, setTransactionsEndDate] = useState(null);
    const [showTransactionPopUp, setShowTransactionPopUp] = useState(false);
    const [transactionRange, setTransactionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [capitalStartDate, setCapitalStartDate] = useState(null);
    const [capitalEndDate, setCapitalEndDate] = useState(null);
    const [capitalRange, setCapitalRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [showCapitalPopUp, setShowCapitalPopUp] = useState(false);
    const [portfolioDate, setPortfolioDate] = useState(null);
    const [transactionLoader, setTransactionLoader] = useState(false);
    const [capitalLoader, setCapitalLoader] = useState(false);
    const [portfolioLoader, setPortfolioLoader] = useState(false);
    const [tabIndex, setTabIndex] = useState(1);
    const [reportHtml, setReportHtml] = useState(null);
    const [reportLoader, setReportLoader] = useState(false);
    const [financialYears, setFinancialYears] = useState([]);
    const [year, setYear] = useState('');

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (tabIndex === 3) {
            _filterData();
        }
    }, [tabIndex]);

    // handle transaction date picker
    function _handleTransactionRangeChange(newRange) {
        setShowTransactionPopUp(false);
        setTransactionStartDate(newRange.startDate);
        setTransactionsEndDate(newRange.endDate);
        setTransactionRange(newRange);
    };

    // clear transaction date picker
    function _handleTransactionDate(clear) {
        setShowTransactionPopUp(!showTransactionPopUp);
        if (clear) {
            setTransactionStartDate(null);
            setTransactionsEndDate(null);
            setTransactionRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
    }

    // handle capital date picker
    function _handleCapitalRangeChange(newRange) {
        setShowCapitalPopUp(false);
        setCapitalStartDate(newRange.startDate);
        setCapitalEndDate(newRange.endDate);
        setCapitalRange(newRange);
    };

    // clear capital date picker
    function _handleCapitalDate(clear) {
        setShowCapitalPopUp(!showCapitalPopUp);
        if (clear) {
            setCapitalStartDate(null);
            setCapitalEndDate(null);
            setCapitalRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
    };

    // handle tab change
    function _handleTabSelect(index) {
        setTabIndex(index);
        setReportHtml(null);
        setReportLoader(false);
    }

    function _filterData() {
        let url = 'reports/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let years = response.data.map((item, key) => {
                    return { value: item.id, label: item.name }
                })
                setFinancialYears(years);
            }
        })
    }
    // file downloader
    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // API :: transaction report
    function _downloadTransactionsReport() {
        setTransactionLoader(true);
        let start_date = null;
        let end_date = null;
        if (transactionStartDate !== null && transactionsEndDate !== null) {
            start_date = format(transactionStartDate, "dd-MM-yyyy");
            end_date = format(transactionsEndDate, "dd-MM-yyyy");
        }
        let url = 'reports/transaction-report';
        let payload = JSON.stringify({
            from_date: start_date,
            till_date: end_date,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.url !== null) {
                    _fileDownloader(response.data.url, 'transactions.pdf')
                } else {
                    toast.dismiss();
                    toast.error('No reports for download!', {
                        type: 'error'
                    })
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setTransactionLoader(false);
        });
    };

    // API :: capital report 
    function _downloadCapitalReport() {
        setCapitalLoader(true);
        let start_date = null;
        let end_date = null;
        // if (capitalStartDate !== null && capitalEndDate !== null) {
        //     start_date = format(capitalStartDate, "dd-MM-yyyy");
        //     end_date = format(capitalEndDate, "dd-MM-yyyy");
        // }
        let url = 'reports/capitalgain-report';
        let payload = JSON.stringify({
            // from_date: start_date,
            // till_date: end_date,
            file_type: 1,
            id: year.value ? year.value : null,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.url !== null) {
                    _fileDownloader(response.data.url, 'transactions.pdf')
                } else {
                    toast.dismiss();
                    toast.error('No reports for download!', {
                        type: 'error'
                    })
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setCapitalLoader(false);
        });
    }

    // API :: portfolio summary
    function _downloadPortfolio() {
        setPortfolioLoader(true);
        let url = 'reports/portfolio';
        let payload = JSON.stringify({
            till_date: portfolioDate,
            file_type: 1,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.url !== null) {
                    _fileDownloader(response.data.url, 'transactions.pdf')
                } else {
                    toast.dismiss();
                    toast.error('No reports for download!', {
                        type: 'error'
                    })
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setPortfolioLoader(false);
        });
    }

    function _viewTransactionReport() {
        let start_date = null;
        let end_date = null;
        if (transactionStartDate !== null && transactionsEndDate !== null) {
            start_date = format(transactionStartDate, "dd-MM-yyyy");
            end_date = format(transactionsEndDate, "dd-MM-yyyy");
        }
        setReportLoader(true);
        let url = 'reports/transaction-report-list';
        let payload = JSON.stringify({
            from_date: start_date,
            till_date: end_date,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setReportHtml(response.data);
                toast.dismiss();
                toast.success('Transaction report generated', {
                    type: 'success'
                })
            } else {
                setReportHtml(null);
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setReportLoader(false);
        })

    }

    function _viewPortfolioReport() {
        setReportLoader(true);
        let url = 'reports/portfolio-list';
        let payload = JSON.stringify({
            till_date: portfolioDate,
            file_type: 1,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setReportHtml(response.data);
                toast.dismiss();
                toast.success('Portfolio report generated', {
                    type: 'success'
                })
            } else {
                setReportHtml(null);
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setReportLoader(false);
        })

    }

    function _viewCapitalReport() {
        setReportLoader(true);
        let url = 'reports/capitalgain-report-list';
        let start_date = null;
        let end_date = null;
        // if (capitalStartDate !== null && capitalEndDate !== null) {
        //     start_date = format(capitalStartDate, "dd-MM-yyyy");
        //     end_date = format(capitalEndDate, "dd-MM-yyyy");
        // }
        let payload = JSON.stringify({
            // from_date: start_date,
            // till_date: end_date,
            id: year.value ? year.value : null,
            customer_id: props.userId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setReportHtml(response.data);
                toast.success();
                toast.success('Capital gain report generated', {
                    type: 'success'
                })
            } else {
                setReportHtml(null);
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setReportLoader(false);
        })

    }

    return (
        <>
            <div className="e-page-container margin-80px-bottom min-height-100vh ">

                <div className="row">
                    <div className="col-12">
                        <div className="e-tap-wrapper">
                            <CustomTab data={TAB_DATA}
                                wrapperClass={'gap-16px'}
                                index={tabIndex}
                                className="px-0 padding-10px-tb e-order-tab mb-4"
                                onSelectTab={(value) => _handleTabSelect(value)} />
                        </div>
                    </div>
                    {
                        tabIndex === 1 ? (
                            <div className="row gx-2">
                                <div className="col-md-4">
                                    <div className="position-relative  e-margin-left-auto">
                                        <p className={`border-radius-6px border-all bg-white px-2 padding-12px-tb mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px cursor-pointer position-relative ${showTransactionPopUp === true ? 'border-primary-color' : 'border-bright-gray'}`}
                                            onClick={() => {
                                                _handleTransactionDate(false);
                                            }}>
                                            {transactionStartDate === null ? 'Start date' : format(transactionStartDate, "dd MMM yy")} - {transactionsEndDate === null ? 'End date' : format(transactionsEndDate, "dd MMM yy")}
                                            <Icon icon="calendarIcon"
                                                className='position-absolute right-10px top-14px'
                                                size={20} />
                                        </p>
                                        {
                                            showTransactionPopUp === true &&
                                            <DateRangeFilterPopup
                                                maxDate={new Date()}
                                                initialRange={transactionRange}
                                                cancel={() => {
                                                    _handleTransactionDate(true)
                                                }}
                                                leftAlign={true}
                                                onUpdate={_handleTransactionRangeChange} />
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-6 col-12 text-end mt-lg-0 mt-md-0 mt-sm-0 mt-4">
                                    <OutlineButton label="View Report"
                                        className="padding-14px-tb px-3 e-font-16 me-3"
                                        loading={reportLoader}
                                        onPress={_viewTransactionReport} />
                                    <GradientButton label="Download Report"
                                        className="padding-14px-tb padding-18px-lr e-font-16"
                                        loading={transactionLoader}
                                        onPress={_downloadTransactionsReport} />
                                </div>

                            </div>
                        ) : tabIndex === 2 ? (
                            <div className="row   gx-2">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 position-relative">
                                    <DatePicker label="Choose date"
                                        value={portfolioDate}
                                        maxDate={new Date()}
                                        dateFormat={'dd-MM-yyyy'}
                                        displayFormat={'dd MM yyyy'}
                                        removeLabelOnFocus={true}
                                        dob={(value) => setPortfolioDate(value)} />
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-6 col-12 text-end mt-lg-0 mt-md-0 mt-sm-0 mt-4">
                                    <OutlineButton label="View Report"
                                        className="padding-12px-tb px-3 e-font-16 me-3"
                                        loading={reportLoader}
                                        onPress={_viewPortfolioReport} />
                                    <GradientButton label="Download Report"
                                        className="padding-12px-tb padding-18px-lr e-font-16"
                                        loading={portfolioLoader}
                                        onPress={_downloadPortfolio} />
                                </div>
                            </div>
                        ) : (
                            <div className="row gx-2">
                                {/* <div className="col-md-4 ">
                                    <div className="position-relative e-margin-left-auto">

                                        <p className={`border-radius-6px border-all bg-white px-2 padding-12px-tb mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px cursor-pointer position-relative ${showCapitalPopUp === true ? 'border-primary-color' : 'border-bright-gray'}`}
                                            onClick={() => {
                                                _handleCapitalDate(false);
                                            }}>
                                            {capitalStartDate === null ? 'Start date' : format(capitalStartDate, "dd MMM yy")} - {capitalEndDate === null ? 'End date' : format(capitalEndDate, "dd MMM yy")}
                                            <Icon icon="calendarIcon"
                                                className='position-absolute right-10px top-14px'
                                                size={20} />
                                        </p>
                                        {
                                            showCapitalPopUp === true &&
                                            <DateRangeFilterPopup
                                                maxDate={new Date()}
                                                initialRange={capitalRange}
                                                cancel={() => {
                                                    _handleCapitalDate(true)
                                                }}
                                                leftAlign={true}
                                                onUpdate={_handleCapitalRangeChange} />
                                        }
                                    </div>
                                </div> */}
                                <div className="col-md-2">
                                    <CustomSearchSelectBox value={year}
                                        label="Financial year"
                                        // error={}
                                        className="margin-32px-bottom "
                                        options={financialYears}
                                        onSelectChange={(value) => setYear(value)} />
                                </div>
                                <div className="col-lg-10 col-md-6 col-sm-6 col-12 text-end mt-lg-0 mt-md-0 mt-sm-0 mt-4">
                                    <OutlineButton label="View Report"
                                        loading={reportLoader}
                                        className="padding-12px-tb px-3 e-font-16 me-3"
                                        onPress={_viewCapitalReport} />
                                    <GradientButton label="Download Report"
                                        className="padding-12px-tb padding-18px-lr e-font-16"
                                        loading={capitalLoader}
                                        onPress={_downloadCapitalReport} />
                                </div>

                            </div>
                        )
                    }

                    {
                        reportLoader === true &&
                        <div className="row">
                            <div className="col-12 h-80vh">
                                <CustomLoader />
                            </div>
                        </div>
                    }

                    {
                        reportHtml !== null &&
                        <div className="row mt-3">
                            <div className="col-12 mt-4">
                                <div className="e-report-view-wrapper " dangerouslySetInnerHTML={{ __html: reportHtml }}></div>
                            </div>
                        </div>
                    }


                </div >
            </div >
        </>
    )
}