
/*
 *   File : explore-filter.js
 *   Author : https://evoqins.com
 *   Description : UI element which is used to do the fund filter.
 *   Integrations : null
 *   Version : 1.0.0
*/
/* import packages */
import { Fragment, useEffect, useState } from "react";

/* import components */
import { CheckBoxGroup } from "../FormElements";
import Icon from "../Icon/icon"
import { GradientButton, OutlineButton } from "../Cta";


const ExploreFilterPopup = (props) => {

    // store admin order
    const [investmentAmount, setInvestmentAmount] = useState(1);
    const [filterType, setFilterType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [investedIn, setInvestedIn] = useState([]);

    // admin login logs
    const [role, setRole] = useState([]);

    useEffect(() => {
        if (props.type === 1) {
            setInvestmentAmount(props.investmentAmount);
            setFilterType(props.filterType);
            setPaymentStatus(props.paymentStatus);
            setOrderStatus(props.orderStatus);
            setInvestedIn(props.investedIn);
        }
        else if (props.type === 2) {
            setRole(props.role);
        }
    }, [props.type])

    // clearing all filter
    const _handleClose = () => {
        props.close()
    }

    const _handleClearFilter = () => {
        switch (props.type) {
            case 1:
                setInvestmentAmount(props.data.invested_amount[0].id);
                setFilterType([props.data.filter_data[0].id]);
                setPaymentStatus([props.data.payment_status[0].id]);
                setOrderStatus([props.data.order_status[0].id]);
                setInvestedIn([props.data.invested_in[0].id]);
                break;
            case 2:
                setRole([props.data[0].id]);
                break;
        }
    }

    const _applyFilter = () => {
        switch (props.type) {
            case 1:
                props.applyFilter(investmentAmount, filterType, paymentStatus, orderStatus, investedIn)
                break;
            case 2:
                props.applyFilter(role)
                break;
        }
    }

    // handler for all checkboxes
    const _handlerCheckBoxIcon = (type, selected_item) => {
        switch (type) {
            case 3:
                setInvestmentAmount(selected_item);
                break;
            case 4:
                _manageCheckBox(filterType, selected_item, type);
                break;
            case 5:
                _manageCheckBox(paymentStatus, selected_item, type);
                break;
            case 6:
                _manageCheckBox(orderStatus, selected_item, type);
                break;
            case 7:
                _manageCheckBox(investedIn, selected_item, type);
                break;
            case 8:
                _manageCheckBox(role, selected_item, type);
                break;
        }
    }
    // helper function for managing the filtered data
    const _manageCheckBox = (array_to_be_modified, selected_filter_type, type) => {
        let filter_type = [...array_to_be_modified];
        // Select all or deselect all
        // if (selected_filter_type === null) {
        //     switch (type) {
        //         case 1:
        //             setAccountType(accountType.length === props.data.account_type.length ? [] : props.data.account_type.map(item => item.id));
        //             break;
        //         case 2:
        //             setStatus(status.length === props.data.status.length ? [] : props.data.status.map(item => item.id));
        //             break;
        //         case 3:
        //             setInvestmentAmount(investmentAmount.length === props.data.invested_amount.length ? [] : props.data.invested_amount.map(item => item.id));
        //             break;
        //         case 4:
        //             setFilterType(filterType.length === props.data.filter_data.length ? [] : props.data.filter_data.map(item => item.id));
        //             break;
        //         case 5:
        //             setPaymentStatus(paymentStatus.length === props.data.payment_status.length ? [] : props.data.payment_status.map(item => item.id));
        //             break;
        //         case 6:
        //             setOrderStatus(orderStatus.length === props.data.order_status.length ? [] : props.data.order_status.map(item => item.id));
        //             break;
        //         case 7:
        //             setInvestedIn(investedIn.length === props.data.invested_in.length ? [] : props.data.invested_in.map(item => item.id));
        //             break;
        //         case 8:
        //             setRole(role.length === props.data.length ? [] : props.data.map(item => item.id));
        //             break;
        //     }
        // }
        // // checked item not All
        // else {
        // filter_type = array_to_be_modified.filter(item => item && item.id !== null);


        // }


        let selected_type_index = filter_type.findIndex(item => item === selected_filter_type);
        if (selected_type_index !== -1) {
            filter_type.splice(selected_type_index, 1);
        } else {
            filter_type.push(selected_filter_type);
            if (selected_filter_type !== null) {
                if (filter_type.includes(null) && filter_type.length > 1) {
                    filter_type = filter_type.filter((item) => item !== null)
                }
            } else {
                filter_type = [null]
            }
        }

        switch (type) {

            case 4:
                if (filter_type.length === props.data.filter_data.length - 1) {
                    filter_type = [null]
                }
                setFilterType(filter_type);
                break;
            case 5:
                if (filter_type.length === props.data.payment_status.length - 1) {
                    filter_type = [null]
                }
                setPaymentStatus(filter_type);
                break;
            case 6:
                if (filter_type.length === props.data.order_status.length - 1) {
                    filter_type = [null]
                }
                setOrderStatus(filter_type);
                break;
            case 7:
                if (filter_type.length === props.data.invested_in.length - 1) {
                    filter_type = [null]
                }
                setInvestedIn(filter_type);
                break;
            case 8:
                if (filter_type.length === props.data.length - 1) {
                    filter_type = [null]
                }
                setRole(filter_type);
                break;
        }
    };

    return (

        <div className="border-radius-16px z-index-9 border-all border-bright-gray box-shadow-filter padding-24px-all pe-1 bg-white position-absolute right-0 top-8px w-max-content">

            <div className="position-relative e-filter-popup-height pe-4">
                <Icon icon="close"
                    size={24}
                    className="position-absolute e-close top-0 right-20px cursor-pointer e-modal-close-btn"
                    onClick={_handleClose} />
                <div className="container-fluid">
                    <div className="row">

                        <Fragment>
                            {

                                // admin order
                                props.type === 1 ?
                                    <div className="bg-transparent">
                                        {/* Investment amount */}
                                        <div className="border-bottom-1px border-bright-gray pb-3">
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Investment amount</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">
                                                {console.log('invested_amount', props.data.invested_amount)}
                                                {
                                                    props.data.invested_amount.map((item, key) =>
                                                        <p className="mb-0 cursor-pointer"
                                                            onClick={(id) => _handlerCheckBoxIcon(3, item.id)}>
                                                            <Icon className="me-2"
                                                                size={22}
                                                                icon={investmentAmount === item.id ? 'radio-selected' : 'radio-unselected'} />
                                                            {item.label}
                                                        </p>

                                                        // <CheckBoxGroup item={item}
                                                        //     key={key}
                                                        //     selectedType={investmentAmount}
                                                        //     onHandleClick={(id) => _handlerCheckBoxIcon(3, id)} />

                                                    )
                                                }

                                            </div>
                                        </div>
                                        {/* Filter type */}
                                        <div className="border-bottom-1px border-bright-gray pb-3 mt-3">
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Filter type</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">

                                                {
                                                    props.data.filter_data.map((item, key) =>
                                                        <CheckBoxGroup item={item}
                                                            key={key}
                                                            selectedType={filterType}
                                                            onHandleClick={(id) => _handlerCheckBoxIcon(4, id)} />

                                                    )
                                                }

                                            </div>
                                        </div>
                                        {/* Payment status */}
                                        <div className="border-bottom-1px border-bright-gray pb-3 mt-3">
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Payment status</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">

                                                {
                                                    props.data.payment_status.map((item, key) =>
                                                        <CheckBoxGroup item={item}
                                                            key={key}
                                                            selectedType={paymentStatus}
                                                            onHandleClick={(id) => _handlerCheckBoxIcon(5, id)} />

                                                    )
                                                }

                                            </div>
                                        </div>
                                        {/* Order status */}
                                        <div className=" border-bottom-1px border-bright-gray pb-3 mt-3">
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Order status</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">

                                                {
                                                    props.data.order_status.map((item, key) =>
                                                        <CheckBoxGroup item={item}
                                                            key={key}
                                                            selectedType={orderStatus}
                                                            onHandleClick={(id) => _handlerCheckBoxIcon(6, id)} />

                                                    )
                                                }

                                            </div>
                                        </div>
                                        {/* Invested in */}
                                        <div className="mt-3">
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Invested in</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">

                                                {
                                                    props.data.invested_in.map((item, key) =>
                                                        <CheckBoxGroup item={item}
                                                            key={key}
                                                            selectedType={investedIn}
                                                            onHandleClick={(id) => _handlerCheckBoxIcon(7, id)} />

                                                    )
                                                }

                                            </div>
                                        </div>

                                    </div>
                                    :
                                    props.type === 2 ?
                                        <div className="bg-transparent">

                                            {/* Admin Roles */}
                                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Roles</p>
                                            <div className="d-flex flex-column gap-12px e-fund-category">

                                                {
                                                    props.data.map((item, key) =>
                                                        <CheckBoxGroup item={item}
                                                            key={key}
                                                            selectedType={role}
                                                            onHandleClick={(id) => _handlerCheckBoxIcon(8, id)} />

                                                    )
                                                }

                                            </div>

                                        </div>
                                        :
                                        null
                            }
                        </Fragment>

                    </div>
                </div>
            </div>

            {/* CTA buttons */}
            <div className="d-flex gap-16px justify-content-end  mt-3 pt-3 border-top-1px border-bright-gray pe-4">
                <GradientButton label="Apply filters"
                    hide_loader={true}
                    className="e-font-16 padding-12px-tb px-3"
                    onPress={_applyFilter} />
                <OutlineButton label="Clear filters"
                    className="e-font-16"
                    onPress={_handleClearFilter} />
            </div>

        </div>

    )
}

export default ExploreFilterPopup

ExploreFilterPopup.defaultProps = {
    type: 1,
}

// type 1 - admin order
// 2 - admin roles