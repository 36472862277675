/*
 *   File : configure-account.js
 *   Author URI : www.evoqins.com
 *   Description : Configure screen.
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 09-01-2024
 */

/* import packages */
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

/* import components */
import { CustomTextInput } from "../../Components/FormElements";
import { GradientButton } from "../../Components/Cta";

/* import services */
import APIService from "../../Services/api-service";

const ConfigureAccount = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [showOtp, setShowOtp] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        // Extract email address from the URL
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');

        // Check if an email address was found
        if (email) {
            console.log("Extracted email:", email);

            // If you want to set the email to state (assuming you have a state variable setEmail)
            setEmail(email);
        }
    }, [location.search]);

    const _handleInput = (value, type) => {
        switch (type) {
            case 1:
                if (value.length <= 4) {
                    setOtp(value);
                }
                setOtpError('');
                break;
            case 2:
                setPassword(value);
                setPasswordError('');
                break;
            case 3:
                setConfirmPassword(value);
                setConfirmPasswordError('');
                break;
        }
    }

    /*---- validation for password and email ----*/
    function _validate() {
        const set_error = (field, message) => {
            switch (field) {
                case 'otp':
                    setOtpError(message);
                    break;
                case 'password':
                    setPasswordError(message);
                    break;
                case 'confirmPassword':
                    setConfirmPasswordError(message);
                    break;
                default:
                    break;
            }
        };

        // OTP Validation
        if (otp.length !== 4 || !/^\d+$/.test(otp)) {
            set_error('otp', 'Invalid OTP. It must be 4 digits.');
            return;
        }

        // Password Validation
        if (password === '') {
            set_error('password', 'Invalid password');
        }

        // Confirm Password Validation
        if (confirmPassword === '') {
            set_error('confirmPassword', 'Invalid password');
        } else if (password !== confirmPassword) {
            set_error('confirmPassword', 'Password mismatch');
        }

        // If all validations pass, proceed with account configuration
        if (otp.length === 4 && /^\d+$/.test(otp) && password !== '' && confirmPassword !== '' && password === confirmPassword) {
            _configureAccount();
            setLoader(true);
        }
    }


    // API - login 
    const _configureAccount = () => {
        let url = 'auth/set-password';
        let payload = JSON.stringify({
            email: email,
            otp: otp,
            password: password,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                navigate("/", {
                    state: {
                        email: email,
                    }
                })
            }
            setLoader(false);
        });
    }

    return (
        <Fragment>
            <div className="bg-light-pastel-blue-gray e-login-bg padding-32px-all w-100vw h-100vh overflowY-scroll">
                <Link to="/">
                    <img draggable={false} src={require('../../Assets/Images/Sidebar/master-trust-logo.svg').default} alt="MT_logo"
                        className="sticky-top e-cover-image" />
                </Link>
                <div className="w-100 d-flex justify-content-center pt-5">

                    <div className="bg-white py-5 padding-72px-lr border-radius-16px w-38vw">
                        <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">Set password</h3>
                        <div className="row pt-5">
                            <div className="col-12 mb-4">
                                <CustomTextInput type="text"
                                    label="Email"
                                    postfix="*"
                                    hide_error_text={true}
                                    disabled={true}
                                    value={email} />
                            </div>

                            <div className="col-12 pb-3 ">
                                <CustomTextInput type={showOtp ? "text" : "password"}
                                    label="Enter OTP"
                                    postfix="*"
                                    max={4}
                                    mandatory={true}
                                    error={otpError}
                                    value={otp}
                                    password_icon={true}
                                    showPassword={() => {
                                        setShowOtp(!showOtp)
                                    }}
                                    valueChange={(value) => _handleInput(value, 1)} />
                            </div>

                            <div className="col-12 pb-3 mt-3">
                                <CustomTextInput type={showPassword ? "text" : "password"}
                                    label="Password"
                                    postfix="*"
                                    value={password}
                                    error={passwordError}
                                    password_icon={true}
                                    showPassword={() => {
                                        setShowPassword(!showPassword)
                                    }}
                                    valueChange={(value) => _handleInput(value, 2)} />
                            </div>
                            <div className="col-12 pb-3 mt-3">
                                <CustomTextInput type={showConfirmPassword ? "text" : "password"}
                                    label="Confirm password"
                                    postfix="*"
                                    // hide_error_text={true}
                                    value={confirmPassword}
                                    error={confirmPasswordError}
                                    password_icon={true}
                                    showPassword={() => {
                                        setShowConfirmPassword(!showConfirmPassword)
                                    }}
                                    valueChange={(value) => _handleInput(value, 3)} />
                            </div>

                            <div className="w-100 d-flex justify-content-center margin-40px-bottom padding-32px-top">
                                <GradientButton label="Confirm"
                                    className='padding-128px-lr'
                                    loading={loader}
                                    onPress={_validate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default ConfigureAccount;