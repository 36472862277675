
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
*/

/*import package*/
import { useEffect, useState } from 'react';


const CustomTab = (props) => {
    const [tabIndex, setTabIndex] = useState(props.index);

    useEffect(() => {
        setTabIndex(props.index);
    }, [props.index])

    //Onclick event for tab change
    const _handleChangeTab = (index, type) => {
        setTabIndex(index);
        props.onSelectTab(index, type);
    }

    return (
        <div className={`d-flex e-tab-wrapper-responsive ${props.wrapperClass ? props.wrapperClass : "gap-16px"}`}>
            {
                props.type === 2 ?
                    props.data.map((item, key) => {
                        return (
                            <span className={
                                item.id === tabIndex ? `border-bottom-2px  border-may-green text-center  e-font-16 cursor-pointer e-poppins-medium padding-6px-tb color-black ${props.className}`
                                    : ` text-center  e-font-16 cursor-pointer padding-6px-tb e-poppins-medium color-outer-space ${props.className}`
                            }
                                key={key}
                                onClick={() => _handleChangeTab(item.id, item.type)}>
                                {item.type}
                            </span>

                        )
                    })
                    :
                    props.data.map((item, key) => {
                        if (props.indexToHide !== key) {
                            return (
                                <span className={
                                    (key + 1) === tabIndex ? `border-bottom-2px  border-may-green text-center  e-font-16 cursor-pointer padding-6px-tb e-poppins-medium color-black ${props.className}`
                                        : ` text-center  e-font-16 cursor-pointer padding-6px-tb e-poppins-medium color-outer-space ${props.className}`
                                }
                                    key={key}
                                    onClick={() => _handleChangeTab(key + 1)}>
                                    {item}
                                </span>

                            )
                        }
                    })
            }

        </div>
    )
}

CustomTab.defaultProps = {
    wrapperClass: "",
    className: "",
    onSelectTab: () => { },
    tabIndex: 1,
    type: 1
}

export default CustomTab;

