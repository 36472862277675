/*
 *   File : list.js
 *   Author URI : www.evoqins.com
 *   Description : SUb admin list
 *   Integrations : null
 *   Version : v1.0
 *   Created : 27-12-2023
 */

import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { DataTableContainer, SearchInput } from "../../../Components/FormElements";
import { GradientButton, LinkText, OutlineButton } from "../../../Components/Cta";
import { NavigationHeader } from "../../../Components/Header";
import { SectionTitle } from "../../../Components/Title";
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { Icon } from "../../../Components/Icon";
import { ImportAdminModal, ImportClients } from "../../../Components/Modal";
import AddAdmin from "./add-admin";

import APIService from "../../../Services/api-service";

import Colors from '../../../Styles/color.module.scss';


const TABLE_STYLE = {
    table: {
        style: {
            padding: "0px",
            borderRadius: "16px",
            background: Colors.white,
            border: `1px solid ${Colors.bright_gray}`,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: Colors.alice_blue,
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            padding: "16px 32px",
            color: Colors.black,
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            lineHeight: "24px",
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: "0 !important",
            borderTop: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "0",
            color: Colors.black,
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            lineHeight: "24px",
            padding: "16px 32px",
            cursor: 'pointer'
        },
    },
    cells: {
        style: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
};

const SubAdminList = () => {
    const navigate = useNavigate();

    const [showArrow, setShowArrow] = useState(0);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(true);
    const [adminList, setAdminList] = useState([]);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [openImportAdminModal, setOpenImportAdminModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [openImportClientModal, setOpenImportClientModal] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [downloadUnmappedLoader, setDownloadUnmappedLoader] = useState(false);
    
    const SUMMARY_COLUMNS = [
        {
            name: 'Admin ID',
            selector: row => row['admin_mt_id'],
            sortable: false,
            left: true,
            // width: "576px",
            width: "150px",
            cell: (row, index) =>
                <div className="d-flex align-items-center e-fund-name cursor-pointer"
                    onClick={() => _handleCardClick(row)}>
                    <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 bg-white">
                        {
                            row['admin_mt_id'] === null ?
                                '-'
                                :
                                row['admin_mt_id']
                        }
                    </p>
                </div>

        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row => <span className="color-primary-color cursor-pointer"
                onClick={() => _handleCardClick(row)}> {row['name']}</span>,
            style: {
                color: Colors.primary_color,
                fontFamily: "Poppins-Regular",
                fontSize: "16px",
                lineHeight: "24px",
            }
        },
        {
            name: 'Sub-admin type',
            selector: row => row['admin_type_name'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 cursor-pointer"
                    onClick={() => _handleCardClick(row)}>
                    {row['admin_type_name']}
                </p>
        },
        // {
        //     name: 'RM/Branches',
        //     selector: row => row['aum'],
        //     sortable: false,
        //     left: true,
        //     maxWidth: "250px",
        //     cell: row =>
        //         <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
        //             {row['aum']}
        //         </p>
        // },
        {
            name: 'Last login',
            selector: row => row['last_login'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 cursor-pointer"
                    onClick={() => _handleCardClick(row)}>
                    {row['last_login'] !== null ? row['last_login'] : '-'}
                </p>
        },


        {
            name: '',
            sortable: false,
            right: true,
            maxWidth: "100%",
            cell: row =>
                <>
                    <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}>
                        <LinkText title="View details"
                            icon="arrow-right"
                            className="d-flex"
                            onClick={() => _handleCardClick(row)} />
                    </div>
                </>

        }

    ];

    // scroll to top
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])


    useEffect(() => {
        if (openImportAdminModal === true) {
            const modal = new Modal(document.getElementById("import-admin"));
            modal.show();
        }
    }, [openImportAdminModal]);

    useEffect(() => {
        if (openImportClientModal === true) {
            const modal = new Modal(document.getElementById("import-clients"));
            modal.show();
        }
    }, [openImportClientModal]);

    useEffect(() => {
        _getAdmins();
    }, [pageNumber]);

    const _handleCardClick = (row) => {
        navigate("/sub-admins/detail", { state: { detail_id: row.admin_id } })
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }


    function _handleUploadClient(id) {
        setSelectedAdmin(id);
        setOpenImportClientModal(true);
    }


    function _addAdminNavigation() {
        navigate('/sub-admins/new');
    }

    function _openImportModal() {
        setOpenImportAdminModal(true);
    }

    function _closeImportAdmin() {
        setOpenImportAdminModal(false);
        _getAdmins();
    }

    function _closeClientUploadModal() {
        setSelectedAdmin(null);
        setOpenImportClientModal(false);
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // API - get admins list
    function _getAdmins(query = searchQuery) {
        setApiLoader(true);
        let url = 'auth/list-admins';
        let payload = JSON.stringify({
            page_num: pageNumber,
            admin_type_id: null,
            query_string: query
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setAdminList(response.data.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setPageSize(response.data.page_size);
            } else {
                setAdminList([]);
            }
            setPageLoader(false);
            setApiLoader(false);
        })
    }

    function _downloadClientsMapped() {
        setDownloadLoader(true);
        let url = 'auth/download-mapped-clients';
        let payload = JSON.stringify({
            admin_id: null
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success(response.message, {
                    type: 'success'
                })
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setDownloadLoader(false);
        })
    }

    function _downloadUnMappedClients () {
        setDownloadUnmappedLoader(true);
        let url = 'auth/unmapped-clients';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
            }else{
                toast.error(response.message,{
                    type: 'error'
                })
            }
            setDownloadUnmappedLoader(false);
        })
    }

    const _handleSearchQuery = (value) => {
        setPageNumber(1);
        setSearchQuery(value);
        if (value === "") {
            _getAdmins("");
        }
    }

    return (
        <Fragment>
            {/* header */}
            <NavigationHeader type={1}
                title="Manage sub admins" />
            {
                pageLoader === true ? (
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                        <div className="row h-80vh">
                            <CustomLoader />
                        </div>
                    </div>
                ) : (
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top pt-4">
                        <div className="mb-4 d-flex justify-content-between">
                            <SectionTitle title="Sub admin summary" />
                            <div>
                                <OutlineButton label="Import admin"
                                    className="ms-4"
                                    onPress={_openImportModal} />
                                <GradientButton label="Add new admin"
                                    className="ms-4"
                                    onPress={_addAdminNavigation} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between padding-32px-bottom">
                            <div className="d-flex w-100 justify-content-between">
                                <div className="w-35">
                                    <SearchInput placeholder="Search with id, name, email or phone"
                                        value={searchQuery}
                                        className="padding-12px-tb "
                                        valueChange={_handleSearchQuery}
                                        handleSearch={_getAdmins} />
                                </div>
                                <div className="d-flex">
                                    <p className={`e-download-text-btn color-primary-color w-max-content mb-0 mt-3 e-font-16 e-poppins-medium line-height-16px pt-1 pe-1 cursor-pointer`}
                                        onClick={() => _handleUploadClient()}>
                                        Hierarchy Mapping
                                        <img src={require('../../../Assets/Images/upload.png')}
                                            width={18}
                                            height={18}
                                            className="ms-2" />
                                    </p>
                                    <div className={`e-download-text-btn color-primary-color e-font-16 e-poppins-medium line-height-16px mt-3 ms-3 cursor-pointer ${downloadLoader === true && 'no-events'}`}
                                            onClick={_downloadUnMappedClients}>
                                            Download unmapped clients
                                            <img src={downloadUnmappedLoader === true ? require('../../../Assets/Images/button-loader.gif') : require('../../../Assets/Images/Users/download.png')}
                                                width={24}
                                                height={24}
                                                className="ms-2" />
                                        </div>
                                    <div className={`e-download-text-btn color-primary-color e-font-16 e-poppins-medium line-height-16px mt-3 ms-3 cursor-pointer ${downloadLoader === true && 'no-events'}`}
                                        onClick={_downloadClientsMapped}>
                                        Download mapped clients
                                        <img src={downloadLoader === true ? require('../../../Assets/Images/button-loader.gif') : require('../../../Assets/Images/Users/download.png')}
                                            width={24}
                                            height={24}
                                            className="ms-2" />
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* list of admins*/}
                        <div className="col-12 position-relative e-admin-list" id="e-table-body">
                            {
                                adminList.length !== 0 ?
                                    <>
                                        <DataTableContainer columns={SUMMARY_COLUMNS}
                                            data={adminList}
                                            customStyles={TABLE_STYLE}
                                            onRowMouseEnter={_handleRowHover}
                                            onRowMouseLeave={_handleRowRemoveHover}
                                            rowClick={_handleCardClick}
                                            pagination={true}
                                            paginationComponentOptions={paginationData}
                                            paginationPerPage={pageSize} />
                                        <div className={`d-flex justify-content-end align-items-center position-absolute bottom-16px right-24 w-max-content mt-3`}>
                                            <Icon icon="pagination-previous" width="24px" height="24px"
                                                className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                onClick={_previousPage} />
                                            <Icon icon="pagination-next" width="24px" height="24px"
                                                className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                onClick={_nextPage} />
                                        </div>
                                    </>
                                    :
                                    <EmptyScreen />
                            }

                        </div>
                    </div>

                    // <AddAdmin toggleScreen={() => setShowAddAdmin(false)}
                    //     successCallBack={_updateList} />
                )
            }
            {
                openImportAdminModal === true &&
                <ImportAdminModal close={_closeImportAdmin} />
            }

            {
                openImportClientModal === true &&
                <ImportClients data={selectedAdmin}
                    close={_closeClientUploadModal} />
            }
        </Fragment >


    )
}

export default SubAdminList