/*
 *   File : search-select.js
 *   Author URI : www.evoqins.com
 *   Description : Select box with custom text box
 *   Integrations : react-select
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";

/* import components */
import Icon from "../Icon/icon";

import Colors from '../../Styles/color.module.scss';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="right-arrow"
                width="20px"
                height="20px"
                color={Colors.space_cadet} />
        </components.DropdownIndicator>
    );
};

const CustomSearchSelectBox = (props) => {
    const [value, setValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (props.type === 2) {
            console.log(props.isMenuOpen)
            setIsOpen(props.isMenuOpen || false);
        }
    }, [props.type, props.isMenuOpen])

    useEffect(() => {
        setValue(props.value);
    }, [props.value, props.creatable]);



    const customStylesDropdown = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "8px",
            borderColor: props.error.length !== 0 ? `${Colors.red}` : `${Colors.columbia_blue}`,
            border: props.error.length !== 0 ? `1px solid ${Colors.red}` : `10px solid ${Colors.columbia_blue}`,
            background: 'transparent',
            padding: "12px",
            boxShadow: "0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03)",
            "&:hover": {
                border: `1px solid ${Colors.columbia_blue}`,
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            width: "max-content",
            left: "-26px",
            paddingRight: "40px",
            border: `1px solid ${Colors.anti_flash_white}`,
            background: Colors.white,
            borderRadius: "8px",
            overflow: "hidden",
            zIndex: "1034",
            boxShadow: '4px 4px 48px 0px rgba(7, 0, 124, 0.08);'
        }),
        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '0px !important',
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxHeight: "max-content",
        }),
        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-90deg)" : "rotate(90deg)"
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "8px 16px",
            color: '#292D32',
            fontFamily: "Poppins-regular",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: "400",
            backgroundColor: Colors.white,
            borderRadius: "0px",
            cursor: "pointer",
            "&:hover": {
                fontWeight: "500",
                fontFamily: "Poppins-medium",
            },
            "&:active": {
                fontWeight: "500",
                fontFamily: "Poppins-medium",
                backgroundColor: "transparent"
            },
            "&:selected": {
                fontFamily: "Poppins-medium",
                fontWeight: "500",
                backgroundColor: "transparent"
            }
        }),
        indicatorContainer: (defaultStyles, state) => ({
            ...defaultStyles,
            zIndex: 2,
            padding: '0px !important',
        }),
        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "24px",
            zIndex: 2,
            fontFamily: "Poppins-Regular",
            color: Colors.charleston_green
        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0px !important",
            padding: '0px',
            fontFamily: "Poppins-Regular",
            color: Colors.charleston_green
        })
    };

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
            opacity: props.isDisabled === true && "0.5"
            // zIndex: "2"
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            display: 'none'
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "8px",
            borderColor: props.error.length !== 0 ? `${Colors.red}` : `${Colors.columbia_blue}`,
            border: props.error.length !== 0 ? `1px solid ${Colors.red}` : `1px solid ${Colors.columbia_blue}`,
            background: 'transparent',
            padding: "12px",
            boxShadow: "0 0.375rem 0.25rem 0px rgba(50, 50, 71, 0.03)",
            "&:hover": {
                border: `1px solid ${Colors.columbia_blue}`,
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            width: "100%",
            left: "0",
            border: `1px solid ${Colors.anti_flash_white}`,
            background: Colors.white,
            borderRadius: "8px",
            overflow: "hidden",
            zIndex: "1034",
            boxShadow: '4px 4px 48px 0px rgba(7, 0, 124, 0.08);'
        }),
        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '0px !important',
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            overflow: 'auto',
            maxHeight: '260px',
            zIndex: '10',
            '&::-webkit-scrollbar': {
                width: '0.125rem'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.argent,
                borderRadius: '0.25rem'
            }
        }),
        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-90deg)" : "rotate(90deg)"
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "18px 16px",
            color: Colors.black,
            fontFamily: "Poppins-regular",
            fontSize: "12px",
            fontStyle: " normal",
            fontWeight: " 400",
            backgroundColor: Colors.white,
            borderRadius: "0px",
            cursor: "pointer",
            '&:not(:last-child)': {
                borderBottom: 'solid 1px #ECEDEF'
            },
            "&:hover": {
                fontWeight: "500",
                fontFamily: "Poppins-medium",
            },
            "&:active": {
                fontWeight: "500",
                fontFamily: "Poppins-medium",
                backgroundColor: "transparent"
            },
            "&:selected": {
                fontFamily: "Poppins-medium",
                fontWeight: "500",
                backgroundColor: "transparent"
            }
        }),
        indicatorContainer: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '0px !important',
        }),
        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "24px",
            fontFamily: "Poppins-Regular",
            color: Colors.charleston_green
        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0px !important",
            padding: '0px',
            fontFamily: "Poppins-Regular",
            color: Colors.charleston_green
        })
    };


    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }


    const _handleMenuToggle = (isOpen) => {
        setIsOpen(isOpen)
        if (props.onMenuToggle) {
            props.onMenuToggle(isOpen);
        }
    };

    const CustomSingleValue = ({ children, ...props }) => (

        <components.SingleValue {...props}>
            <div className="d-flex gap-8px">
                <span className="e-poppins-medium color-charleston-green">{children}</span>
            </div>
        </components.SingleValue >
    );

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => {
        // removing option from dropdown conditionally
        // screen - Add admin role option 
        if (props.data.show_option === false) {
            return;
        }
        return (
            <components.Option {...props}>

                <div className={` ${isSelected} ? "bg-light-cyan" : "d-flex align-items-center gap-10px"`}>
                    <span className={isSelected ? "e-poppins-medium" : "e-poppins-regular"}>{props.data.label} {props.data?.account_number}</span>
                </div>
            </components.Option>
        );
    };


    return (
        <Fragment>
            <div className={`${props.className} position-relative`}>
                {
                    isOpen ?
                        // transforming label to top if menu is open
                        <span className={"color-outer-space e-poppins-regular e-font-14 line-height-24px position-absolute e-select-label-active bg-white"}>
                            {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                {props.postfix}
                            </span>
                        </span>
                        :


                        value !== "" ?
                            // transforming label to top if value is added
                            <span className={"color-outer-space e-poppins-regular e-font-14 line-height-24px e-select-label-active position-absolute bg-white"}>
                                {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                    {props.postfix}
                                </span>
                            </span>
                            :

                            // default placeholder style in select
                            <span className={props.error ? "color-red e-poppins-regular e-font-14 line-height-24px e-select-label-placeholder position-absolute" : "color-outer-space e-poppins-regular e-font-14 line-height-24px e-select-label-placeholder position-absolute bg-white"} >
                                {props.placeholder ? props.placeholder : props.label}<span className='color-red'>
                                    {props.postfix}
                                </span>
                            </span>


                }

                {
                    <Select value={value}
                        styles={props.type === 2 ? customStylesDropdown : customStyles}
                        isSearchable={false}
                        isDisabled={props.isDisabled}
                        id={props.id}
                        isMulti={props.isMulti}
                        options={props.options}
                        placeholder={props.placeholder}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            Option: CustomOption,
                            SingleValue: CustomSingleValue,
                        }}
                        // menuIsOpen={true}
                        onMenuOpen={() => _handleMenuToggle(true)} // Call the function when the menu opens
                        onMenuClose={() => _handleMenuToggle(false)}
                        onChange={(value) => {
                            _onSelectChange(value)
                        }} />
                }

                {
                    props.hide_error_text === true ?
                        null
                        :
                        props.error && (
                            <div className='position-absolute d-flex align-items-center mt-1 '>
                                <Icon icon="info-circle"
                                    size={16}
                                    className="me-1" />
                                <p className='color-red mb-0 e-font-12 '>
                                    {props.error}
                                </p>
                            </div>
                        )
                }
            </div>

        </Fragment>
    );
}

CustomSearchSelectBox.defaultProps = {
    error: "",
    creatable: false,
    isDisabled: false,
    isMulti: false,
    padding: "",
    hide_error_text: false,
    toggleOptions: false,
    onMenuToggle: () => { }
}

export default CustomSearchSelectBox;