/*
 *   File : custom-picker.js
 *   Author : https://evoqins.com
 *   Description : Custom range picker
 *   Integrations : react-data-table-component
 *   Version : 1.0.0
 */
/*import packages*/
import { Fragment } from "react"

/*import components*/
import Icon from "../Icon/icon";
import { DateRangeFilterPopup } from "../Popups";


const CustomDateRangePicker = (props) => {
    return (
        <Fragment>
            <p className={`${props.className} border-radius-6px border-all border-bright-gray bg-white p-2 mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px cursor-pointer d-flex justify-content-between align-items-center mt-0`}
                onClick={props.handleShowDateFilter}>
                {props.startDate} - {props.endDate}

                <Icon icon="calendar1"
                    width="24px"
                    height="24px"
                    className="ms-2" />
            </p>
            {
                props.showDateFilter === true &&
                <div ref={props.popupRef}>
                    <DateRangeFilterPopup
                        maxDate={new Date()}
                        initialRange={props.selectedRange}
                        cancel={() => {
                            props.handleShowDateFilter();
                            props.clearDates();
                        }}
                        onUpdate={props.handleDateRangeChange} />
                </div>
            }
        </Fragment>
    )
}

CustomDateRangePicker.defaultProps = {
    handleDateRangeChange: () => { },
    selectedRange: [],
    startDate: null,
    endDate: null,
    handleShowDateFilter: () => { },
    popupRef: null
}

export default CustomDateRangePicker;