
/*
 *   File : breadcrumb.js
 *   Author URI : https://evoqins.com
 *   Description : UI component to page navigation
 *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { useNavigate } from 'react-router-dom';

/*import components*/
import Icon from "../Icon/icon";

export default function BreadCrumb(props) {
    const navigator = useNavigate();

    // handle breadcrumb node click 
    function _handleRedirection(index) {
        // return when clicking current page
        if (index === props.data.length - 1) {
            return null;
        }
        else {
            if (index === 0) { // navigate to home
                navigator('/');
            } else {
                navigator(-1); // navigate to previous page; in case more than 2 links are there (detail page)
            }
        }
    };

    // props;
    let { data } = props;
    return (
        <p className={`mb-4 mt-0 ${props.className}`}>
            {data.map((page, index) => (
                <span key={index}
                    className={`e-font-12 ${index !== data.length - 1 ? 'e-poppins-regular color-gray cursor-pointer' : 'color-black e-poppins-medium'}`}
                    onClick={() => _handleRedirection(index)}>
                    {page}
                    {index !== data.length - 1 && <Icon icon="gray-right-arrow" size={16} />}
                </span>
            ))}
        </p>
    )
}

BreadCrumb.defaultProps = {
    className: ""
}