/*
 *   File : joint-holders.js
 *   Author URI : https://evoqins.com
 *   Description : Joint holders list
 *   Integrations : null
 *   Version : v1.1
 */

export default function MinorDetail(props) {

    return (
        <div className="row mt-lg-0 mt-md-0 mt-3">
            <div className="col-12">
                <h6 className="e-poppins-medium e-font-16 color-black">Minor guardian details</h6>
                <div className="col-12 mt-4">
                    <div className="border-bright-gray border-all bg-pure-white border-radius-24px p-4">
                        <div className="row">
                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                <h6 className="e-poppins-medium e-font-14 color-outer-space">Name</h6>
                                <p className="e-poppins-medium e-font-14 color-black mb-0">{props.data.guardian.name}</p>
                            </div>
                            <div className="col-md-2 col-sm-6 col-12 border-lg-right-1px">
                                <h6 className="e-poppins-medium e-font-14 color-outer-space">DOB</h6>
                                <p className="e-poppins-medium e-font-14 color-black mb-0">{props.data.guardian.date_of_birth}</p>
                            </div>
                            <div className="col-sm-6 col-12">
                                <h6 className="e-poppins-medium e-font-14 color-outer-space">PAN</h6>
                                <p className="e-poppins-medium e-font-14 color-black mb-0">{props.data.guardian.masked_pan}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}