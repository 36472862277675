/*
 *   File : logic.js.js
 *   Author URI : https://evoqins.com
 *   Description : Quiz logic page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";


/* import components */
import { NavigationHeader } from "../../Components/Header";
import { Icon } from "../../Components/Icon";



const QuizLogic = React.memo(() => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <Fragment>
            <NavigationHeader
                type={1}
                title="Manage quiz" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2">
                <div className="d-flex align-items-center gap-8px padding-12px-lr mb-4 cursor-pointer w-fit-content"
                    onClick={() => navigate("/risk-planning-questionnaire")}>
                    <Icon icon="arrow-right"
                        size={24}
                        className="e-arrow-right-180" />
                    <p className="color-black e-poppins-regular e-font-16 line-height-14px mb-0">Back</p>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <img draggable={false} src={require("../../Assets/Images/Quiz/how-it-works.png")}
                            alt="How_Quiz_works"
                            width="100%"
                            height="100%"
                        />
                    </div>
                    <div className="col-8 mt-3">
                        <img draggable={false} src={require("../../Assets/Images/Quiz/quiz-info.png")}
                            alt="How_Quiz_works"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>

            </div>

        </Fragment>
    )
})
export default QuizLogic;
