
import { put, takeEvery } from "redux-saga/effects";

// manage profile data
function* updateNavigation(action) {
    try {
        yield put({
            type: 'UPDATE_NAVIGATION', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}

function* updateSidebarToggle(action) {
    try {
        yield put({
            type: 'TOGGLE_SIDEBAR', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}


function* getAdminProfile(action) {
    try {
        yield put({
            type: 'GET_ADMIN_PROFILE', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get selected OF filter from Explore page
function* getOFFilter(action) {
    try {
        yield put({
            type: 'SELECTED_OF_FILTERS', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}


// manage search header data


export function* _updateNavigation() {
    yield takeEvery('UPDATE_NAVIGATION', updateNavigation);
}

export function* _updateSidebarToggle() {
    yield takeEvery('TOGGLE_SIDEBAR', updateSidebarToggle);
}

export function* _getAdminProfile() {
    yield takeEvery('GET_ADMIN_PROFILE', getAdminProfile);
}

export function* _getOFFilter() {
    yield takeEvery('SELECTED_OF_FILTERS', getOFFilter);
}

