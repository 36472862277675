/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Notification listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useState } from "react";


/* import components */
import { NavigationHeader } from "../../Components/Header";
import { CustomTab } from "../../Components/Tab";
import { CustomTextArea, CustomTextInput, FileUpload } from "../../Components/FormElements";
import { GradientButton } from "../../Components/Cta";
import { FilePreview, NotificationCard } from "../../Components/Cards";


const PAGE_NAVIGATION = [
    "Dashboard",
    "App Notifications"
]


const NOTIFICATION_TABS = [
    "Create new notification",
    "Notification history"
]


const NOTIFICATION_LIST = [
    {
        date: "Aug 25 2020, 11.05 AM",
        heading: "Invest now",
        content: "Content of the push notification.",
        preview_image: require("../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        date: "Aug 25 2020, 11.05 AM",
        heading: "Invest now",
        content: "Content of the push notification.",
        preview_image: require("../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        date: "Aug 25 2020, 11.05 AM",
        heading: "Invest now",
        content: "Content of the push notification.",
        preview_image: require("../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        date: "Aug 25 2020, 11.05 AM",
        heading: "Invest now",
        content: "Content of the push notification.",
        preview_image: require("../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        date: "Aug 25 2020, 11.05 AM",
        heading: "Invest now",
        content: "Content of the push notification.",
        preview_image: require("../../Assets/Images/Notifications/notification-preview.png")
    }
]
const NotificationListing = React.memo(() => {
    const [tabIndex, setTabIndex] = useState(1);
    const [heading, setHeading] = useState("");
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [headingError, setHeadingError] = useState(null);
    const [contentError, setContentError] = useState(null);
    const [fileError, setFileError] = useState(null);


    const _handleTabIndex = (tab_index) => {
        setTabIndex(tab_index);
    }

    const _handleHeading = (input) => {
        setHeading(input);
        setHeadingError("");
    }

    const _handleContent = (input) => {
        setContent(input);
        setContentError(null);
    }

    const _handleFile = (path) => {
        //eslint-disable-next-line
        var file_formatted = path.replace(/^.*[\\\/]/, '').split('/');
        setFile(file_formatted[0]);
        setFileError("");

    }



    const _validateForm = () => {
        var valid_form = true;
        if (heading.length === 0) {
            valid_form = false;
            setHeadingError('Heading is required');
        }
        if (content.length === 0) {
            valid_form = false;
            setContentError('Content is required');
        }
        if (file === null) {
            valid_form = false;
            setFileError("File is require");
        }
        //eslint-disable-next-line
        if (valid_form = true) {
            _clearFields()
        }
    }

    const _clearFields = () => {
        setHeading("");
        setContent("");
        setFile(null);
    }
    return (
        <Fragment>

            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage app notifications" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row">
                    <div className="col-12">
                        <CustomTab type={1}
                            index={tabIndex}
                            data={NOTIFICATION_TABS}
                            onSelectTab={_handleTabIndex} />

                    </div>


                </div>
                {
                    tabIndex === 1 ?
                        <Fragment>
                            <div className="row padding-28px-top">
                                <div className="col-lg-5 ">
                                    <CustomTextInput label="Heading"
                                        value={heading}
                                        postfix="*"
                                        type="text"
                                        hide_error_text={true}
                                        error={headingError}
                                        valueChange={(input_value) => {
                                            _handleHeading(input_value)
                                        }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 pt-3 ">
                                    <CustomTextArea label="Content"
                                        value={content}
                                        postfix="*"
                                        type="text"
                                        hide_error_text={true}
                                        error={contentError}
                                        valueChange={(input_value) => {
                                            _handleContent(input_value)
                                        }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 pt-3 ">
                                    <FileUpload id="e-upload"
                                        error={fileError}
                                        accept=".png, .jpg, .jpeg "
                                        hide_error_text={true}
                                        onSelectFile={(file) => {
                                            _handleFile(file)
                                        }} />
                                    {
                                        file ?
                                            <FilePreview file={file}
                                                clearFile={() => {
                                                    setFile(null);
                                                }} />

                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="row padding-24px-top">
                                <div className="col-3">
                                    <GradientButton name="Submit"
                                        className="padding-12px-tb padding-24px-lr"
                                        onPress={_validateForm} />

                                </div>

                            </div>
                        </Fragment>
                        :
                        <div className="row padding-24px-top">
                            <div className="col-9">
                                {
                                    NOTIFICATION_LIST.map((item, index) => {
                                        return (
                                            <NotificationCard data={item} key={index} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                }

            </div>
        </Fragment>
    )
})

export default NotificationListing;