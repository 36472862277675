/*
 *   File : editor.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-11-2021
 */

/*import packages*/
import React, { useState, useEffect, useRef } from 'react';

/*import style*/


const Editor = (props) => {
    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { CKEditor, ClassicEditor } = editorRef.current || {}
    //eslint-disable-next-line
    const [data, setData] = useState("");


    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, //Added .CKEditor
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
        }
        setEditorLoaded(true);
        if (props.value) {
            setData(props.value);
        }
    }, [props.value]);

    return (
        <>
            {
                editorLoaded ?

                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList',]
                        }}
                        onChange={(evt, editor) => {
                            var tempData = "";
                            tempData = editor.getData();
                            props.editorChange(tempData);
                        }}
                        data={props?.value}
                    />

                    :
                    <div className='h-100vh'>
                    </div>
            }
        </>
    )
}

export default Editor