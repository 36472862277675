/*
 *   File : slider.js
 *   Author : https://evoqins.com
 *   Description : Slider 
 *   Integrations : NA
 *   Version : 1.0.0
*/

/* import packages */
import ReactSlider from "react-slider";

export default function CustomSlider(props) {

    const _handleChange = (e) => {
        props.handleChange(e)
    }

    return (
        <>

            <ReactSlider
                className={`e-horizontal-slider ${props.className}`}
                thumbClassName="e-thumb"
                trackClassName="e-track"
                min={props.min}
                max={props.max}
                value={props.value}
                disabled={props.disabled}
                step={props.step}
                onChange={(value, index) => {
                    _handleChange(value)
                }}

                renderThumb={(props) => <div {...props}></div>}
            />

        </>
    )
}

CustomSlider.defaultProps = {
    handleChange: () => { },
    className: ""
}
