/*
 *   File : delete.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while removing funds.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

/* import components */
import { GradientButton, OutlineButton } from "../Cta";
import { Icon } from "../Icon";
import { TagCard } from "../Cards";
import { CustomTextInput } from "../FormElements";

import APIService from "../../Services/api-service";

/* import styles */
import style from "../../Styles/Components/banner.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";

const ManageTag = (props) => {
    const [tag, setTag] = useState('');
    const [iconList, setIconList] = useState([]);
    const [tagError, setTagError] = useState(null);
    const [defaultTag, setDefaultTag] = useState(props.data);
    const [tagList, setTagList] = useState(props.tagList);
    const [selectedIconId, setSelectedIconId] = useState();
    const [selectedIcon, setSelectedIcon] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);

    useEffect(() => {
        var my_modal = document.getElementById("manage-tag");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    useEffect(() => {
        if (props.data) {
            setSelectedIconId(props.data.icon_id);
            _getIconList(props.data.icon_id);
        }
    }, [props.data]);


    const _handleInput = (input) => {
        setTag(input);
        setTagError('');
    }

    // checking for selcted tag before updating
    const _validateForm = () => {
        if (Object.keys(selectedIcon).length !== 0) {
            _updateIcon();
        } else {
            toast.dismiss();
            toast.error('Select any icon before submitting', {
                type: 'error'
            });
        }

    }

    // handle tag card click 
    function _handleCardClick(data) {
        setSelectedIcon(data);
        setSelectedIconId(data.id);
    }

    // API - get tag icon list
    function _getIconList(icon_id) {
        let url = 'general-settings/list-icons';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setIconList(response.data);
                let selected_icon = {};
                response.data.map((item) => {
                    if (item.id === icon_id) {
                        selected_icon = item;
                    }
                });
                setSelectedIcon(selected_icon);
            }
            setApiLoader(false);
        })
    };

    // API - update icon
    function _updateIcon() {
        setButtonLoader(true);
        let url = 'general-settings/update-tag';
        let payload = JSON.stringify({
            id: defaultTag.id,
            name: selectedIcon.name,
            icon_id: selectedIconId,
            icon: selectedIcon.icon
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss(); 
                toast.success("Tag updated successfully", {
                    type: 'success'
                });
                props.successCallBack();
                let button = document.getElementById('button-modal-close');
                button.click();

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        });
    }


    return (
        <div className={`modal fade ${style.e_delete}`}
            id="manage-tag"
            tabIndex="-1"
            aria-labelledby="manage-tag"
            aria-hidden="true"
            data-bs-backdrop="true" >

            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_edit_dialog}`}>
                <div className={`modal-content bg-white padding-32px-all ${style.e_content} `}>

                    <div className="col-12 d-flex justify-content-between">
                        <h3 className="color-black e-poppins-semi-bold e-font-18">
                            {
                                Object.keys(selectedIcon).length === 0 ?
                                    'Add tag'
                                    :
                                    'Edit tag'
                            }

                        </h3>
                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <div className="row">
                        {
                            iconList.map((item) => {
                                return (
                                    <div className="col-xl-4 e-quick-invest">
                                        <TagCard data={defaultTag}
                                            selectedId={selectedIconId}
                                            icon={item}
                                            selectedTag={tag}
                                            update={true}
                                            onSelect={() => _handleCardClick(item)} />

                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="col-12 margin-34px-top d-flex justify-content-end">
                        <OutlineButton label="Cancel"
                            id="button-modal-close"
                            className="padding-12px-tb e-font-16 "
                            cancel="modal" />
                        <GradientButton label="Update tag"
                            className="padding-12px-tb e-font-16 ms-3"
                            loading={buttonLoader}
                            onPress={_validateForm} />
                    </div>
                </div>
            </div>

        </div >
    )
}


ManageTag.defaultProps = {
    close: () => { },
    fund: {
        fund_name: ""
    }
}

export default ManageTag