/*
 *   File : manage-scheme.js
 *   Author URI : www.evoqins.com
 *   Description : To show the available funds in Master Trust platform.
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 28-12-2023
 */
/* import packages */
import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { Modal } from "bootstrap";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from 'immutability-helper'


/* import components */
import Icon from "../../../../Components/Icon/icon";
import { GradientButton } from "../../../../Components/Cta";
import { SectionTitle } from "../../../../Components/Title";
import { ManageFundModal } from "../../../../Components/Modal";
import { CustomLoader } from "../../../../Components/Other";
import { toast } from "react-toastify";

/* import styles */


/* import services */
import APIService from "../../../../Services/api-service";

const ManageScheme = (props) => {
    const [showArrow, setShowArrow] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [funds, setFunds] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        _mfList();
        //eslint-disable-next-line
    }, []);

    // call back to open modal
    useEffect(() => {
        if (openModal === true) {
            _openFundModal();
        }
    }, [openModal]);


    // open fund modal function
    function _openFundModal() {
        const modal = new Modal(document.getElementById("manage-fund"));
        modal.show();
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setFunds((previousState) =>
            update(previousState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, previousState[dragIndex]],
                ],
            }),
        )
    }, [])


    // Each box which is draggable
    const DraggableBox = (props) => {
        const ref = useRef(null);
        const [show, setShow] = useState(false);

        let key = props.index;
        let row = props.data;
        const [{ isDragging }, drag] = useDrag({
            type: 'ITEM',
            item: { key },

            collect: monitor => ({
                isDragging: monitor.isDragging()
            })

        });

        const [{ handlerId }, drop] = useDrop({
            accept: 'ITEM',
            hover: (item, monitor) => {
                if (!ref.current) {
                    return
                }
                const draggedIndex = item.key;
                const targetIndex = key;
                if (draggedIndex === targetIndex) {
                    return
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top

                if (draggedIndex < targetIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (draggedIndex > targetIndex && hoverClientY > hoverMiddleY) {
                    return
                }


                moveCard(draggedIndex, targetIndex)
                item.key = targetIndex
            },
            collect: (monitor) => ({
                handlerId: monitor.getHandlerId(),
            }),
        });



        const Item_Style = {
            boxShadow: show ? '0px 2px 8px 2px rgba(0, 0, 0, 0.06)' : '',
            backgroundColor: show ? '#EEFAF7' : '',
            opacity: isDragging ? '0' : '1',
            cursor: 'move'
        }

        drag(drop(ref));

        return (
            <>
                <div data-handler-id={handlerId} className="border-all border-bright-gray  py-4 px-2 border-radius-16px mb-3" ref={ref} style={Item_Style}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}>
                    <div className="row">
                        <div className="col-sm-7 d-flex">
                            <img draggable={false} src={require('../../../../Assets/Images/Datatable/dot.svg').default}
                                alt="dot"

                                className="me-2" />
                            <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                <img draggable={false} src={row.image} alt={'MF_Image'}
                                    width={36}
                                    height={36}
                                    className="object-fit-contain" />
                            </div>
                            <div className="d-flex flex-column gap-12px ps-2">
                                <span className="color-black e-poppins-medium e-font-16 line-height-24px mb-0 ">{row['name']}</span>
                                <span className="border-radius-16px border-all border-bright-gray padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                                    {row['type']}
                                </span>
                            </div>

                        </div>
                        <div className="col-sm-5 d-flex justify-content-end align-items-center">
                            <div className="col-3 text-center">
                                <p className="color-dark-spring-green e-poppins-semi-bold e-font-14 line-height-20px mb-0 text-center">
                                    {row['cagr']}%
                                </p>
                            </div>
                            <div className="col-3 pe-0 text-center">
                                <p className="color-black e-poppins-semi-bold e-font-14 line-height-20px mb-0 ">
                                    {row['aum']}
                                </p>
                            </div>
                            <div className="col-2 text-center px-0">
                                <img draggable={false} src={row.risk_image}
                                    alt="High risk"
                                    width={42}
                                    height={40}
                                    className="object-fit-contain " />
                            </div>
                            <div className="col-3 ps-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <p className="mb-0 e-poppins-semi-bold">{row['rank']}</p>
                                    <img draggable={false} src={require("../../../../Assets/Images/Others/star.png")}
                                        alt="Rating"
                                        width={16}
                                        height={16} />
                                </div>
                            </div>
                            <div className={`col-1 text-start cursor-pointer`}>
                                <span className="e-tool-tip-container position-relative h-fit-content"
                                    data-tooltip-content="Delete selected"
                                    placement="bottom"
                                    onMouseEnter={() => setShowArrow(row.id)}
                                    onMouseLeave={() => setShowArrow(-1)}>
                                    <Icon icon={showArrow === row.id ? "delete-red" : "delete"}
                                        size={24}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        );
    }

    // API - featured list of MF
    const _mfList = () => {
        const url = "mf/list-featured";

        const data = {
            type: 1
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {

                setFunds(response.data)
            }
            setPageLoader(false);
        });
    }

    // API - save order of featured list MF
    const _orderList = (selectedFunds, is_order) => {
        if (is_order) {
            setLoader(true);
        } else {
            setPageLoader(true);

        }
        const url = "mf/update-featured";

        const mf_ids = selectedFunds.map((item) => item.id);

        const data = {
            mutual_fund_ids: mf_ids,
            type: 2
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setFunds(response.data);
                toast.dismiss();
                toast.success('List updated successfully', {
                    type: "success"
                });
            }
            setPageLoader(false);
            setLoader(false);
        });
    }



    return (
        <Fragment>
            <div className="row">
                <div className="col-12 pt-4 d-flex justify-content-between">
                    <SectionTitle title="Manage top funds" />
                    <GradientButton label="Add fund"
                        onPress={() => {
                            setOpenModal(true);
                        }} />
                </div>
                {
                    pageLoader === true ? (
                        <div className="row h-80vh">
                            <CustomLoader />
                        </div>
                    ) :
                        (
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row m-1 py-3 border-bottom border-bright-gray">
                                            <div className="col-sm-7 d-flex">
                                                <h6 className="mb-0 color-eerie-black e-poppins-medium line-height-24px e-font-16">Funds</h6>
                                            </div>
                                            <div className="col-sm-5 d-flex justify-content-end">
                                                <div className="col-3 text-center">
                                                    <h6 className="mb-0 color-eerie-black e-poppins-medium line-height-24px e-font-16 ">CAGR (3Y)</h6>
                                                </div>
                                                <div className="col-3 pe-0 text-center">
                                                    <h6 className="mb-0 color-eerie-black e-poppins-medium line-height-24px e-font-16 ">AUM (Cr)</h6>
                                                </div>
                                                <div className="col-2 text-center px-0">
                                                    <h6 className="mb-0 color-eerie-black e-poppins-medium line-height-24px e-font-16">Risk</h6>
                                                </div>
                                                <div className="col-3 text-center ps-0">
                                                    <h6 className="mb-0 color-eerie-black e-poppins-medium line-height-24px e-font-16 ">Rank </h6>
                                                </div>
                                                <div className="col-1"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* ============ MF List which can be sorted ===========-*/}
                                <DndProvider backend={HTML5Backend}>
                                    <div className="row">
                                        <div className="col-12 mt-3">
                                            {
                                                funds.map((row, index) => {
                                                    return (
                                                        <>
                                                            <DraggableBox data={row} index={index} />
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>


                                </DndProvider>
                                {/* ============ MF List ends ===========-*/}


                                <GradientButton label='Save'
                                    className='mt-3'
                                    onPress={() => _orderList(funds, true)}
                                    loading={loader} />



                            </div>
                        )
                }
            </div>
            {/* ============Manage fund modal ===========-*/}
            {
                openModal ?
                    <ManageFundModal investment_type={'Manage funds'}
                        fund={null}
                        manage_scheme={true}
                        closeModal={() => {
                            setOpenModal(false);
                        }}
                        updateFeatureFund={(selected_funds) => {
                            _orderList(selected_funds)
                        }} />
                    :
                    null
            }
        </Fragment>

    )
}

ManageScheme.defaultProps = {
    select: () => { }
}

export default ManageScheme