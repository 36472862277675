/*
 *   File : firgot-password.js
 *   Author URI : www.evoqins.com
 *   Description : Forgot password screen
 *   Integrations : NA
 *   Version : v1.0
 *   Created : 28-12-2023
 */

// import packages
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import components
import { CustomTextInput } from "../../Components/FormElements";
import { GradientButton, LinkText } from "../../Components/Cta";
import { Icon } from "../../Components/Icon";
import { toast } from "react-toastify";

// services
import APIService from "../../Services/api-service";
import { _resendOTP } from "../../Helper/api";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [loader, setLoader] = useState(false);
    const [resetPasswordStep, setResetPasswordStep] = useState(1); // 1 => email screen, 2 => otp, 3 update password
    const [tempToken, setTempToken] = useState("");
    const [timer, setTimer] = useState(30);
    const [showPassword, setShowPassword] = useState(false)
    const [showOtp, setShowOtp] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (resetPasswordStep === 2 && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [resetPasswordStep, timer]);

    useEffect(() => {
        if (location.state !== null) {
            setEmail(location.state.email);
        }
    }, [location])

    // Calculate whether the Resend OTP button should be disabled
    const isResendButtonDisabled = timer > 0;

    // handle email input
    function _handleEmailInput(value) {
        setEmail(value);
        setEmailError('');
    }

    // handle otp input
    function _handleOTPInput(value) {
        if (value.length <= 4) {
            setOtp(value);
        }
        setOtpError('');
    }

    // handle password input
    function _handlePasswordInput(value) {
        setPassword(value);
        setPasswordError('');
    }

    // handle confirm password
    function _handleConfirmPassword(value) {
        setConfirmPassword(value);
        setConfirmPasswordError('');
    }

    function _prevStep() {
        if (resetPasswordStep === 3) {
            setResetPasswordStep(1);
        }
        else {
            setResetPasswordStep(resetPasswordStep - 1);
        }
        setTimer(30);
        setEmail("");
        setEmailError("");
        setOtp("");
        setOtpError("");
        setPassword("");
        setPasswordError("");
        setConfirmPassword("");
        setConfirmPasswordError("");
    }

    /*---- validation for email ----*/
    const _validateEmail = (e) => {
        e.preventDefault();
        let mail_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email === '') {
            setEmailError("Email is required");
            return;
        }
        if (!mail_regex.test(email)) {
            setEmailError("Invalid email");
            return;
        }
        _sendOTP();
    }

    // Validate OTP
    function _validateOTP(e) {
        e.preventDefault();
        if (otp.length !== 4) {
            setOtpError('OTP must be 4 digits');
            return;
        }
        if (!/^\d+$/.test(otp)) {
            setOtpError('Invalid OTP');
            return;
        }
        _verifyOTP();
    }

    // API - update password
    function _login() {
        navigate(-1);
    }


    // Validate password 
    function _validatePassword(e) {
        e.preventDefault();
        if (password === '') {
            setPasswordError('Password is required');
            return;
        }


        if (confirmPassword === '') {
            setConfirmPasswordError('Confirm Password is required');
            return;
        }


        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }

        _changePassword();
    }


    function _isStrongPassword(password) {
        if (password.length < 6) {
            return false;
        }
        // Check if the password includes a combination of numbers, letters, and special characters
        const hasNumber = /\d/.test(password);
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasSpecialChar = /[!$@%]/.test(password);

        // Return true if all criteria are met, otherwise false
        return hasNumber && hasLetter && hasSpecialChar;
    }

    // API - send otp
    const _sendOTP = () => {
        setLoader(true);
        const url = "auth/forgot-password";
        const data = {
            "email": email,
        }
        APIService(true, url, data).then((response) => {

            if (response.status_code === 200) {
                setResetPasswordStep(2);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });

    }

    // API - - verify otp 

    const _verifyOTP = () => {
        setLoader(true);
        const url = "auth/verify-otp";
        const data = {
            "email": email,
            "otp": otp,
            "type": "FORGOT_PASSWORD"
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setResetPasswordStep(3);
                setTempToken(response.data.temp_token);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API - resend OTP
    const _resend = () => {
        const data = {
            email: email,
            type: 'FORGOT_PASSWORD'
        }

        _resendOTP(data).then((response) => {
            setTimer(30);
            toast.dismiss();
            toast.success('OTP resent', {
                type: 'success'
            })
        }).catch(error => {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
        });;
    }

    // API - reset password
    const _changePassword = () => {
        setLoader(true);
        const url = "auth/reset-password";
        const data = {
            temp_token: tempToken,
            password: confirmPassword
        }

        APIService(true, url, data).then((response) => {

            if (response.status_code === 200) {
                _login(); // navigate to login
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }


    return (
        <Fragment>
            <div className="bg-light-pastel-blue-gray e-login-bg padding-32px-all w-100vw h-100vh overflowY-scroll">
                <Link to="/">
                    <img draggable={false} src={require('../../Assets/Images/Sidebar/master-trust-logo.svg').default} alt="MT_logo"
                        className="sticky-top e-cover-image" />
                </Link>
                <div className="w-100 d-flex justify-content-center pt-5">
                    <div className="bg-white p-5 border-radius-16px w-42vw position-relative h-450px">
                        {
                            resetPasswordStep > 1 && (
                                <Icon icon="pagination-previous"
                                    size={24}
                                    className="position-absolute top-13 cursor-pointer"
                                    onClick={_prevStep} />
                            )
                        }
                        {
                            resetPasswordStep === 1 ? (
                                // Validate email
                                <>
                                    <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">Reset password</h3>
                                    <p className="color-light-black e-poppins-regular e-font-16 mt-4">Please confirm your registered email id. We will send an 4 digit OTP to your email.</p>
                                    <form onSubmit={(e) => _validateEmail(e)}>
                                        <div className="row pt-2">
                                            <div className="col-12 pb-3 mb-1">
                                                <CustomTextInput type="text"
                                                    label="Email ID"
                                                    postfix="*"
                                                    error={emailError}
                                                    value={email}
                                                    valueChange={_handleEmailInput} />
                                            </div>
                                            <div className="text-center d-flex justify-content-center align-items-center">
                                                <span className="e-font-14 line-height-24px e-poppins-medium">
                                                    Navigate to</span> <LinkText title="Login"
                                                        type={2}
                                                        onClick={_login}
                                                        className='ms-1' />
                                            </div>

                                            <div className="w-100 d-flex justify-content-center margin-40px-bottom pt-3">
                                                <GradientButton label="Send OTP"
                                                    className='padding-128px-lr'
                                                    loading={loader} />
                                            </div>
                                        </div>
                                    </form>
                                </>
                            ) : resetPasswordStep === 2 ? (
                                // OTP screen
                                <>
                                    <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">Confirm OTP</h3>
                                    <p className="color-light-black e-poppins-regular e-font-16 mt-4 text-center mb-0">Enter 4 digit OTP sent to your registered ID</p>
                                    <form onSubmit={(e) => _validateOTP(e)}>
                                        <div className="row pt-4">
                                            <div className="col-12">
                                                <CustomTextInput type={showOtp ? "text" : "password"}
                                                    label="Enter OTP"
                                                    postfix="*"
                                                    max={6}
                                                    error={otpError}
                                                    value={otp}
                                                    password_icon={true}
                                                    showPassword={() => {
                                                        setShowOtp(!showOtp)
                                                    }}
                                                    valueChange={_handleOTPInput} />
                                            </div>
                                            <div className="col-12 d-flex gap-8px justify-content-center mt-3">
                                                <p className="color-light-black e-poppins-regular e-font-14 line-height-24px mb-0">
                                                    Didn't get OTP?
                                                </p>
                                                <LinkText type={2}
                                                    disabled={isResendButtonDisabled}
                                                    title="Send again"
                                                    className=''
                                                    onClick={_resend} /> {timer > 0 && `in ${timer}s`}

                                            </div>

                                            <div className="w-100 d-flex justify-content-center margin-40px-bottom padding-32px-top">
                                                <GradientButton label="Verify & proceed"
                                                    className='padding-128px-lr'
                                                    loading={loader} />
                                            </div>
                                        </div>
                                    </form>

                                </>
                            ) : (
                                // Update password
                                <>
                                    <h3 className="text-center e-font-32 e-poppins-medium color-dark-charcoal line-height-48px">Update password</h3>
                                    <p className="color-light-black e-poppins-regular e-font-16 mt-4">
                                        The password must have at least 8 characters, including one special character and one uppercase letter.
                                    </p>
                                    <form onSubmit={(e) => _validatePassword(e)}>
                                        <div className="row pt-2">
                                            <div className="col-12 mb-1">
                                                <CustomTextInput type={showPassword ? "text" : "password"}
                                                    label="New password"
                                                    postfix="*"
                                                    error={passwordError}
                                                    value={password}
                                                    password_icon={true}
                                                    showPassword={() => {
                                                        setShowPassword(!showPassword)
                                                    }}
                                                    valueChange={_handlePasswordInput} />
                                            </div>

                                            <div className="col-12 mt-4">
                                                <CustomTextInput type={showConfirmPassword ? "text" : "password"}
                                                    label="Re-enter new password"
                                                    postfix="*"
                                                    error={confirmPasswordError}
                                                    value={confirmPassword}
                                                    password_icon={true}
                                                    showPassword={() => {
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }}
                                                    valueChange={_handleConfirmPassword} />
                                            </div>

                                            <div className="w-100 d-flex justify-content-center margin-40px-bottom padding-32px-top">
                                                <GradientButton label="Login"
                                                    className='padding-128px-lr'
                                                    loading={loader} />
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default ForgotPassword;