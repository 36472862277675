
/*
 *   File : download-button.js
 *   Author : https://evoqins.com
 *   Description : Download button
 *   Integrations : NA
 *   Version : 1.0.0
*/

/* import styles */
import styles from '../../Styles/Components/download.module.scss';

const DownloadButton = (props) => {

    return (
        <button className={`${props.disabled ? styles.e_disabled_btn : ''} ${props.loader ? styles.e_download_loader : styles.e_download_btn} ${props.className} e-poppins-regular e-font-14 line-height-24px border-radius-6px border-all border-bright-gray justify-content-center align-items-center gap-8px w-max-content color-outer-space bg-white `}
            id={props.id}
            name={props.name}
            data-bs-dismiss={props.cancel}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            type={props.type}
            onClick={props.onPress}>
            {/* <Icon icon="download"
                width="24px"
                height="24px"
                className="me-2" /> */}

            Download
        </button>
    )
}
DownloadButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    type: '',
    id: null,
    name: "",
}
export default DownloadButton;

