import axios from "axios";
import Cookies from "js-cookie";

export default async function APIService(is_post, url, data) {
    let API_URL = process.env.REACT_APP_API_URL + url;
    const admin_session = Cookies.get('admin_session');

    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${admin_session}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        Cookies.remove('admin_session');
                        Cookies.remove('refreshToken');
                        window.location = "/";
                    }
                    return error.response.data;
                } else if (error.request) {
                    return { message: 'Network connection time out', status_code : 504}
                }
            });
    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${admin_session}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        Cookies.remove('admin_session');
                        Cookies.remove('refreshToken');
                        window.location = "/";
                    }
                    return error.response.data;
                }
                else if (error.request) {
                    return { message: 'Network connection time out', status_code : 504}
                }
            });
    }
}
