
/*
 *   File : chip.js
 *   Author URI : https://evoqins.com
 *   Description : UI element for clickable chip for filtering the data
 *   Integrations : null
 *   Version : v1.1
 */

import Icon from "../Icon/icon";

const Chip = (props) => {
    // props;
    let { data, chipIndex, activeChipIndex, className, activeClassName, icon, iconSize, onPress } = props;

    return (

        props.type === 1 ?
            <span className={`cursor-pointer 
        ${activeChipIndex === chipIndex ?
                    `${activeClassName}`
                    : ` ${className}`}`
            }
                onClick={() => onPress(chipIndex, data)}>
                {data}
                {
                    icon && (
                        <Icon icon={icon}
                            size={iconSize} />
                    )
                }
            </span>
            : props.type === 2 ?

                <span className={`cursor-pointer 
            ${activeChipIndex === chipIndex ?
                        "color-black e-font-16 e-poppins-medium bg-pastel-green border-all border-radius-20px border-sea-green  py-2 px-3"
                        : "color-black e-font-16 e-poppins-regular bg-light-greenish-white border-radius-20px border-all border-transparent  py-2 px-3 "}`
                }
                    onClick={() => onPress(chipIndex)}>
                    {data}
                    {
                        icon && (
                            <Icon icon={icon}
                                size={iconSize} />
                        )
                    }
                </span>
                :
                <span className={`cursor-pointer 
            ${activeChipIndex.includes(chipIndex) ?
                        "color-black e-font-16 e-poppins-medium bg-pastel-green border-all border-radius-20px border-sea-green  py-2 px-3"
                        : "color-black e-font-16 e-poppins-regular bg-light-greenish-white border-radius-20px border-all border-transparent  py-2 px-3 "}`
                }
                    onClick={() => onPress(chipIndex)}>
                    {data}
                    {
                        icon && (
                            <Icon icon={icon}
                                size={iconSize} />
                        )
                    }
                </span>

    )
}

Chip.defaultProps = {
    onPress: () => { },
    type: 1
}

export default Chip;