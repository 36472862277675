/*
 *   File : Terms.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Terms container
 *   Version : v1.0
 */
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { GradientButton } from '../../../Components/Cta';
import { Editor } from '../../../Components/FormElements';
import { CustomLoader } from '../../../Components/Other';

/* import services */
import { _addContent } from '../../../Helper/api';

const Terms = (props) => {
    const [editorData, setEditorData] = useState(null);
    //eslint-disable-next-line
    const [current, setCurrent] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (props.data !== null) {
            setEditorData(props.data.content);
            setCurrent(true);
        } else {
            setCurrent(false);
        }
    }, [props.data])


    // API - add data
    const _addTerms = () => {
        setApiLoader(true);
        const data = {
            "content_id": props.data !== null ? props.data.id : null,
            "content_type_id": 3,
            "heading": "",
            "content": editorData,
            "page": null,
            "section": null

        }
        _addContent(data).then((response) => {
            setApiLoader(false);
            props.termsAdded();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })
    }


    return (
        <>

            <div className='row pt-2'>
                {
                    !current ?
                        <div className='col-lg-12 e-poppins-regular'>
                            <Editor
                                editorChange={(temp) => {
                                    setEditorData(temp)
                                }} value={editorData} />
                        </div>
                        :
                        editorData ?
                            <div className='col-lg-12 e-poppins-regular'>
                                <span dangerouslySetInnerHTML={{ __html: editorData }} />
                            </div>
                            :
                            <div className="h-80vh">
                                <CustomLoader />
                            </div>
                }
            </div>
            {props.permissionType !== 'read' && <div className='row pt-4 mt-3'>
                {
                    !current ?
                        editorData ?
                            <div className='col-lg-1'>
                                <GradientButton
                                    label="Save"
                                    className="e-editor-save"
                                    loading={apiLoader}
                                    onPress={() => {
                                        _addTerms()
                                    }} />
                            </div>
                            :
                            <div className='col-lg-1'>
                                <GradientButton
                                    label="Save"
                                    className="e-editor-save e-disabled"
                                    disable={true} />
                            </div>
                        :
                        <div className='col-lg-1'>
                            <GradientButton
                                label="Update"
                                className="e-editor-save"
                                onPress={() => {
                                    setCurrent(false);
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    });
                                }} />
                        </div>
                }
            </div>}


        </>

    )
}

export default Terms;
