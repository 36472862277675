
/*
 *   File : list.js
 *   Author URI : https://evoqins.com
 *   Description : Container file to show the notifications list .
 *   Integrations : date-fns
 *   Version : v1.1
 */

/* import packages */
import { Fragment, useEffect, useRef, useState } from "react";
import { format } from "date-fns";

/* import components */
import { Chip } from "../../../Components/Other";
import { DateRangeFilterPopup, NotificationFilterPopup, } from "../../../Components/Popups";
import { NotificationCard } from "../../../Components/Cards";
import { Footer } from "../../../Components/Footer";
import { NavigationHeader } from "../../../Components/Header";

/* import helper */
import { useClickOutside } from "../../../Helper/helper-function";


const NOTIFICATION_DATA = [{
    is_read: false,
    transactional_notification: true,
    title: "Add a new associate",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate.",
    order_status: "Success",
    created: "8 hours ago"
},
{
    is_read: true,
    transactional_notification: true,
    title: "Order received",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis..",
    order_status: "Success",
    created: "8 hours ago"
},
{
    is_read: true,
    transactional_notification: false,
    title: "Payment received",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate.",
    order_status: "Success",
    created: "8 hours ago"
},
{
    is_read: true,
    transactional_notification: true,
    title: "Order received",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    order_status: "Success",
    created: "8 hours ago"
},
{
    is_read: false,
    transactional_notification: false,
    title: "Change a sub admin to associate level",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    order_status: "Success",
    created: "8 hours ago"
}]

const CHIP_DATA = [
    {
        value: null,
        name: "All"
    },
    {
        value: true,
        name: "Read"
    },
    {
        value: false,
        name: "Un-read"
    },
];

const FILTER_BY = [{
    value: null,
    label: "All"
},
{
    value: true,
    label: "Transactions"
},
{
    value: false,
    label: "Non-transactions"
}]

const Notifications = () => {
    const filterRef = useRef();
    const [filterId, setFilterId] = useState(CHIP_DATA[0].value);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [filterBy, setFilterBy] = useState(FILTER_BY[0]);
    const [notificationList, setNotificationList] = useState(NOTIFICATION_DATA);


    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // hooks for closing popup on mouse event
    useClickOutside(filterRef, () => {
        setShowDateFilter(false);
    });

    // handler for chip filter
    function _handlefilter(value) {
        setFilterId(value);
        if (value !== null) {
            let notification_list = NOTIFICATION_DATA.filter((item) => item.is_read === value);
            setNotificationList(notification_list);
        } else {
            setNotificationList(NOTIFICATION_DATA);
        }
    }

    // date filter change
    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd MMM yy"));
        setEndDate(format(newRange.endDate, "dd MMM yy"));
        setSelectedRange(newRange);
    };

    // toggle for filter 
    const _handleShowDateFilter = (clear) => {
        setShowDateFilter(!showDateFilter);
        if (clear) {
            setStartDate("Start date");
            setEndDate("End date");
            setSelectedRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
    }


    // handler for filter dropdown
    const _handleFilter = (value) => {
        setFilterBy(value);
    }


    // const _handleClick = (notification) => {

    // };

    return (
        <Fragment>

            {/*======= Header ====*/}
            <NavigationHeader
                type={1}
                title="Notifications" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row">

                    <div className="col-9">
                        <div className="d-flex justify-content-between align-items-center margin-32px-bottom">
                            <div className="d-flex gap-8px flex-wrap ">
                                {
                                    CHIP_DATA.map((tag, index) => {
                                        return (
                                            <Chip data={tag.name}
                                                key={index}
                                                type={2}
                                                chipIndex={tag.value}
                                                activeChipIndex={filterId}
                                                onPress={_handlefilter} />
                                        )
                                    })
                                }
                            </div>

                            <div className="d-flex gap-16px">
                                <div className="position-relative">
                                    <p className={`border-radius-6px border-all bg-white p-2 mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px cursor-pointer ${startDate !== "Start date" && endDate !== "End date" ? 'border-sea-green' : 'border-bright-gray'}`}
                                        onClick={() => {
                                            _handleShowDateFilter(false);
                                        }}>
                                        {startDate} - {endDate}
                                    </p>
                                    {
                                        showDateFilter === true &&
                                        <div ref={filterRef}>
                                            <DateRangeFilterPopup

                                                maxDate={new Date()}
                                                initialRange={selectedRange}
                                                cancel={() => {
                                                    _handleShowDateFilter(true)
                                                }}
                                                onUpdate={_handleDateRangeChange} />
                                        </div>
                                    }
                                </div>
                                {/* Drop down filter */}
                                <NotificationFilterPopup value={filterBy}
                                    options={FILTER_BY}
                                    onSelectChange={_handleFilter}

                                />
                            </div>

                        </div>

                        <div className="d-flex flex-column gap-16px">
                            {
                                notificationList.map((item, key) =>
                                    <NotificationCard data={item}
                                        key={key}
                                        type={1}
                                        notificationClick={() => {
                                            // _handleClick(item)
                                        }} />)
                            }

                        </div>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Notifications