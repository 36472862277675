/*
 *   File : footer.js
 *   Author URI : https://evoqins.com
 *   Description : A common component to create a platform footer.
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment } from 'react';


const Footer = React.memo(() => {
    return (
        <Fragment>
            <div className="container-fluid position-sticky bottom-0 d-lg-block d-md-block d-sm-block d-none">
                <div className="row ">
                    <div className="col-12 e-footer bg-ghost-white">
                        <div className="row">
                            <div className="col-12 px-0 padding-24px-tb d-flex justify-content-center">
                                <img draggable={false}
                                    src={require("../../Assets/Images/Sidebar/master-trust-logo.svg").default}
                                    alt="Master Trust logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

export default Footer