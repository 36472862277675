
/*
 *   File : add-admin.js
 *   Author URI : www.evoqins.com
 *   Description : Add sub admin
 *   Integrations : null
 *   Version : v1.0
 *   Created : 27-12-2023
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { GradientButton, OutlineButton } from "../../../Components/Cta";
import { NavigationHeader } from "../../../Components/Header";
import { SectionTitle } from "../../../Components/Title";
import { CustomSearchSelectBox, CustomTextInput, CustomPhoneInput } from "../../../Components/FormElements";
import { Icon } from "../../../Components/Icon";

import { _getAdminTypes, _getPermissions } from "../../../Helper/api";
import APIService from "../../../Services/api-service";

const PERMISSION_OBJ = { module: '', permission: '', error: false };

export default function AddAdmin(props) {

    const navigate = useNavigate();

    let roles_options_static = [];

    const [adminTypes, setAdminTypes] = useState([]);
    const [selectedAdminType, setSelectedAdminType] = useState('');
    const [selectedAdminError, setSelectedAdminError] = useState('');
    const [adminName, setAdminName] = useState('');
    const [adminNameError, setAdminNameError] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminEmailError, setAdminEmailError] = useState('');
    const [adminPhone, setAdminPhone] = useState('');
    const [adminPhoneError, setAdminPhoneError] = useState('');
    const [adminPermissions, setAdminPermissions] = useState([PERMISSION_OBJ]);
    const [mtID, setMtID] = useState('');
    const [mtIDError, setMtIDError] = useState('');
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        _fetchAdminList();
        _fetchPermissions();
    }, []);

    // update admin type 
    function _handleAdminTypeChange(value) {
        setSelectedAdminType(value);
        setSelectedAdminError('');
    }
    // update admin name input
    function _handleAdminName(value) {
        setAdminName(value);
        setAdminNameError('');
    }

    // update MT ID
    function _handleMtId(value) {
        setMtID(value);
        setMtIDError('');
    }

    // update admin mail input
    function _handleAdminMail(value) {
        setAdminEmail(value);
        setAdminEmailError('');
    }

    // update admin number input 
    function _handleAdminPhone(value) {
        setAdminPhone(value);
        setAdminPhoneError('');
    }

    // navigate to listing
    function _navigateToListing() {
        navigate('/sub-admins');
    }
    // handle role option change
    function _handleRoleChange(value, index) {

        let roles = [...adminPermissions];
        roles[index] = {
            module: value,
            permission: ''
        };
        let updated_roles_options = [...permissionOptions];
        // removing selected item from role menu option by setting show_option as false, 
        // in component we handle this based on show_option 
        let option_index = updated_roles_options.findIndex(item => item.value === value.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = false;
        }
        setPermissionOptions(updated_roles_options);
        setAdminPermissions(roles);
    }

    // handle action option change
    function _handleActionChange(value, key) {
        let actions = [...adminPermissions];
        actions[key] = {
            module: actions[key].module,
            permission: value
        };
        setAdminPermissions(actions);
    }

    // add new permission to array
    function _handleAddNew() {
        const is_valid = adminPermissions.every(element => element.module !== '' && element.permission !== '');
        // validating before adding new items;
        if (is_valid) {
            setAdminPermissions([...adminPermissions, { module: '', permission: '', error: false }]);
        } else {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
        }
    }

    // scroll to form elements
    function _scrollToElement (id) {
        let element = document.getElementById(id);
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: "nearest"
        })
    }

    // Remove permission from array
    function _deletePermission(index) {
        let permission_array = [...adminPermissions];
        let updated_roles_options = [...permissionOptions];
        // adding removed item role option to role dropdown 
        let dropdown_option = permission_array[index].module;
        let option_index = updated_roles_options.findIndex(item => item.value === dropdown_option.value);
        if (option_index !== -1) {
            updated_roles_options[option_index].show_option = true;
        }
        setPermissionOptions(updated_roles_options);
        permission_array.splice(index, 1);
        setAdminPermissions(permission_array);
    }

    // validate form before submitting 
    function _validateForm() {
        let valid = true;
        const is_valid_permissions = adminPermissions.every(element => element.module !== '' && element.permission !== '');
        if (selectedAdminType === '') {
            setSelectedAdminError('Admin type is required');
            _scrollToElement('admin-type-input');
            valid = false;
        }
        if (adminName.trim().length === 0) {
            setAdminNameError('Admin name is required');
            _scrollToElement('name-input');
            valid = false
        }
        if (mtID.trim().length === 0) {
            setMtIDError('Create code is required');
            _scrollToElement('code-input');
            valid = false
        }
        if (adminEmail.trim().length === 0) {
            setAdminEmailError('Admin Email is required');
            _scrollToElement('mail-input');
            valid = false;
            //eslint-disable-next-line
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(adminEmail)) {
            setAdminEmailError('Invalid email');
            _scrollToElement('mail-input');
            valid = false;
        }
        if (adminPhone === '') {
            setAdminPhoneError('Admin phone is required');
            _scrollToElement('number-input');
            valid = false;
        } else if (adminPhone.length !== 10) {
            setAdminPhoneError('Invalid phone');
            _scrollToElement('number-input');
            valid = false;
        }
        if (is_valid_permissions === false) {
            const updated_permissions = adminPermissions.map(element => ({
                ...element,
                error: element.module === '' || element.permission === '',
            }));
            setAdminPermissions(updated_permissions);
            updated_permissions.forEach((item, key) => {
                if (item.module === '' || item.permission === ''){
                    _scrollToElement(`role-input-${key}`);
                }
            });
            valid = false;
        }
        // checking for duplicate
        else {
            const module_labels = adminPermissions.map(entry => entry.module.label);
            const has_duplicates = new Set(module_labels).size !== module_labels.length;
            if (has_duplicates) {
                toast.dismiss();
                toast.error('Please avoid duplicate Roles', {
                    type: 'error'
                });
                valid = false;
            }
        }

        if (valid === true) {
            _createNewAdmin();
        }
    }

    // API - get admin type list
    async function _fetchAdminList() {
        let admin_data = await _getAdminTypes();
        if (admin_data.length !== 0) {
            admin_data.forEach(item => {
                item.label = item.name;
                delete item.name;
            });
            setAdminTypes(admin_data);
        } else {
            setAdminTypes([]);
        }
    };

    // API - get permissions 
    async function _fetchPermissions() {
        let permission_data = await _getPermissions();
        if (permission_data.modules.length !== 0) {
            let formatted_data = permission_data.modules.map(item => ({
                value: item.id,
                label: item.name,
                permissions: item.permissions.map(permission => ({
                    value: permission.permission_id,
                    label: permission.name
                })),
                short_name: item.short_name,
                show_option: true,
            }));
            setPermissionOptions(formatted_data);
            roles_options_static = formatted_data;
        } else {
            setPermissionOptions([]);
        }

    }

    // API - add admin 
    function _createNewAdmin() {
        setApiLoader(true);
        const permissions = adminPermissions.map((item) => {
            return { module_id: item.module.value, permission_type_id: item.permission.value }
        });
        console.log('selectedAdminType', selectedAdminType);
        let url = 'auth/add-admin';
        let payload = JSON.stringify({
            name: adminName,
            email: adminEmail,
            admin_type_id: selectedAdminType.id,
            mt_id: mtID,
            phone: adminPhone,
            permissions
        });
        APIService(true, url, payload).then((response) => {
            if (response.status === true) {
                toast.dismiss();
                toast.success('Admin added successfully', {
                    type: 'success'
                });
                _navigateToListing();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        });
    }

    return (
        <>
            {/* header */}
            <NavigationHeader type={1}
                title="Role categorization" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 ">
                <div className="row padding-32px-bottom">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="Create admin" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-12">
                                <CustomSearchSelectBox label="Additional information"
                                    value={selectedAdminType}
                                    placeholder=""
                                    id="admin-type-input"
                                    error={selectedAdminError}
                                    options={adminTypes}
                                    postfix="*"
                                    onSelectChange={_handleAdminTypeChange} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Add sub admin name"
                                    value={adminName}
                                    postfix="*"
                                    id='name-input'
                                    type="text"
                                    error={adminNameError}
                                    valueChange={_handleAdminName} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Email"
                                    value={adminEmail}
                                    id='mail-input'
                                    postfix="*"
                                    type="text"
                                    error={adminEmailError}
                                    valueChange={_handleAdminMail} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomPhoneInput label="Phone"
                                    value={adminPhone}
                                    postfix="*"
                                    id='number-input'
                                    type="number"
                                    error={adminPhoneError}
                                    handleChange={_handleAdminPhone} />
                            </div>
                            <div className="col-12 mt-4 pt-3">
                                <CustomTextInput label="Create code"
                                    value={mtID}
                                    postfix="*"
                                    id='code-input'
                                    type="text"
                                    error={mtIDError}
                                    valueChange={_handleMtId} />
                            </div>
                            <div className="col-12 mt-4 pt-2">
                                <SectionTitle title="Add admin permissions" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            adminPermissions.map((item, key) => {
                                return (
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className={`col-6 ${key === 0 ? 'mt-3' : 'mt-4'} `} key={key}>
                                                    <CustomSearchSelectBox label="Role"
                                                        value={item.module}
                                                        placeholder=""
                                                        isDisabled={(selectedAdminType.length !== 0 &&
                                                            adminName.length !== 0 &&
                                                            adminEmail.length !== 0 &&
                                                            adminPhone.length !== 0 &&
                                                            mtID.length !== 0) ? false : true}
                                                        error={item.error === true && item.module === '' ? 'Role required' : ''}
                                                        options={permissionOptions}
                                                        postfix="*"
                                                        id={`role-input-${key}`}
                                                        toggleOptions={true}
                                                        onSelectChange={(value) => _handleRoleChange(value, key)} />

                                                </div>
                                                <div className={`col-6 ${key === 0 ? 'mt-3' : 'mt-4'}`}
                                                    key={key}
                                                    data-disabled={item.module === '' ? 'true' : 'false'}>
                                                    <CustomSearchSelectBox label="Action"
                                                        value={item.permission}
                                                        placeholder=""
                                                        error={item.error === true && item.permission === '' ? 'Action required' : ''}
                                                        options={item.module.permissions}
                                                        id={`action-input-${key}`}
                                                        postfix="*"
                                                        onSelectChange={(value) => _handleActionChange(value, key)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 mt-4 pt-2">
                                            <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer me-3" onClick={() => _deletePermission(key)}>
                                                <Icon icon="delete" size={24} className='me-2' />
                                                Delete
                                            </span>
                                            {
                                                key + 1 === adminPermissions.length && permissionOptions.length !== adminPermissions.length &&(
                                                    <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer " onClick={_handleAddNew}>
                                                        <Icon icon="add-circle1" size={24} className='me-2' />
                                                        Add new
                                                    </span>
                                                )
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        adminPermissions.length === 0 && adminPermissions.length < permissionOptions.length &&(
                            <div className="col-12 h-initial position-relative mt-3">
                                <span className="color-primary-color e-font-16 line-height-16px e-poppins-medium cursor-pointer " onClick={_handleAddNew}>
                                    <Icon icon="add-circle1" size={24} className='me-2' />
                                    Add new
                                </span>
                            </div>
                        )
                    }

                    <div className="col-12 mt-4 pt-3">
                        <OutlineButton label="Cancel"
                            onPress={_navigateToListing}
                            className="me-3" />
                        <GradientButton label="Save & send invite"
                            className="padding-12px-lr"
                            loading={apiLoader}
                            onPress={_validateForm} />
                    </div>
                </div>
            </div>
        </>
    );
}
