
/*
 *   File : notification-filter.js
 *   Author : https://evoqins.com
 *   Description : UI element which is used to do the notification filter
 *   Integrations : null
 *   Version : 1.0.0
*/

/* import package */
import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";

/* import components */
import Icon from "../Icon/icon";

import Colors from '../../Styles/color.module.scss'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img draggable={false} src={require('../../Assets/Images/Filter/filter.png')}
                alt="Filter"
                width={24}
                height={24}
            />
        </components.DropdownIndicator>
    );
};


const NotificationFilterPopup = (props) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0"
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "6px",
            border: state.isFocused ? `1px solid ${Colors.primary}` : `1px solid ${Colors.bright_gray}`,
            background: Colors.white,
            padding: "8px",
            boxShadow: "none",
            "&:hover": {
                // border: `1px solid ${Colors.bright_gray}`,
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            width: "248px",
            right: "0",
            border: `2px solid ${Colors.gainsboro}`,
            background: Colors.white,
            boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
        }),

        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '8px 10px',
            display: "flex",
            flexDirection: "column",
            gap: "12px"
        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            fontFamily: "Poppins-regular",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: "400",
            backgroundColor: Colors.white,
            color: state.isSelected ? Colors.white : Colors.eerie_black,
            borderRadius: "12px",
            cursor: "pointer",
            "&:active": {
                backgroundColor: Colors.white,
            },

        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "24px"
        }),
    };

    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }

    const CustomSingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <div className="d-flex gap-8px">
                <span className="color-outer-space e-poppins-regular e-font-14 line-height-24px">
                    Filter
                </span>
                {/* <span className="e-poppins-medium color-charleston-green">{children}</span> */}
            </div>
        </components.SingleValue>
    );

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => (
        <components.Option {...props}>
            <div className="d-flex align-items-center gap-10px">
                {
                    isSelected ?
                        <Icon icon="radio-selected"
                            size={24} />
                        :
                        <Icon icon="radio-unselected"
                            size={24} />
                }
                <span className={isSelected ? "e-poppins-medium" : "e-poppins-regular"}>{children}</span>
            </div>
        </components.Option>
    );

    return (
        <Fragment>
            <div className={`${props.className} position-relative`}>
                <Select
                    value={value}
                    styles={customStyles}
                    isSearchable={false}
                    options={props.options}
                    placeholder={""}
                    // menuIsOpen={true}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                    }}
                    onChange={(value) => {
                        _onSelectChange(value)
                    }}
                />
            </div>

        </Fragment>
    );
}

export default NotificationFilterPopup;