/*
 *   File : header.js
 *   Author URI : https://evoqins.com
 *   Description : Header 
 *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumb } from '../Breadcrumb';


const NavigationHeader = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const SIDEBAR_TOGGLE = useSelector(state => state.MT_ADMIN_STORE.toggle);
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    return (
        <Fragment>
            {/* large screen view */}
            <div className={SIDEBAR_TOGGLE ? 'w-90vw d-lg-block md-w-100 position-fixed top-0 bg-white e-header top-0 ps-4 pe-lg-4 pe-md-2 pe-2' : 'd-lg-block md-w-100 position-fixed top-0 bg-white e-header top-0 ps-4 padding-72px-right w-85vw'}>
                <div className="row padding-22px-tb position-relative mb-3" >
                    {/* search widget */}

                    <div className='col-xl-5 col-5 d-flex justify-content-start align-items-center gap-16px'>
                        {
                            props.type === 1 ?
                                <h6 className='color-black e-font-20 line-height-32px e-poppins-medium mb-0'>
                                    {props.title}
                                </h6>
                                :
                                <div className='d-block'>
                                    
                                    <h6 className='color-black e-font-20 line-height-32px e-poppins-medium mb-0 pt-3'>
                                        {props.title}
                                    </h6>
                                </div>
                        }

                    </div>
                    <div className="col-xl-7 col-7 d-flex justify-content-end align-items-center gap-16px ">

                        {/* {
                            ADMIN_PROFILE_DATA.admin_type_id !== 1 &&
                            <div className={`p-3 border-radius-10px e-heading-icons position-relative cursor-pointer ${location.pathname === "/admin-settings" && "outline-1px-solid"}`}
                                onClick={() => {
                                    navigate("/admin-settings");
                                }}>
                                <img draggable={false} src={require('../../Assets/Images/Navigation/setting-img.png')} alt="Settings"
                                    width={24}
                                    height={24}
                                     />
                            </div>
                        } */}
                        {/* {
                            ADMIN_PROFILE_DATA.admin_type_id !== 1 &&
                            <div className={`p-3 border-radius-10px e-heading-icons position-relative cursor-pointer ${location.pathname === "/notifications" && "outline-1px-solid"}`}
                                onClick={() => {
                                    navigate("/notifications");
                                }}>
                                <img draggable={false} src={require('../../Assets/Images/Navigation/notification-img.png')} alt="Notification"
                                    width={24}
                                    height={24}
                                     />
                                <p className='color-white border-radius-6px e-poppins-bold e-font-12 line-height-12px e-badge position-absolute mb-0 bg-sea-green d-flex justify-content-center align-items-center'>
                                    3
                                </p>
                            </div>
                        } */}
                        <img draggable={false} src={ADMIN_PROFILE_DATA.image} alt="Profile"
                            width={50}
                            height={50}

                            className='cursor-pointer border-radius-8px object-fit-contain'
                            onClick={() => {
                                navigate("/profile");
                            }} />
                    </div>
                </div>
            </div>


        </Fragment>

    )
}

export default NavigationHeader;