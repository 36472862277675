/*
 *   File : file-viewer.js
 *   Author URI : https://evoqins.com
 *   Description : UI element to view uploaded files.
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect, useState } from "react";

import { Icon } from "../Icon";
import { CustomLoader } from "../Other";

import style from "../../Styles/Components/file-viewer.module.scss";


export default function FileViewer(props) {

    const [fileType, setFileType] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        var my_modal = document.getElementById("file-viewer");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const handleLoad = () => {
        setIsLoading(false);
        console.log('load complete')
    };

    const handleError = () => {
        setIsLoading(false); // Set loading state to false if there's an error loading the PDF
        console.error("Error loading PDF");
    };



    return (
        <div className={`modal fade ${style.e_file_viewer}`}
            id="file-viewer"
            tabIndex="-1"
            aria-labelledby="file-viewer"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        id="close-modal"
                        className="cursor-pointer ms-auto e-modal-close-btn"
                        data-bs-dismiss="modal" />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center align-items-middle h-100">
                                {
                                    props.data.type === 2 ?
                                        <img src={props.data.url} className="w-90"/>
                                        :
                                        <>

                                            {isLoading === true &&
                                                <div className="position-absolute w-100 h-75">
                                                    <CustomLoader />
                                                </div>
                                            }

                                            <iframe
                                                title="PDF Viewer"
                                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(props.data.url)}&embedded=true`}
                                                style={{ width: '500px', height: '500px', border: 'none' }}
                                                onLoad={handleLoad}
                                                onError={handleError}
                                            />
                                        </>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 