/*
 *   File : update-admin-password.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for updating password.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

/* import components */
import { GradientButton, SecondaryButton } from "../Cta";
import { Icon } from "../Icon";
import { CustomTextInput } from "../FormElements";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";



const UpdateAdminPassword = (props) => {
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [reset, setReset] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("update-admin-password");
        my_modal.addEventListener("hidden.bs.modal", () => {
            setNewPassword('');
            setReset(false);
            setConfirmPassword('');
            setValidationErrors([]);
            props.closeModal(); // to re-open modal if gets closed  
        });
        //eslint-disable-next-line
    }, [props.close]);


    function _handleNewPassword(value, field) {
        setNewPasswordError('');
        setNewPassword(value);
    }

    function _handleConfirmPassword(value, field) {
        setConfirmPasswordError('');
        setConfirmPassword(value);
    }

    // validation for fields
    function _validateForm() {
        let valid = true;
        const password_validator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; //password validator regex       


        if (newPassword === '') {
            valid = false;
            setNewPasswordError("New password required");
        }

        if (confirmPassword === '') {
            valid = false;
            setConfirmPasswordError('Confirm password required');
        }

        if (newPassword !== confirmPassword) {
            valid = false;
            setConfirmPasswordError('New & Confirm passwords must match');
        }

        if (valid === true) {
            _changePassword();
        }
    }


    // close modal
    function _handleContinue() {
        closeModal("update-admin-password")
    }


    // API - change password for sub admin
    const _changePassword = () => {
        setLoader(true);
        const url = "auth/change-admin-password";

        const data = {
            "admin_id": props.admin_id,
            "password": newPassword
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setNewPassword('');
                setConfirmPassword('');
                setReset(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="update-admin-password"
            tabIndex="-1"
            aria-labelledby="update-admin-password"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white  padding-32px-all ${style.e_content} `}>
                    {
                        !reset ?
                            <Fragment>
                                <div className="col-12 d-flex justify-content-between">
                                    <h3 className="color-black e-poppins-semi-bold e-font-18">
                                        Set password
                                    </h3>
                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>

                                <div className="col-md-7  padding-20px-top position-relative">
                                    <CustomTextInput label="New password"
                                        value={newPassword}
                                        type={showNewPassword ? "text" : "password"}
                                        postfix="*"
                                        className="mb-3 pa"
                                        error={newPasswordError}
                                        password_icon={true}
                                        showPassword={() => {
                                            setShowNewPassword(!showNewPassword)
                                        }}
                                        valueChange={(value) => _handleNewPassword(value, 'newPassword')} />

                                </div>

                                <div className="col-md-7  padding-20px-top position-relative">
                                    <CustomTextInput label="Confirm new password"
                                        value={confirmPassword}
                                        type={showConfirmPassword ? "text" : "password"}
                                        postfix="*"
                                        className="mb-3"
                                        error={confirmPasswordError}
                                        password_icon={true}
                                        showPassword={() => {
                                            setShowConfirmPassword(!showConfirmPassword)
                                        }}
                                        valueChange={(value) => _handleConfirmPassword(value, 'confirmPassword')} />

                                </div>
                                <div className="col-md-7">
                                    <SecondaryButton label="Cancel" className="me-3"
                                        cancel="modal" />
                                    <GradientButton label="Update password"
                                        loading={loader}
                                        className="px-3 padding-12px-tb e-font-16 mt-4"
                                        onPress={_validateForm} />
                                </div>

                            </Fragment>
                            :
                            <Fragment>

                                <div className="col-12 d-flex justify-content-between">

                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>
                                <img draggable={false} src={require("../../Assets/Images/Modal/kyc-success.png")}
                                    alt="Kyc success"
                                    width={72}
                                    height={72}
                                    className="mx-auto"
                                />


                                <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-3 mb-0 text-center">
                                    Password reset!
                                </h6>
                                <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5 text-center letter-spacing-point28">
                                    your password has been successfully reset, click below to continue your access.
                                </p>
                                <div className="d-flex  justify-content-center margin-32px-top margin-32px-bottom w-100">

                                    <GradientButton label="Back"
                                        className="padding-12px-tb e-font-16 "
                                        cancel="modal"
                                        onPress={_handleContinue} />
                                </div>

                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}


UpdateAdminPassword.defaultProps = {
    closeModal: () => { }
}

export default UpdateAdminPassword