/*
 *   File : manage-notification.js
 *   Author URI : https://evoqins.com
 *   Description : Manage notification page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useState } from "react";
import { NavigationHeader } from "../../../Components/Header";
import { CustomTab } from "../../../Components/Tab";
import { ManageCategory, ManageContactDetail, ManageScheme, BannerList } from "./helper";



// tab data
const SETTINGS_TABS = [
    "Manage banners",
    "Contact details",
    "Manage MT funds",
    "Manage popular funds",
    "Category"
]


const ManageSettings = React.memo(() => {
    const [tabIndex, setTabIndex] = useState(1);

    // handle tab index
    const _handleTabIndex = (tab_index) => {
        setTabIndex(tab_index);
    }
    return (
        <Fragment>
            <NavigationHeader title="App general settings "
                type={1} />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top ">
                <div className="row">
                    <div className="col-12">
                        <CustomTab type={1}
                            index={tabIndex}
                            data={SETTINGS_TABS}
                            onSelectTab={_handleTabIndex} />

                    </div>
                    <div className="col-12">
                        {
                            tabIndex === 1 ?
                                <BannerList />
                                :
                                tabIndex === 2 ?
                                    <ManageContactDetail />
                                    :
                                    tabIndex === 3 ?
                                        <ManageScheme type={1} />
                                        :
                                        tabIndex === 4 ?
                                            <ManageScheme type={2} />
                                            :
                                            <ManageCategory />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
})
export default ManageSettings;