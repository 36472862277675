/*
 *   File : create-notification.js
 *   Author URI : https://evoqins.com
 *   Description : Create notification page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useState } from "react";


/* import components */
import { CustomTextArea, CustomTextInput, FileUpload, RadioGroup } from "../../../Components/FormElements";
import { GradientButton, SecondaryButton } from "../../../Components/Cta";
import { FilePreview } from "../../../Components/Cards";
import { Icon } from "../../../Components/Icon";
import { SectionTitle } from "../../../Components/Title";

/* import styles */
import Colors from '../../../Styles/color.module.scss';


// options for sub admins
const OPTION_TYPES = [
    {
        label: "All",
        value: null
    },
    {
        label: "Sub-admin(ops)",
        value: 1
    },
    {
        label: "Sub-admins(view)",
        value: 2
    },
    {
        label: "Associate",
        value: 3
    },
    {
        label: "Branch",
        value: 4
    },
    {
        label: "RM",
        value: 5
    },
    {
        label: "Customer",
        value: 6
    }
]

// type of notification
const TYPE = [
    {
        label: "Both",
        id: 1
    },
    {
        label: "In-app push notification",
        id: 2
    },
    {
        label: "Email",
        id: 3
    },
]

const CreateNotification = React.memo(() => {
    const [heading, setHeading] = useState("");
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [headingError, setHeadingError] = useState(null);
    const [contentError, setContentError] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [type, setType] = useState(TYPE[0].id);

    // heading handler
    function _handleHeading(input) {
        setHeading(input);
        setHeadingError("");
    }


    // content handler
    function _handleContent(input) {
        setContent(input);
        setContentError(null);
    }

    // type handler
    function _handleType(value) {
        setType(value);
    }

    // content handler
    function _handleFile(path) {
        var file_formatted = path.replace(/^.*[\\\/]/, '').split('/');
        setFile(file_formatted[0]);
        setFileError("");
    }

    // content handler
    function _handleCheckBox(value) {
        let selected_users = [...selectedUsers];

        const index = selected_users.findIndex((item) => item === value);

        if (value !== null) {
            if (index === -1) {
                selected_users = selected_users.filter((item) => item !== value); //filtering with unique values
                selected_users.push(value); //push if value does not exist in array
                if (selected_users.length === OPTION_TYPES.length - 1) {
                    selected_users.push(null) // to push all if all items are pushed in array
                }
            } else {
                selected_users.splice(index, 1); //remove if value is present
                selected_users = selected_users.filter(item => item !== null); //remove null "ALL" if any value is deselected
            }

        } else {
            // filter for selecting all 

            if (selectedUsers.length < OPTION_TYPES.length) {

                OPTION_TYPES.forEach((element) => {
                    selected_users = selected_users.filter((item) => item !== element.value); //push all unqiue values avoid repetition
                    selected_users.push(element.value);
                });

            } else {
                selected_users = []
            }
        }

        setSelectedUsers(selected_users);
    }


    // validation for form
    const _validateForm = () => {
        var valid_form = true;
        if (heading.length === 0) {
            valid_form = false;
            setHeadingError('Heading is required');
        }
        if (content.length === 0) {
            valid_form = false;
            setContentError('Content is required');
        }
        if (file === null) {
            valid_form = false;
            setFileError("File is require");
        }
        //eslint-disable-next-line
        if (valid_form = true) {
            _clearFields()
        }
    }

    // clear form
    const _clearFields = () => {
        setHeading("");
        setContent("");
        setFile(null);
        setSelectedUsers([]);
    }



    return (
        <Fragment>


            <Fragment>
                {/* checkboxes to send notification */}
                <div className="row padding-28px-top">
                    <div className="row">
                        <div className="col-lg-9 d-flex flex-wrap padding-32px-bottom">
                            {
                                OPTION_TYPES.map((item, key) => {
                                    return (
                                        <div key={key}
                                            className={`border-radius-8px border-all padding-12px-all ${selectedUsers.includes(item.value) ? 'border-primary-color' : 'border-bright-gray'} w-auto cursor-pointer mt-2 me-3`}
                                            onClick={() => {
                                                _handleCheckBox(item.value, 5)
                                            }}>
                                            <Icon className="me-2"
                                                icon={selectedUsers.includes(item.value) ? 'checked' : 'unchecked'}
                                                size={22} />
                                            <span className={selectedUsers.includes(item.value) ? "color-primary-color e-poppins-medium e-font-16" : "color-black e-poppins-medium e-font-16"}>
                                                {item.label}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <SectionTitle title="Add notification content" />

                {/* notification form */}
                <div className="row">
                    <div className="col-lg-5 pt-4">
                        <CustomTextInput label="Heading"
                            value={heading}
                            postfix="*"
                            type="text"
                            hide_error_text={true}
                            error={headingError}
                            valueChange={(input_value) => {
                                _handleHeading(input_value, 1)
                            }} />
                    </div>


                </div>
                <div className="row">
                    <div className="col-lg-5 pt-3 ">
                        <CustomTextArea label="Content"
                            value={content}
                            postfix="*"
                            type="text"
                            hide_error_text={true}
                            error={contentError}
                            valueChange={(input_value) => {
                                _handleContent(input_value, 2)
                            }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 pt-3 ">
                        <FileUpload id="e-upload"
                            error={fileError}
                            icon={require('../../../Assets/Images/Notifications/gallery.svg').default}
                            accept=".png, .jpg , .jpeg "
                            hide_error_text={true}
                            title="Select an image"
                            content="Supports only jpeg , jpg & png"
                            onSelectFile={(file) => {
                                _handleFile(file, 3)
                            }} />
                        <div className="mt-2">
                            {
                                file ?
                                    <FilePreview file={file}
                                        clearFile={() => {
                                            setFile(null);
                                        }} />

                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 padding-24px-top">
                        <label className="e-poppins-medium e-font-16 line-height-16px color-light-black mb-2">Select type</label>
                        <RadioGroup data={TYPE}
                            type={1}
                            id={type}
                            wrapperClass='w-auto'
                            radioHandler={(id) => {
                                _handleType(id, 4)
                            }} />
                    </div>
                </div>

                <div className="row margin-40px-top">
                    <div className="col-3">
                        <SecondaryButton label="Cancel"
                            className="padding-12px-tb padding-24px-lr"
                            onPress={_clearFields} />
                        <GradientButton name="Send now"
                            className="padding-12px-tb padding-24px-lr ms-3"
                            onPress={_validateForm} />

                    </div>

                </div>
            </Fragment>
        </Fragment>
    )
})

export default CreateNotification;