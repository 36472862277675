/*
 *   File : manage-content.js
 *   Author URI : https://evoqins.com
 *   Description : Page for manage content
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


/* import container */
import Privacy from "./privacy";
import Terms from "./terms";
import Disclaimers from "./disclaimers";
import InfoList from "./info-list";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { CustomTab } from "../../../Components/Tab";
import { SectionTitle } from "../../../Components/Title";
import { GradientButton } from "../../../Components/Cta";
import { QuizAccordion } from "../../../Components/Accordion";

import { DeleteConfirmationModal, ManageFAQ } from "../../../Components/Modal";
import { CustomLoader } from "../../../Components/Other";

/* import services */
import APIService from "../../../Services/api-service";


const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage content"
];

const CONTENT_TABS = [
    {
        label: `FAQ`,
        type: 'faqs',
        id: 2
    },
    {
        label: `Terms & conditions`,
        type: 'terms_and_conditions',
        id: 3
    },
    // {
    //     label: `Privacy policy`,
    //     type: 'policies',
    //     id: 4
    // },
    // {
    //     label: `Disclaimers`,
    //     type: 'disclaimers',
    //     id: 5
    // },
];

const SubAdminManageContent = React.memo(() => {
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [tabIndex, setTabIndex] = useState(CONTENT_TABS[0].id);
    const [faqItem, setFaqItem] = useState(null);
    const [faqDetail, setFaqDetail] = useState(null);
    const [pageLoader, setPageLoader] = useState(true);
    //eslint-disable-next-line
    const [infoList, setInfoList] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [terms, setTerms] = useState(null);
    const [disclaimers, setDisclaimers] = useState(null);
    const [policies, setPolicies] = useState(null);
    const [tabData, setTabData] = useState([]);
    const [openFaq, setOpenFaq] = useState(false);
    const [permissionType, setPermissionType] = useState('');

    useEffect(() => {
        _getContents();
    }, []);

    useEffect(() => {
        if (openFaq === true) {
            _openFaqModal();
        }
    }, [openFaq]);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    useEffect(() => {
        if (faqItem !== null) {
            _openConfirmation();
        }
    }, [faqItem]);

    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
    }

    function _handleFaq() {
        setOpenFaq(true);
    }

    // open delete confirmation
    function _openConfirmation() {
        const modal = new Modal(document.getElementById("delete"));
        modal.show()
    }

    // open faq modal
    function _openFaqModal() {
        const modal = new Modal(document.getElementById("manage-faq"));
        modal.show()
    }

    // Remove faq 
    function _removeFAQ(faq) {
        setFaqItem(faq)
    }

    // Update faq
    function _updateFAQ(faq) {
        setOpenFaq(true)
        setFaqDetail(faq);
    }
    // API - list content 
    const _getContents = () => {
        const url = "content/list";
        APIService(false, url).then((response) => {

            if (response.status_code === 200) {
                if (response.data.faqs !== null) {
                    setFaqs(response.data.faqs);
                } else {
                    setFaqs([]);
                }
                if (response.data.terms_and_conditions !== null) {
                    setTerms(response.data.terms_and_conditions);
                }

                if (response.data.policies !== null) {
                    setPolicies(response.data.policies);
                }
                if (response.data.disclaimers !== null) {
                    setDisclaimers(response.data.disclaimers);
                }
            } else {
                setFaqs([]);
                setTerms(null);
                setPolicies(null);
                setDisclaimers(null);
            }

            let content_tabs = [];
            const response_tabs = Object.keys(response.data);
            const common_array = CONTENT_TABS.filter(tab => response_tabs.includes(tab.type));
            common_array.map((item) => {
                return (
                    content_tabs.push({
                        id: item.id,
                        type: item.label
                    })
                )
            })
            setTabData(content_tabs);
            setPageLoader(false);
        })
    }

    return (
        <Fragment>

            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage content" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                {
                    pageLoader === true ?
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                        : <Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <CustomTab type={2}
                                        index={tabIndex}
                                        data={tabData}
                                        onSelectTab={_handleTabIndex} />
                                </div>
                            </div>

                            {
                                tabIndex === 1 ?

                                    <InfoList data={infoList} onUpdateInfo={_getContents}
                                        permissionType={permissionType} />
                                    :
                                    tabIndex === 2 ?
                                        <div className="min-height-100vh">
                                            <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                <div className="col-12 d-flex justify-content-between mb-3">
                                                    <SectionTitle title="Added questions" />
                                                    {permissionType !== 'read' && <GradientButton label="Add new question"
                                                        onPress={_handleFaq} />}
                                                </div>

                                                {faqs.length !== 0 ? <div className="col-12 padding-34px-top">
                                                    <QuizAccordion data={faqs}
                                                        type={1}
                                                        removeData={_removeFAQ}
                                                        updateFaq={_updateFAQ}
                                                        permissionType={permissionType} />
                                                </div>
                                                    :
                                                    <span className="color-black e-font-14 e-poppins-regular me-2 mt-5 ">No Data Found </span>}
                                            </div>
                                        </div>
                                        :
                                        tabIndex === 3 ?
                                            <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <SectionTitle title="Terms and conditions" />
                                                    {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                </div>
                                                <Terms data={terms} termsAdded={_getContents}
                                                    permissionType={permissionType} />
                                            </div>
                                            :
                                            tabIndex === 4 ?
                                                <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                    <div className="col-12 d-flex justify-content-between">
                                                        <SectionTitle title="Privacy policy" />
                                                        {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                    </div>
                                                    <Privacy data={policies} policiesAdded={_getContents}
                                                        permissionType={permissionType} />
                                                </div>
                                                :
                                                <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                    <div className="col-12 d-flex justify-content-between">
                                                        <SectionTitle title="Disclaimers" />
                                                        {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                    </div>
                                                    <Disclaimers data={disclaimers} disclaimerAdded={_getContents}
                                                        permissionType={permissionType} />
                                                </div>
                            }
                        </Fragment>}


            </div>
            {
                faqItem !== null ?
                    <DeleteConfirmationModal content={`Are you sure you want to delete this FAQ ?`}
                        data={faqItem}
                        close={() => {
                            setFaqItem(null);
                        }}
                        confirmDelete={() => {
                            setFaqItem(null);
                            _getContents()
                        }} />
                    :
                    null
            }
            {
                openFaq === true ?
                    <ManageFAQ
                        close={() => {
                            setOpenFaq(false);
                            setFaqDetail(null);
                        }}
                        data={faqDetail}
                        faqAdded={() => {
                            _getContents();
                            setOpenFaq(false);
                        }} />
                    :
                    null
            }
        </Fragment>
    )
})

export default SubAdminManageContent;