/*
 *   File : profile-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useState } from "react";


/* import components */
import { UserInfo } from "../../../../Components/Cards";

const CustomerProfile = React.memo((props) => {


    return (
        <Fragment>
            {Object.keys(props.customer_info).length > 0 && <div className="row padding-24px-top">
                <div className="col-12">
                    <UserInfo
                        basicInfo={props.customer_info.profile}
                        type={1} />
                </div>
                <div className="col-12 pt-3">
                    <UserInfo
                        bankInfo={props.customer_info.profile.banks}
                        type={2} />
                </div>
                <div className="col-12 pt-3">
                    <UserInfo
                        nomineeInfo={props.customer_info.nominees}
                        type={3} />
                </div>
            </div>}
        </Fragment>
    )
})

export default CustomerProfile;