/*
 *   File : user-info.js
 *   Author URI : https://evoqins.com
 *   Description : User Information card
 *   Integrations : null
 *   Version : v1.1
 */

/*import packages*/
import { Fragment, React } from "react";

/* import components */
import Icon from "../Icon/icon";


const UserInfo = (props) => {
    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className="border-all border-bright-gray padding-24px-all border-radius-16px">
                        <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black ">
                            Personal information

                        </h5>
                        <div className="row padding-24px-top">
                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    Name
                                </p>
                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                    {props.basicInfo.name}
                                </h6>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    PAN number
                                </p>
                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                    {props.basicInfo.pan}
                                </h6>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    Date of birth
                                </p>
                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                    {props.basicInfo.dob}
                                </h6>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    Gender
                                </p>
                                <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                    {props.basicInfo.gender}
                                    {
                                        props.basicInfo.gender_mismatch === true &&
                                        <span className="e-tool-tip-container position-relative"
                                            data-tooltip-content="Gender is not same as the value received from Digilocker">
                                            ⚠️
                                        </span>
                                    }
                                </h6>
                            </div>
                        </div>
                        <div className="row padding-24px-top">
                            <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-3 pb-4">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    Marital status
                                </p>
                                <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                    {props.basicInfo.marital_status}
                                </h6>
                            </div>
                            <div className="col-md-6 col-12">
                                <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                    Address
                                </p>
                                <h6 className="e-poppins-medium e-font-14 line-height-18px color-light-black mb-0 mt-2">
                                    {props.basicInfo.address}
                                </h6>
                            </div>
                        </div>
                    </div>
                    :
                    props.type === 2 ?
                        <div className="border-all border-bright-gray padding-24px-all border-radius-16px">
                            <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                Bank details
                            </h5>
                            <div className="row padding-24px-top">
                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                        Bank
                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                        Account No.
                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                        Account type
                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                        IFSC
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                        Branch
                                    </p>
                                </div>
                                {
                                    props.bankInfo.map((item, key) =>
                                        <div className={`col-12   ${key === 0 ? "mt-2" : "mt-3"}`} key={key}>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                        {item.bank_name}
                                                    </h6>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                        {item.masked_account_number}
                                                    </h6>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                        {item.account_type_label}
                                                    </h6>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                        {item.ifsc_code}
                                                    </h6>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-6 pb-lg-0 pb-md-4 pb-4">
                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                        {item.branch_name}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        :
                        <div className="border-all border-bright-gray padding-24px-all border-radius-16px">
                            {
                                Object.keys(props.nomineeInfo).length === 0 ?
                                    <Fragment>
                                        <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                            Nominees
                                        </h5>
                                        <p className="e-poppins-regular e-font-14 line-height-14px mb-0 mt-3">No nominee added</p>
                                    </Fragment>
                                    : props.nomineeInfo.skip_nominee === true ?
                                        <Fragment>
                                            <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                                Nominees
                                            </h5>
                                            <p className="e-poppins-regular e-font-14 line-height-14px mb-0 mt-3">The customer has opted out of nominee</p>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <h5 className="mb-0 e-poppins-medium e-font-16 line-height-16px color-light-black">
                                                Nominees ({props.nomineeInfo.total_nominees})
                                            </h5>

                                            <div className="row padding-24px-top">
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Name
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Relation
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Date of Birth
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Allocation%
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian name
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian relationship
                                                    </p>
                                                </div>
                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-light-black my-0">
                                                        Guardian pan
                                                    </p>
                                                </div>
                                                {
                                                    props.nomineeInfo.nominees.map((item, key) =>
                                                        <div className={`col-12   ${key === 0 ? "mt-2" : "mt-3"}`} key={key}>
                                                            <div className="row">
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.name}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.relationship}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14 line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.date_of_birth_str}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.allocation}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.guardian_name}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.guardian_relationship}
                                                                    </h6>
                                                                </div>
                                                                <div className="col pb-lg-0 pb-md-4 pb-4">
                                                                    <h6 className="e-poppins-medium e-font-14  line-height-14px color-light-black mb-0 mt-2">
                                                                        {item.guardian_pan}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </Fragment>
                            }
                        </div>
            }
        </Fragment>
    )
}

UserInfo.defaultProps = {
    basicInfo: {
        first_name: "",
        last_name: "",
        pan_number: "",
        dob: "",
        gender: "",
        marital_status: "",
        address: "",

    },
    bankInfo: {
        bank: "",
        account_no: "",
        account_type: "",
        ifsc: "",
        branch: ""
    },
    nomineeInfo: []
}

export default UserInfo;