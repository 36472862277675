/*
 *   File : aprrove-kyc.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for approving kyc.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

/* import components */
import { GradientButton } from "../Cta";
import { Icon } from "../Icon";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";

const ApproveKYMCodal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        var my_modal = document.getElementById("kyc-success");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    const _handleDelete = () => {
        closeModal('kyc-success');
        navigate("/manage-onboarding");
    }

    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="kyc-success"
            tabIndex="-1"
            aria-labelledby="kyc-success"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <img draggable={false} src={require("../../Assets/Images/Modal/kyc-success.png")}
                        alt="Kyc success"
                        width={72}
                        height={72}
                    />


                    <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        KYC documents added successfully
                    </h6>
                    <p className="color-outer-space e-poppins-regular e-font-14 mt-2 mb-0 px-5">
                        The KYC application has been submitted to CVL KRA.
                    </p>

                    <div className="d-flex  justify-content-center margin-32px-top margin-32px-bottom w-100">

                        <GradientButton label="Back"
                            className="padding-12px-tb e-font-16 "
                            cancel="modal"
                            onPress={_handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    )
}


ApproveKYMCodal.defaultProps = {
    close: () => { }
}

export default ApproveKYMCodal