/*
 *   File : sub-admin-details.js
 *   Author URI : www.evoqins.com
 *   Description : Sub admin details
 *   Integrations : null
 *   Version : v1.0
 *   Created : 27-12-2023
 */

import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { NavigationHeader } from "../../../Components/Header";
import { SectionTitle } from "../../../Components/Title";
import { AdminDetailSummary } from "../../../Components/Cards";
import { Icon } from "../../../Components/Icon";
import { ManagePermissionModal } from "../../../Components/Modal";
import { CustomLoader } from "../../../Components/Other";
import { BreadCrumb } from "../../../Components/Breadcrumb";

import APIService from "../../../Services/api-service";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage sub admin",
    'Sub admin details'
];

const SubAdminDetail = () => {

    const location = useLocation();
    const navigator = useNavigate();

    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    const [detailData, setDetailData] = useState({});
    const [adminId, setAdminId] = useState('');

    useEffect(() => {
        if (location.state !== null) {
            let id = location.state.detail_id;
            _getDetail(id);
            setAdminId(id);
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        //eslint-disable-next-line
    }, []);

    // open block admin modal
    useEffect(() => {
        if (showPermissionModal === true) {
            const modal = new Modal(document.getElementById("manage-permission"));
            modal.show();
        }
    }, [showPermissionModal]);

    const _handlePermissionModal = () => {
        setShowPermissionModal(true);
    }

    // Update permission data after modal success
    function _updatePermissionList() {
        _getDetail(adminId);
        setShowPermissionModal(false);
    }


    // API - API to get selected admin details
    function _getDetail(admin_id) {
        let url = 'auth/admin-details';
        let payload = JSON.stringify({
            admin_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setDetailData(response.data);
                setApiLoader(false);
            } else {
                toast.dismiss();
                toast.error("Unable to find admin details", {
                    type: 'error'
                });
                navigator('/sub-admins');
            }
        });
    }

    return (
        <Fragment>
            <NavigationHeader
                title="Sub admin details" />
            {
                apiLoader === true ? (
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                        <div className="row h-100">
                            <CustomLoader />
                        </div>
                    </div>
                ) : (
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                        <BreadCrumb data={PAGE_NAVIGATION} />
                        <div className="row mt-3">
                            {/* summary card */}
                            <div className="col-12">
                                <AdminDetailSummary data={detailData} />
                            </div>

                            <div className="col-12 mt-3">
                                <div className="row border-all border-bright-gray border-radius-16px p-3">
                                    <div className="col-md-6">
                                        <SectionTitle title="Allowed permissions "
                                            className="line-height-16px" />
                                    </div>
                                    {detailData.permission_details.length === 0 && <div className="col-md-6 d-flex justify-content-end cursor-pointer" onClick={_handlePermissionModal}>
                                        <span className="color-primary-color e-font-16 e-poppins-medium line-height-16px pt-1 pe-1">
                                            Add permission
                                        </span>
                                        <Icon icon='Pen-square'
                                            size={24} />
                                    </div>}
                                    {/* permissions */}
                                    {
                                        detailData.permission_details.length !== 0 ? (
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="row padding-28px-top">
                                                        <div className="col-md-8">
                                                            <h6 className="color-light-black e-font-14 e-poppins-medium line-height-14px mb-2">
                                                                Role
                                                            </h6>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <h6 className="color-light-black e-font-14 e-poppins-medium line-height-14px mb-2">
                                                                Action
                                                            </h6>
                                                        </div>
                                                        <>
                                                            {
                                                                detailData.permission_details.map((item, key) => {
                                                                    return (
                                                                        <>
                                                                            <div className="col-md-8" key={key}>
                                                                                <p className="e-font-14 e-poppins-regular line-height-20px color-light-black mb-3">
                                                                                    {item.module}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-4" key={key}>
                                                                                <p className="e-font-14 e-poppins-regular line-height-20px color-light-black mb-3">
                                                                                    {item.action}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 d-flex justify-content-end cursor-pointer" onClick={_handlePermissionModal}>
                                                    <span className="color-primary-color e-font-16 e-poppins-medium line-height-16px pt-1 pe-1">
                                                        Edit permission
                                                    </span>
                                                    <Icon icon='Pen-square'
                                                        size={24} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row my-4">
                                                <div className="col-12 text-center">
                                                    <p className="e-font-14 e-poppins-regular line-height-14px color-gray">
                                                        No permissions allowed
                                                    </p>
                                                </div>
                                            </div>

                                        )
                                    }
                                </div>
                            </div>
                            {/* Commented RM section because data not getting in API */}
                            {/* <div className="col-12 padding-32px-top">
                                <SectionTitle title="RM & branch managing" />
                                <DataTableContainer columns={SUMMARY_COLUMNS}
                                    data={SUMMARY_DATA}
                                    pagination={false}
                                    customStyles={TABLE_STYLE}
                                />
                            </div> */}
                        </div>
                    </div>
                )
            }

            {
                showPermissionModal === true ?
                    <ManagePermissionModal data={detailData.permission_details}
                        adminId={adminId}
                        successCallBack={_updatePermissionList}
                        close={() => {
                            setShowPermissionModal(false);
                        }} />
                    :
                    null
            }
        </Fragment>
    );
};

export default SubAdminDetail;
