/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : SIP listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useSelector } from "react-redux";


/* import components */
import { Chip, CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { DownloadButton } from "../../../../Components/Cta";
import { CustomDateRangePicker, DataTableContainer, SearchInput } from "../../../../Components/FormElements";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import { UnlockSIPModal } from "../../../../Components/Modal";
import { Icon } from "../../../../Components/Icon";

/* import styles */
import Colors from '../../../../Styles/color.module.scss';

/* import services */
import APIService from "../../../../Services/api-service";
import { _sortDate, useClickOutside } from "../../../../Helper/helper-function";

const CHIP_DATA = [
    {
        label: 'All',
        value: null
    },
    {
        label: 'Mutual funds',
        value: false
    },
    {
        label: 'Life goals',
        value: true
    },
];

const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "18px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "start !important",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            justifyContent: "start !important",
            color: Colors.black,
            '&:first-child': {
                paddingLeft: "0px",
            },
            '&:nth-child(2)': {
                paddingLeft: "14px !important",
                paddingRight: "0px !important",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }


}

const ActiveSIPList = (props) => {
    const popupRef = useRef(null);
    const navigator = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [chipFilter, setChipFilter] = useState(CHIP_DATA[0].value);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLoader, setPageLoader] = useState(true);
    const [activeSips, setActiveSips] = useState([]);

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [totalLength, setTotalLength] = useState(null);
    const [permissionType, setPermissionType] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedSips, setSelectedSips] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);

    useEffect(() => {
        _getActiveSIPs();
        console.log('end dataaa', endDate);
    }, [pageNumber, startDate, chipFilter, pageSize]);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    useEffect(() => {
        if (selectAll === true) {
            const sip_id_set = new Set(activeSips.map((item) => item.sip_id));
            setSelectedSips((prevSet) => new Set([...prevSet, ...sip_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedSips(new Set());
            }
        }
    }, [selectAll, activeSips])

    useEffect(() => {
        if (unlockModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [unlockModal])

    useClickOutside(popupRef, () => {
        setShowDateFilter(false);
        const body_element = document.getElementById('e-table-body');
        body_element.removeAttribute('style');
    });


    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedSips)
        if (selectedSips.has(row.sip_id)) {
            setSelectedSips((prevSipIds) => {
                const new_sip_ids = new Set(prevSipIds);
                new_sip_ids.delete(row.sip_id);
                return new_sip_ids;
            });
        }
        else {
            setSelectedSips((prevSipIds) => new Set([...prevSipIds, row.sip_id]));
        }
        setRowSelectFalse(true);
    }


    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const ACTIVE_COLUMNS = [
        {
            name: <div className={`position-relative w-fit-content ${permissionType !== 'read' && 'padding-40px-left'}`}>
                {/* Add your custom image or icon here */}
                {
                    permissionType !== 'read' && (
                        <Icon icon={selectAll === true ? "checked" : "unchecked"}
                            size={24}
                            className="position-absolute left-10"
                            onClick={(event) => _handleSelectAll(event)} />
                    )
                }

                <p className="mb-0">MT.ID</p>
            </div>,
            selector: row => row['mt_id'],
            sortable: true,
            left: true,
            width: "200px",
            // maxWidth: "300px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    {
                        permissionType !== 'read' && (
                            <Icon icon={selectedSips.has(row['sip_id']) ? "checked" : "unchecked"}
                                size={24}
                                onClick={(event) => _handleRowCheckBox(row, event)} />
                        )
                    }

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['mt_id']}</p>
                </div>
        },
        // {
        //     name: 'Cus.ID',
        //     selector: row => row['customer_id'],
        //     sortable: true,
        //     left: true,
        //     // width: "576px",
        //     minWidth: "120px",
        //     cell: row =>
        //         <div className="d-flex align-items-center gap-12px e-fund-name ps-3" onClick={() => _handleCardClick(row)}>
        //             <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['customer_id']}</p>
        //         </div>
        // },
        {
            name: 'Name',
            selector: row => row['customer_name'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" onClick={() => _handleCardClick(row)}> {row['customer_name']}</span>,
        },
        {
            name: 'Invested in',
            selector: row => <> {row['name']}</>,
            sortable: false,
            left: true,
            minWidth: "250px",
            cell: row => <div className="d-block">
                <p className="truncate-first-line my-0" onClick={() => _handleCardClick(row)}>
                    <img draggable={false} src={row['image']}
                        alt={row.image} width='40px'
                        height='40px'
                        className="me-2 object-fit-contain border-all border-bright-gray border-radius-8px" />
                    {row['name']}
                </p>
            </div>
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: false,
            left: true,
            minWidth: "200px",
            cell: row => <span className="color-outer-space truncate-first-line" onClick={() => _handleCardClick(row)}> {row['email'] ? row['email'] : '-'}</span>,
        },
        {
            name: 'Phone',
            selector: row => row['phone'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span className="color-outer-space" onClick={() => _handleCardClick(row)}> {row['phone'] ? row['phone'] : '-'}</span>,
        },
        {
            name: 'Created on',
            selector: row => row['created_date'],
            sortable: false,
            center: true,
            minWidth: "190px",
            sortFunction: (a, b) => _sortDate(a.created_date, b.created_date),
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['created_date']}
                </p>
        },
        {
            name: 'Next SIP on',
            selector: row => row['next_sip_date'],
            sortable: false,
            center: true,
            minWidth: "180px",
            sortFunction: (a, b) => _sortDate(a.next_sip_date, b.next_sip_date),
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['next_sip_date']}
                </p>
        },

        {
            name: 'SIP Amount',
            selector: row => row['amount'],
            sortable: true,
            left: true,
            minWidth: "150px",
            style: {
                display: "flex",
                justifyContent: "center !important"
            },
            cell: row => <span className="color-black " onClick={() => _handleCardClick(row)}>
                <span className="e-inter-regular">₹</span>
                {row['amount'].toLocaleString('en-IN')}</span>,
        },

        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            center: true,
            minWidth: "150px",

            cell: row =>
                <div >
                    <p className="color-sea-green e-poppins-medium e-font-16 line-height-24px mb-0 mt-0 ps-3" onClick={() => _handleCardClick(row)}>
                        {row['status']}
                    </p>
                </div>
        },
    ];

    // handler for opening date picker
    function _handleShowDateFilter() {
        window.scrollTo({
            top: 100,
            behavior: 'smooth'
        })
        setShowDateFilter(!showDateFilter);
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.style.pointerEvents = 'none'
        }

    }

    // clear dates on close
    function _handleClearDates() {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.removeAttribute('style');
        }
        setPageNumber(1);
    }

    function _handleChipFilter(selected_status) {
        if (selected_status !== chipFilter) {
            setChipFilter(selected_status);
            setPageNumber(1);
        }
    };

    // navigation 
    function _handleCardClick(row) {
        if (row.goal_id !== null) {
            navigator("/sip-manage/sip-goal-summary", {
                state: {
                    sip_id: row.sip_id,
                    customer_id: row.customer_id,
                    sip_status: 1
                }
            })
        } else {
            navigator("/sip-manage/sip-summary", {
                state: {
                    sip_id: row.sip_id,
                    customer_id: row.customer_id,
                    sip_status: 1
                }
            })
        }
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // search handler
    function _handleSearchQuery(value) {
        setPageNumber(1);
        setSearchQuery(value);
        if (value === '') {
            setPageNumber(1);
            _getActiveSIPs('');
        }
    }

    // formating date
    function _handleDateRangeChange(newRange) {
        setPageNumber(1);
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        console.log('Ed data', newRange.endDate);
        setSelectedRange(newRange);
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.removeAttribute('style');
        }
        setPageNumber(1);
    };

    // API - active list sip
    const _getActiveSIPs = (search_query = searchQuery) => {

        setPageLoader(true);

        const url = "sip/list";

        const request = {
            query: search_query,
            is_goal: chipFilter,
            start_date: startDate === 'Start date' ? null : startDate,
            end_date: endDate === 'End date' ? null : endDate,
            page_num: pageNumber,
            page_size: pageSize
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setActiveSips(response.data.data.fund_data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
            } else {
                setActiveSips([]);
            }
            setPageLoader(false);
        })
    }


    // API - confirm sip download
    const _confirmSipDownload = () => {
        setDownloadLoader(true);
        const sip_id = [...selectedSips]
        const url = "sip/confirm-download";

        const data = {
            "sip_ids":  selectAll === true ? [] : sip_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setUnlockModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }


    // API -download csv
    const _downloadSips = (pin) => {
        setPageLoader(true);
        const sip_id = [...selectedSips];
        const url = "sip/download";

        const data = {
            "sip_ids": selectAll === true ? [] : sip_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setPageLoader(false);
            setUnlockModal(false);
            setSelectedSips(new Set());
            setSelectAll(false);
        })
    }


    return (
        <Fragment>

            <div className="row">
                <Fragment>
                    <div className="row margin-32px-top margin-12px-bottom d-flex">
                        <div className="col-lg-4  ">
                            <div className="d-flex gap-8px flex-wrap">
                                {
                                    CHIP_DATA.map((tag, index) => {
                                        return (
                                            <Chip data={tag.label}
                                                key={index}
                                                type={2}
                                                chipIndex={tag.value}
                                                activeChipIndex={chipFilter}
                                                onPress={_handleChipFilter} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-8 position-relative pe-0">
                            <div className="row">
                                <div className="col-lg-7 offset-lg-1 d-flex gap-8px pe-2">
                                    <SearchInput placeholder="Search with id, mobile , email & PAN "
                                        value={searchQuery}
                                        valueChange={_handleSearchQuery}
                                        handleSearch={() => {
                                            setPageNumber(1);
                                            _getActiveSIPs(searchQuery);
                                        }} />
                                    {permissionType !== 'read' && <DownloadButton onPress={_confirmSipDownload}
                                        // disabled={selectedSips.length !== 0 ? false : true}
                                        loader={downloadLoader} />}
                                </div>
                                <div className="col-lg-4 d-flex gap-8px  px-0">
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        popupRef={popupRef}
                                        className='w-100'
                                        showDateFilter={showDateFilter}
                                        handleShowDateFilter={_handleShowDateFilter}
                                        selectedRange={selectedRange}
                                        handleDateRangeChange={_handleDateRangeChange}
                                        clearDates={_handleClearDates} />
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-12">
                        <p className="color-outer-space e-poppins-regular e-font-12 mb-0 text-end">
                            <img draggable={false} src={require('../../../../Assets/Images/Onboarding/scroll-gif.gif')} alt="scroll"
                                width='24px'
                                height='24px'
                                className='e-rotate-gif' />
                            Scroll horizontally for view more  information</p>
                    </div>
                </Fragment>

                <div className="col-12" >
                    {
                        pageLoader === true ?
                            <div className="h-80vh">
                                <CustomLoader />
                            </div>
                            :
                            activeSips.length !== 0 ?
                                (<div className="min-height-50vh">
                                    <div id="e-table-body" className="position-relative">
                                        <DataTableContainer columns={ACTIVE_COLUMNS}
                                            data={activeSips}
                                            selectableRows={false}
                                            pagination={true}
                                            customStyles={TABLE_STYLE}
                                            defaultSortFieldId={1}
                                            paginationComponentOptions={paginationData}
                                            onChangeRowsPerPage={(e) => {
                                                setPageSize(e);
                                                setPageNumber(1);
                                            }}
                                            paginationTotalRows={totalLength}
                                            paginationCurrentPage={pageNumber}
                                            paginationPerPage={pageSize}
                                            paginationIconNext={null}
                                            paginationIconPrevious={null}
                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                        <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                            <Icon icon="pagination-previous" width="24px" height="24px"
                                                className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                onClick={_previousPage} />
                                            <Icon icon="pagination-next" width="24px" height="24px"
                                                className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                onClick={_nextPage} />
                                        </div>
                                    </div>

                                </div>)
                                :
                                <EmptyScreen title="Empty active sip data!" />

                    }

                </div>





            </div>

            {
                unlockModal === true ?
                    <UnlockSIPModal
                        handleOTPSubmission={_downloadSips}
                        close={() => {
                            setUnlockModal(false);
                        }} />
                    :
                    null
            }

        </Fragment>
    )

}

export default ActiveSIPList;