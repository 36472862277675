/*
 *   File : login-logs.js
 *   Author URI : https://evoqins.com
 *   Description : Page to list login and logout which the sub-admin did in MT Wealth 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react"
import { format } from "date-fns";
import { toast } from "react-toastify";

import { NavigationHeader } from "../../../Components/Header"
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { CustomDateRangePicker, DataTableContainer, SearchInput } from "../../../Components/FormElements";
import { ExploreFilterPopup } from "../../../Components/Popups";
import { useClickOutside } from "../../../Helper/helper-function";
import APIService from "../../../Services/api-service";

import Colors from "../../../Styles/color.module.scss"

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: Colors.alice_blue,
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-regular",
            lineHeight: "24px",
            color: Colors.jet_black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const LoginLogs = () => {

    const popupRef = useRef(null);
    const filterRef = useRef(null);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [role, setRole] = useState([])
    const [loginData, setLoginData] = useState([])
    const [loader, setLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(true);
    const [initialLoader, setInitialLoader] = useState(true);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(() => {
        _getFilterData();
    }, []);

    useEffect(() => {
        if (initialLoader === false) {
            _getUserList();
        }
    }, [role, startDate, endDate])

    useEffect(() => {
        if (showFilter === true) {
            document.body.style.overflow = 'hidden';
            window.scrollTo({
                behavior: "smooth",
                top: 20
            });

        } else {
            document.body.removeAttribute("style");
        }
    }, [showFilter]);

    useEffect(() => {
        if (showFilter === true) {
            setShowDateFilter(false);
        }
        else {
            setShowFilter(false);
        }
    }, [showDateFilter, showFilter]);

    useEffect(() => {
        let count = 0;
        if (role.length !== 0 && !role.includes(null)) {
            count++;
        }
        setFilterCount(count);

    }, [role]);

    useClickOutside(filterRef, () => {
        setShowFilter(false);
    });

    const USER_COLUMNS = [

        {
            name: 'Sub-admin ID',
            selector: row => row['admin_id'],
            sortable: false,
            left: true,
            // width: "576px",
            width: "180px",
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['admin_id']}</p>
        },
        {
            name: 'Sub-admin name',
            selector: row => row['admin_name'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['admin_name']}</p>
        },

        {
            name: 'Admin user type',
            selector: row => row['admin_type'],
            sortable: false,
            left: true,
            width: "180px",
            cell: row => <p className="color-black e-poppins-regular e-font-16 mb-0">{row['admin_type']}</p>
        },
        {
            name: 'Activity',
            selector: row => row['activity'],
            sortable: false,
            left: true,
            cell: row => <p className="color-outer-space e-poppins-regular e-font-16 mb-0">{row['activity']}</p>
        },

    ];

    const _handleShowDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    }

    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setSelectedRange(newRange);
    };

    const _handleClearDates = () => {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
    }

    const _handleSearchQuery = (value) => {
        setSearchQuery(value)
    }

    const _handleToggleFilter = () => {
        setShowFilter(!showFilter);
    }

    const _handleApplyFilter = (role) => {
        setShowFilter(false);
        setRole(role)

    }

    const _handleCardClick = (row) => {

    }

    // API - search data
    function _getUserList() {
        setApiLoader(true);
        let url = 'login-logs/get';
        let payload = JSON.stringify({
            admin_id: searchQuery.length === 0 ? null : searchQuery,
            filter_ids: role[0] === null ? null : role,
            start_date: startDate === "Start date" ? null : startDate,
            end_date: endDate === "End date" ? null : endDate
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setLoginData(response.data);
            }
            else {
                setLoginData([]);
            }
            setApiLoader(false);
        })
    }

    // API - Get filter data 
    function _getFilterData() {
        let url = 'auth/list-admin-types';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const roles_with_id_label = [
                    { id: null, label: 'All' },
                    ...response.data.map(role => ({
                        id: role.id,
                        label: role.name
                    }))
                ];
                setRole([null]);
                setInitialLoader(false);
                setFilterData(roles_with_id_label);
            }
            setLoader(false)
        })
    }

    return (
        <Fragment>
            <NavigationHeader type={1}
                title="Admin logs" />
            {
                apiLoader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top min-height-100vh">
                        <div className="row">
                            <div className="offset-6 col-6 d-flex gap-16px justify-content-end">
                                {/* <SearchInput placeholder="Search with admin id"
                            acceptNumber={true}
                            value={searchQuery}
                            divClass="w-unset"
                            valueChange={_handleSearchQuery}
                            handleSearch={_getUserList} /> */}
                                <div className="position-relative">
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        popupRef={popupRef}
                                        showDateFilter={showDateFilter}
                                        handleShowDateFilter={_handleShowDateFilter}
                                        selectedRange={selectedRange}
                                        handleDateRangeChange={_handleDateRangeChange}
                                        clearDates={_handleClearDates} />
                                </div>

                                <div className="position-relative" >
                                    {
                                        filterCount > 0 &&
                                        <span className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-filter-badge position-absolute bg-sea-green d-flex justify-content-center align-items-center">{filterCount}</span>
                                    }
                                    <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10"
                                        onClick={_handleToggleFilter}>
                                        <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                        <img draggable={false} src={require('../../../Assets/Images/Users/filter.png')}
                                            alt="Filter"
                                            width={24}
                                            height={24}
                                            className="cursor-pointer me-2" />
                                    </div>
                                    {
                                        showFilter === true &&
                                        <Fragment>
                                            <div className="position-relative" ref={filterRef}>
                                                <ExploreFilterPopup type={2}
                                                    data={filterData}
                                                    role={role}
                                                    applyFilter={_handleApplyFilter}
                                                    close={() => setShowFilter(false)} />
                                            </div>
                                            <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                                onClick={() => setShowFilter(false)}></div>
                                        </Fragment>
                                    }
                                </div>
                            </div>

                            {
                                loginData.length !== 0 ?
                                    <div className="col-12 mt-3 px-0">
                                        <DataTableContainer columns={USER_COLUMNS}
                                            data={loginData}
                                            selectableRows={false}
                                            pagination={false}
                                            customStyles={TABLE_STYLE}
                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                    </div>
                                    :
                                    <EmptyScreen title="Empty login logs" />
                            }

                        </div>
                    </div>
            }

        </Fragment>
    )
}

export default LoginLogs