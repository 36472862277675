/*
 *   File : user-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { UserDetailSummary } from "../../../Components/Cards";
import { CustomTab } from "../../../Components/Tab";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { GradientButton } from "../../../Components/Cta";

/* import container */
import { CustomerProfile, MandateList, OrderList, Portfolio, RiskAssessment, SipHistory, SipList, Report, JointHolders, MinorDetail } from "./helper";
import { CustomLoader } from "../../../Components/Other";
import APIService from "../../../Services/api-service";

/* import styles */

const PAGE_NAVIGATION = [
    "Dashboard",
    "Users",
    "User details"
]

const TAB_DATA = [
    "Profile & KYC",
    "Portfolio",
    "Orders",
    "SIPs",
    "Mandates",
    "Risk-Assessment",
    "SIP History",
    "Reports"
]

const AdminUserDetail = React.memo(() => {

    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(1);
    const [userData, setUserData] = useState({});
    const [summaryData, setSummaryData] = useState({});
    const [loader, setLoader] = useState(true);
    const [tabData, setTabData] = useState([]);

    const [syncing, setSyncing] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getUserSummary();
    }, []);

    useEffect(() => {
        return () => {
            setTabData([]);
        };
    }, []);

    useEffect(() => {
        if (Object.keys(userData).length !== 0) {
            if (tabData.length === 0) {
                let tab = [...TAB_DATA];
                console.log('userData', userData.profile);
                if (userData.profile.minor_flag === true) {
                    tab.push('Guardian details');
                }
                if (userData.profile.account_type === 'Joint account') {
                    tab.push('Joint holders');
                }
                if (userData.profile.account_type === 'Anyone or Survivor') {
                    tab.push('Other holders');
                }
                if (userData.profile.account_type === 'Either or Survivor') {
                    tab.push('Other holders');
                }
                console.log('tab', tab);
                setTabData(tab);
            }
        }
    }, [userData]);

    useEffect(() => {
        if (location.state !== null) {
            _getUserData();
        }
        //eslint-disable-next-line
    }, [location])

    const _handleTabIndex = (tab_index) => {
        setTabIndex(tab_index);
    }

    // API - Get user data
    function _getUserData() {
        let url = 'user/profile/get';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setUserData(response.data);
            }
            setLoader(false);
        })
    }


    // API - Get user summary
    function _getUserSummary() {
        let url = 'user/profile/summary';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
            }
        })
    }

    // API - sync folios
    function _syncFolio() {
        setSyncing(true);
        let url = 'user/folio-sync';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Data synced successfully', {
                    type: 'success'
                });
                _getUserData();
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setSyncing(false);
        })
    }

    return (
        <Fragment>
            <NavigationHeader
                title="User details" />
            {
                loader === true ?
                    <div className="row h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                        <BreadCrumb data={PAGE_NAVIGATION} />
                        <div className="row">
                            <div className="col-12">
                                <UserDetailSummary
                                    basicInfo={userData.profile}
                                    summaryInfo={summaryData}
                                    updateDetail={_getUserData} />
                            </div>
                        </div>
                        <div className="row gx-0 padding-28px-top">
                            <div className="col-9 scrollbar-container overflow-auto white-space-nowrap">
                                <CustomTab data={TAB_DATA}
                                    index={tabIndex}
                                    onSelectTab={_handleTabIndex} />
                            </div>
                            {
                                tabIndex === 1 &&
                                <div className="col-3 text-md-end ">
                                    <GradientButton label="Sync data"
                                        onPress={_syncFolio}
                                        loading={syncing} />
                                </div>
                            }

                        </div>
                        {
                            tabIndex === 1 ?
                                <CustomerProfile customer_info={userData}
                                    updateProfile={_getUserData} />
                                :
                                tabIndex === 2 ?
                                    <Portfolio />
                                    :
                                    tabIndex === 3 ?
                                        <OrderList />
                                        :
                                        tabIndex === 4 ?
                                            <SipList /> :
                                            tabIndex === 5 ?
                                                <MandateList userBank={userData.profile.banks}/>
                                                :
                                                tabIndex === 6 ?
                                                    <RiskAssessment />
                                                    :
                                                    tabIndex === 7 ?
                                                        <SipHistory userId={location.state.user_id} />
                                                        : tabIndex === 8 ?
                                                            <Report userId={location.state.user_id} />
                                                            : tabIndex === 9 ?
                                                                <>
                                                                    {
                                                                        userData.profile.minor_flag === true ?
                                                                            <MinorDetail data={userData.profile} />
                                                                            :
                                                                            <JointHolders data={userData.profile} />
                                                                    }
                                                                </>
                                                                :
                                                                <JointHolders data={userData.profile} />


                        }

                    </div>
            }

        </Fragment>
    )
})

export default AdminUserDetail;