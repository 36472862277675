/*
 *   File : delete.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while removing funds.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import { GradientButton, OutlineButton } from "../Cta";
import { Icon } from "../Icon";
import { toast } from "react-toastify";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */
import { closeModal } from "../../Helper/helper-function";
import APIService from "../../Services/api-service";

const DeleteQuestionModal = (props) => {
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("delete-question");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    const _handleCancel = () => {
        props.close();
    }


    const _deleteFaq = () => {
        setApiLoader(true);
        const url = 'risk/delete-question';
        const data = {
            "question_id": props.question_id,
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.confirmDelete();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_delete}`}
            id="delete-question"
            tabIndex="-1"
            aria-labelledby="delete-question"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-all ${style.e_content} `}>
                    <div className="col-12 d-flex justify-content-between">

                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            className="cursor-pointer ms-auto e-modal-close-btn"
                            data-bs-dismiss="modal" />
                    </div>
                    <img draggable={false} src={require("../../Assets/Images/Modal/delete-icon.png")}
                        alt="Delete account"
                        width={72}
                        height={72}
                    />


                    <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                        Are you sure, you want to delete this question?
                    </p>

                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  w-100 px-5">
                        <OutlineButton label="No, cancel"
                            cancel="modal"
                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                            handleClick={_handleCancel} />
                        <GradientButton label="Yes, delete"
                            className="padding-12px-tb e-font-16 w-100 "
                            cancel="modal"
                            loading={apiLoader}
                            onPress={_deleteFaq} />
                    </div>
                </div>
            </div>
        </div>
    )
}


DeleteQuestionModal.defaultProps = {
    close: () => { },
}

export default DeleteQuestionModal