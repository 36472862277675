
/*
 *   File : stp-listing.js
 *   Author URI : https://evoqins.com
 *   Description : STP listing page
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect, useRef, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import format from "date-fns/format";

import { Icon } from "../../../../Components/Icon";
import { CustomDateRangePicker, DataTableContainer, SearchInput } from "../../../../Components/FormElements";
import { CustomLoader, Chip, EmptyScreen } from "../../../../Components/Other";
import { DownloadButton } from "../../../../Components/Cta";
import { UnlockSIPModal } from "../../../../Components/Modal";

import APIService from "../../../../Services/api-service";

import Colors from '../../../../Styles/color.module.scss';

const FILTER_DATA = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Cancelled' },
];

const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "18px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "start !important",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            justifyContent: "start !important",
            color: Colors.black,
            '&:first-child': {
                paddingLeft: "0px",
            },
            '&:nth-child(2)': {
                paddingLeft: "14px !important",
                paddingRight: "0px !important",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }


}

export default function STPListing(props) {

    const navigator = useNavigate();
    const location = useLocation();
    const popupRef = useRef(null);
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [selectedFilter, setSelectedFilter] = useState(1);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageLoader, setPageLoader] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [totalLength, setTotalLength] = useState(null);
    const [apiLoader, setApiLoader] = useState(true);
    const [stpListing, setStpListing] = useState({});
    const [cancelledList, setCancelledList] = useState([]);
    const [permissionType, setPermissionType] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedSips, setSelectedSips] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);

    const STP_ACTIVE_COLUMNS = [
        {
            name: <div className={`position-relative w-fit-content ${permissionType !== 'read' && 'padding-40px-left'}`}>
                {/* Add your custom image or icon here */}
                {
                    permissionType !== 'read' && (
                        <Icon icon={selectAll === true ? "checked" : "unchecked"}
                            size={24}
                            className="position-absolute left-0 cursor-pointer"
                            onClick={(event) => _handleSelectAll(event)} />
                    )
                }

                <p className="mb-0">Funds</p>
            </div>,
            selector: row => row['name'],
            left: true,
            minWidth: "550px",
            cell: row =>
                <div className="d-flex gap-12px e-fund-name py-4"
                    onClick={() => _handleCardClick(row, true)}>
                    {
                        permissionType !== 'read' && (
                            <Icon icon={selectedSips.has(row['stp_id']) ? "checked" : "unchecked"}
                                size={24}
                                onClick={(event) => _handleRowCheckBox(row, event)} />
                        )
                    }
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                    <div>
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer Out
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-0">{row['name']}</p>
                        <img src={require('../../../../Assets/Images/Orders/stp-arrow.png')}
                            className="e-arrow-down my-3 w-35px" />
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer In
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-2">{row['switch_in_fund_name']}</p>
                    </div>

                </div>
        },
        {
            name: 'MT.ID',
            selector: row => row['mt_id'],
            sortable: true,
            left: true,
            width: "200px",
            // maxWidth: "300px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row, true)}>

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['mt_id']}</p>
                </div>
        },
        {
            name: 'Name',
            selector: row => row['customer_name'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" onClick={() => _handleCardClick(row, true)}> {row['customer_name']}</span>,
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: false,
            left: true,
            minWidth: "250px",
            cell: row => <span className="color-outer-space truncate-first-line" onClick={() => _handleCardClick(row, true)}> {row['email'] ? row['email'] : '-'}</span>,
        },
        {
            name: 'Phone',
            selector: row => row['phone'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span className="color-outer-space" onClick={() => _handleCardClick(row, true)}> {row['phone'] ? row['phone'] : '-'}</span>,
        },
        {
            name: 'Type',
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-2">STP</p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row, true)}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "200px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }
        },
        {
            name: 'STP date',
            selector: row => row['next_sip_date'],
            sortable: false,
            left: true,
            width: "200px",
            center: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
    ];

    const STP_CANCELLED_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['name'],
            left: true,
            minWidth: "550px",
            cell: row =>
                <div className="d-flex gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row, false)}>
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                    <div>
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer Out
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-0">{row['name']}</p>
                        <img src={require('../../../../Assets/Images/Orders/stp-arrow.png')}
                            className="e-arrow-down my-3 w-35px" />
                        <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                            Transfer In
                        </p>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-2">{row['switch_in_fund_name']}</p>
                    </div>

                </div>
        },
        {
            name: 'MT.ID',
            selector: row => row['mt_id'],
            sortable: true,
            left: true,
            width: "200px",
            // maxWidth: "300px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row, false)}>

                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['mt_id']}</p>
                </div>
        },
        {
            name: 'Name',
            selector: row => row['customer_name'],
            sortable: false,
            left: true,
            minWidth: "160px",

            cell: row => <span className="color-black ps-3" onClick={() => _handleCardClick(row, false)}> {row['customer_name']}</span>,
        },
        {
            name: 'Type',
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            },
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-24px bg-white mb-2">STP</p>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row, false)}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "200px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }
        },
        {
            name: 'Cancelled date',
            selector: row => row['cancelled_on'],
            sortable: false,
            left: true,
            width: "200px",
            center: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
    ]

    useEffect(() => {
        if (selectedFilter === 1) {
            _getActiveSTPs();
        } else {
            _getCancelledSTPs();
        }
    }, [selectedFilter, pageNumber, startDate, pageSize]);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    useEffect(() => {
        if (location.state && location.state.status) {
            setSelectedFilter(location.state.status);
        }
    }, [location]);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse]);

    useEffect(() => {
        if (selectAll === true) {
            const stp_id_set = new Set(stpListing.data.fund_data.map((item) => item.stp_id));
            setSelectedSips((prevSet) => new Set([...prevSet, ...stp_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedSips(new Set());
            }
        }
    }, [selectAll, stpListing]);

    useEffect(() => {
        if (openDownloadModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [openDownloadModal])

    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedSips)
        if (selectedSips.has(row.stp_id)) {
            setSelectedSips((prevStpIds) => {
                const new_stp_ids = new Set(prevStpIds);
                new_stp_ids.delete(row.stp_id);
                return new_stp_ids;
            });
        }
        else {
            setSelectedSips((prevStpIds) => new Set([...prevStpIds, row.stp_id]));
        }
        setRowSelectFalse(true);
    }


    // handle chip filter
    function _handleChipFilter(value) {
        if (value !== selectedFilter) {
            setSelectedFilter(value);
            setPageNumber(1);
        }
    }

    // search handler
    function _handleSearchQuery(value) {
        setPageNumber(1);
        setSearchQuery(value);
        if (value === '') {
            setPageNumber(1);
            _getActiveSTPs('');
        }
    }

    // handler for opening date picker
    function _handleShowDateFilter() {
        window.scrollTo({
            top: 100,
            behavior: 'smooth'
        })
        setShowDateFilter(!showDateFilter);
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.style.pointerEvents = 'none'
        }

    }

    // formating date
    function _handleDateRangeChange(newRange) {
        setPageNumber(1);
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setSelectedRange(newRange);
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.removeAttribute('style');
        }
        setPageNumber(1);
    };

    // clear dates on close
    function _handleClearDates() {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
        const body_element = document.getElementById('e-table-body');
        if (body_element) {
            body_element.removeAttribute('style');
        }
        setPageNumber(1);
    }

    // navigation 
    function _handleCardClick(row, status) {
        navigator("/manage-stp-swp/stp-summary", {
            state: {
                fund_id: row.stp_id,
                customer_id: row.customer_id,
                status
            }
        })
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }



    // API - active STP List
    function _getActiveSTPs(query) {
        let url = 'stp/list';
        let payload = JSON.stringify({
            query,
            is_goal: false,
            start_date: startDate === 'Start date' ? null : startDate,
            end_date: endDate === 'End date' ? null : endDate,
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
                setStpListing(response.data);
            }
            else {
                setStpListing({});
            }
            setApiLoader(false);
        })
    }

    // API - cancelled STP List
    function _getCancelledSTPs() {
        setApiLoader(true);
        let url = 'stp/cancelled-list';
        let payload = JSON.stringify({
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
                setCancelledList(response.data.data);
            } else {
                setCancelledList([]);
            }
            setApiLoader(false);
        })
    }

    // API - confirm download
    function _confirmStpDownload() {
        const stp_id = [...selectedSips]
        const url = "stp/confirm-download";

        const data = {
            "ids": selectAll === true ? [] : stp_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setOpenDownloadModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // API - download active STP 
    function _downloadSTPs(pin) {
        setPageLoader(true);
        const stp_id = [...selectedSips];
        const url = "stp/download";

        const data = {
            "ids": selectAll === true ? [] : stp_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setOpenDownloadModal(false);
            setSelectedSips(new Set());
            setSelectAll(false);
        })
    }
    return (
        <div>
            <div className="row margin-32px-top margin-12px-bottom d-flex">
                <div className="col-lg-4  ">
                    <div className="d-flex gap-8px flex-wrap">
                        {
                            FILTER_DATA.map((tag, index) => {
                                return (
                                    <Chip data={tag.label}
                                        key={index}
                                        type={2}
                                        chipIndex={tag.value}
                                        activeChipIndex={selectedFilter}
                                        onPress={_handleChipFilter} />
                                )
                            })
                        }
                    </div>
                </div>
                {
                    selectedFilter === 1 &&
                    <div className="col-lg-8 position-relative pe-0">
                        <div className="row">
                            <div className="col-lg-7 offset-lg-1 d-flex gap-8px pe-2">
                                <SearchInput placeholder="Search with id, mobile , email & PAN "
                                    value={searchQuery}
                                    valueChange={_handleSearchQuery}
                                    handleSearch={() => {
                                        setPageNumber(1);
                                        _getActiveSTPs(searchQuery);
                                    }} />
                                {
                                    permissionType !== 'read' &&
                                    <DownloadButton onPress={_confirmStpDownload}
                                        loader={downloadLoader} />
                                }
                            </div>
                            <div className="col-lg-4 d-flex gap-8px  px-0">
                                <CustomDateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    popupRef={popupRef}
                                    className='w-100'
                                    showDateFilter={showDateFilter}
                                    handleShowDateFilter={_handleShowDateFilter}
                                    selectedRange={selectedRange}
                                    handleDateRangeChange={_handleDateRangeChange}
                                    clearDates={_handleClearDates} />
                            </div>
                        </div>
                    </div>
                }

                <div className="col-12">
                    <p className="color-outer-space e-poppins-regular e-font-12 mb-0 text-end">
                        <img draggable={false} src={require('../../../../Assets/Images/Onboarding/scroll-gif.gif')} alt="scroll"
                            width='24px'
                            height='24px'
                            className='e-rotate-gif' />
                        Scroll horizontally for view more  information</p>
                </div>
            </div>
            <div className="min-height-80vh">
                {
                    apiLoader === true ? (
                        <CustomLoader />
                    ) : (
                        <>
                            {
                                selectedFilter === 1 ?
                                    <div className="row">
                                        <div className="col-12" >
                                            {
                                                stpListing.data.fund_data.length ?
                                                    <div className="col-12" >
                                                        <div className="min-height-50vh">
                                                            <div id="e-table-body" className="position-relative">
                                                                <DataTableContainer columns={STP_ACTIVE_COLUMNS}
                                                                    data={stpListing.data.fund_data}
                                                                    selectableRows={false}
                                                                    pagination={true}
                                                                    customStyles={TABLE_STYLE}
                                                                    defaultSortFieldId={1}
                                                                    paginationComponentOptions={paginationData}
                                                                    onChangeRowsPerPage={(e) => {
                                                                        setPageSize(e);
                                                                        setPageNumber(1);
                                                                    }}
                                                                    paginationTotalRows={totalLength}
                                                                    paginationCurrentPage={pageNumber}
                                                                    paginationPerPage={pageSize}
                                                                    paginationIconNext={null}
                                                                    paginationIconPrevious={null}
                                                                    rowClick={(row) => {
                                                                        _handleCardClick(row, true)
                                                                    }} />
                                                                <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                                                    <Icon icon="pagination-previous" width="24px" height="24px"
                                                                        className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                                        onClick={_previousPage} />
                                                                    <Icon icon="pagination-next" width="24px" height="24px"
                                                                        className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                                        onClick={_nextPage} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <EmptyScreen title="Empty active STP data!" />

                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-12" >
                                            {
                                                cancelledList.length ?
                                                    <div className="col-12" >
                                                        <div className="min-height-50vh">
                                                            <div id="e-table-body" className="position-relative">
                                                                <DataTableContainer columns={STP_CANCELLED_COLUMNS}
                                                                    data={cancelledList}
                                                                    selectableRows={false}
                                                                    pagination={true}
                                                                    customStyles={TABLE_STYLE}
                                                                    defaultSortFieldId={1}
                                                                    paginationComponentOptions={paginationData}
                                                                    onChangeRowsPerPage={(e) => {
                                                                        setPageSize(e);
                                                                        setPageNumber(1);
                                                                    }}
                                                                    paginationTotalRows={totalLength}
                                                                    paginationCurrentPage={pageNumber}
                                                                    paginationPerPage={pageSize}
                                                                    paginationIconNext={null}
                                                                    paginationIconPrevious={null}
                                                                    rowClick={(row) => {
                                                                        _handleCardClick(row, false)
                                                                    }} />
                                                                <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                                                    <Icon icon="pagination-previous" width="24px" height="24px"
                                                                        className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                                        onClick={_previousPage} />
                                                                    <Icon icon="pagination-next" width="24px" height="24px"
                                                                        className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                                        onClick={_nextPage} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <EmptyScreen title="Empty active stp data!" />

                                            }
                                        </div>
                                    </div>
                            }
                        </>

                    )
                }
            </div>
            {
                openDownloadModal === true ?
                    <UnlockSIPModal
                        handleOTPSubmission={_downloadSTPs}
                        close={() => {
                            setOpenDownloadModal(false);
                        }} />
                    :
                    null
            }
        </div>
    );
}