/*
 *   File :tag.js
 *   Author URI : https://evoqins.com
 *   Description : UI card to manage cards. 
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useState, useEffect } from "react";

const TagCard = (props) => {
    const { data } = props;

    const [selectedTag, setSelectedTag] = useState(0);

    useEffect(() => {
        setSelectedTag(props.selectedId);
    }, [props.selectedId]);

    return (
        <>
            {
                props.update === true ? (
                    <div className={`${selectedTag === props.icon.id ? 'border-primary-color' : 'border-anti-flash-white'} border-radius-24px border-all min-height-142px padding-18px-tb px-3 text-center position-relative margin-24px-top e-quick-invest-card`}
                        onClick={() => props.onSelect()}>

                        <img draggable={false} src={data.tag_image}
                            alt={data.name}
                            width={40}
                            height={40}
                        />
                        <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-24px-top'>{data.name}</p>


                        {
                            props.icon.icon_text !== null && (
                                <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                    <p className='color-black e-poppins-regular line-height-12px e-font-10 my-0'>{props.icon.icon_text}
                                        <img draggable={false} src={props.icon.icon}
                                            alt={props.icon.icon_text}
                                            width={12}
                                            height={12}
                                        /></p>
                                </div>
                            )
                        }


                    </div>
                ) : (
                    <div className={`${selectedTag === data.id ? 'border-primary-color' : 'border-anti-flash-white'} border-radius-24px border-all min-height-142px padding-18px-tb px-3 text-center position-relative margin-24px-top e-quick-invest-card`}
                        onClick={() => props.onSelect()}>

                        <img draggable={false} src={data.tag_image}
                            alt={data.name}
                            width={40}
                            height={40}
                        />
                        <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-0 margin-24px-top'>{data.name}</p>
                        {
                            data.icon_text !== null && (
                                <div className='position-absolute e-trending p-1 bg-cornsilk border-tbr-radius-12px'>
                                    <p className='color-black e-poppins-regular line-height-12px e-font-10 my-0'>{data.icon_text}
                                        <img draggable={false} src={data.icon_image}
                                            alt={data.icon_text}
                                            width={12}
                                            height={12}
                                        /></p>
                                </div>
                            )
                        }


                    </div>
                )
            }
        </>

    )
}
TagCard.defaultProps = {
    onSelect: () => { },
    iconList: [],
    update: false
}

export default TagCard;