
/*
 *   File : user-summary.js
 *   Author URI : https://evoqins.com
 *   Description : User summary card
 *   Integrations : null
 *   Version : v1.1
 */

/*import packages*/
import { Fragment, React, useState } from "react";


const UserSummary = (props) => {
    const [showUnitsHeld, setShowUnitsHeld] = useState(false);

    const _showUnitsHeld = () => {
        setShowUnitsHeld(!showUnitsHeld);
    }

    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className={`border-radius-16px padding-24px-all w-100 h-100 ${props.borderColor ? props.borderColor : "bg-pale-steel-blue"}`}>
                        <div className="d-flex align-items-center justify-content-between ">
                            <span className="e-poppins-medium e-font-14 line-height-20px color-outer-space capitalized-text">{props.title}</span>

                            <img draggable={false} src={props.icon}
                                alt="User info"
                                width={40}
                                height={40}
                            />
                        </div>
                        <h6 className="e-poppins-bold e-font-24 line-height-36px color-black mt-0 mb-0">
                            {props.count}
                        </h6>

                    </div>
                    :
                    props.type === 2 ?
                        <div className="border-radius-16px bg-white padding-24px-all w-100">
                            <div className="d-flex align-items-center justify-content-between ">
                                <span className="e-poppins-medium e-font-14 line-height-20px color-outer-space capitalized-text">
                                    {props.title}
                                </span>

                                <img draggable={false} src={props.icon}
                                    alt="Orders"
                                    width={40}
                                    height={40}
                                />
                            </div>

                            <h6 className="e-poppins-bold e-font-24 line-height-36px color-black mt-0 mb-0 position-relative cursor-pointer"
                                onMouseEnter={_showUnitsHeld}
                                onMouseLeave={_showUnitsHeld}>
                                <span className="e-inter-bold">₹</span>
                                {props.count_text}
                                {
                                    showUnitsHeld &&
                                    <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-white mt-0">
                                        <span className="e-inter-regular pe-1">₹</span>
                                        {props.count.toLocaleString('en-IN')}
                                    </p>
                                }
                            </h6>

                        </div>
                        :
                        <div className={`border-radius-16px  padding-24px-all w-100 h-100 ${props.borderColor ? props.borderColor : "bg-pale-steel-blue"}`}>
                            <div className="d-flex align-items-center justify-content-between ">
                                <span className="e-poppins-medium e-font-14 line-height-20px color-outer-space">{props.title}</span>

                                <img draggable={false} src={props.icon}
                                    alt="User info"
                                    width={40}
                                    height={40}
                                />
                            </div>
                            <h6 className="e-poppins-bold e-font-24 line-height-36px color-black mt-2 mb-0">
                                {props.count}
                            </h6>

                        </div>
            }
        </Fragment>
    )
}

UserSummary.defaultProps = {
    count: 0,
    count_text: "0",
    title: "",
    icon: null,
    type: 1
}

export default UserSummary;

