
/*
 *   File : order-funds-accordion.js
 *   Author URI : https://evoqins.com
 *   Description : common accordion component used to show the invested funds.
 *   Integrations : null
 *   Version : v1.1
 */
import { useState } from "react";

import Icon from "../Icon/icon";

import style from "../../Styles/Components/order-funds-accordian.module.scss";


const OrderFunds = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [collapsedAccordion, setCollapsedAccordion] = useState(-1);

    function _handleAccordionClick(index) {
        if (activeIndex === index) {
            return;
        }
        setActiveIndex(index);
    }

    function _collapseAccordion(key) {
        if (collapsedAccordion === key) {
            setCollapsedAccordion(-1)
        } else {
            setCollapsedAccordion(key)
        }

    }



    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../Assets/Images/Orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../Assets/Images/Orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../Assets/Images/Orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../Assets/Images/Orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>

        )
    }

    const Progress = (props) => {
        return (
            <div className={`d-flex flex-column align-items-center text-center ${props.index !== 3 && "margin-64px-right"}`}>
                <Icon icon={`${props.progress === 3 ? 'check-green' : props.progress === 2 ? 'pending-yellow' : props.progress === 0 ? 'info-circle' : 'pending'}`}
                    size={24}
                    className="z-index-1" />
                <span className={`e-poppins-medium e-font-12 line-height-12px my-1 w-max-content ${props.progress === 3 ? 'color-sea-green' : props.progress === 2 ? 'color-yellow-orange' : props.progress === 0 ? 'color-red' : 'color-gray'}`}>{props.title}</span>
                <span className={`e-poppins-medium e-font-10 line-height-10px ${props.data !== null ? 'color-outer-space' : 'color-gray'}`}>{props.data}</span>
            </div>
        )
    }


    return (
        <div class={`accordion accordion-flush d-flex flex-column gap-8px overflow-auto`}
            id="order-funds">
            {
                props.data.funds.map((item, key) =>
                    <div class={`${style.e_accordian_item} bg-light-sky-blue border-radius-16px `}
                        key={key}
                        onClick={() => _handleAccordionClick(key)}>
                        <h2 class={`${style.e_accordian_header} mb-0 py-0`}
                            id={`heading-${key}`}>
                            <button class={` e-poppins-regular e-font-16 line-height-24px accordion-button ${collapsedAccordion !== key && 'collapsed'} ${style.e_accordian_button}`}
                                type="button"
                                id={`accordion-btn-${key}`}
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse-${key}`}
                                aria-expanded={`${collapsedAccordion === key ? false : true}`}
                                aria-controls={`collapse-${key}`}
                                onClick={() => _collapseAccordion(key)}>
                                {item.fund_name}
                            </button>
                        </h2>

                        <div class={`accordion-collapse collapse ${collapsedAccordion === key && 'show'}`}
                            aria-labelledby={`heading-${key}`}
                            data-bs-parent="#order-funds">
                            <div class={`${style.e_accordian_body}`}>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <Description title="Amount:"
                                            description={item.amount.toLocaleString('en-IN')}
                                            symbol={true} />
                                    </div>
                                    <div className="col-8">
                                        <Description title="Units:"
                                            description={item.units} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 mb-3">
                                        <Description title="Order ID:"
                                            description={item.mt_order_id} />
                                    </div>
                                    <div className="col-8">
                                        <Description title="Folio:"
                                            description={item.folio_no} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={`${key === activeIndex ? 'col-4' : 'col-6'}`}>
                                        <Description title="Allocated date:"
                                            description={item.portfolio_updated} />
                                    </div>
                                    <div className={`${key === activeIndex ? 'col-8' : 'col-6'}`}>
                                        <Description title="Order status:"
                                            description={item.order_status}
                                            status={true} />
                                    </div>
                                </div>

                                <div className="w-100 overflow-auto">
                                    <div className={`position-relative margin-26px-top mb-3 ${style.progress_status}`}>
                                        <div className={`position-absolute bg-platinum ${style.e_progress_line}`}></div>
                                        <div className="d-flex">
                                            <div className="d-flex ">
                                            <Progress title={props.data.summary.type === 'Withdrawal' ? "Order created" : "Payment created"}
                                                    data={item.order_date}
                                                    progress={3}
                                                    index={0}
                                                    status={item.order_status} />
                                                <Progress title={props.data.summary.type === 'Withdrawal' ? "2FA completed" : props.data.summary.order_status === 'Payment pending' ? props.data.summary.order_status : "Payment confirmed"}
                                                    data={props.data.summary.type === 'Withdrawal' ? item.payment_date : item.payment_received}
                                                    progress={
                                                        props.data.summary.type === 'Withdrawal' ?
                                                            item.payment_date !== null ? 3 : (item.order_date !== null && item.payment_date === null && props.data.summary.order_status !== 'Failed') ? 2 : props.data.summary.order_status === 'Failed' ? 0 : 1
                                                            :
                                                            item.payment_received !== null ? 3 : (item.order_date !== null && item.payment_received === null && props.data.summary.order_status !== 'Failed') ? 2 : props.data.summary.order_status === 'Failed' ? 0 : 1
                                                    }
                                                    index={1}
                                                    status={item.order_status} />
                                                <Progress title="Accepted by Exchange/RTA"
                                                    data={item.order_accepted}
                                                    progress={
                                                        props.data.summary.type === 'Withdrawal' ?
                                                            item.order_accepted !== null ? 3 : (item.payment_date !== null && item.order_accepted === null && props.data.summary.order_status !== 'Failed') ? 2 : props.data.summary.order_status === 'Failed' ? 0 : 1
                                                            :
                                                            item.order_accepted !== null ? 3 : (item.payment_received !== null && item.order_accepted === null && props.data.summary.order_status !== 'Failed') ? 2 : props.data.summary.order_status === 'Failed' ? 0 : 1
                                                    }
                                                    index={2}
                                                    status={item.order_status} />
                                                <Progress title={props.data.summary.type === 'Withdrawal' ? "Amount transferred" : "Unit(s) allocated "}
                                                    data={item.portfolio_updated}
                                                    progress={item.portfolio_updated !== null ? 3 : (item.order_accepted !== null && item.portfolio_updated === null && props.data.summary.order_status !== 'Failed') ? 2 : props.data.summary.order_status === 'Failed' ? 0 : 1}
                                                    index={3}
                                                    status={item.order_status} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    props.data.summary.order_status === 'Failed' &&  item.failed_reason &&
                                    <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink  mt-4 p-3 border-radius-24px ">
                                        <img src={require('../../Assets/Images/Orders/clock-failed.png')}
                                            className="me-2" /> <span className="mt-1">{item.failed_reason}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {/* <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Accordion Item #2
                    </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#order-funds">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Accordion Item #3
                    </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#order-funds">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                </div>
            </div> */}
        </div>
    )
}

export default OrderFunds