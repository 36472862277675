/*
 *   File : switch.js
 *   Author : https://evoqins.com
 *   Description : Toggle component
 *   Integrations : null
 *   Version : 1.0.0
*/

/* import packages */
import { useEffect, useState } from 'react';

/* import styles */
import Color from "../../Styles/color.module.scss"
import style from '../../Styles/Components/toggle.module.scss'

const Toggle = (props) => {
    const [leftPosition, setLeftPosition] = useState(props.check);

    useEffect(() => {
        setLeftPosition(props.check);
    }, [props.check]);

    const _handleClick = () => {
        props.onChange(!leftPosition);
    }

    return (
        <div className={`border-radius-16px position-relative z-index-1 cursor-pointer ${style.e_switch}  ${props.className}`}
            style={{
                backgroundColor: leftPosition === false?
                    props.leftColor ?
                        props.leftColor :
                        Color.light_gray
                    :
                    props.barColor ?
                        props.barColor :
                        Color.primary
            }}
            onClick={() => _handleClick()}>
            <div className={`bg-white border-radius-100 position-absolute cursor-pointer z-index-1 ${style.e_head} `}
                style={{ left: leftPosition === false ? '0' : '50%' }}></div>
        </div>
    )
}

Toggle.defaultProps = {
    className: "",
    onChange: () => { }
}

export default Toggle;
