/*
 *   File : notification-log.js
 *   Author URI : https://evoqins.com
 *   Description : Notification log page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

import { DataTableContainer } from "../../../Components/FormElements";
import { ViewContent } from "../../../Components/Modal";
import { LinkText } from "../../../Components/Cta";

/* import styles */
import Colors from '../../../Styles/color.module.scss';

// static notification data
const NOTIFICATION_LIST = [
    {
        id: 1,
        date: "Aug 25 2020, 11.05 AM",
        sent: "Sub-admin",
        content: "Content of the push notification.",
        message: "Invest for better future.",
        preview_image: require("../../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        id: 2,
        date: "Aug 25 2020, 11.05 AM",
        sent: "Associates",
        content: "Content of the push notification.",
        message: "Invest for better future.",
        preview_image: require("../../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        id: 3,
        date: "Aug 25 2020, 11.05 AM",
        sent: "Associates",
        content: "Content of the push notification.",
        message: "Invest for better future.",
        preview_image: require("../../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        id: 4,
        date: "Aug 25 2020, 11.05 AM",
        sent: "Associates",
        content: "Content of the push notification.",
        message: "Invest for better future.",
        preview_image: require("../../../Assets/Images/Notifications/notification-preview.png")
    },
    {
        id: 5,
        date: "Aug 25 2020, 11.05 AM",
        sent: "Users",
        content: "Content of the push notification.",
        message: "Invest for better future.",
        preview_image: require("../../../Assets/Images/Notifications/notification-preview.png")
    }
]

// table style
const TABLE_STYLE = {
    table: {
        style: {
            padding: "0",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 16px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}



const NotificationLog = () => {
    const [notificationDetail, setNotificationDetail] = useState(null);

    // open view content modal
    useEffect(() => {
        if (notificationDetail !== null) {
            const modal = new Modal(document.getElementById("view-content"));
            modal.show();
        }
    }, [notificationDetail])

    // open modal 
    const _handleOpenModal = (item) => {
        setNotificationDetail(item);
    }

    // columns
    const NOTIFICATION_COLUMNS = [
        {
            name: 'Send to',
            selector: row => row['sent'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row => <span className="color-outer-space"> {row['sent']}</span>,
        },

        {
            name: 'Delivered time',
            selector: row => row['date'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row => <span className="color-outer-space"> {row['date']}</span>,
        },
        {
            name: 'Notification content',
            selector: row => row['content'],
            sortable: false,
            left: true,
            maxWidth: "450px",
            cell: row => <span className="color-outer-space"> {row['content']}</span>,
        },
        {
            name: 'AOF',
            sortable: false,
            left: true,
            maxWidth: "140px",
            cell: row => <span className="ps-0">
                <LinkText title="View content"
                    onClick={() => {
                        _handleOpenModal(row)
                    }} />
            </span>

        },
    ]


    return (

        <Fragment>
            <div className="row padding-24px-top">
                <div className="col-12">
                    <DataTableContainer columns={NOTIFICATION_COLUMNS}
                        data={NOTIFICATION_LIST}
                        selectableRows={false}
                        pagination={false}
                        customStyles={TABLE_STYLE}
                        defaultSortFieldId={1} />
                </div>
            </div>
            {
                notificationDetail !== null ?
                    <ViewContent data={notificationDetail}
                        close={() => {
                            setNotificationDetail(null);
                        }} />
                    :
                    null
            }
        </Fragment>
    )
}

export default NotificationLog