
/*
 *   File : customer-detail.js
 *   Author URI : https://evoqins.com
 *   Description : List and update all contact detail and bank detail of the user
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect, useState } from "react";
import { Modal } from 'bootstrap';
import { toast } from "react-toastify";

import { LinkText } from "../../../../Components/Cta";
import { Icon } from "../../../../Components/Icon";
import { CustomToolTip } from "../../../../Components/Popups";
import { SocialShareSuccess } from "../../../../Components/Modal";
import { CustomLoader } from "../../../../Components/Other";

import APIService from "../../../../Services/api-service";


export default function CustomerDetail(props) {
    const [profileData, setProfileData] = useState(null);
    const [showShareWidget, setShowShareWidget] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [profileFilterData, setProfileFilterData] = useState([]);
    const [apiLoader, setApiLoader] = useState(true);
    const [cardId, setCardId] = useState(1);

    useEffect(() => {
        _getProfileFilters();
    }, []);

    useEffect(() => {
        if (Object.keys(props.data).length !== 0) {
            setProfileData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (showSuccessModal === true) {
            _openSuccessModal();
        }
    }, [showSuccessModal]);

    function _openSuccessModal() {
        const modal = new Modal(document.getElementById("social-share-success"));
        modal.show()
    }

    // copy link to clipboard
    function _copyLink() {
        const linkText = document.getElementById('e-copy-link').innerText;
        const tempInput = document.createElement('input');
        tempInput.setAttribute('value', linkText);
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);

        setShowToolTip(true);
        setTimeout(() => {
            setShowToolTip(false);
            setShowShareWidget(false);
        }, 2000);
    }

    // handle share icon click
    function _handleSocialIcon(type) {
        switch (type) {
            case 1: _shareLink(`${process.env.REACT_APP_WHATSAPP_SHARE}${profileData.profile.mobile}&text=`);
                break;
            case 2: _shareLink(process.env.REACT_APP_TELEGRAM);
                break;
            case 3: _shareLink(process.env.REACT_APP_FACEBOOK_SHARE_URL);
                break;
            case 4: _shareLink(process.env.REACT_APP_TWITTER_SHARE_URL);
                break;
            case 5: _shareLink(`mailto:${profileData.profile.email}?body=`);
                break;
        }
    }

    // share link to social media
    function _shareLink(platform_url) {
        let url = platform_url + shareUrl;
        var new_window;
        new_window = window.open(url, '_blank');
        setShowShareWidget(false);
        // if (new_window !== null) {
        //     new_window.opener = null;
        //     let timer = setInterval(function () {
        //         if (new_window.closed) {
        //             clearInterval(timer);
        //             setShowSuccessModal(true);
        //         }
        //     }, 1000);
        // }
    }

    // call back function share success modal
    function _callBackSuccessModal() {
        setShowSuccessModal(false);
        setShowShareWidget(false);
    }

    function _hideWidget() {
        setShowShareWidget(false);
    }
    // API - get available edit options
    function _getProfileFilters() {
        let url = 'user/profile-update-filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setProfileFilterData(response.data);
            }
            setApiLoader(false);
        })
    }

    // API - get share link
    function _getLink(type, id) {
        setCardId(id);
        let url = 'user/initiate-profile-update';
        let payload = JSON.stringify({
            customer_id: props.userId,
            type
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setShareUrl(response.data);
                setShowShareWidget(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }

        })
    }


    // UI component for detail card
    function DetailCard(props) {
        const is_array = Array.isArray(props.data);

        return (
            <div className="bg-white border-all border-bright-gray border-radius-16px p-3">
                <div className="d-flex justify-content-between">
                    <h6 className="color-light-black e-font-16 e-poppins-medium">{props.title}</h6>
                    <LinkText title="Generate link to edit"
                        onClick={() => _getLink(props.filterTypeId, props.cardId)} />
                </div>
                {
                    typeof props.data === 'string' ? (
                        <p className="color-light-black e-font-16 e-poppins-regular mb-0">{props.data}</p>
                    )
                        : is_array === true ? (
                            <div>
                                {
                                    props.data.length !== 0 ?
                                        props.data.map((item, key) => {
                                            return (
                                                <div className={`d-flex ${key > 0 && 'mt-2'}`} key={key}>
                                                    <p className="color-light-black e-font-16 e-poppins-regular mb-0">
                                                        {item.name || item.bank_name}
                                                    </p>
                                                    <p className="color-light-black e-font-16 e-poppins-regular ms-5 mb-0">
                                                        {item.allocation || item.masked_account_number}
                                                    </p>
                                                </div>
                                            )
                                        })
                                        :
                                        <p className="color-light-black e-font-16 e-poppins-regular mb-0">
                                            -
                                        </p>

                                }
                            </div>
                        ) :
                            <p className="color-light-black e-font-16 e-poppins-regular mb-0">
                                -
                            </p>
                }
            </div>
        )
    };

    const LinkCopy = () => {
        return (
            <div className="position-absolute top-0 bg-white w-100 right--592">
                <div className={`w-100 d-flex justify-content-between border-all border-bright-gray border-radius-8px p-3 `}
                // style={{ marginTop: cardId === 1 ? '0px' : cardId === 2 ? '110px' : cardId === 3 ? '210px' : '308px' }}
                >
                    <div className=" col-8">
                        <div className="d-flex">
                            <Icon icon="copy"
                                size={16}
                                className='mt-1 me-2' />
                            <p className="color-primary-color e-font-14 e-poppins-regular mb-0"
                                id="e-copy-link">{shareUrl}</p>
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <span className="color-light-black e-poppins-regular e-font-14 position-relative cursor-pointer animated-underline me-3"
                            onClick={_hideWidget}>
                            Cancel
                        </span>

                        <span className="color-light-black e-poppins-regular e-font-14 position-relative cursor-pointer animated-underline white-space-nowrap"
                            onClick={_copyLink}>
                            Copy link
                            {showToolTip === true && (
                                <CustomToolTip content="Link copied!" />
                            )}
                        </span>
                    </div>
                </div>
                <h5 className="color-light-black e-poppins-medium e-font-16 mt-3 mb-3">Share link via</h5>
                <div className="d-flex">
                    {
                        profileData !== null && profileData.profile.mobile !== '-' &&
                        <Icon icon="Whatsapp"
                            size={40}
                            className="me-3 cursor-pointer edit-link-share"
                            onClick={() => _handleSocialIcon(1)} />
                    }

                    <Icon icon="Telegram"
                        size={40}
                        className="me-3 cursor-pointer edit-link-share"
                        onClick={() => _handleSocialIcon(2)} />
                    <Icon icon="Facebook"
                        size={40}
                        className="me-3 cursor-pointer edit-link-share"
                        onClick={() => _handleSocialIcon(3)} />
                    <Icon icon="x"
                        size={40}
                        className="me-3 cursor-pointer edit-link-share"
                        onClick={() => _handleSocialIcon(4)} />
                    {
                        profileData !== null && profileData.profile.email &&
                        <Icon icon="Gmail"
                            size={40}
                            className="me-3 cursor-pointer edit-link-share"
                            onClick={() => _handleSocialIcon(5)} />
                    }

                </div>
            </div>
        )
    }

    return (
        <div >
            {
                apiLoader === true ? (
                    <CustomLoader />
                ) : (
                    <div className="row pb-5">
                        <div className="col-6 mt-4">
                            <div className="bg-sea-blue p-4 border-radius-24px">
                                {
                                    profileFilterData.some(item => item.id === 3) && (
                                        <div className="col-12 position-relative">
                                            <DetailCard title="Email detail"
                                                data={profileData !== null ? profileData.profile.email : '-'}
                                                filterTypeId={3}
                                                cardId={1} />
                                            {showShareWidget === true && cardId === 1 &&
                                                <LinkCopy />
                                            }
                                        </div>
                                    )
                                }
                                {
                                    profileFilterData.some(item => item.id === 1) && (
                                        <div className="col-12 mt-3 position-relative">
                                            <DetailCard title="Nominees"
                                                data={profileData !== null ? profileData.nominees.nominees : '-'}
                                                filterTypeId={1}
                                                cardId={2} />
                                            {showShareWidget === true && cardId === 2 &&
                                                <LinkCopy />
                                            }
                                        </div>
                                    )
                                }
                                {
                                    profileFilterData.some(item => item.id === 2) && (
                                        <div className="col-12 mt-3 position-relative">
                                            <DetailCard title="Bank"
                                                data={profileData !== null ? profileData.profile.banks : '-'}
                                                filterTypeId={2}
                                                cardId={3} />
                                            {showShareWidget === true && cardId === 3 &&
                                                <LinkCopy />
                                            }
                                        </div>
                                    )
                                }
                                {
                                    profileFilterData.some(item => item.id === 3) && (
                                        <div className="col-12 mt-3 position-relative">
                                            <DetailCard title="Phone"
                                                data={profileData !== null ? profileData.profile.mobile : '-'}
                                                filterTypeId={3}
                                                cardId={4} />
                                            {showShareWidget === true && cardId === 4 &&
                                                <LinkCopy />
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        {/* {
                            showShareWidget === true && (
                                <div className="col-6 mt-5">
                                    
                                </div>
                            )
                        } */}
                    </div>
                )
            }
            {
                showSuccessModal === true && (
                    <SocialShareSuccess close={_callBackSuccessModal} />
                )
            }
        </div>
    );
}

