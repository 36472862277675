/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : SIP listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

/* import components */
import { CustomTab } from "../../../Components/Tab";
import { NavigationHeader } from "../../../Components/Header";
import { UserSummary } from "../../../Components/Cards";


/* import styles */

/* import services */
import APIService from "../../../Services/api-service";
import { _sortDate } from "../../../Helper/helper-function";


import ActiveSIPList from "./helper/active-sip";
import PausedSIPList from "./helper/paused-sip";
import CancelledSIPList from "./helper/cancelled-sip";


const SIP_TABS = [
    "Active SIPs",
    "Paused SIPs",
    "Cancelled SIPs"
]


const List = React.memo(() => {
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(1);
    const [sipSummary, setSipSummary] = useState(null);


    useEffect(() => {
        _getSIPSummary();
    }, []);

    useEffect(() => {
        // Clear the location once the component is mounted
        clearLocation();

        // Optionally, you may want to clear the location again when the user navigates away
        const handleBeforeUnload = () => {
            clearLocation();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const clearLocation = () => {
        // Update the URL without triggering a page reload
        window.history.pushState({}, '', '/sip-manage');
    };

    useEffect(() => {
        if (location.state !== null) {
            setTabIndex(location.state.selectedTab)
        }
    }, [location.state])


    // handler for tab index
    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }



    // API - get sip summary 
    const _getSIPSummary = () => {
        const url = "sip/summary";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSipSummary(response.data)
            } else {
                setSipSummary(null);
            }
        })
    }


    return (
        <Fragment>
            <NavigationHeader
                type={1}
                title={SIP_TABS[tabIndex - 1]} />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row">
                    {
                        sipSummary !== null ?

                            <Fragment>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="Active SIPs"
                                        count={sipSummary.total_active_sip}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/Sidebar/sip-color.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="Cancelled SIPs"
                                        count={sipSummary.total_cancelled_sip}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/cancel-sip.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="Total amount"
                                        count={sipSummary.amount_active_sip.toLocaleString('en-IN')}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/total-amount.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="AMC invested"
                                        count={sipSummary.amc_counts}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/amc-invested.png')} />
                                </div>
                            </Fragment>
                            :
                            null
                    }
                    <div className="col-12">
                        <CustomTab type={1}
                            index={tabIndex}
                            indexToHide={1}
                            data={SIP_TABS}
                            wrapperClass="gap-24px"
                            onSelectTab={_handleTabIndex} />
                    </div>

                    <div className="col-12 min-height-80vh">
                        {sipSummary !== null &&
                            (tabIndex === 1 ?
                                <ActiveSIPList total_active_sip={sipSummary.total_active_sip} />
                                :
                                tabIndex === 2 ?
                                    <PausedSIPList />
                                    :
                                    <CancelledSIPList total_cancelled_sip={sipSummary.total_cancelled_sip} />)
                        }
                    </div>

                </div>
            </div>


        </Fragment>
    )

})

export default List;