
const initialState = {
    path: '/',
    toggle: false,
};

export default function associateReducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_NAVIGATION":
            return {
                ...state,
                path: action.payload
            };
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                toggle: action.payload
            };
        default:
            return state
    }
}