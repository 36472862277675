/*
 *   File : fund-sip-detail.js
 *   Author URI : https://evoqins.com
 *   Description :  Container file which shows details of SIP created against Fund.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { NavigationHeader } from "../../../Components/Header";
import { Icon } from "../../../Components/Icon";
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import { ActivateSIPModal } from "../../../Components/Modal";
import { BreadCrumb } from "../../../Components/Breadcrumb";

import style from "../../../Styles/Components/fund-sip-detail.module.scss";


import APIService from "../../../Services/api-service";
import { _getSIPDetail } from "../../../Helper/api";
import { closeModal } from "../../../Helper/helper-function";

const PAGE_NAVIGATION = [
    'Dashboard',
    'Manage SIP',
    'SIP Summary'
];

const FundSIPDetail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [sipDetailData, setSipDetailData] = useState(null);
    const [pageLoader, setPageLoader] = useState(true);
    const [userId, setUserId] = useState(null);
    const [sipId, setSipId] = useState(null);
    const [pauseSipModal, setPauseSipModal] = useState(false);
    const [cancelSipModal, setCancelSipModal] = useState(false);
    const [resumeSipModal, setResumeSipModal] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [resumeLoader, setResumeLoader] = useState(false);
    const [permissionType, setPermissionType] = useState('');
    const [sipStatus, setSipStatus] = useState(1);
    const [pagination, setPagination] = useState(PAGE_NAVIGATION);
    const [buttonLabel, setButtonLabel] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    useEffect(() => {
        if (location.state !== null) {
            setSipStatus(location.state.sip_status);
            let pagination_data = [...pagination];
            let title = location.state.sip_status === 1 ? "Active SIPs" : location.state.sip_status === 2 ? "Paused SIPs" : "Cancelled SIPs";
            pagination_data[2] = title;
            setPagination(pagination_data);
            setSipId(location.state.sip_id);
            setUserId(location.state.customer_id);
            const request = {
                sip_id: location.state.sip_id,
                customer_id: location.state.customer_id
            }
            _getSIPDetail(request).then((response) => {
                setSipDetailData(response)
                setPageLoader(false)
            }).catch((error) => {
                setSipDetailData(null)
                setPageLoader(false)
            });
        }
    }, [location.state]);


    useEffect(() => {
        if (resumeSipModal === true || cancelSipModal === true || pauseSipModal === true) {
            const modal = new Modal(document.getElementById("active-sip"));
            modal.show();
        }
    }, [resumeSipModal, pauseSipModal, cancelSipModal]);


    // generate 2fa
    const _generate2FA = (type) => {
        setApiLoader(true);
        const url = "sip/generate-2fa";

        const data = {
            sip_id: sipId
        }
        APIService(true, url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success(`OTP sent successfully`, {
                    type: "success",
                });
                switch (type) {
                    case 1:
                        setResumeSipModal(true);
                        setButtonLabel("Resume SIP");
                        break;
                    case 2:
                        setPauseSipModal(true);
                        setButtonLabel("Pause SIP");
                        break;
                    case 3:
                        setCancelSipModal(true);
                        setButtonLabel("Cancel SIP");
                        break;
                }
            } else {
                toast.error(`Failed to send OTP`, {
                    type: "error",
                });
            }
            setApiLoader(false);
        });
    }

    // API - resume SIP, cancel SIP, pause SIP
    const _confirmSIP = (otp, reason, remark) => {
        setResumeLoader(true)
        let url = "";
        if (resumeSipModal === true) {
            url = "sip/resume";
        }
        else if (pauseSipModal === true) {
            url = "sip/pause"
        }
        else {
            url = "sip/cancel"
        }

        let payload = JSON.stringify({
            sip_id: sipId,
            customer_id: userId,
            otp: parseInt(otp),
            reason,
            remark
        });
        APIService(true, url, payload).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {

                if (resumeSipModal === true) {
                    setResumeSipModal(false);
                    toast.success(`The SIP has been resumed`, {
                        type: "success",
                    });
                    navigate("/sip-manage", { state: { selectedTab: 1 } });
                }
                else if (pauseSipModal === true) {
                    setPauseSipModal(false);
                    toast.success(`The SIP has been paused`, {
                        type: "success",
                    });
                    navigate("/sip-manage", { state: { selectedTab: 2 } });
                }
                else {
                    setCancelSipModal(false);
                    toast.success(`The SIP has been cancelled`, {
                        type: "success",
                    });
                    navigate("/sip-manage", { state: { selectedTab: 3 } });
                }
                document.body.classList.contains('modal-backdrop') && document.body.classList.remove('modal-backdrop');
                closeModal('active-sip');

            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setResumeLoader(false)
        });
    }

    const Heading = (props) => {
        return (
            <div className={`d-flex ${props.className}`}>
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">{props.heading}&nbsp;</p>
                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{props.answer}</p>
            </div>

        )
    }

    const PaymentAndOtherActivities = (props) => {
        return (
            <div className="mt-3">
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px margin-10px-bottom e-payment-other-activities">{props.heading}</p>
                <p className="color-black e-poppins-semi-bold e-font-18 line-height-16px mb-0">{props.answer}</p>
            </div>
        )
    }


    return (
        <Fragment>
            <NavigationHeader
                type={1}
                title={sipStatus === 1 ? "Active SIPs" : sipStatus === 2 ? "Paused SIPs" : "Cancelled SIPs"} />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                <BreadCrumb data={pagination} />
                {/* <div className="d-flex align-items-center gap-8px padding-12px-lr mb-3 cursor-pointer w-fit-content"
                    onClick={() => navigate("/sip-manage")}>
                    <Icon icon="arrow-right"
                        size={24}
                        color={Color.black}
                        className="e-arrow-right-180" />
                    <p className="color-black e-poppins-regular e-font-16 line-height-14px mb-0">Back</p> 
                    </div>*/}
                {
                    pageLoader === true ?
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                        :

                        (
                            sipDetailData !== null ?
                                <div className="row">
                                    <div className="col-lg-10 col-12">
                                        <div className="border-radius-24px border-all border-bright-gray p-4">
                                            <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="d-flex align-items-center gap-12px">
                                                    <div className="border-all border-bright-gray border-radius-8px p-2">
                                                        <img draggable={false} src={sipDetailData.image}
                                                            alt={sipDetailData.name}
                                                            width={36}
                                                            height={36}
                                                            className="object-fit-contain" />
                                                    </div>
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{sipDetailData.name}</p>
                                                </div>
                                                <div>
                                                    <p className="color-outer-space e-poppins-semi-bold e-font-20 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{(sipDetailData.amount).toLocaleString("en-IN")}
                                                    </p>
                                                    <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0">SIP amount</p>
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex flex-wrap justify-content-between align-items-center">
                                                <Heading heading="Last installment:"
                                                    answer={sipDetailData.last_installment} />
                                                <div className="d-flex align-items-center border-all border-primary-color border-radius-30px bg-pale-steel-blue px-3 py-2 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                    <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">Next installment &nbsp;</p>
                                                    <p className="color-primary-color e-poppins-semi-bold e-font-16 mb-0">{sipDetailData.next_sip_date}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-2 flex-wrap">
                                                <Heading heading="SIP created on:"
                                                    answer={sipDetailData.sip_created_on}
                                                    className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                                <Heading heading="First purchase:"
                                                    answer="Yes"
                                                    className="mt-lg-0 mt-md-0 mt-sm-0 mt-4" />
                                            </div>
                                            <div className="border-radius-16px bg-light-grayish-white p-3 my-4">
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <Heading heading="SIP mandate:"
                                                        answer={sipDetailData.mandate_id} />
                                                    <Heading heading="Start date:"
                                                        answer={sipDetailData.start_date}
                                                        className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                                </div>
                                                <div className="d-flex justify-content-between mt-3 flex-wrap">
                                                    <Heading heading="Bank:"
                                                        answer={`${sipDetailData.bank_name} ${sipDetailData.account_number}`} />
                                                    <Heading heading="End date:"
                                                        answer={sipDetailData.end_date}
                                                        className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                                </div>

                                            </div>
                                            <div className="border-top-1px border-bright-gray pt-3 d-flex justify-content-between algin-items-center flex-wrap">
                                                {
                                                    permissionType !== 'read' &&
                                                    <div className="d-flex align-items-center gap-16px">
                                                        {/* {
                                                            sipDetailData.status !== "Cancelled" && (sipDetailData.is_paused ?
                                                                <div className="border-all border-radius-6px border-bright-gray p-3 d-flex align-items-center gap-8px cursor-pointer" onClick={() => _generate2FA(1)}>
                                                                    {
                                                                        apiLoader ?
                                                                            <span className="e-ring-loader" />
                                                                            :
                                                                            <Icon icon="resume-sip"
                                                                                size={16} />

                                                                    }
                                                                    <p className="color-black e-poppins-medium e-font-14 mb-0">Resume SIP</p>
                                                                </div>
                                                                :
                                                                <div className="border-all border-radius-6px border-bright-gray p-3 d-flex align-items-center gap-8px cursor-pointer position-relative" onClick={() => _generate2FA(2)}>
                                                                    <Icon icon="pause"
                                                                        size={16} />
                                                                    <p className="color-black e-poppins-medium e-font-14 mb-0">Pause SIP</p>
                                                                </div>
                                                                )
                                                        } */}

                                                        {
                                                            sipDetailData.is_active && <p className="color-primary-color e-poppins-regular e-font-16 mb-0 cursor-pointer" onClick={() => _generate2FA(3)}>Cancel SIP</p>

                                                        }
                                                    </div>}
                                                {/* <GradientButton label="Invest more"
                                            className="e-font-16 px-3 padding-12px-tb mt-lg-0 mt-md-0 mt-sm-0 mt-4 e-sip-detail-invest-btn" /> */}
                                            </div>
                                        </div>

                                        <p className="color-jett-black e-poppins-medium e-font-16 my-3">Payments and other activities</p>
                                        <div className="border-all border-bright-gray pb-3 px-4 d-flex flex-wrap gap-24px border-radius-16px">
                                            <PaymentAndOtherActivities heading="Total no. of installments"
                                                answer={sipDetailData.total_number_of_installments} />
                                            <PaymentAndOtherActivities heading="Missed installments"
                                                answer={sipDetailData.missed_of_installments} />
                                            <PaymentAndOtherActivities heading="Rejected installments"
                                                answer={sipDetailData.rejected_of_installments} />
                                            <PaymentAndOtherActivities heading="Remaining installments"
                                                answer={sipDetailData.remaining_installments} />
                                        </div>


                                        {
                                            sipDetailData.installments.length !== 0 &&
                                            <div className="border-radius-24px border-all border-bright-gray p-4 mt-3" >
                                                {
                                                    sipDetailData.installments.map((item, key) =>
                                                        <div key={key}
                                                            className={`d-flex gap-8px  cursor-pointer ${sipDetailData.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(item.status === "Upcoming" ? 'e-pointer-events-none' : 'cursor-pointer')}`}
                                                            onClick={() => {
                                                                if (item.status === "Success") {
                                                                    navigate("/order-book/fund-order-detail", {
                                                                        state: {
                                                                            customer_id: userId,
                                                                            order_id: item.order_id
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                            <Icon icon={(item.status === "Upcoming" || item.status === "Pending") ? "upcoming" : item.status === "Success" ? "check-green" : "cancelled"}
                                                                size={24} />
                                                            <div className="w-100">
                                                                <div className="d-flex justify-content-between align-items-center ">
                                                                    <div>
                                                                        <div className="d-flex gap-16px align-items-center mb-2">
                                                                            <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{item.sl_no} installment: {item.status === "Upcoming" ? "Not paid yet" : item.status === "Success" ? "Paid" : "Pending"}</p>
                                                                            {
                                                                                item.status !== "Upcoming" &&
                                                                                <p className={`position-relative e-font-14 e-poppins-regular mb-0 ${style.e_sip}`}>SIP</p>
                                                                            }
                                                                        </div>
                                                                        <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{item.transaction_date}</p>
                                                                    </div>
                                                                    {
                                                                        item.status === "Upcoming" || item.status === 'Pending' ?
                                                                            <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0">{item.status}</p>
                                                                            :
                                                                            item.status === "Success" ?
                                                                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">Success</p>
                                                                                :

                                                                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                                    }
                                                                </div>


                                                                {
                                                                    item.failure_reason &&
                                                                    <div className="row mt-2">
                                                                        <div className="col-lg-10 col-md-10">
                                                                            <div className="border-radius-8px  bg-antique-white py-3 px-2 d-flex align-items-center">
                                                                                {/* <Icon icon="info"
                                                                style={{ color :'red'}}
                                                                className="color-lust"
                                                                size={24} /> */}

                                                                                <p className="color-outer-space e-poppins-regular e-font-14 line-height-22px mb-0">{item.failure_reason}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        }
                                    </div>
                                </div> :
                                <div className="col-12">
                                    <EmptyScreen/>
                                </div>
                        )
                }

                {
                    pauseSipModal === true || cancelSipModal === true || resumeSipModal === true ?
                        <ActivateSIPModal
                            loading={resumeLoader}
                            label={buttonLabel}
                            cancelSip={cancelSipModal}
                            close={() => {
                                setResumeSipModal(false);
                                setCancelSipModal(false);
                                setPauseSipModal(false);
                            }}
                            handleOTPSubmission={_confirmSIP}
                        />
                        :
                        null
                }
            </div>
        </Fragment>
    )
}

export default FundSIPDetail