/*
 *   File : sip.js
 *   Author URI : https://evoqins.com
 *   Description : SIP listing page
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

import { DataTableContainer } from "../../../../Components/FormElements"
import { CustomLoader, EmptyScreen } from "../../../../Components/Other"
import { Icon } from "../../../../Components/Icon"

import Colors from "../../../../Styles/color.module.scss"

import APIService from "../../../../Services/api-service";

const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
            color: Colors.black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
            cursor: "pointer",
            color: Colors.black,
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                // display: 'flex',
            },
            '&:first-child': {
                padding: "16px 0px 16px 16px",
                justifyContent: "start"
            }
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                // display: 'flex',
                // justifyContent: `start !important`
            },
            '&:first-child': {
                padding: "16px 0px 16px 16px",
                justifyContent: "start"
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}
const SipList = React.memo((props) => {

    const location = useLocation();
    const [sipData, setSipData] = useState({});
    const [loader, setLoader] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        _getSipData();
    }, [pageNumber, pageSize]);

    const SIP_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            // width: "450px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={_handleCardClick}>
                    <div className="padding-6px-all border-radius-8px border-all border-bright-gray">
                        <img draggable={false} src={row['image']} alt={row['name']}
                            width={46}
                            height={46}
                            className="object-fit-contain" />
                    </div>
                    <div>
                        <p className={`color-black e-poppins-medium e-font-16 line-height-16px bg-white ${row['is_goal'] === false ? "mb-2" : "mb-0"}`}>{row['name']}</p>
                        {
                            row['is_goal'] === false &&
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px">
                                {row['sip_tag']}
                            </span>
                        }
                    </div>

                </div>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={_handleCardClick}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "130px",
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    {
                        row.status === "Active" ?
                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 mt-0">
                                {row['status']}
                            </p>
                            :
                            <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 mt-0">
                                {row['status']}
                            </p>
                    }
                </div>
        },
        {
            name: 'Next SIP on',
            selector: row => row['next_sip_date'],
            sortable: true,
            width: "180px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-18px mb-0"
                    onClick={_handleCardClick}>
                    {row['next_sip_date']}
                </p>
        },
    ];

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const _handleRowHover = (row) => {

    }

    const _handleRowRemoveHover = () => {

    }


    const _handleCardClick = (row) => {
        // props.onClick(row.id);
    }

    // API - Get user data
    function _getSipData() {
        let url = 'user/sip/active-list';
        let payload = JSON.stringify({
            customer_id: location.state.user_id,
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
            } else {
                setSipData({});
            }
            setLoader(false);
        })
    }

    return (
        loader === true ?
            <div className="h-80vh">
                <CustomLoader />
            </div>
            :

            sipData !== null && sipData.fund_data.length !== 0 ?
                <div className="position-relative">
                    <DataTableContainer columns={SIP_COLUMNS}
                        data={sipData.fund_data}
                        pagination={true}
                        customStyles={TABLE_STYLE}
                        onRowMouseEnter={_handleRowHover}
                        onRowMouseLeave={_handleRowRemoveHover}
                        rowClick={_handleCardClick}
                        paginationComponentOptions={paginationData}
                        onChangeRowsPerPage={(e) => {
                            setPageSize(e);
                            setPageNumber(1);
                        }}
                        paginationTotalRows={totalLength}
                        paginationCurrentPage={pageNumber}
                        paginationPerPage={pageSize} />
                    <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                        <Icon icon="pagination-previous" width="24px" height="24px"
                            className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                            onClick={_previousPage} />
                        <Icon icon="pagination-next" width="24px" height="24px"
                            className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                            onClick={_nextPage} />
                    </div>
                </div>
                :
                <EmptyScreen title="Empty sip data!" />
    )
})

export default SipList