import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.min.js";
import './Styles/styles.scss';
import './Styles/global.scss';
import './Styles/responsive.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import reportWebVitals from './reportWebVitals';

import App from './App';
import Store from './Store';

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === 'PROD') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
