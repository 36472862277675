
/*
 *   File : sip-dates.js
 *   Author URI : https://evoqins.com
 *   Description : Date picker component for sip date
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect, useRef, useMemo } from 'react';

import { GradientButton } from '../Cta';
import Icon from '../Icon/icon';

import inputStyle from '../../Styles/Components/input.module.scss';

const SIPDate = (props) => {

    const { date, selectedDate, label } = props;
    const [openDatePanel, setOpenDatePanel] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [confirmedDate, setConfirmedDate] = useState();
    const datePickerRef = useRef(null);
    const [datesAllowed, setDatesAllowed] = useState(props.enabledDates);
    const [monthSelected, setMonthSelected] = useState(props.monthString);

    useEffect(()=>{
        if (props.monthString !== "") {
            setMonthSelected(props.monthString);
        }
    },[props.monthString]);

    // Validation and handling of props.date
    useEffect(() => {
        if (date) {
            const dates = date.split(",");
            if (dates.length > 0 && !isNaN(parseInt(dates[0]))) {
                console.log(dates);
                setDateSelected(parseInt(dates[0]));
                setConfirmedDate(parseInt(dates[0]))
            }
        }
    }, [date, selectedDate]);

    useEffect(() => {
        if (props.enabledDates) {
            let sip_dates = props.enabledDates;
            // const formatted_sip_dates = sip_dates => sip_dates.map(Number);
            let processed_sip_dates = [];
            for (let i = 0; i < sip_dates.length; i++) {
                processed_sip_dates.push(Number(sip_dates[i]));
            }
            setDatesAllowed(processed_sip_dates);
        }
    }, [props.enabledDates])


    // Memoized date options generation
    const dateOptions = useMemo(() => {
        const temp = [];
        for (let index = 1; index <= 28; index++) {
            temp.push(
                <span key={index}>{index}</span>
            )
        }
        return temp;
    }, []);

    // input focus
    const _handleFocus = () => {
        setOpenDatePanel(true);
        // Scroll to the top of the Investment Goal Modal
        // const modalElement = ReactDOM.findDOMNode(props.modalRef.current);
        // if (modalElement) {
        //     modalElement.scrollTop = 0;
        // }
    };

    // hide date picker
    const _handleBlur = () => {
        setOpenDatePanel(false);
    };

    //Update selected date
    const handleDateSelection = (index) => {
        setDateSelected(index.key);
    };

    // render date picker title
    const renderTitle = () => {
        console.log("selected date", dateSelected);
        // no date selected
        if (!confirmedDate) {
            return <h5 className='color-black e-poppins-medium e-font-20 '>Choose a date</h5>
        }
        if (confirmedDate > 3 && confirmedDate < 21) {//return date+th if selected date bw 3 to 21
            return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}th of every month`}</h5>;
        }
        switch (confirmedDate % 10) { // get reminder of selected date 
            case 1: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}st of every month`}</h5>; // return date+st;
            case 2: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}nd of every month`}</h5>; // return date+nd;
            case 3: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}rd of every month`}</h5>; // return date+rd
            default: return <h5 className='color-black e-poppins-medium e-font-20 '>{`${confirmedDate}th of every month`}</h5>;// return date+th
        }
    };

    const formatValue = () => {
        if (!confirmedDate) {
            return;
        } else {
            const teen = confirmedDate % 100;
            console.log('dateText', props.dateText);
            if (props.dateText == "") {
                switch (true) {
                    case teen >= 11 && teen <= 13:
                        return `${confirmedDate}th of every month`;
                    case confirmedDate % 10 === 1:
                        return `${confirmedDate}st of every month`;
                    case confirmedDate % 10 === 2:
                        return `${confirmedDate}nd of every month`;
                    case confirmedDate % 10 === 3:
                        return `${confirmedDate}rd of every month`;
                    default:
                        return `${confirmedDate}th of every month`;
                }
            }else{
                const current_date = new Date();
                const month = current_date.toLocaleString('default', { month: 'short' });
                switch (true) {
                    case teen >= 11 && teen <= 13:
                        return ` ${props.dateText} ${confirmedDate}th ${monthSelected}`;
                    case confirmedDate % 10 === 1:
                        return `${props.dateText} ${confirmedDate}st ${monthSelected}`;
                    case confirmedDate % 10 === 2:
                        return `${props.dateText} ${confirmedDate}nd ${monthSelected}`;
                    case confirmedDate % 10 === 3:
                        return `${props.dateText} ${confirmedDate}rd ${monthSelected}`;
                    default:
                        return `${props.dateText} ${confirmedDate}th ${monthSelected}`;
                }
            }
            
        }
    };

    function _confirmDate() {
        const today = new Date();
        let month = today.getMonth();
        let next_month = month + 1;
        let month_string;

        // Make sure to handle the case where next_month is December (11)
        if (today.getDate() > parseInt(dateSelected)) {
            // Increment month by one, and wrap around if it's December
            if (next_month > 11) {
                next_month = 0; // January of the next year
            }
            month_string = new Date(today.getFullYear(), next_month).toLocaleString('en-US', { month: 'short' });
        } else {
            month_string = today.toLocaleString('en-US', { month: 'short' });
        }
        setMonthSelected(month_string);
        setConfirmedDate(dateSelected);
        setOpenDatePanel(false);
        props.dateSelected(dateSelected)
    }


    return (
        <div className={` position-relative`} ref={datePickerRef}>
            {/* Input */}
            <div className={`${props.className} ${inputStyle.e_input_wrapper} position-relative w-100`}>
                <input value={formatValue()}
                    id="sip-date"
                    type='text'
                    readOnly={true}
                    className={`${inputStyle.e_sip_input} ${props.inputClass} padding-16px-tb color-charleston-green e-poppins-regular e-font-16 w-100 no-outline border-radius-12px`}
                    onFocus={_handleFocus}
                    required
                />
                {/* label */}
                <label className={`bg-white position-absolute left-15px e-poppins-regular e-font-14 cursor-text py-0 padding-0-left padding-30px-right ${props.labelClass}`}
                    htmlFor='sip-date'>
                    {label}
                    <span className='color-red'>*</span>
                </label>

                <Icon icon="calendarIcon"
                    size={24}
                    className="position-absolute right-20px top-16px"
                    onClick={_handleFocus} />
            </div>

            {/* Drop down */}
            {
                openDatePanel ?
                    <>
                        <div className='position-fixed w-100 h-100 bg-jet-black opacity-7 z-index-9 top-0 left-0'></div>
                        <div className={`bg-white border-radius-8px pt-4 position-absolute left-0 margin-12px-top border-all border-bright-gray z-index-10 e-sip-date-picker-panel`} >
                            {/* Pane title */}
                            <div className={` text-center`}>
                                {renderTitle()}
                                <Icon icon="close"
                                    size={24}
                                    className="position-absolute top-18px right-20px cursor-pointer e-modal-close-btn"
                                    onClick={_handleBlur} />
                            </div>

                            {/* Dates */}
                            <div className='text-center'>
                                {
                                    dateOptions.map((index) => {
                                        let enabled = datesAllowed.includes(Number(index.key));
                                        return (
                                            <span className={` d-inline-flex justify-content-center cursor-pointer padding-7px-top w-50px h-39px e-sip-date-picker-date e-poppins-regular e-font-16 ${(enabled == false && 'e-disabled-date no-events')} ${dateSelected == (Number(index.key)) ? 'bg-vivid-cerulean color-white ' : 'color-black '}`}
                                            key={index}
                                                onClick={() => handleDateSelection(index)}>
                                                {index.key}
                                            </span>
                                        )
                                    })
                                }
                            </div>

                            {/* Confirm button */}
                            <div className='text-center mt-3'>
                                <GradientButton label="Confirm"
                                    className="padding-10px-tb padding-50px-lr"
                                    onPress={_confirmDate} />
                            </div>

                        </div>
                    </>
                    :
                    null
            }
        </div>
    );
};

SIPDate.defaultProps ={
    dateText: "",
    monthString: ""
};

export default SIPDate;
