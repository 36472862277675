/*
 *   File : fund-order-details.js
 *   Author URI : www.evoqins.com
 *   Description : Order details for Fund.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 12-12-2023
 */

/* import packages */
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

/* import components */
import { NavigationHeader } from "../../Components/Header";
import Icon from "../../Components/Icon/icon";
import { SectionTitle } from "../../Components/Title";
import { GradientButton, SecondaryButton } from "../../Components/Cta";
import { ApproveKYCModal, RejectKYCModal, FileViewer } from "../../Components/Modal";
import { BreadCrumb } from "../../Components/Breadcrumb";

/* import styles */
import Color from "../../Styles/color.module.scss"
import { CustomLoader } from "../../Components/Other";
import APIService from "../../Services/api-service";
import { _manageKyc } from "../../Helper/api";

const PAGE_NAVIGATION = [
    'Dashboard',
    'Onboarding',
    'Uploaded details'
];


const OnboardingDetail = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [approveModal, setApproveModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [detail, setDetail] = useState({});
    const [reasonList, setReasonList] = useState([]);
    const [rejectCustomerId, setRejectCustomerId] = useState(null)
    const [loader, setLoader] = useState(true);
    const [file, setFile] = useState('');
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _getOnboardingDetail()
        }
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
        //eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (approveModal === true) {
            const modal = new Modal(document.getElementById("kyc-success"));
            modal.show();
        }
    }, [approveModal]);

    useEffect(() => {
        if (rejectModal === true) {
            const modal = new Modal(document.getElementById("reject-kyc"));
            modal.show();
        }
    }, [rejectModal]);

    useEffect(() => {
        if (openFileViewer === true) {
            const modal = new Modal(document.getElementById("file-viewer"));
            modal.show();
        }
    }, [openFileViewer]);

    const _handleApproveModal = () => {
        setApproveLoader(true);
        const data = {
            "customer_id": location.state.customer_id,
            "is_approve": true,
            "reason_id": null,
            "reason": null
        }
        _manageKyc(data).then((response) => {
            setApproveModal(true)
        }).catch((response) => {
            setApproveLoader(false);
            _getOnboardingDetail();
            toast.dismiss();
            toast.error(response, {
                type: "error"
            });
        });
    }

    const _handleRejectModal = () => {
        setRejectModal(true);
        setRejectLoader(true);
        setRejectCustomerId(location.state.customer_id)
    }

    const _getOnboardingDetail = () => {
        let url = 'onboarding/kyc/detail';
        let payload = JSON.stringify({
            customer_id: location.state.customer_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            _getReasonList();
        })
    }

    const _getReasonList = () => {
        let url = 'onboarding/kyc/reason';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map(item => {
                    return {
                        id: item.id,
                        name: item.label
                    }
                })
                setReasonList(modified_data);
            }
            setLoader(false);
        })
    }

    const _extractFileNameFromUrl = (url) => {
        const match = url.match(/\/([^\/?#]+)[^\/]*$/);
        return match ? match[1] : null;
    };

    const _getFileType = (url) => {
        let path = url.split('?')[0]; 
        let filename = path.split('/').pop();
        let extension = filename.split('.').pop().toLowerCase();
        return extension;
    }

    const _handlePreviewImage = (url, type) => {
        // window.open(url, "_blank")
        type = type === true ? 1 : 2;
        setFile({ url, type });
        setOpenFileViewer(true);
    }

    function _callbackFileViewerModalClose() {
        setFile("");
        setOpenFileViewer(false);
    }

    const FilePreview = (props) => {
        return (
            <div className="d-flex align-items-center justify-content-between border-radius-8px border-all-dashed bg-pale-baby-blue px-3 padding-10px-tb cursor-pointer border-primary-color mt-4">
                <div className="d-flex align-items-center">
                    {
                        props.pdf !== true && (
                            <img draggable={false} src={props.image}
                                alt="preview"
                                width={24}
                                height={24}
                                className="me-2 object-fit-contain" />
                        )
                    }

                    <div>
                        {
                            props.image !== null && (
                                <p className="border-radius-8px d-block bg-pale-baby-blue color-jett-black e-font-14 e-poppins-regular line-height-22px  mb-1 overflow-wrap-anywhere">
                                    {_extractFileNameFromUrl(props.image)}
                                </p>
                            )
                        }

                        <p className="color-outer-space d-block e-font-8 mb-0">
                            File size: {props.fileSize}
                        </p>
                    </div>
                </div>
                <span>
                    <Icon icon={"eye"} alt="Preview"
                        size={24}
                        className="margin-6px-left cursor-pointer"
                        onClick={() => _handlePreviewImage(props.image, props.pdf)} />
                </span>
            </div>
        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>

                {/* Header */}
                <NavigationHeader type={1}
                    title="Uploaded details" />

                {/* Breadcrumb */}
                <div className="e-fund-order-summary ps-4 pe-lg-5 pe-md-2 pe-2 pt-2">
                    <BreadCrumb data={PAGE_NAVIGATION} />
                    {/* <div className="d-flex align-items-center gap-8px padding-12px-all mb-3 cursor-pointer w-fit-content"
                        onClick={() => navigate("/manage-onboarding")}>
                        <Icon icon="arrow-right"
                            size={24}
                            color={Color.black}
                            className="e-arrow-right-180" />
                        <p className="color-black e-poppins-regular e-font-16 line-height-14px mb-0">Back</p>
                    </div> */}
                    <div className="row">
                        <div className="col-xl-8">

                            <div className="row">
                                <div className="col-md-12">

                                    {/*=================== PAN detail ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px">

                                        <SectionTitle title="PAN details" />
                                        {
                                            detail.kyc_step >= 1 ?
                                                <div className="row pt-4">
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            PAN
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.pan_details.pan}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            DOB
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.pan_details.date_of_birth_str}
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }

                                    </div>

                                    {/*=================== DIgilocker detail ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Digilocker information" />

                                        {
                                            detail.kyc_step >= 2 ?
                                                <div className="row pt-4">
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Aadhaar number
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.kra_info.aadhaar}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Name as on Aadhaar
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.kra_info.name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Nationality
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.kra_info.nationality === true ? "Indian" : null}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Address
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-24px color-light-black my-0 word-wrap">
                                                            {detail.kra_info.address}
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>

                                    {/*=================== Additional detail ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Additional information" />

                                        {
                                            detail.kyc_step >= 3 ?
                                                <div className="row pt-4">
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Fathers name
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.additional_info.father_name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Gender
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.additional_info.gender_text} 
                                                            {
                                                                detail.additional_info.gender_mismatch === true &&
                                                                <span className="e-tool-tip-container position-relative"  
                                                                    data-tooltip-content="Gender is not same as the value received from Digilocker">
                                                                    ⚠️
                                                                </span>
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Marital status
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.additional_info.marital_status_text}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Occupation
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.additional_info.occupation_text}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Annual income
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.additional_info.income_text}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Signature status
                                                        </h6>
                                                        {
                                                            Object.keys(detail.additional_info).length !== 0 && detail.additional_info.signature_status !== null &&
                                                            <div className="col-8">
                                                                <FilePreview image={detail.additional_info.signature_status}
                                                                    fileSize={detail.additional_info.file_size} />
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>

                                    {/*=================== Declarations , T&C ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Declarations" />

                                        {
                                            detail.kyc_step >= 4 ?
                                                <div className="row pt-4">
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Tax payee in India
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.declaration.tax_payer_in_india === true ? "Yes" : "No"}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Politically exposed
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {!detail.declaration.politically_exposed === true ? "Yes" : "No"}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Terms & conditions
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.declaration.terms_and_condition === true ? "Checked" : "Unchecked"}
                                                        </p>
                                                    </div>

                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>

                                    {/*=================== Bank details ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Bank details" />
                                        {
                                            detail.kyc_step >= 5 ?
                                                <div className="row pt-4">
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Bank name
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.bank_details.bank_name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Account holder name
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.bank_details.account_holder_name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Account number
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.bank_details.account_number}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            Account type
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.bank_details.account_type_text}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                            IFSC code
                                                        </h6>
                                                        <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                            {detail.bank_details.ifsc_code}
                                                        </p>
                                                    </div>

                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>

                                    {/*=================== Nominee details ======================= */}

                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Nominee" />
                                        {
                                            detail.skip_nominee === false ?
                                                detail.kyc_step >= 6 ?

                                                    detail.nominees.map((item, key) =>
                                                        <div className="row pt-4" key={key}>
                                                            <div className="col-6">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Nominee name
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.name}
                                                                </p>
                                                            </div>
                                                            <div className="col-6">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Relation
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.relationship}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Allocation percentage
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.allocation_text}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Date of birth
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.date_of_birth_str}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Guardian name
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.guardian_name}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Guardian relationship
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.guardian_relationship}
                                                                </p>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                    Guardian pan number
                                                                </h6>
                                                                <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                    {item.guardian_pan}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    )
                                                    :
                                                    <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Opted out</p>
                                        }
                                    </div>

                                    {/*=================== E-sign ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Signature" />
                                        {
                                            detail.kyc_step >= 7 ?
                                                Object.keys(detail.additional_info).length !== 0 && detail.additional_info.signature_status !== null &&
                                                <div className="col-8">
                                                    <FilePreview image={detail.additional_info.signature_status}
                                                        fileSize={detail.additional_info.file_size} 
                                                        pdf={_getFileType(detail.additional_info.signature_status) === 'pdf' ? true : false}/>
                                                </div>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>

                                    {/*=================== Selfie ======================= */}
                                    <div className="bg-light-sky-blue p-3 border-radius-8px mt-3">

                                        <SectionTitle title="Selfie capture" />
                                        {
                                            detail.kyc_step >= 8 ?
                                                detail.selfie_captured !== null &&
                                                <>
                                                    <div className="col-8">
                                                        <FilePreview image={detail.selfie_captured}
                                                            fileSize={detail.selfie_file_size} />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3 mt-4">
                                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                Latitude
                                                            </h6>
                                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                {detail.selfie_captured_object.latitude}
                                                            </p>
                                                        </div>
                                                        <div className="col-3 mt-4">
                                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                Longitude
                                                            </h6>
                                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                {detail.selfie_captured_object.longitude}
                                                            </p>
                                                        </div>
                                                        <div className="col-6 mt-4">
                                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                                Address
                                                            </h6>
                                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                                {detail.selfie_captured_object.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <p className="e-poppins-regular color-outer-space e-font-14 line-height-14px mb-0 mt-3 ">Yet to complete</p>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-xl-4">
                            {/*=================== Approve or reject kyc ======================= */}
                            {
                                detail.kyc_step >= 8 && (
                                    <div className="border-radius-16px bg-light-lotion p-3">
                                        <SectionTitle title="Additional details" />

                                        <div className="col-12 padding-32px-top">
                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                Details updated on
                                            </h6>
                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black my-0">
                                                {detail.kyc_submission_details.detail_submitted_at_date}
                                            </p>
                                        </div>
                                        <div className="col-12 margin-22px-top ">
                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black mb-0">AOF</p>
                                            <FilePreview image={detail.kyc_submission_details.aof}
                                                pdf={true}
                                                fileSize={detail.kyc_submission_details.aof_file_size} />
                                            <p className="e-font-16 e-poppins-medium line-height-16px color-light-black mt-4 mb-0">CVL agreement</p>
                                            <FilePreview image={detail.kyc_submission_details.cvl_agreement}
                                                pdf={true}
                                                fileSize={detail.kyc_submission_details.cvl_agreement_file_size} />
                                            {/* <div className="d-flex">
                                                <img draggable={false} src={require("../../Assets/Images/Onboarding/agreement.svg").default}
                                                    alt="preview"
                                                    className="me-2" />
                                                <p className="border-radius-8px d-block bg-pale-baby-blue  color-jett-black e-font-14 e-poppins-regular line-height-14px  mb-0">
                                                    Uploaded agreements
                                                    <p className="color-outer-space d-block e-font-8 mb-0">
                                                        File size: 1.4 mb
                                                    </p>
                                                </p>
                                            </div>
                                            <Icon icon={"eye"} alt="delete"
                                                size={24}
                                                className="margin-6px-left cursor-pointer"
                                            /> */}
                                        </div>
                                        <div className="col-12 margin-22px-top">
                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                Agreements uploaded status
                                            </h6>
                                            <p className="e-font-16 e-poppins-medium line-height-24px color-light-black my-0">
                                                {detail.kyc_submission_details.agreement_uploaded_status}
                                            </p>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                Approval status
                                            </h6>
                                            <p className={`e-font-16 e-poppins-medium line-height-16px my-0 ${detail.kyc_submission_details.approval_status !== 'Verified' ? 'color-yellow-orange' : 'color-sea-green'}`}>
                                                {detail.kyc_submission_details.approval_status}
                                            </p>
                                        </div>
                                        {
                                            detail.kyc_submission_details.reason_summary &&
                                            <div className="col-12 mt-4">
                                                <h6 className="color-outer-space e-font-16 e-poppins-regular line-height-16px mb-2">
                                                    Reason summary
                                                </h6>
                                                <p className={`e-font-16 e-poppins-medium line-height-16px my-0 `}>
                                                    {detail.kyc_submission_details.reason_summary}
                                                </p>
                                            </div>
                                        }
                                        {
                                            detail.can_approve === true &&
                                            <div className="col-12 d-flex padding-40px-tb">
                                                <SecondaryButton label="Reject"
                                                    loading={rejectLoader}
                                                    onPress={_handleRejectModal} />
                                                <GradientButton label="Approve"
                                                    className='ms-3'
                                                    loading={approveLoader}
                                                    onPress={_handleApproveModal} />
                                            </div>
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </div>


                </div >

                {
                    approveModal === true ?
                        <ApproveKYCModal
                            close={() => {
                                _getOnboardingDetail();
                                setApproveModal(false);
                                setApproveLoader(false);
                            }} />
                        :
                        null
                }
                {
                    rejectModal === true ?
                        <RejectKYCModal data={reasonList}
                            customerId={rejectCustomerId}
                            email={detail.email}
                            mobile={detail.mobile}
                            close={() => {
                                setRejectLoader(false);
                                _getOnboardingDetail();
                                setRejectModal(false);
                            }} />
                        :
                        null
                }

                {
                    openFileViewer === true &&
                    <FileViewer data={file}
                        close={_callbackFileViewerModalClose} />
                }
            </Fragment >
    )
}

export default OnboardingDetail 