
/*
 *   File : notification.js
 *   Author URI : https://evoqins.com
 *   Description : Notification card
 * Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { Fragment, React } from "react";
import { Icon } from "../Icon";

/*import components*/

import style from "../../Styles/Components/notification.module.scss"

const NotificationCard = (props) => {

    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className={`border-radius-8px border-all p-4 d-flex position-relative cursor-pointer .align-items-flex-start ${props.data.is_read === false ? "border-pearl-aqua bg-light-pastel-blue-gray cursor-pointer" : "border-bright-gray"}`}
                        onClick={() => props.notificationClick()}>

                        <div className="ms-1 w-100">
                            <div className="d-flex justify-content-between align-items-center margin-12px-bottom mt-1">
                                <p className="color-light-black e-poppins-medium e-font-16 line-height-16px mb-0">{props.data.title}</p>
                                <p className="color-gray e-poppins-medium e-font-10 mb-0 opacity-6">{props.data.created}</p>
                            </div>
                            <p className="color-gray e-poppins-medium e-font-14 line-height-22px mb-0">{props.data.message}</p>

                        </div>

                    </div>
                    :
                    <div className="border-radius-8px bg-light-grayish-white p-2 mb-2">
                        <div className="row">
                            <div className="col-3">
                                <img draggable={false} src={props.data.preview_image}
                                    alt="preview"
                                    height={86}
                                    width={"100%"} />
                            </div>
                            <div className="col-9 ps-0">
                                <span className="e-font-12 color-gray e-poppins-regular line-height-24px mb-1">
                                    {props.data.date}
                                </span>
                                <h6 className="e-font-14 color-light-black e-poppins-semi-bold line-height-20px mb-2">
                                    {props.data.heading}
                                </h6>
                                <p className="e-font-14 color-outer-space e-poppins-regular line-height-20px mb-2">
                                    {props.data.content}
                                </p>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>
    )
}

NotificationCard.defaultProps = {
    data: {
        date: "",
        heading: "",
        content: "",
        preview_image: null,
    }
}

export default NotificationCard;