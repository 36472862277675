/*
 *   File : manage-content.js
 *   Author URI : https://evoqins.com
 *   Description : Manage Content page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

/* import containers */
import Privacy from "./privacy";
import Terms from "./terms";
import Disclaimers from "./disclaimers";
import InfoList from "./info-list";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { CustomTab } from "../../../Components/Tab";
import { SectionTitle } from "../../../Components/Title";
import { GradientButton } from "../../../Components/Cta";
import { QuizAccordion } from "../../../Components/Accordion";
import { DeleteConfirmationModal, ManageFAQ } from "../../../Components/Modal";
import { CustomLoader } from "../../../Components/Other";

/* import services */
import APIService from "../../../Services/api-service";


const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage content"
]


const CONTENT_TABS = [
    {
        label: `Manage "i" info`,
        type: 'info',
        id: 1
    },
    {
        label: `FAQ`,
        type: 'faqs',
        id: 2
    },
    {
        label: `Terms & conditions`,
        type: 'terms_and_conditions',
        id: 3
    },
    // {
    //     label: `Privacy policy`,
    //     type: 'policies',
    //     id: 4
    // },
    // {
    //     label: `Disclaimers`,
    //     type: 'disclaimers',
    //     id: 5
    // },
]



const ManageContent = React.memo(() => {
    const [tabIndex, setTabIndex] = useState(1);
    const [faqItem, setFaqItem] = useState(null);
    const [faqDetail, setFaqDetail] = useState(null);
    const [pageLoader, setPageLoader] = useState(true);
    const [infoList, setInfoList] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [terms, setTerms] = useState(null);
    const [disclaimers, setDisclaimers] = useState(null);
    const [policies, setPolicies] = useState(null);
    const [tabData, setTabData] = useState([]);
    const [openFaq, setOpenFaq] = useState(false);

    // list content
    useEffect(() => {
        _listContents();
    }, []);

    // open FAQ modal
    useEffect(() => {
        if (openFaq === true) {
            _openFaqModal();
        }
    }, [openFaq]);

    // listener remove faq modal 
    useEffect(() => {
        if (faqItem !== null) {
            _openConfirmation();
        }
    }, [faqItem])

    // handle tab
    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
    }

    // add new faq
    function _handleFaq() {
        setOpenFaq(true);
    }

    // open delete confirmation
    function _openConfirmation() {
        const modal = new Modal(document.getElementById("delete"));
        modal.show()
    }

    // open faq modal
    function _openFaqModal() {
        const modal = new Modal(document.getElementById("manage-faq"));
        modal.show()
    }

    // Remove FAQ 
    function _removeFAQData(faq) {
        setFaqItem(faq)
    }

    // Update faq
    function _updateFAQ(faq) {
        setOpenFaq(true)
        setFaqDetail(faq);
    }

    // API - list content 
    const _listContents = () => {
        const url = "content/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setInfoList(response.data.info);
                if (response.data.faqs !== null) {
                    setFaqs(response.data.faqs);
                } else {
                    setFaqs([]);
                }
                if (response.data.terms_and_conditions !== null) {
                    setTerms(response.data.terms_and_conditions);
                }

                if (response.data.policies !== null) {
                    setPolicies(response.data.policies);
                }
                if (response.data.disclaimers !== null) {
                    setDisclaimers(response.data.disclaimers);
                }
            } else {
                setInfoList([]);
                setFaqs([]);
                setTerms(null);
                setPolicies(null);
                setDisclaimers(null);
            }
            setPageLoader(false);
            let content_tabs = [];
            const response_tabs = Object.keys(response.data);
            const common_array = CONTENT_TABS.filter(tab => response_tabs.includes(tab.type));
            common_array.map((item) => {
                return (
                    content_tabs.push({
                        id: item.id,
                        type: item.label
                    })
                )
            })
            setTabData(content_tabs)


        })
    }

    return (
        <Fragment>

            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage content" />
            {
                pageLoader === true ?
                    <div className="h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top ">
                        <Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <CustomTab type={2}
                                        index={tabIndex}
                                        data={tabData}
                                        onSelectTab={_handleTabIndex} />
                                </div>
                            </div>
                            {
                                tabIndex === 1 ?
                                    // Tab for manage 'i' info
                                    <InfoList data={infoList} onUpdateInfo={_listContents} />
                                    :
                                    tabIndex === 2 ?
                                        // Tab for manage faq
                                        <div className="">
                                            <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                <div className="col-12 d-flex justify-content-between mb-3">
                                                    <SectionTitle title="Added questions" />
                                                    <GradientButton label="Add new question"
                                                        onPress={_handleFaq} />
                                                </div>
                                                {faqs.length !== 0 ? <div className="col-12 padding-34px-top">
                                                    <QuizAccordion data={faqs}
                                                        type={1}
                                                        removeData={_removeFAQData}
                                                        updateFaq={_updateFAQ} />
                                                </div>
                                                    :
                                                    <span className="color-black e-font-14 e-poppins-regular me-2 mt-5 ">No Data Found </span>
                                                }
                                            </div>
                                        </div>
                                        :
                                        // Manage terms and conditions
                                        tabIndex === 3 ?
                                            <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <SectionTitle title="Terms and conditions" />
                                                    {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                </div>
                                                <Terms data={terms} termsAdded={_listContents} />
                                            </div>
                                            :
                                            // Manage privacy policy
                                            tabIndex === 4 ?
                                                <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                    <div className="col-12 d-flex justify-content-between">
                                                        <SectionTitle title="Privacy policy" />
                                                        {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                    </div>
                                                    <Privacy data={policies} policiesAdded={_listContents} />
                                                </div>
                                                :
                                                // Manage disclaimer
                                                <div className="border-all border-bright-gray border-radius-16px padding-24px-all margin-24px-top">
                                                    <div className="col-12 d-flex justify-content-between">
                                                        <SectionTitle title="Disclaimers" />
                                                        {/* <span className="e-poppins-semi-bold e-font-14 line0height-14px color-quick-silver"> Updated on: 12 MAR 2020 10:00 AM</span> */}
                                                    </div>
                                                    <Disclaimers data={disclaimers} disclaimerAdded={_listContents} />
                                                </div>
                            }
                        </Fragment>
                    </div>
            }
            {
                faqItem !== null ?
                    <DeleteConfirmationModal content={`Are you sure you want to delete this FAQ ?`}
                        data={faqItem}
                        close={() => {
                            setFaqItem(null);
                        }}
                        confirmDelete={() => {
                            setFaqItem(null);
                            _listContents()
                        }} />
                    :
                    null
            }
            {
                openFaq === true ?
                    <ManageFAQ
                        close={() => {
                            setOpenFaq(false);
                            setFaqDetail(null);
                        }}
                        data={faqDetail}
                        faqAdded={() => {
                            _listContents();
                            setOpenFaq(false);
                        }} />
                    :
                    null
            }
        </Fragment>
    )
})

export default ManageContent;