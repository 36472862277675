/*
 *   File : delete.js
 *   Author URI : https://evoqins.com
 *   Description : UI element which asks for confirmation while removing funds.
 *   Integrations : null
 *   Version : v1.1
 */
/* import packages */
import { useEffect } from "react";

/* import components */
import { Icon } from "../Icon";

/* import styles */
import style from "../../Styles/Components/delete.module.scss";

/* import helper */

const ViewContent = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("view-content");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    // const _handleDelete = () => {
    //     closeModal('view-content');
    //     navigate("/onboarding");
    // }

    return (
        <div className={`modal fade ${style.e_approve_kyc}`}
            id="view-content"
            tabIndex="-1"
            aria-labelledby="view-content"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                {
                    props.type === 1 ?
                        <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                            <div className="col-12 d-flex justify-content-between">
                                <h3 className="color-black e-poppins-semi-bold e-font-18">
                                    Information content
                                </h3>
                                <Icon icon="close"
                                    size={24}
                                    className="cursor-pointer ms-auto e-modal-close-btn"
                                    data-bs-dismiss="modal" />
                            </div>


                            <div className="col-12">
                                <p className="color-outer-space e-poppins-regular e-font-16 line-height-24px mt-3 margin-32px-bottom text-start" dangerouslySetInnerHTML={{ __html: props.data.content }}>
                                </p>
                            </div>

                        </div>
                        :
                        <div className={`modal-content bg-white text-start d-flex align-items-center padding-32px-all ${style.e_content} `}>
                            <div className="col-12 d-flex justify-content-between">
                                <h3 className="color-black e-poppins-semi-bold e-font-18">
                                    Notification content
                                </h3>
                                <Icon icon="close"
                                    size={24}
                                    className="cursor-pointer ms-auto e-modal-close-btn"
                                    data-bs-dismiss="modal" />
                            </div>


                            <div className="col-12">
                                <h6 className="color-eerie-black e-poppins-medium e-font-18 mt-3 mb-3 text-start">
                                    {props.data.message}
                                </h6>
                                <p className="color-outer-space e-poppins-regular e-font-14  mb-4 text-start">
                                    {props.data.content}
                                </p>
                            </div>
                            <img draggable={false} src={props.data.preview_image}
                                alt="preview"
                                className="w-100 " />

                        </div>
                }
            </div>
        </div>
    )
}


ViewContent.defaultProps = {
    close: () => { }
}

export default ViewContent