/*
 *   File : create-notification.js
 *   Author URI : https://evoqins.com
 *   Description : Create notification page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";


/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { CustomTab } from "../../../Components/Tab";

import { NotificationFilterPopup } from "../../../Components/Popups";

import CreateNotification from "./create-notification";
import NotificationLog from "./notification-log";



// tab data
const NOTIFICATION_TABS = [
    "Send notification",
    "Notification send log  "
]

// filter options for list
const FILTER_BY = [{
    value: null,
    label: "All"
},
{
    value: true,
    label: "Transactions"
},
{
    value: false,
    label: "Non-transactions"
}]

const ManageNotification = React.memo(() => {
    const [tabIndex, setTabIndex] = useState(1);
    const [filterBy, setFilterBy] = useState(FILTER_BY[0]);


    // handle tab index
    const _handleTabIndex = (tab_index) => {
        setTabIndex(tab_index);
    }

    // handler for filter dropdown
    const _handleFilter = (value) => {
        setFilterBy(value);
    }

    return (
        <Fragment>
            {/* header */}
            <NavigationHeader
                type={1}
                title="Notifications" />

            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row">
                    <div className="col-11">
                        <CustomTab type={1}
                            index={tabIndex}
                            data={NOTIFICATION_TABS}
                            onSelectTab={_handleTabIndex} />

                    </div>
                    <div className="col-1">
                        {/* Drop down filter */}
                        {
                            tabIndex === 2 ?
                                <NotificationFilterPopup value={filterBy}
                                    options={FILTER_BY}
                                    onSelectChange={_handleFilter} />
                                :
                                null
                        }
                    </div>


                </div>
                {
                    tabIndex === 1 ?
                        <CreateNotification />
                        :
                        // notification table
                        <NotificationLog />

                }

            </div>

        </Fragment>
    )
})

export default ManageNotification;