/*
 *   File :  portfolio-goals.js
 *   Author URI : www.evoqins.com
 *   Description : Table component which lists invested goals in the UI
 *   Integrations : null
 *   Version : v1.0
 *   Created : 09-11-2023
 */
/* import packages */
import { useState } from "react";

/* import components */
import { DataTableContainer } from "../FormElements"
import Icon from "../Icon/icon";

/* import styles */
import Colors from "../../Styles/color.module.scss"


const PortfolioGoalsTable = (props) => {

    const [showArrow, setShowArrow] = useState(0);

    const GOAL_COLUMNS = [
        {
            name: 'Fund name',
            selector: row => row['name'],
            sortable: true,
            width: "450px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="padding-6px-all border-radius-8px border-all border-bright-gray">
                        <img draggable={false} src={row['image']} alt={row['name']}
                            width={46}
                            height={46}
                            className="object-fit-contain" />
                    </div>
                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 bg-white">{row['name']}</p>
                </div>
        },
        {
            name: 'Invested',
            selector: row => row['invested_amount'],
            sortable: true,
            maxWidth: '180px',
            cell: row =>
                <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current',
            selector: row => row['current_amount'],
            sortable: true,
            // width: "150px",
            maxWidth: '150px',
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Total units',
            selector: row => row['units'],
            sortable: true,
            maxWidth: '150px',
            cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-18px mb-0"
                onClick={() => _handleCardClick(row)}>{row['units']}</p>
        },
        {
            name: 'Returns',
            selector: row => row['returns'],
            sortable: true,
            maxWidth: '180px',
            cell: row => <p className={`${row['returns_percentage'] < 0 ? 'color-red' : 'color-sea-green'} e-poppins-medium e-font-16 line-height-18px mb-0`}
                onClick={() => _handleCardClick(row)}>{row['returns'].toLocaleString('en-IN')}({row['returns_percentage']}%)</p>

        },
        {
            name: '',
            sortable: true,
            width: "24px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>
        }

    ];

    const TABLE_STYLE = {
        table: {
            style: {
                padding: "0 16px 16px 16px",
                background: Colors.white,
            },
        },
        tableBody: {
            style: {
                margin: '0px',
                marginTop: "0px !important"
            }
        },
        headRow: {
            style: {
                background: 'transparent',
                borderRadius: "16px 16px 0px 0px",
                border: "0",
                borderBottom: `1px solid ${Colors.bright_gray} !important`,
                fontSize: "16px",
                fontFamily: "Poppins-Medium",
                lineHeight: "18px",
                color: Colors.black
            }
        },
        rows: {
            style: {
                margin: "0",
                background: "transparent",
                border: `1px solid ${Colors.bright_gray} !important`,
                borderRadius: "16px",
                marginTop: "14px",
                fontSize: "16px",
                fontFamily: "Poppins-Medium",
                lineHeight: "18px",
                cursor: "pointer",
                color: Colors.black,
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "16px 0px 16px 16px",
                },
                '&:last-child': {
                    padding: "0px",
                }
            },
        },
        headCells: {
            style: {
                '&:not(:last-child)': {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    display: 'flex',
                    justifyContent: `start !important`
                },
                '&:first-child': {
                    padding: "16px 0px 16px 16px",
                },
                '&:last-child': {
                    padding: "0px",
                }
            },
        },
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _handleCardClick = (row) => {
        props.onClick(row.id);
    }

    return (
        <DataTableContainer columns={GOAL_COLUMNS}
            data={props.data}
            pagination={false}
            customStyles={TABLE_STYLE}
            onRowMouseEnter={_handleRowHover}
            onRowMouseLeave={_handleRowRemoveHover}
            rowClick={(row) => _handleCardClick(row)} />
    )
}

export default PortfolioGoalsTable