/*
 *   File : manage-quiz.js
 *   Author URI : https://evoqins.com
 *   Description : add and edit question
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


/* import components */
import { NavigationHeader } from "../../Components/Header";
import { SectionTitle } from "../../Components/Title";
import { PrimaryButton, SecondaryButton } from "../../Components/Cta";
import { CustomTextInput, RadioGroup } from "../../Components/FormElements";
import { Icon } from "../../Components/Icon";
import { toast } from "react-toastify";


/* import services */
import APIService from "../../Services/api-service";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage quiz",
    "Add new question"

]

const ANSWERS = [
    {
        from: '',
        too: '',
        score: '',
        error: ['', '', ''],
    },
]

const QUESTION_TYPE = [
    {
        id: 2, label: 'Range',
    },
    {
        id: 3, label: 'Multiple choices',
    },

    // {
    //     id: 1, label: 'Yes or No',
    // },
]

const MULTIPLE_CHOICES = [
    {
        label: '',
        id: null,
        score: '',
        error: ['', ''],
        default: false,
    },
]



const AddQuestion = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const [question, setQuestion] = useState("");
    const [type, setType] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [pageTitle, setPageTitle] = useState("");
    const [questionError, setQuestionError] = useState("");
    const [weightageError, setWeightageError] = useState("");
    const [weightage, setWeightage] = useState('');
    const [minError, setMinError] = useState('');
    const [maxError, setMaxError] = useState('');
    const [defaultError, setDefaultError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [questionId, setQuestionId] = useState(null);
    const [config, setConfig] = useState({
        postfix_enabled: false,
        singular_postfix: "",
        plural_postfix: "",
        min_max: {
            min: "",
            max: "",
            default: "",
            step: 1,
        }
    })
    const [showDeletedRed, setShowDeleteRed] = useState(-1);
    const [selectedDefaultOption, setSelectedDefaultOption] = useState(null);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (location.state !== null) {
            _updateData()
        } else {
            setType(QUESTION_TYPE[0].id);
            _handleRadio(QUESTION_TYPE[0].id)
        }
        //eslint-disable-next-line
    }, [location.state]);

    const _updateData = () => {
        setIsEdit(true);
        setPageTitle("Edit question");
        setType(location.state.question.question_type);
        setQuestion(location.state.question.question);
        setQuestionId(location.state.question.question_id);
        setWeightage(location.state.question.weight);
        console.log(location)
        if (location.state.question.config === null || location.state.question.config.default_answer !== undefined) {
            let selected = [];
            location.state.question.answers.map((item) => (
                selected.push({
                    label: item.label,
                    answer_id: item.id,
                    score: item.score,
                    error: ['', '', ''],
                    default: item.default
                })
            ))
            setAnswers(selected);
        } else {
            let selected = [];
            console.log('location.state', location.state);
            location.state.question.config.slabs.map((item) => (
                selected.push({
                    from: item.range_start,
                    too: item.range_end,
                    answer_id: item.id,
                    score: item.score,
                    error: ['', ''],
                })
            ))
            setAnswers(selected);
            let config = location.state.question.config;
            if (!config.min_max.hasOwnProperty('step')) {
                config.min_max['step'] = 1;
            }
            setConfig(config);
        }
    }

    const _handleQuestion = (value) => {
        setQuestionError('');
        setQuestion(value);
    }

    const _handleWeightage = (value) => {
        if (value >= 0 && value <= 10) {
            if (value.includes(".")) {
                let input_value = value.split(".");
                const integer_value = input_value[0];
                const decimal_value = input_value[1];
                if (decimal_value.length <= 2) {
                    setWeightage(parseFloat(`${integer_value}.${decimal_value}`));
                }
            } else {
                setWeightage(value);
            }
        }

        setWeightageError('');
    }


    const _handleRadio = (e) => {
        setQuestionError('');
        setWeightageError('');
        setMinError('');
        setMaxError('');
        setDefaultError('');
        if (location.state !== null && location.state.question.question_type === e) {
            _updateData()
        } else {
            setType(e);

            if (e === 2) {
                var selected_answers = [...ANSWERS];
                for (let i = 0; i < selected_answers.length; i++) {
                    selected_answers[i]['error'] = ['', '', ''];
                    selected_answers[i]['from'] = '';
                    selected_answers[i]['too'] = '';
                    selected_answers[i]['score'] = '';
                }
                setAnswers(selected_answers);
            } else {
                var selected_answers = [...MULTIPLE_CHOICES];
                for (let i = 0; i < selected_answers.length; i++) {
                    selected_answers[i]['error'] = ['', ''];
                    selected_answers[i]['label'] = '';
                    selected_answers[i]['score'] = '';
                }
                setAnswers(selected_answers);
            }
        }
    }

    function _handleConfig(key, value, category) {
        let config_value = { ...config }
        if (category === 1) {
            if (value >= 0) {
                config_value.min_max[`${key}`] = value !== '' ? parseInt(value) : '';
            }
        } else {
            config_value[`${key}`] = value;
        }
        setConfig(config_value);
    }

    const _addChoices = (index) => {
        let selected_answers = [...answers];
        if (type === 2) {
            selected_answers.push(
                {
                    from: '',
                    too: '',
                    score: '',
                    error: ['', '', ''],
                }
            )
        } else {
            selected_answers.push(
                {
                    label: '',
                    score: '',
                    error: ['', ''],
                    default: false
                }
            )
        }
        setAnswers(selected_answers)

    }

    function _deleteOptions(index) {
        let selected_answers = [...answers];
        setShowDeleteRed(-1);
        selected_answers.splice(index, 1);
        setAnswers(selected_answers);
    }

    function _scrollToElement(id) {
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: "end",
                inline: "nearest"
            })
        }
    }

    const _handleFields = (input, key, index, error_index) => {
        let selected_answers = [...answers];
        if (key === 'score') {
            if (input <= 10 && input >= 0) {
                selected_answers[index][key] = input;

            }
        } else {
            if (type === 2) {
                if (input >= 0) {
                    selected_answers[index][key] = input;
                }
            } else {
                selected_answers[index][key] = input;
            }
        }

        console.log(selected_answers)
        if (selected_answers[index].error.length > 0) {
            selected_answers[index].error[error_index] = ''
        }
        setAnswers(selected_answers)
    }

    const _handleSubmit = () => {
        var valid_form = true;
        let selected_answers = [...answers];

        if (question.trim().length === 0) {
            valid_form = false;
            setQuestionError("Question is required");
            _scrollToElement('question-input')
        }
        // if (weightage === 0 || weightage.length === 0) {
        //     valid_form = false;
        //     setWeightageError("Weightage is required");
        //     _scrollToElement('weightage-input');
        // }

        if (type === 2) {
            if (config.min_max.min.length === 0) {
                valid_form = false;
                setMinError("Value required");
                _scrollToElement('least-point-input');
            }
            if (config.min_max.max.length === 0) {
                valid_form = false;
                setMaxError("Value required");
                _scrollToElement('largest-point-input');
            }
            if (config.min_max.default.length === 0) {
                valid_form = false;
                setDefaultError("Value required");
                _scrollToElement('default-value-input');
            }
            for (let index = 0; index < selected_answers.length; index++) {
                const element = selected_answers[index];
                if (element.from === '') {
                    valid_form = false;
                    selected_answers[index].error[0] = ("Value required");
                    _scrollToElement(`from-${index}`);
                }
                if (element.too === '') {
                    valid_form = false;
                    selected_answers[index].error[1] = ("value required");
                    _scrollToElement(`To-${index}`);
                }
                if (element.score === '' || parseInt(element.score) <= 0) {
                    valid_form = false;
                    selected_answers[index].error[2] = ("Value required");
                    _scrollToElement(`score-${index}`);
                }

            }
            if (selected_answers.length === 0) {
                toast.dismiss();
                toast.error("Add a option", {
                    type: 'error'
                })
            }
            console.log(selected_answers)
            if (valid_form === true) {
                _addQuestion()
            }
        } else {
            // Check if any selected answer has an empty or whitespace-only label
            if (selected_answers.some(item => !item.label.trim())) {
                // If any selected answer has an empty or whitespace-only label, set valid_form to false
                valid_form = false;

                // Loop through each selected answer
                for (let i = 0; i < selected_answers.length; i++) {
                    // If the label of the selected answer is empty or whitespace-only, assign the error message
                    if (!selected_answers[i].label.trim()) {
                        selected_answers[i]['error'][0] = "Value required"
                        selected_answers[i]['label'] = "";
                        _scrollToElement(`choice-input-${i}`);
                    }
                }
            }


            if (selected_answers.some((item) => parseInt(item.score) < 0 || item.score === '')) {
                valid_form = false;
                console.log('inside error');
                for (let i = 0; i < selected_answers.length; i++) {
                    selected_answers[i]['error'][1] = "Value required";
                    _scrollToElement(`score-${i}`);
                }

            }
            if (valid_form === true) {
                _addQuestion()
            }
        }
        console.log('selected_answers', selected_answers);
        setAnswers(selected_answers);
    }

    // API - add question
    const _addQuestion = () => {

        const url = "risk/upsert-question";
        let range_answers = [];
        let multiple_choice_answers = [];
        let selected_answers = answers;

        answers.map((item) => {
            if (type === 2) {
                return (
                    range_answers.push({
                        range_start: parseInt(item.from),
                        range_end: parseInt(item.too),
                        score: parseInt(item.score)
                    })
                )
            } else {
                return (
                    multiple_choice_answers.push({
                        answer_id: item.answer_id ? item.answer_id : undefined,
                        answer: item.label,
                        score: parseInt(item.score),
                        default: item.default
                    })
                )
            }
        })
        const request = {
            "question_id": questionId,
            "question": question,
            "question_type": type,
            // "weight": parseFloat(weightage),
            "config": type === 2 ? {
                "min_max": {
                    "min": config.min_max.min,
                    "max": config.min_max.max,
                    "min_text": config.min_max.min_text,
                    "max_text": config.min_max.max_text,
                    "default": config.min_max.default,
                    "step" :  config.min_max.step,
                },
                "postfix_enabled": config.postfix_enabled,
                "singular_postfix": config.singular_postfix,
                "plural_postfix": config.plural_postfix,
                "slabs": range_answers
            } : null,
            "answers": type === 2 ? [] : multiple_choice_answers


        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (type === 2) {
                    for (let i = 0; i < selected_answers.length; i++) {
                        selected_answers[i]['from'] = '';
                        selected_answers[i]['too'] = '';
                        selected_answers[i]['score'] = '';
                        selected_answers[i]['error'] = ['', '', ''];
                    }
                } else {
                    for (let i = 0; i < selected_answers.length; i++) {
                        selected_answers[i]['label'] = '';
                        selected_answers[i]['score'] = '';
                        selected_answers[i]['error'] = ['', ''];
                        selected_answers[i]['default'] = false;
                    }
                }
                navigate("/risk-planning-questionnaire");
                setQuestion("");
                setWeightage("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        })
    }

    return (
        <Fragment>
            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage quiz" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row d-flex justify-content-between">
                    <div className="col-12">
                        <SectionTitle title={pageTitle ? pageTitle : "Add new questions"} />
                    </div>

                </div>
                <div className="row padding-36px-top">
                    <div className="col-4">
                        <CustomTextInput
                            label="Add question"
                            id="question-input"
                            postfix="*"
                            error={questionError}
                            value={question}
                            valueChange={(input) => {
                                _handleQuestion(input)
                            }}
                        />
                    </div>
                    {/* <div className="col-2">
                        <CustomTextInput
                            label="Weightage"
                            id="weightage-input"
                            postfix="*"
                            type='number'
                            error={weightageError}
                            value={weightage}
                            valueChange={(input) => {
                                _handleWeightage(input)
                            }}
                        />
                    </div> */}

                </div>

                <div className="row padding-36px-top">
                    <div className="col-5">
                        <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">Type</label>
                        <RadioGroup data={QUESTION_TYPE}
                            className="gap-16px"
                            disabled={isEdit}
                            radioItemClassName="py-2"
                            size={20}
                            fontSize="e-font-16"
                            type={2}
                            id={type}
                            radioHandler={(e) => _handleRadio(e)} />
                    </div>
                </div>
                {
                    type === 2 ?
                        <Fragment>
                            <label className="color-light-black e-font-16 line-height-16px e-poppins-medium mt-4">
                                Options
                            </label>
                            <div className="row pt-3">
                                <div className="col-lg-2 mb-2">
                                    <CustomTextInput label="Least point"
                                        postfix="*"
                                        id="least-point-input"
                                        type='number'
                                        error={minError}
                                        value={config.min_max.min}
                                        valueChange={(input) => {
                                            _handleConfig('min', input, 1);
                                            setMinError("");
                                        }} />
                                </div>
                                <div className="col-lg-2 mb-2">
                                    <CustomTextInput label="Largest point"
                                        postfix="*"
                                        type='number'
                                        id='largest-point-input'
                                        error={maxError}
                                        value={config.min_max.max}
                                        valueChange={(input) => {
                                            _handleConfig('max', input, 1);
                                            setMaxError("");
                                        }} />
                                </div>

                                <div className="col-lg-2 mb-2">
                                    <CustomTextInput label="Default value"
                                        postfix="*"
                                        type='number'
                                        id='default-value-input'
                                        error={defaultError}
                                        value={config.min_max.default}
                                        valueChange={(input) => {
                                            _handleConfig('default', input, 1);
                                            setDefaultError("");
                                        }} />
                                </div>

                                <div className="col-lg-2 mb-2">
                                    <CustomTextInput label="Step"
                                        postfix="*"
                                        type='number'
                                        id='default-value-input'
                                        error={defaultError}
                                        value={config.min_max.step}
                                        valueChange={(input) => {
                                            _handleConfig('step', input, 1);
                                            setDefaultError("");
                                        }} />
                                </div>

                                <div className="row">
                                    <div className={`w-max-content d-flex align-items-center my-4 pe-0 gap-8px`}
                                        onClick={() => {
                                            _handleConfig('postfix_enabled', !config.postfix_enabled);
                                        }}>
                                        <span>
                                            <Icon icon={config.postfix_enabled ? 'checked' : 'unchecked'}
                                                size={24}
                                                className="cursor-pointer"
                                            />
                                        </span>
                                        <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer">
                                            Postfix enabled
                                        </span>
                                    </div>
                                </div>
                                {config.postfix_enabled === true && (<Fragment>
                                    <div className="col-lg-2">
                                        <CustomTextInput label="Singular postfix"
                                            value={config.singular_postfix}
                                            valueChange={(input) => {
                                                _handleConfig('singular_postfix', input, 2);
                                            }} />
                                    </div>
                                    <div className="col-lg-2">
                                        <CustomTextInput label="Plural postfix"
                                            value={config.plural_postfix}
                                            valueChange={(input) => {
                                                _handleConfig('plural_postfix', input, 2);

                                            }} />
                                    </div>
                                </Fragment>)}

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">
                                            Score and weightage
                                        </label>

                                        {
                                            answers.map((item, index) => {
                                                return (
                                                    <div key={index}
                                                        className="row d-flex align-items-center pb-3 pt-1">
                                                        <div className="col-3">
                                                            <CustomTextInput
                                                                label={"From"}
                                                                type='number'
                                                                postfix="*"
                                                                id={`from-${index}`}
                                                                value={item.from}
                                                                error={item.from !== '' ? '' : item.error[0]}
                                                                hide_error_text={false}
                                                                valueChange={(input) => {
                                                                    _handleFields(input, 'from', index, 0)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-3 px-0">
                                                            <CustomTextInput
                                                                label={'To'}
                                                                type='number'
                                                                postfix="*"
                                                                value={item.too}
                                                                id={`To-${index}`}
                                                                error={item.too !== '' ? '' : item.error[1]}
                                                                hide_error_text={false}
                                                                valueChange={(input) => {
                                                                    _handleFields(input, 'too', index, 1)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <CustomTextInput
                                                                label={'Score against answer'}
                                                                type='number'
                                                                placeholder='10'
                                                                postfix="*"
                                                                id={`score-${index}`}
                                                                value={item.score}
                                                                error={item.score !== '' ? '' : item.error[2]}
                                                                hide_error_text={false}
                                                                valueChange={(input) => {
                                                                    _handleFields(input, 'score', index, 2)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            {
                                                                answers.length > 1 &&
                                                                <span onMouseEnter={() => setShowDeleteRed(index)} onMouseLeave={() => setShowDeleteRed(-1)}
                                                                    className="position-relative h-fit-content cursor-pointer e-tool-tip-container "
                                                                    data-tooltip-content="Delete">
                                                                    <Icon icon={index === showDeletedRed ? "delete-red" : "delete"} size={24}
                                                                        className="cursor-pointer"
                                                                        onClick={() => _deleteOptions(index)} />
                                                                </span>
                                                            }

                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                        <p className="d-flex color-primary-color my-0 p-3 ps-0 e-font-16 line-height-14px e-poppins-medium w-max-content align-items-center cursor-pointer"
                                            onClick={() => {
                                                _addChoices()
                                            }}>
                                            <Icon icon="add-square"
                                                size={24}
                                                className="me-2" />
                                            Add new option
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        :

                        <Fragment>

                            <div className="row padding-24px-top">
                                <div className="col-9">
                                    <label className="pb-3 color-light-black e-font-16 line-height-16px e-poppins-medium">
                                        Options and weightage
                                    </label>
                                    {
                                        answers.map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="row d-flex align-items-center pb-3 pt-1">
                                                    <div className="col-5">
                                                        <CustomTextInput
                                                            label={`Choice ${index + 1}`}
                                                            postfix="*"
                                                            id={`choice-input-${index}`}
                                                            type='text'
                                                            hide_error_text={false}
                                                            error={item.label !== '' ? '' : item.error[0]}
                                                            value={item.label}
                                                            valueChange={(input) => {
                                                                _handleFields(input, 'label', index, 0)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-3 px-0">
                                                        {console.log('item.score', item.score)}
                                                        <CustomTextInput
                                                            label={'Score against answer'}
                                                            postfix="*"
                                                            type='number'
                                                            id={`score-${index}`}
                                                            placeholder='10'
                                                            error={item.score !== '' ? '' : item.error[1]}
                                                            hide_error_text={false}
                                                            value={item.score}
                                                            valueChange={(input) => {
                                                                _handleFields(input, 'score', index, 1)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={`col-1 w-max-content d-flex align-items-center my-4 pe-0 gap-8px`}
                                                        onClick={() => {
                                                            // Update the default values based on the clicked item
                                                            let updated_answers = answers.map((answer, i) => ({
                                                                ...answer,
                                                                default: i === index, // Set to true only for the clicked item, false for others
                                                            }));

                                                            console.log("answers", updated_answers);
                                                            setAnswers(updated_answers);
                                                            // _handleConfig('postfix_enabled', !config.postfix_enabled);
                                                        }}>
                                                        <span>
                                                            <Icon icon={item.default ? 'radio-selected' : 'radio-unselected'}
                                                                size={24}
                                                                className="cursor-pointer"
                                                            />
                                                        </span>
                                                        <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer">
                                                            Default value
                                                        </span>
                                                    </div>
                                                    <div className="col-1">
                                                        {
                                                            answers.length > 1 &&
                                                            <span onMouseEnter={() => setShowDeleteRed(index)} onMouseLeave={() => setShowDeleteRed(-1)}
                                                                className="e-tool-tip-container position-relative h-fit-content"
                                                                data-tooltip-content="Delete">
                                                                <Icon icon={index === showDeletedRed ? "delete-red" : "delete"} size={24}
                                                                    className="cursor-pointer"
                                                                    onClick={() => _deleteOptions(index)} />
                                                            </span>
                                                        }

                                                    </div>

                                                </div>

                                            )
                                        })
                                    }
                                    <p className="d-flex color-primary-color my-0 p-3 ps-0 e-font-16 line-height-14px e-poppins-medium align-items-center w-max-content cursor-pointer"
                                        onClick={() => {
                                            _addChoices()
                                        }}>
                                        <Icon icon="add-square"
                                            size={24}
                                            className="me-2" />
                                        Add new option
                                    </p>
                                </div>

                            </div>
                        </Fragment>

                }
                <div className="row padding-24px-top">
                    <div className="col-4 d-flex">
                        <SecondaryButton label="Cancel"
                            onPress={() => {
                                navigate("/risk-planning-questionnaire");
                            }} />
                        <PrimaryButton label="Save question"
                            className='ms-3'
                            onPress={_handleSubmit} />
                    </div>
                </div>
            </div>

        </Fragment >
    )
})
export default AddQuestion;
