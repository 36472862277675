
/*
 *   File : user-summary.js
 *   Author URI : https://evoqins.com
 *   Description : User detail
 *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { React, Fragment, useState, useEffect } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
/*import components*/
import { Icon } from "../Icon";
import { Toggle } from "../FormElements";
import { SecondaryButton } from "../Cta";
import { UpdateAdminPassword, BlockAdminModal } from "../Modal";

import APIService from "../../Services/api-service";

const AdminDetailSummary = (props) => {
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [showBlockAdmin, setShowBlockAdmin] = useState(false);
    const [blocked, setBlocked] = useState(!props.data.active);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [adminId, setAdminId] = useState(null);
    const [downloadLoader, setDownloadLoader] = useState(false);

    // open block admin modal
    useEffect(() => {
        if (showBlockAdmin === true) {
            const modal = new Modal(document.getElementById("block-admin"));
            modal.show();
        }
    }, [showBlockAdmin]);

    // open block admin modal
    useEffect(() => {
        if (showResetPasswordModal === true) {
            const modal = new Modal(document.getElementById("update-admin-password"));
            modal.show();
        }
    }, [showResetPasswordModal]);


    function _handleOpenResetModal() {
        setShowResetPasswordModal(true);
        setAdminId(props.data.admin_id);
    }

    // handle block admin modal state
    const _handleBlockAdmin = () => {
        setShowBlockAdmin(true);
    }

    const _handleBlockAdminClose = () => {
        setShowBlockAdmin(false);
    }

    const _handleBlockAdminConfirm = () => {
        setShowBlockAdmin(false);
        setBlocked(!blocked);
    }

    const _handleUpdateAdminClose = () => {
        setShowResetPasswordModal(false)
        setAdminId(null);
    }

    // API - download mapped client
    function _downloadClients() {
        setDownloadLoader(true);
        let url = 'auth/download-mapped-clients';
        let payload = JSON.stringify({
            admin_id: props.data.admin_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            }else{
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setDownloadLoader(false);
        });
    }

    return (
        <Fragment>
            <div className="bg-light-grayish-white border-radius-16px p-4">
                <div className="row px-2">
                    <div className="col-lg-9 col-md-9 ">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-2 col-md-3 px-0">
                                <img draggable={false} src={props.data.image}
                                    alt="profile"
                                    className="border-radius-16px w-100 h-100" />
                            </div>

                            <div className="col-lg-10 col-md-9">
                                <h6 className="color-jett-black e-font-16 e-poppins-medium line-height-24px mb-0">
                                    {props.data.name}
                                </h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 pe-0">
                                        <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-3">
                                            Admin type:
                                            <span className="color-charleston-green ps-2">
                                                {props.data.admin_type}
                                            </span>
                                        </p>
                                        <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                            Admin ID:
                                            <span className="color-charleston-green ps-2">
                                                {props.data.admin_mt_id}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-lg-7 col-md-7">
                                        <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-3">
                                            Account created on:
                                            <span className="color-charleston-green ps-2">
                                                {props.data.account_created}
                                            </span>
                                        </p>
                                        <p className="e-font-14 e-poppins-medium line-height-14px color-gray my-0">
                                            Registered email:
                                            <span className="color-charleston-green ps-2">
                                                {props.data.email}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {props.permissionType !== 'read' &&
                        <div className="col-lg-3 col-md-3 px-0 text-end">
                            {
                                (ADMIN_PROFILE_DATA.admin_type_id === 1 || ADMIN_PROFILE_DATA.admin_type_id === 2)&&
                                <p className={`e-download-text-btn color-primary-color w-100 e-font-16 e-poppins-medium line-height-16px pt-1 pe-1 cursor-pointer ${downloadLoader === true && 'no-events'}`}
                                    onClick={_downloadClients}>
                                    Download mapped clients
                                    <img src={downloadLoader === true ? require('../../Assets/Images/button-loader.gif') : require('../../Assets/Images/Users/download.png')}
                                        width={24}
                                        height={24}
                                        className="ms-2" />
                                </p>
                            }
                            
                            <div className="d-flex align-items-center justify-content-end mb-4 cursor-pointer" onClick={_handleBlockAdmin}>
                                <span className="color-jett-black e-poppins-regular e-font-16 me-2">Block sub admin</span>
                                <Toggle
                                    check={blocked} />
                            </div>
                            <SecondaryButton label="Reset password"
                                className='e-font-16 padding-12px'
                                onPress={_handleOpenResetModal} />

                        </div>}
                </div>
            </div>
            {
                showBlockAdmin === true ?
                    <BlockAdminModal admin_id={props.data.admin_id}
                        block_status={blocked}
                        close={_handleBlockAdminClose}
                        confirm={_handleBlockAdminConfirm} />
                    :
                    null
            }
            {
                showResetPasswordModal === true ?
                    <UpdateAdminPassword
                        admin_id={adminId}
                        closeModal={_handleUpdateAdminClose} />
                    :
                    null
            }
        </Fragment>
    )

}

AdminDetailSummary.defaultProps = {
    basic_info: {
        first_name: "",
        last_name: "",
        total_invested: 0,
        current_value: 0,
        customer_id: "",
        return: 0,
        returns: 0,
        xirr: 0,
        returns_percentage: 0
    }
}
export default AdminDetailSummary;
