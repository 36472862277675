/*
 *   File : update-rm.js
 *   Author URI : https://evoqins.com
 *   Description :  To link a customer account against a Relationship Manager.
 *   Integrations : null
 *   Version : v1.1
 */


import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Icon } from "../Icon";
import { CustomSearchSelectBox } from "../FormElements";
import APIService from "../../Services/api-service";
import { GradientButton, OutlineButton } from "../Cta";
import { closeModal } from "../../Helper/helper-function";
import { CustomLoader } from "../Other";

import style from "../../Styles/Components/update-rm.module.scss";

const UpdateRMModal = (props) => {

    const [selectedZone, setSelectedZone] = useState({});
    const [selectedZoneError, setSelectedZoneError] = useState('');

    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({});
    const [selectedRegionError, setSelectedRegionError] = useState('');

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedBranchError, setSelectedBranchError] = useState('');

    const [managerOptions, setManagerOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState({});
    const [selectedManagerError, setSelectedManagerError] = useState('');

    const [associateOptions, setAssociateOptions] = useState([]);
    const [selectedAssociate, setSelectedAssociate] = useState({});
    const [selectedAssociateError, setSelectedAssociateError] = useState('');

    const [apiLoader, setApiLoader] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        var my_modal = document.getElementById("update-rm");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        if (props.rmDetail) {
            if (props.rmDetail.zone_id !== "-") {
                const selected_zone = props.zoneOptions.find(item => item.value === props.rmDetail.zone_id);
                setSelectedZone(selected_zone);
            }
        }
    }, [props.rmDetail]);

    useEffect(() => {
        if (regionOptions.length !== 0) {
            if (props.rmDetail.region_id !== "-") {
                const selected_region = regionOptions.find(item => item.value === props.rmDetail.region_id);
                if (selected_region !== undefined) {
                    setSelectedRegion(selected_region);
                }
            }
        }
    }, [regionOptions]);

    useEffect(() => {
        if (branchOptions.length !== 0) {
            if (props.rmDetail.branch_id !== "-") {
                const selected_branch = branchOptions.find(item => item.value === props.rmDetail.branch_id);
                if (selected_branch !== undefined) {
                    setSelectedBranch(selected_branch);
                }
            }
        }
    }, [branchOptions])

    useEffect(() => {
        if (managerOptions.length !== 0) {
            if (props.rmDetail.rm_id !== "-") {
                const selected_manager = managerOptions.find(item => item.value === props.rmDetail.rm_id);
                if (selected_manager !== undefined) {
                    setSelectedManager(selected_manager);
                }
            }
        }
    }, [managerOptions]);

    useEffect(() => {
        if (associateOptions.length !== 0) {
            if (props.rmDetail.ap_id !== "-") {
                const selected_associate = associateOptions.find(item => item.value === props.rmDetail.ap_id);
                if (selected_associate !== undefined) {
                    setSelectedAssociate(selected_associate);
                }
            }
        }
    }, [associateOptions]);

    useEffect(() => {
        if (Object.keys(selectedZone).length !== 0) {
            _getRegion();
        }
    }, [selectedZone]);

    useEffect(() => {
        // console.log('props.rmDetail',props.rmDetail)
        if ( props.rmDetail &&
            Object.keys(selectedZone).length !== 0 &&
            Object.keys(selectedBranch).length !== 0 &&
            Object.keys(selectedManager).length !== 0 &&
            Object.keys(selectedRegion).length !== 0
        ) {
            setShowLoader(false);
        }else{
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }
    }, [selectedZone, selectedManager, selectedBranch, selectedRegion]);

    useEffect(() => {
        if (Object.keys(selectedRegion).length !== 0) {
            _getBranch();
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (Object.keys(selectedBranch).length !== 0) {
            _getManager();
        }
    }, [selectedBranch]);

    useEffect(()=>{
        if (Object.keys(selectedManager).length !== 0) {
            _getAssociate();
        }
    },[selectedManager]);

    const _selectZone = (zone) => {
        setSelectedZone(zone);
        setSelectedZoneError('');
        setSelectedRegion({});
        setSelectedBranch({});
        setSelectedManager({});
    }

    const _selectRegion = (region) => {
        setSelectedRegion(region);
        setSelectedRegionError('');
        setSelectedBranch({});
        setSelectedManager({});
    }

    const _selectBranch = (branch) => {
        setSelectedBranch(branch);
        setSelectedBranchError('');
        setSelectedManager({});
    }

    const _selectManager = (manager) => {
        setSelectedManager(manager);
        setSelectedManagerError('');
        setSelectedAssociate({});
    }

    const _selectAssociate = (ap) => {
        setSelectedAssociate(ap);
        setSelectedAssociateError('');
    }
    const _handleCancel = () => {
        props.close();
    }

    const _validateForm = () => {
        let valid = true;
        if (Object.keys(selectedZone).length === 0) {
            valid = false;
            setSelectedZoneError("Select zone");
        }
        if (Object.keys(selectedRegion).length === 0) {
            valid = false;
            setSelectedRegionError("Select region");
        }
        if (Object.keys(selectedBranch).length === 0) {
            valid = false;
            setSelectedBranchError("Select branch");
        }
        if (Object.keys(selectedManager).length === 0) {
            valid = false;
            setSelectedManagerError("Select manager");
        }
        if (valid === true) {
            setApiLoader(true);
            _handleUpdate();
        }
    }

    // API- helper function to fetch and set data for region, branch and manager
    const _fetchAndSetOptions = async (url, request_data, setOptionsCallback) => {
        try {
            const response = await APIService(true, url, request_data);

            if (response.status_code === 200) {
                const mappedData = response.data.map((item) => ({
                    value: item.id,
                    label: item.name
                }));
                setOptionsCallback(mappedData);
            } else {
                // toast.dismiss();
                // toast.error(response.message, {
                //     type: "error",
                // });
                setOptionsCallback([]);
            }
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
            setOptionsCallback([]);
        }
    };

    // API - get region
    const _getRegion = () => {
        const url = "client_mapping/region/list";
        const request_data = { "id": selectedZone.value };
        _fetchAndSetOptions(url, request_data, setRegionOptions);
    };

    // API - get branch
    const _getBranch = () => {
        const url = "client_mapping/branch/list";
        const request_data = { "id": selectedRegion.value };
        _fetchAndSetOptions(url, request_data, setBranchOptions);
    };

    // API - get manager
    const _getManager = () => {
        const url = "client_mapping/rm/list";
        const request_data = { "id": selectedBranch.value };
        _fetchAndSetOptions(url, request_data, setManagerOptions);
    };

    // API - get associate
    const _getAssociate = () => {
        const url = "client_mapping/ap/list";
        const request_data = {"id": selectedManager.value};
        _fetchAndSetOptions(url, request_data, setAssociateOptions);
    }
    // API - update manager
    const _handleUpdate = () => {
        const url = "user/update-rm-code";

        const data = {
            customer_id: props.customerId,
            rm_id: selectedManager.value,
            branch_id: selectedBranch?.value,
            ap_id: selectedAssociate?.value
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                closeModal('update-rm');
                props.close();
                props.updateDetail();
                toast.dismiss();
                toast.success('Successfully updated', {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }


    return (
        <div className={`modal fade ${style.e_update_rm}`}
            id="update-rm"
            tabIndex="-1"
            aria-labelledby="update-rm"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white  padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="e-poppins-medium e-font-18 line-height-16px mb-0">Manage Relationship Manager</p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className="cursor-pointer e-modal-close-btn" />
                    </div>
                    {
                        showLoader === true ? (
                            <div className="min-height-40vh">
                                <CustomLoader className="min-height-40vh"/>
                            </div>
                        ) : (
                            <>
                                <div className="mt-4">
                                    <CustomSearchSelectBox options={props.zoneOptions}
                                        value={Object.keys(selectedZone).length === 0 ? "" : selectedZone}
                                        label="Select zone"
                                        error={selectedZoneError}
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectZone} />
                                </div>

                                <div className="mt-4 pt-3">
                                    {console.log('selectedRegion',selectedRegion)}
                                    <CustomSearchSelectBox options={regionOptions}
                                        value={Object.keys(selectedRegion).length === 0 ? "" : selectedRegion}
                                        label="Select region"
                                        error={selectedRegionError}
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectRegion} />
                                </div>

                                <div className="mt-4 pt-3">
                                    <CustomSearchSelectBox options={branchOptions}
                                        value={Object.keys(selectedBranch).length === 0 ? "" : selectedBranch}
                                        label="Select branch"
                                        error={selectedBranchError}
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectBranch} />
                                </div>

                                <div className="mt-4 pt-3">
                                    <CustomSearchSelectBox options={managerOptions}
                                        value={Object.keys(selectedManager).length === 0 ? "" : selectedManager}
                                        label="Select manager"
                                        error={selectedManagerError}
                                        hide_error_text={false}
                                        placeholder=""
                                        postfix="*"
                                        onSelectChange={_selectManager} />
                                </div>

                                <div className="mt-4 pt-3">
                                    <CustomSearchSelectBox options={associateOptions}
                                        value={Object.keys(selectedAssociate).length === 0 ? "" : selectedAssociate}
                                        label="Select associate"
                                        error={selectedAssociateError}
                                        hide_error_text={false}
                                        placeholder=""
                                        onSelectChange={_selectAssociate} />
                                </div>

                                <div className="d-flex align-items-center justify-content-center gap-8px margin-32px-top w-100 ">
                                    <OutlineButton label="Cancel"
                                        cancel="modal"
                                        className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "
                                        handleClick={_handleCancel} />
                                    <GradientButton label="Update"
                                        className="padding-12px-tb e-font-16  "
                                        loading={apiLoader}
                                        onPress={_validateForm} />
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default UpdateRMModal;