/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : Order listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { DataTableContainer, SearchInput, CustomDateRangePicker } from "../../../Components/FormElements";
import { ExploreFilterPopup } from "../../../Components/Popups";
import { DownloadButton } from "../../../Components/Cta";
import Icon from "../../../Components/Icon/icon";
import { OrderSummary } from "../../../Components/Cards";
import APIService from "../../../Services/api-service";
import { UnlockSIPModal } from "../../../Components/Modal";

/* import styles */
import Colors from '../../../Styles/color.module.scss';
import { CustomLoader, EmptyScreen } from "../../../Components/Other";
import Store from "../../../Store";
import { SectionTitle } from "../../../Components/Title";

const PAGE_NAVIGATION = [
    "Dashboard",
    "Orders"
]

const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.black
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            color: Colors.black,
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 16px",
            },
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const PAGINATION_OPTIONS = {
    rowsPerPageText: "6 of 10",
    noRowsPerPage: true
}


const Listing = React.memo(() => {

    const navigate = useNavigate();
    const popupRef = useRef(null);
    const SELECTED_FILTER = useSelector(state => state.MT_ADMIN_STORE.OF_FILTERS);

    const [showFilter, setShowFilter] = useState(false);
    const filterRef = useRef(null)
    const [searchQuery, setSearchQuery] = useState("");
    const [orderList, setOrderList] = useState([]);

    // filter data
    const [investmentAmount, setInvestmentAmount] = useState(1);
    const [filterType, setFilterType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [investedIn, setInvestedIn] = useState([]);
    const [orderSummary, setOrderSummary] = useState([]);
    const [filterData, setFilterData] = useState({});

    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(true);
    const [initialLoader, setInitialLoader] = useState(true);

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);

    //eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [showDateFilter, setShowDateFilter] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [listStartDate, setListStartDate] = useState("Start date");
    const [listEndDate, setListEndDate] = useState("End date");
    const [listDateFilter, setListDateFilter] = useState(false);

    useEffect(() => {
        if (initialLoader === true) {
            _getOrderSummary(startDate, endDate);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(SELECTED_FILTER).length !== 0) {
            setInvestmentAmount(SELECTED_FILTER.investmentAmount);
            setFilterType(SELECTED_FILTER.filterType);
            setPaymentStatus(SELECTED_FILTER.paymentStatus);
            setOrderStatus(SELECTED_FILTER.orderStatus);
            setInvestedIn(SELECTED_FILTER.investedIn);
            setInitialLoader(false);
        }
    }, [SELECTED_FILTER]);

    useEffect(() => {
        if (initialLoader === false) {
            _getOrderList();
        }
        //eslint-disable-next-line
    }, [investmentAmount, filterType, paymentStatus, orderStatus, investedIn, pageNumber, listStartDate, listEndDate]);

    useEffect(() => {
        if (apiLoader === false) {
            const body_element = document.getElementById("e-table-body")
            if (body_element) {
                if (showFilter === true) {
                    document.body.style.overflow = 'hidden';
                    body_element.style.pointerEvents = 'none';
                    window.scrollTo({
                        behavior: "smooth",
                        top: 200
                    });
                } else {
                    body_element.removeAttribute("style");
                    document.body.removeAttribute("style");
                }
            }
        }
    }, [showFilter, apiLoader]);

    useEffect(() => {
        if (unlockModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [unlockModal])

    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedOrders)
        if (selectedOrders.has(row.order_id)) {
            setSelectedOrders((prevOrderIds) => {
                const new_order_ids = new Set(prevOrderIds);
                new_order_ids.delete(row.order_id);
                return new_order_ids;
            });
        }
        else {
            setSelectedOrders((prevOrderIds) => new Set([...prevOrderIds, row.order_id]));
        }
        setRowSelectFalse(true);
    }

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    useEffect(() => {
        if (selectAll === true) {
            const order_id_set = new Set(orderList.map((item) => item.order_id));
            setSelectedOrders((prevSet) => new Set([...prevSet, ...order_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedOrders(new Set());
            }
        }
    }, [selectAll, orderList]);

    const ORDER_COLUMNS = [
        {
            name: <div className="position-relative padding-50px-left w-fit-content">
                {/* Add your custom image or icon here */}
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={22}
                    className="position-absolute left-18px"
                    onClick={(event) => _handleSelectAll(event)} />
                <p className="mb-0">Order.ID</p>
            </div>,
            selector: row => row['master_order_id'],
            sortable: true,
            left: true,
            // width: "576px",
            minWidth: "200px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    <Icon icon={selectedOrders.has(row['order_id']) ? "checked" : "unchecked"}
                        size={22}
                        onClick={(event) => _handleRowCheckBox(row, event)} />
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['master_order_id']}</p>
                </div>
        },
        {
            name: 'Client ID',
            selector: row => row['mt_id'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span className="color-primary-color e-name-link position-relative "
                onClick={() => navigate("/user-summary/customer-detail", {
                    state: {
                        user_id: row.customer_id
                    }
                })}>{row['mt_id']}</span>,
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span onClick={() => _handleCardClick(row)}> {row['name']}</span>,
        },
        {
            name: 'Invested in',
            selector: row => row['invested_in'],
            sortable: false,
            left: true,
            minWidth: "250px",
        },
        {
            name: 'Created on',
            selector: row => row['created'],
            sortable: true,
            center: true,
            maxWidth: "180px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['created']}
                </p>
        },
        {
            name: 'Type',
            selector: row => row['type'],
            sortable: true,
            left: true,
            maxWidth: "150px",
            cell: row => <span onClick={() => _handleCardClick(row)} className="ps-2"> {row['type']}</span>,
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: true,
            left: true,
            maxWidth: "50px",
            cell: row => <span onClick={() => _handleCardClick(row)}>
                {
                    row['amount'] !== null ? (
                        <>
                            <span className="e-inter-regular">₹</span>
                            {row['amount'].toLocaleString('en-IN')}
                        </>
                    ) : '-'
                }
            </span>,
        },

        {
            name: 'Order status',
            selector: row => row['order_status'],
            sortable: false,
            left: true,
            maxWidth: '250px',
            cell: row =>
                <div onClick={() => _handleCardClick(row)}>
                    {
                        row['order_status'] === 'Failed' ?
                            <div className="d-flex align-items-center justify-content-center"
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img src={require("../../../Assets/Images/Orders/failed.png")} alt="Failed"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>
                            :
                            row['order_status'] === "Success" ?
                                <div className="d-flex align-items-center justify-content-center "
                                    onClick={() => _handleCardClick(row)}>
                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                        {row['order_status']}
                                    </p>
                                    <img src={require("../../../Assets/Images/Orders/success.png")} alt="Success"
                                        width={24}
                                        height={24}
                                        draggable={false} />
                                </div>
                                :
                                row['order_status'] === "Payment pending" ?
                                    <div className="d-flex align-items-center justify-content-center"
                                        onClick={() => _handleCardClick(row)}>
                                        <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                            {row['order_status']}
                                        </p>
                                        <img src={require("../../../Assets/Images/Orders/payment-pending.svg").default} alt="Processing"
                                            width={24}
                                            height={24}
                                            draggable={false} />
                                    </div>
                                    :
                                    <div className="d-flex align-items-center justify-content-center"
                                        onClick={() => _handleCardClick(row)}>
                                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                            {row['order_status']}
                                        </p>
                                        <img src={require("../../../Assets/Images/Orders/processing.png")} alt="Processing"
                                            width={24}
                                            height={24}
                                            draggable={false} />
                                    </div>
                    }
                </div>
        },

    ];

    const _handleCardClick = (row) => {
        console.log("DATA sending", investmentAmount,
            filterType,
            paymentStatus,
            orderStatus,
            investedIn)
        Store.dispatch({
            type: "SELECTED_OF_FILTERS",
            payload: {
                investmentAmount,
                filterType,
                paymentStatus,
                orderStatus,
                investedIn
            }
        });
        if (row.is_goal === true) {
            navigate("/orders/goal-order-detail", {
                state: {
                    customer_id: row.customer_id,
                    order_id: row.order_id
                }
            })
        }
        else {
            navigate("/orders/fund-order-detail", {
                state: {
                    customer_id: row.customer_id,
                    order_id: row.order_id
                }
            })
        }
    }

    const _handleRowHover = (row) => {
    }

    const _handleRowRemoveHover = () => {
    }

    const _handleToggleFilter = () => {
        setShowFilter(!showFilter);
        window.scrollTo({
            top: 300,
            behavior: "smooth"
        })
    }

    const _handleSearchQuery = (value) => {
        setPageNumber(1);
        setSearchQuery(value);
        if (value === "") {
            _getOrderList("");
        }
    }

    const _handleApplyFilter = (invested_amount, filter_type, payment_status, order_status, invested_in) => {
        setPageNumber(1);
        setShowFilter(false);
        setInvestmentAmount(invested_amount);
        setFilterType(filter_type);
        setPaymentStatus(payment_status);
        setOrderStatus(order_status);
        setInvestedIn(invested_in);
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const _handleShowDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    }

    const _handleDateRangeChange = (newRange) => {
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setSelectedRange(newRange);
        _getOrderSummary(format(newRange.startDate, "dd-MM-yyyy"), format(newRange.endDate, "dd-MM-yyyy"));
    };

    const _handleClearDates = () => {
        setShowDateFilter(false);
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setStartDate("Start date");
        setEndDate("End date");
    }

    // List date
    const _handleShowDateList = () => {
        setListDateFilter(!listDateFilter);
    }

    const _handleDateRangeChangeList = (newRange) => {
        setListDateFilter(false);
        setListStartDate(format(newRange.startDate, "dd-MM-yyyy"));
        setListEndDate(format(newRange.endDate, "dd-MM-yyyy"));
        setDateRange(newRange);
    };

    const _handleClearDatesList = () => {
        setListDateFilter(false);
        setDateRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
        setListStartDate("Start date");
        setListEndDate("End date");
    }

    // API - get orders
    const _getOrderList = (query = searchQuery) => {
        // setApiLoader(true);
        let url = 'order/list';
        let payload = JSON.stringify({
            query: searchQuery,
            sort_by: investmentAmount,
            order_status: orderStatus,
            payment_status: paymentStatus,
            filter_data: filterType,
            invested_in: investedIn,
            page_num: pageNumber,
            start_date: listStartDate === 'Start date' ? null : listStartDate,
            end_date: listEndDate === 'End date' ? null : listEndDate
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderList(response.data.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setPageSize(response.data.page_size);
            } else {
                setOrderList([]);
            }
            setApiLoader(false);
        });
    }

    // API - Get order summary 
    function _getOrderSummary(startDate, endDate) {
        let url = 'order/summary';
        let payload = JSON.stringify({
            start_date: startDate === "Start date" ? null : startDate,
            end_date: endDate === "End date" ? null : endDate
        })
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderSummary(response.data);
                if (initialLoader === true) {
                    _getOrderFilterData();
                }
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setPageSize(response.data.page_size);
            } else {
                setOrderSummary([]);
            }
        });
    }

    // API - Get order filter 
    function _getOrderFilterData() {
        let url = 'order/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
                if (Object.keys(SELECTED_FILTER).length === 0) {
                    setInvestmentAmount(response.data.invested_amount[0].id)
                    setFilterType([response.data.filter_data[0].id]);
                    setPaymentStatus([response.data.payment_status[0].id]);
                    setOrderStatus([response.data.order_status[0].id]);
                    setInvestedIn([response.data.invested_in[0].id]);
                    setInitialLoader(false);
                }
                setPageLoader(false);
            }
        });
    }

    // API - confirm sip download
    const _confirmOrderDownload = () => {
        setDownloadLoader(true);
        const order_id = [...selectedOrders]
        const url = "order/confirm-download";

        const data = {
            "order_ids": selectAll === true ? [] : order_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setUnlockModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    const _downloadOrders = (pin) => {
        setApiLoader(true);
        const order_id = [...selectedOrders]
        const url = "order/download";

        const data = {
            "order_ids": selectAll === true ? [] : order_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
            setUnlockModal(false);
            setSelectedOrders(new Set());
            setSelectAll(false);
        })
    }


    return (
        <Fragment>
            <NavigationHeader type={1}
                data={PAGE_NAVIGATION}
                title="Order summary" />
            {
                pageLoader === true ?
                    <div className="row h-80vh">
                        <CustomLoader />
                    </div>
                    :
                    <div className={`ps-4 pe-lg-5 pe-md-2 pe-2 ${showFilter ? '' : 'padding-32px-top'}`}>
                        <div className="row">
                            <div className="col-3 offset-9 mb-4 position-relative">
                                <CustomDateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    popupRef={popupRef}
                                    showDateFilter={showDateFilter}
                                    handleShowDateFilter={_handleShowDateFilter}
                                    selectedRange={selectedRange}
                                    handleDateRangeChange={_handleDateRangeChange}
                                    clearDates={_handleClearDates} />
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                                <OrderSummary
                                    title='Total orders'
                                    data={orderSummary.find(item => item.label === "All")}
                                    icon={require('../../../Assets/Images/Dashboard/orders.svg').default} />
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                                <OrderSummary
                                    title='Total processing orders'
                                    data={orderSummary.find(item => item.label === "Pending")}
                                    icon={require('../../../Assets/Images/Dashboard/processing-orders.svg').default} />
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                                <OrderSummary
                                    title='Total successful orders'
                                    data={orderSummary.find(item => item.label === "Successful")}
                                    icon={require('../../../Assets/Images/Dashboard/successful-orders.svg').default} />
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                                <OrderSummary
                                    title='Total failed orders'
                                    data={orderSummary.find(item => item.label === "Failed")}
                                    icon={require('../../../Assets/Images/Dashboard/failed-orders.svg').default} />
                            </div>

                            <div className="row d-flex justify-content-between mt-4 pe-0">
                                <div className="col-lg-2">
                                    <SectionTitle title="Order history" />
                                </div>
                                <div className="col-lg-10 d-flex gap-8px pe-0 justify-content-end">
                                    <div className="col-5">
                                        <SearchInput placeholder="Search with Client name, ID, PAN, Phone"
                                            value={searchQuery}
                                            valueChange={_handleSearchQuery}
                                            handleSearch={_getOrderList} />
                                    </div>
                                    <div className="position-relative">
                                        <CustomDateRangePicker
                                            startDate={listStartDate}
                                            endDate={listEndDate}
                                            popupRef={popupRef}
                                            showDateFilter={listDateFilter}
                                            handleShowDateFilter={_handleShowDateList}
                                            selectedRange={dateRange}
                                            handleDateRangeChange={_handleDateRangeChangeList}
                                            clearDates={_handleClearDatesList} />
                                    </div>
                                    <DownloadButton onPress={_confirmOrderDownload}
                                        loader={downloadLoader} />
                                    <div className="position-relative" >
                                        <div className="border-radius-6px p-2 border-bright-gray border-all bg-white  d-flex align-items-center z-index-10"
                                            onClick={_handleToggleFilter}>
                                            <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 pe-1 cursor-pointer mt-0">Filter</p>
                                            <img draggable={false} src={require('../../../Assets/Images/Users/filter.png')}
                                                alt="Filter"
                                                width={24}
                                                height={24}
                                                className="cursor-pointer me-2" />
                                        </div>
                                        {
                                            showFilter === true &&
                                            <Fragment>
                                                <div className="position-relative" ref={filterRef}>
                                                    <ExploreFilterPopup type={1}
                                                        investmentAmount={investmentAmount}
                                                        filterType={filterType}
                                                        paymentStatus={paymentStatus}
                                                        orderStatus={orderStatus}
                                                        investedIn={investedIn}
                                                        data={filterData}
                                                        applyFilter={_handleApplyFilter}
                                                        close={() => setShowFilter(false)} />
                                                </div>
                                                <div className="position-fixed w-100 z-index-8 h-100 left-0 top-20px cursor-default"
                                                    onClick={() => {
                                                        console.log("is this gettgin run")
                                                        setShowFilter(false)
                                                    }}></div>
                                            </Fragment>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                        {
                            apiLoader === true ?
                                <div className="col-12 h-60vh">
                                    <CustomLoader />
                                </div>
                                :
                                orderList.length !== 0 ?
                                    <div className="col-12 position-relative min-height-100vh" id="e-table-body">
                                        <div className="position-relative">
                                            <DataTableContainer columns={ORDER_COLUMNS}
                                                data={orderList}
                                                selectableRows={false}
                                                pagination={true}
                                                customStyles={TABLE_STYLE}
                                                paginationTotalRows={totalLength}
                                                onRowMouseEnter={_handleRowHover}
                                                // defaultSortFieldId={1}
                                                onRowMouseLeave={_handleRowRemoveHover}
                                                paginationComponentOptions={paginationData}
                                                onChangeRowsPerPage={(e) => {
                                                    setPageSize(e);
                                                    setPageNumber(1);
                                                }}
                                                paginationPerPage={pageSize}
                                                rowClick={(row) => _handleCardClick(row)} />
                                            <div className={`d-flex justify-content-end align-items-center position-absolute bottom-16px right-24 w-max-content mt-3`}>
                                                <Icon icon="pagination-previous" width="24px" height="24px"
                                                    className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                    onClick={_previousPage} />
                                                <Icon icon="pagination-next" width="24px" height="24px"
                                                    className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                    onClick={_nextPage} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <EmptyScreen title="Empty order data!" />
                        }
                    </div>
            }

            {
                unlockModal === true ?
                    <UnlockSIPModal
                        handleOTPSubmission={_downloadOrders}
                        close={() => {
                            setUnlockModal(false);
                        }} />
                    :
                    null
            }

        </Fragment>
    )
});

export default Listing;