/*
 *   File : profile.js
 *   Author URI : www.evoqins.com
 *   Description : Admin Profile
 *   Integrations : null
 *   Version : v1.0
 *   Created : 01-01-2024
 */
import { Fragment, useEffect, useState } from "react";
import { NavigationHeader } from "../../../Components/Header";
import { LinkText, OutlineButton } from "../../../Components/Cta";
import { Icon } from "../../../Components/Icon";
import { ManageEmail, ManageMobile, UpdatePassword } from "../../../Components/Modal";
import { Modal } from "bootstrap";
import APIService from "../../../Services/api-service";
import { CustomLoader } from "../../../Components/Other";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";


const ProfileDetail = () => {
    const [openManageEmail, setOpenManageEmail] = useState(false);
    const [openManageNumber, setOpenManageNumber] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const DETAILS = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    useEffect(() => {
        window.scrollTo({
            top: '0',
            behavior: 'smooth'
        });
       
    }, []);
    
    // open manage email modal
    useEffect(() => {
        if (openManageEmail === true) {
            const modal = new Modal(document.getElementById("manage-email"));
            modal.show();
        }
    }, [openManageEmail]);

    // open manage number modal
    useEffect(() => {
        if (openManageNumber === true) {
            const modal = new Modal(document.getElementById("manage-mobile"));
            modal.show();
        }
    }, [openManageNumber]);

    // open update password modal
    useEffect(() => {
        if (openPassword === true) {
            const modal = new Modal(document.getElementById("update-password"));
            modal.show();
        }
    }, [openPassword]);

    // handler for email
    function _handleManageEmail() {
        setOpenManageEmail(true);
    }

    // handler for number
    function _handleManageNumber() {
        setOpenManageNumber(true);
    }

    // handler for password
    function _handlePasswordModal() {
        setOpenPassword(true);
    }
    // logout handler
    function _logout() {
        let url = 'auth/logout';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Cookies.remove('admin_session');
                window.location.href = '/';
            }
        });
    }

    return (
        <Fragment>
            <NavigationHeader type={1}
                title="Profile" />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">

                <div className="row">
                    {/* user detail card */}
                    <div className="col-12 h-60vh">
                        <div className="bg-light-pastel-blue-gray border-radius-16px p-4 ">
                            <div className="row d-flex align-items-end">
                                <div className="col-lg-10 col-md-9 ">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-lg-2 col-md-3 px-0">
                                            <img draggable={false} src={DETAILS.image}
                                                alt="profile"
                                                width='120px'
                                                height='120px'
                                                className="border-radius-16px" />
                                        </div>

                                        <div className="col-lg-10 col-md-9 ">

                                            <div className="row">
                                                <div className="col-lg-12 col-md-5 px-0">
                                                    <h6 className="color-black e-font-18 e-poppins-medium line-height-18px mb-0">
                                                        {DETAILS.name}
                                                    </h6>
                                                    {/* {
                                                        DETAILS.admin_type_id !== 1 &&
                                                        <LinkText title="Upload new picture" />
                                                    } */}
                                                    <p className="e-font-14 e-poppins-medium line-height-14px color-gray d-flex align-items-end my-3">
                                                        Phone number:
                                                        <span className="color-charleston-green ps-2">
                                                            {DETAILS.phone}
                                                        </span>
                                                        {/* {
                                                            DETAILS.admin_type_id !== 1 &&
                                                            <span className="color-primary-color e-font-14 e-poppins-medium line-height-16px  ps-2 d-flex align-items-end cursor-pointer" onClick={() => _handleManageNumber()}>
                                                                Edit <Icon icon='Pen-square'
                                                                    size={24}
                                                                    className='ms-1' />
                                                            </span>
                                                        } */}

                                                    </p>
                                                    <p className="e-font-14 e-poppins-medium line-height-14px color-gray d-flex align-items-end my-0">
                                                        Registered email:
                                                        <span className="color-charleston-green ps-2">
                                                            {DETAILS.email}
                                                        </span>
                                                        {/* {
                                                            DETAILS.admin_type_id !== 1 &&
                                                            <span className="color-primary-color e-font-14 e-poppins-medium line-height-16px ps-2 d-flex align-items-end cursor-pointer" onClick={() => _handleManageEmail()}>
                                                                Edit
                                                                <Icon icon='Pen-square'
                                                                    size={24}
                                                                    className='ms-1' />
                                                            </span>
                                                        } */}
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-2 col-md-3 px-0">

                                    <OutlineButton label="Reset password"
                                        className='e-font-16 padding-12px'
                                        onPress={_handlePasswordModal} />

                                </div>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-12">
                                <div className="p-4 bg-white">
                                    <h6 className="color-light-black e-font-16 e-poppins-medium line-height-16px mb-3">Securely logout</h6>
                                    <p className="color-light-black e-font-14 e-poppins-regular line-height-14px mb-3">End your session with confidence. Keeping your data and account safe.</p>
                                    <div className="d-flex align-items-center color-orange-soda e-poppins-semi-bold line-height-16px e-font-16 w-max-content cursor-pointer" onClick={_logout}>
                                        <Icon icon="secure-logout" size={24}
                                            className='me-2' />
                                        Logout account
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            {
                openPassword === true ?
                    <UpdatePassword type={2}
                        closeModal={() => {
                            setOpenPassword(false);
                        }} />
                    :
                    null
            }

            {
                openManageEmail ?
                    <ManageEmail close={() => {
                        setOpenManageEmail(false);
                    }} />
                    :
                    null
            }

            {
                openManageNumber ?
                    <ManageMobile close={() => {
                        setOpenManageNumber(false);
                    }} />
                    :
                    null
            }
        </Fragment >
    )
}

export default ProfileDetail;