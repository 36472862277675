/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : SIP listing page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* import components */
import { CustomLoader, EmptyScreen } from "../../../../Components/Other";
import { DataTableContainer } from "../../../../Components/FormElements";
import { Icon } from "../../../../Components/Icon";

/* import styles */
import Colors from '../../../../Styles/color.module.scss';

/* import services */
import APIService from "../../../../Services/api-service";
import { _sortDate } from "../../../../Helper/helper-function";

const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "16px",
            fontFamily: "Poppins-Medium",
            lineHeight: "18px",
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "18px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "start !important",
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            justifyContent: "start !important",
            color: Colors.black,
            // '&:first-child': {
            // padding: "24px 0px 14px 24px",
            // }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }


}

const PausedSIPList = React.memo(() => {
    const navigator = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLoader, setPageLoader] = useState(true);
    const [pausedSips, setPausedSips] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);


    useEffect(() => {
        _getPausedSIPs();
    }, [pageNumber, pageSize]);

    const PAUSED_COLUMNS = [
        {
            name: 'MT.ID',
            selector: row => row['mt_id'],
            sortable: true,
            left: true,
            width: "200px",
            // maxWidth: "120px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['mt_id']}</p>
                </div>
        },
        // {
        //     name: 'Cus.ID',
        //     selector: row => row['customer_id'],
        //     sortable: true,
        //     left: true,
        //     // width: "576px",
        //     minWidth: "120px",
        //     cell: row =>
        //         <div className="d-flex align-items-center gap-12px e-fund-name ps-3" onClick={() => _handleCardClick(row)}>
        //             <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0">{row['customer_id']}</p>
        //         </div>
        // },
        {
            name: 'Name',
            selector: row => row['customer_name'],
            sortable: false,
            left: true,
            minWidth: "200px",
            cell: row => <span className="color-black" onClick={() => _handleCardClick(row)}> {row['customer_name']}</span>,
        },
        {
            name: 'Invested in',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "260px",
            cell: row => <div className="d-block">
                <p className="truncate-first-line my-0" onClick={() => _handleCardClick(row)}>
                    <img draggable={false} src={row['image']}
                        alt={row.image} width='40px'
                        height='40px'
                        className="me-2 object-fit-contain border-all border-bright-gray border-radius-8px" />
                    {row['name']}

                </p>
            </div>
        },

        {
            name: 'Start date',
            selector: row => row['start_date'],
            sortable: false,
            center: true,
            minWidth: "190px",
            sortFunction: (a, b) => _sortDate(a.start_date, b.start_date),
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)}>
                    {row['start_date']}
                </p>
        },
        {
            name: 'Next SIP on',
            selector: row => row['end_date'],
            sortable: false,
            center: true,
            minWidth: "180px",
            sortFunction: (a, b) => _sortDate(a.end_date, b.end_date),

            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 gap-12px" onClick={() => _handleCardClick(row)} >
                    {row['end_date']}
                </p>
        },
        {
            name: 'SIP amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            minWidth: "160px",
            cell: row => <div className="d-block ps-1" onClick={() => _handleCardClick(row)}>
                <p className="my-0">
                    <span className="e-inter-regular">₹</span>{row['amount'].toLocaleString('en-IN')}

                </p>
            </div>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            center: true,
            minWidth: "150px",
            cell: row =>
                <p className="color-yellow-orange e-poppins-medium e-font-16 line-height-24px mb-0 mt-0" onClick={() => _handleCardClick(row)}>
                    {row['status']}
                </p>
        },
    ];

    // navigation 
    function _handleCardClick(row) {
        if (row.goal_id !== null) {
            navigator("/sip-manage/sip-goal-summary", {
                state: {
                    sip_id: row.sip_id,
                    customer_id: row.customer_id,
                    sip_status: 2
                }
            })
        } else {
            navigator("/sip-manage/sip-summary", {
                state: {
                    sip_id: row.sip_id,
                    customer_id: row.customer_id,
                    sip_status: 2
                }
            })
        }
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    // API - pause list sip
    const _getPausedSIPs = () => {
        setPageLoader(true);

        const url = "sip/paused-list";

        const request = {
            page_num: pageNumber,
            page_size: pageSize
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setPausedSips(response.data.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
            } else {
                setPausedSips([]);
            }
            setPageLoader(false);
        })
    }


    return (
        <Fragment>
            <div className="row">

                <div className="col-12" id="e-table-body">
                    {
                        pageLoader === true ?
                            <div className="h-80vh">
                                <CustomLoader />
                            </div>
                            :
                            pausedSips.length !== 0 ? (<div className="position-relative">
                                <DataTableContainer columns={PAUSED_COLUMNS}
                                    data={pausedSips}
                                    selectableRows={false}
                                    pagination={true}
                                    customStyles={TABLE_STYLE}
                                    defaultSortFieldId={1}
                                    paginationTotalRows={totalLength}
                                    paginationComponentOptions={paginationData}
                                    onChangeRowsPerPage={(e) => setPageSize(e)}
                                    paginationPerPage={pageSize}
                                    paginationIconNext={null}
                                    paginationIconPrevious={null}
                                    rowClick={(row) => {
                                        _handleCardClick(row)
                                    }} />
                                <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                    <Icon icon="pagination-previous" width="24px" height="24px"
                                        className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                        onClick={_previousPage} />
                                    <Icon icon="pagination-next" width="24px" height="24px"
                                        className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                        onClick={_nextPage} />
                                </div>
                            </div>)
                                :
                                <EmptyScreen title="Empty paused sip data!" />
                    }
                </div>

            </div>


        </Fragment>
    )

})

export default PausedSIPList;