/*
 *   File : fund-order.js
 *   Author URI : www.evoqins.com
 *   Description : Fund order data table
 *   Integrations : null
 *   Version : v1.0
 *   Created : 11-09-2023
 */

/* import packages */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* import components */
import { DataTableContainer } from "../../Components/FormElements";
import { LinkText } from "../../Components/Cta";


/* import styles */
import Colors from '../../Styles/color.module.scss';
import { _sortDate } from "../../Helper/helper-function";
import { Icon } from "../../Components/Icon";


const TABLE_STYLE = {
    table: {
        style: {
            padding: "0",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `none`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            padding: "24px 0px 0px 16px",
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 0px 16px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}
const OrderHistory = (props) => {
    const navigate = useNavigate();

    const [showArrow, setShowArrow] = useState(0);
    const [pageNum, setPageNum] = useState(props.pageNumber);

    useEffect(() => {
        setPageNum(props.pageNumber);
    }, [props.pageNumber]);

    const EXECUTION_COLUMNS = [
        {
            id: 1,
            name: 'Customer Id',
            // selector: row => row['mt_id'],
            sortable: false,
            left: true,
            minWidth: "280px",
            cell: row =>
                <div className="d-flex align-items-center w-100 e-fund-name">
                    <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white w-100">
                        <span className="color-primary-color e-name-link position-relative "
                            onClick={() => _handleCardClick(row)}>{row['mt_id']}
                        </span>
                    </p>
                    {
                        row.order_status !== 'Success' && row.generated_link !== null ? (
                            <LinkText title="Generated links"
                                className='me-5'
                                onClick={() => {
                                    props.openPaymentModal(row.generated_link);
                                }} />
                        ) : (
                            <div className="w-100">
                                <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white">
                                    -
                                </p>
                            </div>
                        )
                    }

                </div >
        },
        {
            id: 2,
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-primary-color e-name-link position-relative "
                onClick={() => _handleCardClick(row)}>{row['name']}</span>,
            style: {
                color: Colors.primary_color,
                fontFamily: "Poppins-Regular",
                fontSize: "16px",
                lineHeight: "24px",
            }
        }, {
            id: 3,
            name: 'Investment style',
            selector: row => row['is_goal'],
            sortable: true,
            left: true,
            minWidth: "180px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['is_goal'] ? 'Goal based' : 'Non-goal based'}
                </p>
        },
        {
            id: 4,
            name: 'Invested in',
            selector: row => row['invested_in'],
            sortable: false,
            left: true,
            width: "380px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 pe-3">
                    {row['invested_in']}
                </p>
        },
        {
            id: 5,
            name: 'Investment type',
            selector: row => row['type'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['type']}
                </p>
        },
        {
            id: 6,
            name: 'Units',
            selector: row => row['units'],
            sortable: false,
            left: true,
            maxWidth: "150px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['units']}
                </p>
        },

        {
            id: 7,
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row =>
                <p className="color-black e-inter-regular  e-font-16 line-height-24px mb-0">
                    {(`₹ ${row['amount'].toLocaleString('en-IN')}`)}
                </p>
        },

        {
            id: 8,
            name: 'Link created on',
            sortable: false,
            center: true,
            minWidth: "150px",
            sortFunction: (a, b) => _sortDate(a.created, b.created),
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0">
                    {row['created']}
                </p>
        },
        {
            id: 9,
            name: 'Status',
            sortable: false,
            left: true,
            minWidth: "250px",
            cell: row =>
                <p className={`${row.order_status == 'Success' ? 'color-sea-green' : row.order_status == 'Processing' ? 'color-yellow-orange' : row.order_status == 'Payment pending' ? 'color-sunset-blaze'  : 'color-red'}  e-poppins-regular e-font-16 line-height-24px mb-0`}>
                    {row['order_status']}
                    {
                        row.order_status === 'Processing' ?
                            <img draggable={false} src={require("../../Assets/Images/Orders/processing.png")} alt="Processing"
                                width={24}
                                height={24}
                                className="ms-1" />
                            : row.order_status === 'Success' ?
                                <img draggable={false} src={require("../../Assets/Images/Orders/success.png")} alt="Success"
                                    width={24}
                                    height={24}
                                    className="ms-1" />
                                : row.order_status === 'Payment pending' ?
                                <img draggable={false} src={require("../../Assets/Images/Orders/payment-pending.svg").default} alt="Payment pending"
                                    width={24}
                                    height={24}
                                    className="ms-1" />
                                :
                                <img draggable={false} src={require("../../Assets/Images/Orders/failed.png")} alt="Failed"
                                    width={24}
                                    height={24}
                                    className="ms-1" />

                    }
                </p>
        }

    ];


    function _handleCardClick(row) {
        navigate("/user-summary/customer-detail", {
            state: {
                user_id: row.customer_id
            }
        });
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    // handler for page change
    function _nextPage() {

        props.getPage(pageNum + 1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    // got to previous page
    function _previousPage() {

        props.getPage(pageNum - 1)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    return (
        <div className="position-relative">
            <DataTableContainer columns={EXECUTION_COLUMNS}
                data={props.data.data}
                pagination={true}
                customStyles={TABLE_STYLE}
                onRowMouseEnter={_handleRowHover}
                paginationComponentOptions={props.paginationData}
                onRowMouseLeave={_handleRowRemoveHover}
                onChangeRowsPerPage={(e) => props.setPageSize(e)}
                paginationPerPage={props.pageSize}
                paginationIconNext={null}
                paginationIconPrevious={null}
                defaultSortFieldId={8}
            />
            <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                <Icon icon="pagination-previous" width="24px" height="24px"
                    className={pageNum === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                    onClick={_previousPage} />
                <Icon icon="pagination-next" width="24px" height="24px"
                    className={pageNum === props.totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                    onClick={_nextPage} />
            </div>
        </div>
    )
}

export default OrderHistory;