/*
 *   File : manage-mobile.js
 *   Author URI : https://evoqins.com
 *   Description : ManageMobile modal
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";


import { closeModal } from "../../Helper/helper-function";
import { CustomTextInput, OTPInput } from "../FormElements";

import style from "../../Styles/Components/manage.module.scss"
import { Icon } from "../Icon";
import { GradientButton, SecondaryButton } from "../Cta";

const ManageMobile = (props) => {


    const [pinString, setPinString] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [verifyPin, setVerifyPin] = useState(false);
    const [mobile, setMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [otpError, setOtpError] = useState("");

    // event listener to be performed when modal gets closed
    useEffect(() => {
        var my_modal = document.getElementById("manage-mobile");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, []);

    // handler for PIN
    function _handlePin(value) {
        setPinString(value);
        setErrorMessage("");
    }

    // handler for mobile number
    function _handleNumber(value) {
        if (value.length <= 10) {
            setMobile(value);
        }
        setMobileError("");
    }

    // handler for OTP
    function _handleOtp(value) {
        if (value.length <= 4) {
            setOtp(value);
        }
        setOtpError("");
    }

    // validation for PIN
    function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Enter valid pin");
        } else {
            setVerifyPin(true);
        }
    }


    // validation submit function for number and OTP
    function _validate() {
        let valid = true;
        // eslint-disable-next-line

        if (mobile.length !== 10) {
            valid = false;
            setMobileError("Invalid number");
        }

        if (otp.length !== 4) {
            valid = false;
            setOtpError('Invalid OTP');
        }
        if (valid === true) {
            props.close();
            closeModal('manage-mobile');
        }
    }

    return (
        <div id="manage-mobile"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="manage-mobile"
            aria-hidden="true"
            className={`modal  ${style.e_unlock}`}>
            <div className={`modal-dialog modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white ${style.e_content}`}>
                    {
                        !verifyPin ?
                            <Fragment>
                                {/* =================== Two factor screen =================*/}
                                <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img draggable={false} src={require("../../Assets/Images/Modal/pin_illustration.png")}
                                        alt="Unlock icon"
                                        width={72}
                                        height={72} />
                                    <h6 className="color-eerie-black e-poppins-medium e-font-18  line-height-24px mb-2">Two factor authentication</h6>

                                    <p className="color-outer-space e-poppins-regular e-font-14 letter-space-point28 line-height-21px mb-3 px-5">
                                        To download the selected detail you need to confirm the verification code that sent to your mail
                                    </p>

                                    <OTPInput pinString={pinString}
                                        id="mt-unlock-pin"
                                        autoFocus={true}
                                        pinError={errorMessage}
                                        handleChange={_handlePin} />
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal" />
                                        <GradientButton label="Verify"
                                            className="ms-3"
                                            onPress={_verifyPin} />
                                    </div>


                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                {/* =================== Number & OTP screen =================*/}
                                <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                                <div className="col-12 d-flex justify-content-between">
                                    <h3 className="color-black e-poppins-semi-bold e-font-18">
                                        Change mobile number
                                    </h3>
                                    <Icon icon="close"
                                        size={24}
                                        className="cursor-pointer ms-auto e-modal-close-btn"
                                        data-bs-dismiss="modal" />
                                </div>
                                <div className="row padding-32px-top">
                                    <div className="col-sm-6">
                                        <CustomTextInput label="Add new number"
                                            postfix="*"
                                            type='number'
                                            value={mobile}
                                            error={mobileError}
                                            valueChange={(value) => _handleNumber(value)} />
                                    </div>
                                    <div className="col-sm-6">
                                        <CustomTextInput label="Verify number"
                                            postfix="*"
                                            type={'password'}
                                            error={otpError}
                                            value={otp}
                                            valueChange={(value) => _handleOtp(value)} />
                                    </div>
                                </div>
                                <p className="color-jett-black e-poppins-regular e-font-16 margin-40px-bottom mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                <div className="w-100 d-flex justify-content-center mt-4">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal" />
                                    <GradientButton label="Update number"
                                        className="ms-3"
                                        onPress={_validate} />
                                </div>
                            </Fragment>
                    }

                </div>
            </div>
        </div >
    )
}

export default ManageMobile;