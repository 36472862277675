
/*
 *   File : order-summary.js
 *   Author URI : https://evoqins.com
 *   Description : Order summary card
 *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { Fragment, React, useState } from "react";


const OrderSummary = (props) => {
    const [showUnitsHeld, setShowUnitsHeld] = useState(false);

    const _showUnitsHeld = () => {
        setShowUnitsHeld(!showUnitsHeld);
    }

    return (
        <Fragment>
            <div className="border-radius-16px bg-pale-steel-blue w-100 h-100">
                <div className="d-flex align-items-center padding-24px-all border-bottom-1px border-bright-gray">
                    <img src={props.icon}
                        alt="Orders icon"
                        width={40}
                        height={40}
                        draggable={false} />
                    <span className="e-poppins-medium e-font-16 line-height-20px color-black ms-3">
                        {props.title}
                    </span>
                </div>

                <div className="row">
                    <div className="col-6 border-right-1px border-bright-gray px-0">
                        <div className="pt-2 padding-24px-bottom text-center">
                            <h6 className="e-poppins-bold e-font-24 line-height-36px color-black mb-3">
                                {props.data.count.toLocaleString('en-IN')}
                            </h6>
                            <p className="e-poppins-medium e-font-14 line-height-20px color-outer-space mb-0">
                                Orders
                            </p>
                        </div>
                    </div>
                    <div className="col-6 ps-0">
                        <div className="pt-2 padding-24px-bottom text-center">
                            <h6 className="e-poppins-bold e-font-24 line-height-36px color-black mb-3 position-relative cursor-pointer"
                                onMouseEnter={_showUnitsHeld}
                                onMouseLeave={_showUnitsHeld}>
                                <span className="e-inter-bold">₹</span>
                                {props.data.amount_str}
                                {
                                    showUnitsHeld &&
                                    <p className="border-radius-8px border-all border-bright-gray position-absolute color-jett-black e-poppins-regular e-font-14 line-height-12px p-2 bg-white mt-0 right-10px">
                                        <span className="e-inter-regular pe-1">₹</span>
                                        {props.data.amount.toLocaleString('en-IN')}
                                    </p>
                                }
                            </h6>
                            <p className="e-poppins-medium e-font-14 line-height-20px color-outer-space mb-0">
                                Amount
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

OrderSummary.defaultProps = {
    icon: null,
    title: "",
    data: {
        count: 0,
        amount: 0,
        amount_str: "0"
    }
}

export default OrderSummary;