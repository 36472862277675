/*
 *   File : general.js
 *   Author URI : https://evoqins.com
 *   Description : Manage dashboard
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

/* import components */
import { CustomTab } from "../../Components/Tab";
import { NavigationHeader } from "../../Components/Header";
import { BannerList, MTFunds, ManageTags } from "./helper";

/* import styles */

const PAGE_NAVIGATION = [
    "Dashboard",
    "Manage dashboard"
]


const INVESTMENT_STYLES = [
    {
        type: "Manage banners",
        id: 1
    },
    {
        type: "Manage tags",
        id: 2
    },
    {
        type: "Manage MT funds",
        id: 3
    },
    {
        type: "Manage popular funds",
        id: 4
    }
]


const OtherSettings = React.memo(() => {
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [tabIndex, setTabIndex] = useState(INVESTMENT_STYLES[0].id);
    const [permissionType, setPermissionType] = useState('');

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    function _handleTabIndex(tab_index, type) {
        setTabIndex(tab_index);
    }

    return (
        <Fragment>
            <NavigationHeader
                type={2}
                data={PAGE_NAVIGATION}
                title="Manage dashboard" />
            <div className="ps-4 pe-lg-5 pe-md-2 padding-42px-top e-others">
                <div className="row">
                    <div className="col-12">
                        <CustomTab type={2}
                            index={tabIndex}
                            data={INVESTMENT_STYLES}
                            onSelectTab={_handleTabIndex} />

                    </div>
                    {
                        tabIndex === 1 ?
                            <BannerList permissionType={permissionType} />
                            :
                            tabIndex === 2 ?

                                <ManageTags permissionType={permissionType} />
                                :
                                tabIndex === 3 ?
                                    <MTFunds type={1} permissionType={permissionType} />
                                    :
                                    <MTFunds type={2} permissionType={permissionType} />
                    }
                </div>
            </div>
        </Fragment>
    )
})

export default OtherSettings;