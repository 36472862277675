/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : KYC page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

/* import components */
import { NavigationHeader } from "../../Components/Header";
import { UserSummary } from "../../Components/Cards";
import { SectionTitle } from "../../Components/Title";
import { DataTableContainer, SearchInput } from "../../Components/FormElements";
import { CustomTab } from "../../Components/Tab";
import { DownloadButton, LinkText } from "../../Components/Cta";
import { ApproveKYCModal, RejectKYCModal, UnlockSIPModal, FileViewer } from "../../Components/Modal";
import { Icon } from "../../Components/Icon";
import { CustomLoader, EmptyScreen } from "../../Components/Other";

/* import styles */
import Colors from '../../Styles/color.module.scss';

/* import services */
import APIService from "../../Services/api-service";
import { _manageKyc } from "../../Helper/api";


const TABLE_STYLE = {
    table: {
        style: {
            padding: "12px 16px 16px 16px",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 16px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}

const ManageOnboarding = React.memo((props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const [onHoverApprove, setOnHoverApprove] = useState(null);
    const [onHoverReject, setOnHoverReject] = useState(null);
    const [approveModal, setApproveModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [tabData, setTabData] = useState([]);
    const [tabIndex, setTabIndex] = useState(null);
    const [onboardingList, setOnboardingList] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [loader, setLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(true);
    const [rejectCustomer, setRejectCustomer] = useState({})
    const [reasonList, setReasonList] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);

    const [paginationData, setPaginationData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [totalLength, setTotalLength] = useState(null);
    const [permissionType, setPermissionType] = useState('');
    const [file, setFile] = useState('');
    const [openFileViewer, setOpenFileViewer] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);

    useEffect(() => {
        _getOnboardingFilterData();
    }, []);

    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
        }
    }, [ADMIN_PROFILE_DATA, location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        _getOnboardingList();
        //eslint-disable-next-line
    }, [tabIndex, pageNumber, pageSize])

    // open kyc success modal
    useEffect(() => {
        if (approveModal === true) {
            const modal = new Modal(document.getElementById("kyc-success"));
            modal.show();
        }
    }, [approveModal]);

    // open kyc reject modal
    useEffect(() => {
        if (rejectModal === true) {
            const modal = new Modal(document.getElementById("reject-kyc"));
            modal.show();
        }
    }, [rejectModal]);


    useEffect(() => {
        if (unlockModal === true) {
            const modal = new Modal(document.getElementById("unlock"));
            modal.show();
        }
    }, [unlockModal])

    // API - approve kyc
    const _handleApproveModal = (row) => {
        setActionLoader(true);
        const data = {
            "customer_id": row.customer_id,
            "is_approve": true,
            "reason_id": null,
            "reason": null
        }
        _manageKyc(data)
            .then((response) => {
                setApproveModal(true);
            }).catch((response) => {
                setActionLoader(false);
                _getOnboardingList();
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    const _handleRejectModal = (row) => {
        setRejectModal(true);
        setActionLoader(true);
        setRejectCustomer(row)
    }

    // navigate to onboarding detail page
    const _handleCardClick = (row) => {
        navigate('/manage-onboarding/detail', {
            state: {
                customer_id: row.customer_id,
            },
        });
    }

    const _handleTabIndex = (id) => {
        setTabIndex(id);
    }

    const _handleSearchQuery = (value) => {
        setPageNumber(1);
        setSearchQuery(value)
        if (value === "") {
            _getOnboardingList();
        }
    }

    // handler for page change
    function _nextPage() {
        setPageNumber(prevCount => prevCount + 1);
    }

    // got to previous page
    function _previousPage() {
        setPageNumber(prevCount => prevCount - 1);
    }

    // API - get kyc filter data
    const _getOnboardingFilterData = () => {
        let url = 'onboarding/kyc/filter-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.filter_data.map((item) => {
                    return {
                        id: item.id,
                        type: item.label
                    }
                })
                setTabData(modified_data);
                setTabIndex(response.data.filter_data[0].id)
                _getReasonList();
            }
        })
    }

    // API - get kyc reason
    const _getReasonList = () => {
        let url = 'onboarding/kyc/reason';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const modified_data = response.data.map(item => {
                    return {
                        id: item.id,
                        name: item.label
                    }
                })
                setReasonList(modified_data);
            }
            _getKycSummary();
        })
    }

    // API - get kyc summary
    const _getKycSummary = () => {
        let url = 'onboarding/kyc/summary';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
                setLoader(false);
            }
        })
    }

    // API - search id from onboarding list
    const _getOnboardingList = () => {
        setApiLoader(true);
        let url = 'onboarding/kyc/list';
        let payload = JSON.stringify({
            query: searchQuery,
            filter_id: tabIndex,
            page_num: pageNumber,
            page_size: pageSize
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOnboardingList(response.data);
                let total_pages = Math.ceil(response.data.number_of_records / response.data.page_size);
                setTotalPages(total_pages);
                setPaginationData({
                    rowsPerPageText: `${response.data.page} | Total records: ${response.data.number_of_records}`,
                });
                setTotalLength(response.data.number_of_records);
            }
            else {
                // toast.dismiss();
                // toast.error(response.message, {
                //     type: "error"
                // });
                setOnboardingList([]);
            }
            setApiLoader(false);
        })
    }

    // API - confirm onboarding download
    const _confirmOnboardingDownload = () => {
        setDownloadLoader(true);
        const customer_id = [...selectedCustomers]
        const url = "onboarding/kyc/confirm-download";

        const data = {
            "customer_ids": customer_id
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDownloadLoader(false);
                setUnlockModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    const _downloadOnboarding = (pin) => {
        setApiLoader(true);
        const customer_id = [...selectedCustomers]
        const url = "onboarding/kyc/download";

        const data = {
            "customer_ids": customer_id,
            "otp": pin,
            "type": "DOWNLOAD"
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url, "_blank");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
            setUnlockModal(false);
            setSelectedCustomers(new Set());
        })
    }

    const _handleSelectAll = (event) => {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    const _handleRowCheckBox = (row, event) => {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedCustomers)
        if (selectedCustomers.has(row.customer_id)) {
            setSelectedCustomers((prevCustomerIds) => {
                const new_customer_ids = new Set(prevCustomerIds);
                new_customer_ids.delete(row.customer_id);
                return new_customer_ids;
            });
        }
        else {
            setSelectedCustomers((prevCustomerIds) => new Set([...prevCustomerIds, row.customer_id]));
        }
        setRowSelectFalse(true);
    }

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    useEffect(() => {
        if (selectAll === true) {
            const customer_id_set = new Set(onboardingList.data.map((item) => item.customer_id));
            setSelectedCustomers((prevSet) => new Set([...prevSet, ...customer_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedCustomers(new Set());
            }
        }
    }, [selectAll, onboardingList.data]);

    useEffect(()=>{
        if (openFileViewer === true) {
            const modal = new Modal(document.getElementById("file-viewer"));
            modal.show();
        }
    },[openFileViewer]);

    function _callbackFileViewerModalClose () {
        setFile("");
        setOpenFileViewer(false);
    }

    function _viewFile (url) {
        setFile({url, type: 1});
        setOpenFileViewer(true);
    }

    // onboarding table data
    const ONBOARDING_COLUMNS = [

        {
            name: <div className={`position-relative w-fit-content ${permissionType !== 'read' ? 'padding-50px-left' : 'padding-30px-left'}`}>
                {/* Add your custom image or icon here */}
                {
                    permissionType !== 'read' && (
                        <Icon icon={selectAll === true ? "checked" : "unchecked"}
                            size={22}
                            className="position-absolute left-18px cursor-pointer"
                            onClick={(event) => _handleSelectAll(event)} />
                    )
                }

                <p className="mb-0">Actions</p>
            </div>,
            sortable: false,
            left: true,
            // width: "576px",
            minWidth: "300px",
            cell: row =>
                row['can_approve'] === true ?
                    <Fragment>
                        <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                            {
                                permissionType !== 'read' && (
                                    <Icon icon={selectedCustomers.has(row['customer_id']) ? "checked" : "unchecked"}
                                        size={22}
                                        onClick={(event) => _handleRowCheckBox(row, event)} />
                                )
                            }

                        </div>
                        <div className="d-flex align-items-center position-relative"
                            data-disabled={`${actionLoader}`}>
                            <img draggable={false} src={require('../../Assets/Images/Onboarding/verified.svg').default} alt="verified"
                                onMouseEnter={() => {
                                    setOnHoverApprove(row.customer_id);
                                }}
                                onMouseLeave={() => {
                                    setOnHoverApprove(null)
                                }}
                                onClick={() => _handleApproveModal(row)} />

                            {
                                onHoverApprove === row.customer_id ?
                                    <span className="position-absolute bg-light-sky-blue border-radius-4px p-1 e-font-12 e-poppins-regular top--30 left--30">
                                        Approve KYC
                                    </span>
                                    :
                                    null
                            }
                            <img draggable={false} src={require('../../Assets/Images/Onboarding/failed.svg').default} alt="verified" className="margin-36px-lr"
                                onMouseEnter={() => {
                                    setOnHoverReject(row.customer_id);
                                }}
                                onMouseLeave={() => {
                                    setOnHoverReject(null)
                                }}
                                onClick={() => _handleRejectModal(row)} />
                            {
                                onHoverReject === row.customer_id ?
                                    <span className="position-absolute bg-light-sky-blue border-radius-4px p-1 e-font-12 e-poppins-regular top--30 left-45">
                                        Reject KYC
                                    </span>
                                    :
                                    null
                            }

                            <LinkText title="View details"
                                onClick={() => _handleCardClick(row)} />
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="d-flex align-items-center gap-12px e-fund-name ps-2" onClick={() => _handleCardClick(row)}>
                            {
                                permissionType !== 'read' && (
                                    <Icon icon={selectedCustomers.has(row['customer_id']) ? "checked" : "unchecked"}
                                        size={22}
                                        onClick={(event) => _handleRowCheckBox(row, event)} />
                                )
                            }

                        </div>
                        <div className="d-flex align-items-center position-relative">
                            <p className="d-flex align-items-center e-font-16 ps-2 color-outer-space mb-0">Not updated</p>
                            <LinkText title="View details"
                                className='ms-4 ps-1'
                                onClick={() => _handleCardClick(row)} />
                        </div>
                    </Fragment>


        },
        {
            name: 'CVL agreement',
            // omit: tabIndex === 3 || tabIndex === null ? false : true,
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="ps-2">
                {row['cvl_agreement'] !== null ? (
                    <LinkText title="View document" onClick={() => _viewFile(row['cvl_agreement'])} />
                ) : (
                    '-'
                )}
            </span>

        },
        {
            name: 'AOF',
            omit: tabIndex === 3 || tabIndex === null ? false : true,
            sortable: false,
            left: true,
            maxWidth: "140px",
            cell: row => <span className="ps-2">
                {row['aof'] !== null ? (
                    <LinkText title="View document" onClick={() => _viewFile(row['aof'])} />
                ) : (
                    '-'
                )}
            </span>

        },
        {
            name: 'Customer. ID',
            selector: row => row['mt_client_id'],
            sortable: true,
            left: true,
            minWidth: "120px",
            cell: row =>
                <p className="color-primary-color e-poppins-regular e-font-16 line-height-24px mb-0 bg-white mt-0 ps-2"
                    onClick={() => _handleCardClick(row)}>
                    {row['mt_client_id']}
                </p>
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['name'] === null ? "-" : row['name']}</span>,
        },

        {
            name: 'Created on',
            selector: row => row['account_created_on'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['account_created_on'] === null ? "-" : row['account_created_on']}</span>,
        },
        {
            name: 'PAN',
            selector: row => row['pan'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['pan'] === null ? "-" : row['pan']}</span>,
        },
        {
            name: 'Aadhar number',
            selector: row => row['aadhaar'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['aadhaar'] === null ? "-" : row['aadhaar']}</span>,
        },
        {
            name: 'Linked bank',
            selector: row => row['bank_name'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['bank_name'] === null ? "-" : row['bank_name']}</span>,
        },
        {
            name: 'Selfie capture',
            selector: row => row['selfie_captured'],
            sortable: false,
            left: true,
            minWidth: "150px",
            cell: row => <span className="color-outer-space"
                onClick={() => _handleCardClick(row)}> {row['selfie_captured'] === true ? "Yes" : "No"}</span>,
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: false,
            left: true,
            minWidth: "200px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space"
                    onClick={() => _handleCardClick(row)}>
                    {row['email'] === null ? "-" : row['email']}
                </p>
        },
        {
            name: 'Phone',
            selector: row => row['mobile'],
            sortable: false,
            left: true,
            minWidth: "180px",
            cell: row =>
                <p className="color-black e-poppins-regular e-font-16 line-height-24px mb-0 mt-0 color-outer-space"
                    onClick={() => _handleCardClick(row)}>
                    {row['mobile'] === null ? "-" : row['mobile']}
                </p>
        },

    ];
    

    return (

        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <NavigationHeader
                    type={1}
                    title="Onboarding details" />
                <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                    <div className="row padding-32px-bottom">
                        <div className="col-lg-9 col-md-7 col-5">
                            <SectionTitle title="Summary" />
                        </div>
                    </div>

                    {/* Summary cards */}
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                            <UserSummary title={summaryData[0].label}
                                type={3}
                                count={summaryData[0].count ? summaryData[0].count : 0}
                                borderColor="bg-alice-blue"
                                icon={require('../../Assets/Images/Onboarding/approved.svg').default} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                            <UserSummary title={summaryData[1].label}
                                type={3}
                                count={summaryData[1].count ? summaryData[1].count : 0}
                                borderColor="bg-alice-blue"
                                icon={require('../../Assets/Images/Onboarding/in-process.svg').default} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                            <UserSummary title={summaryData[3].label}
                                type={3}
                                count={summaryData[3].count ? summaryData[3].count : 0}
                                borderColor="bg-alice-blue"
                                icon={require('../../Assets/Images/Onboarding/rejected.svg').default} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 pb-lg-0 pb-md-3 pb-3">
                            <UserSummary title={summaryData[2].label}
                                type={3}
                                count={summaryData[2].count ? summaryData[2].count : 0}
                                borderColor="bg-alice-blue"
                                icon={require('../../Assets/Images/Onboarding/cancelled.svg').default} />
                        </div>

                    </div>

                    <div className="row pt-4 pb-5">
                        <div className="col-lg-7 scrollbar-container overflow-auto white-space-nowrap">
                            <CustomTab data={tabData}
                                type={2}
                                index={tabIndex}
                                onSelectTab={_handleTabIndex} />
                        </div>

                        <div className="col-lg-5">
                            <div className="d-flex gap-8px ">
                                {
                                    permissionType !== 'read' && (
                                        <DownloadButton onPress={_confirmOnboardingDownload}
                                            loader={downloadLoader} />
                                    )
                                }

                                <SearchInput placeholder="Search with Client name, ID, PAN, Phone"
                                    value={searchQuery}
                                    valueChange={_handleSearchQuery}
                                    handleSearch={_getOnboardingList} />

                            </div>
                        </div>
                        <div className="col-12 text-end mt-2">
                            <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                <img draggable={false} src={require('../../Assets/Images/Onboarding/scroll-gif.gif')} alt="scroll"
                                    width='24px'
                                    height='24px'
                                    className='e-rotate-gif' />
                                Scroll horizontally for view more  information</p>
                        </div>

                        {
                            apiLoader === true ?
                                <div className="h-80vh">
                                    <CustomLoader />
                                </div>
                                :
                                onboardingList.length !== 0 ?
                                    <div className="col-12 position-relative">
                                        {/* onboarding list */}
                                        <DataTableContainer columns={ONBOARDING_COLUMNS}
                                            data={onboardingList.data}
                                            selectableRows={false}
                                            pagination={true}
                                            customStyles={TABLE_STYLE}
                                            // defaultSortFieldId={}
                                            paginationComponentOptions={paginationData}
                                            paginationTotalRows={totalLength}
                                            paginationCurrentPage={pageNumber}
                                            paginationPerPage={pageSize}
                                            paginationIconNext={null}
                                            paginationIconPrevious={null}
                                            onChangeRowsPerPage={(e) => {
                                                setPageSize(e);
                                                setPageNumber(1);
                                            }}
                                            rowClick={(row) => {
                                                _handleCardClick(row)
                                            }} />
                                        <div className={`d-flex justify-content-end align-items-center mt-3 position-absolute bottom-16px right-24 w-max-content`}>
                                            <Icon icon="pagination-previous" width="24px" height="24px"
                                                className={pageNumber === 1 ? 'pointer-events-none opacity-4 mx-3' : 'mx-3 cursor-pointer'}
                                                onClick={_previousPage} />
                                            <Icon icon="pagination-next" width="24px" height="24px"
                                                className={pageNumber === totalPages ? 'ms-1 pointer-events-none opacity-4' : 'ms-1 cursor-pointer'}
                                                onClick={_nextPage} />
                                        </div>
                                    </div>
                                    :
                                    <EmptyScreen title="Empty onboarding data!" />

                        }
                    </div>

                </div>
                {/* approve modal */}
                {
                    approveModal === true ?
                        <ApproveKYCModal
                            close={() => {
                                setActionLoader(false);
                                setApproveModal(false);
                                _getOnboardingList();
                            }} />
                        :
                        null
                }

                {/* reject modal */}
                {
                    rejectModal === true ?
                        <RejectKYCModal data={reasonList}
                            customerId={rejectCustomer.customer_id}
                            email={rejectCustomer.email}
                            mobile={rejectCustomer.mobile}
                            close={() => {
                                setActionLoader(false);
                                setRejectModal(false);
                                _getOnboardingList();
                            }} />
                        :
                        null
                }

                {
                    unlockModal === true ?
                        <UnlockSIPModal
                            handleOTPSubmission={_downloadOnboarding}
                            close={() => {
                                setUnlockModal(false);
                            }} />
                        :
                        null
                }
                {
                    openFileViewer === true &&
                    <FileViewer data={file}
                        close={_callbackFileViewerModalClose} />
                }
            </Fragment >
    )
});
export default ManageOnboarding;