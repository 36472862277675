/*
 *   File : notification-log.js
 *   Author URI : https://evoqins.com
 *   Description : Notification log page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

import { DataTableContainer } from "../../../Components/FormElements";
import { ManageInfo, ViewContent } from "../../../Components/Modal";
import { LinkText } from "../../../Components/Cta";
import { SectionTitle } from "../../../Components/Title";
import { Icon } from "../../../Components/Icon";
import { CustomToolTip } from "../../../Components/Popups";

/* import styles */
import Colors from '../../../Styles/color.module.scss';
import { EmptyScreen } from "../../../Components/Other";


// table style
const TABLE_STYLE = {
    table: {
        style: {
            padding: "0 0 40px 0",
            background: Colors.white,
        },
    },
    tableBody: {
        style: {
            margin: '0px',
            marginTop: "0px !important"
        }
    },
    headRow: {
        style: {
            background: 'transparent',
            borderRadius: "16px 16px 0px 0px",
            border: "0",
            borderBottom: `1px solid ${Colors.bright_gray} !important`,
            fontSize: "14px",
            fontFamily: "Poppins-Medium",
            lineHeight: "24px",
            color: Colors.outer_space
        }
    },
    rows: {
        style: {
            margin: "0",
            background: "transparent",
            border: `1px solid ${Colors.bright_gray} !important`,
            borderRadius: "16px",
            marginTop: "14px",
            fontSize: "16px",
            fontFamily: "Poppins-Regular",
            lineHeight: "24px",
            cursor: "pointer",
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 24px",
            }
        },
    },
    headCells: {
        style: {
            '&:not(:last-child)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
            },
            '&:first-child': {
                padding: "24px 0px 24px 16px",
            }
        },
    },
    pagination: {
        style: {
            borderTop: `none `,
            color: Colors.outer_space,
            fontSize: '16px',
            fontFamily: "Poppins-Regular",
        },
    }
}



const InfoList = (props) => {
    const [notificationDetail, setNotificationDetail] = useState(null);
    const [manageContent, setManageContent] = useState(false);
    const [infoDetail, setInfoDetail] = useState(null);
    const [infoList, setInfoList] = useState([]);

    useEffect(() => {
        if (props.data !== null) {
            setInfoList(props.data);
            setTimeout(() => {
                var paragraphs = document.querySelectorAll('#htmlContent');
                // Loop through each <p> element
                paragraphs.forEach(function (paragraph) {
                    // Replace &nbsp; with an empty string
                    paragraph.innerHTML = paragraph.innerHTML.replace(/&nbsp;/g, '');
                });
            }, 20);
        } else {
            setInfoList([])
        }

    }, [props.data])

    // open view content modal
    useEffect(() => {
        if (notificationDetail !== null) {
            const modal = new Modal(document.getElementById("view-content"));
            modal.show();
        }
    }, [notificationDetail]);

    // open manage content modal
    useEffect(() => {
        if (manageContent === true) {
            const modal = new Modal(document.getElementById("manage-info"));
            modal.show();
        }
    }, [manageContent])

    // open modal 
    function _handleOpenModal(item) {
        setNotificationDetail(item);
    }

    function _handleContentModal(item) {
        setManageContent(true);
        setInfoDetail(item);
    }

    // columns
    const INFO_COLUMNS = [
        {
            name: 'Page',
            selector: row => row['page'],
            sortable: false,
            left: true,
            maxWidth: "250px",
            cell: row => <span className="color-outer-space"> {row['page']}</span>,
        },

        {
            name: 'Section',
            selector: row => row['section'],
            sortable: false,
            left: true,
            maxWidth: "500px",
            cell: row => <span className="color-outer-space"> {row['section']}</span>,
        },
        {
            name: 'Info content',
            selector: row => <span dangerouslySetInnerHTML={{ __html: row['content'] }} className="color-outer-space e-parse-content truncate-second-line" id="htmlContent"></span>,
            sortable: false,
            left: true,
            maxWidth: "250px",
            style: {
                color: Colors.outer_space
            }
            // cell: row => <span className="color-outer-space"> {row['content']}</span>,
        },
        {
            name: '',
            sortable: false,
            right: true,
            maxWidth: "140px",
            cell: row => <span className="ps-0">
                <LinkText title="View content"
                    type={2}
                    className='e-font-16'
                    onClick={() => {
                        _handleOpenModal(row)
                    }} />
            </span>

        },
        {
            name: '',
            sortable: false,
            right: true,
            maxWidth: "140px",
            cell: row => <span className="ps-0 position-relative e-tool-tip-container"  
                data-tooltip-content="Edit">
                
                <Icon icon="Pen-square" size={24}
                    onClick={() => _handleContentModal(row)} />
            </span>

        },
    ]


    return (

        <Fragment>
            <div className="row padding-32px-top">
                <div className="row pb-3">
                    <div className="col-12 d-flex justify-content-between">
                        <SectionTitle title="Manage i information contents" />
                    </div>
                </div>

                <div className="col-12 h-100">
                    {infoList.length !== 0 ? <DataTableContainer columns={INFO_COLUMNS}
                        data={infoList}
                        selectableRows={false}
                        pagination={false}
                        customStyles={TABLE_STYLE}
                        defaultSortFieldId={1} />
                        :
                        <EmptyScreen title="No data found!" />
                    }
                </div>

            </div>
            {
                notificationDetail !== null ?
                    <ViewContent data={notificationDetail}
                        type={1}
                        close={() => {
                            setNotificationDetail(null);
                        }} />
                    :
                    null
            }
            {
                manageContent === true ?
                    <ManageInfo data={infoDetail}
                        close={() => {
                            setManageContent(false);
                            setInfoDetail(null);
                        }}
                        contentAdded={() => {
                            setManageContent(false);
                            setInfoDetail(null);
                            props.onUpdateInfo()
                        }} />
                    :
                    null
            }
        </Fragment>
    )
}

InfoList.defaultProps = {
    data: []
}
export default InfoList