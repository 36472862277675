
/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : SWP & STP listing page
 *   Integrations : null
 *   Version : v1.1
 */


import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from '../../../Components/Icon/icon';
import { CustomTab } from "../../../Components/Tab";
import { NavigationHeader } from "../../../Components/Header";
import { UserSummary } from "../../../Components/Cards";

import STPListing from "./helper/stp-listing";
import SWPListing from "./helper/swp-listing";

import APIService from "../../../Services/api-service";

const TABS = [
    "STP",
    "SWP"
]

export default function ListingSTPSWP(props) {

    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(1);
    const [summaryData, setSummaryData] = useState({});
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        _getSummaryData();
    }, [tabIndex]);


    useEffect(()=>{
        if (location.state && location.state.tabIndex) {
            setTabIndex(location.state.tabIndex);
        }
    },[location]);

    // handler for tab index
    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    // API - summary data
    // API - STP summary
    function _getSummaryData() {
        setApiLoader(true);
        let url = 'stp/summary';
        if (tabIndex === 2) {
            url = 'swp/summary';
        }
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
            }
            setApiLoader(false);
        })
    }


    return (
        <div>
            <NavigationHeader
                type={1}
                title={TABS[tabIndex - 1]} />
            <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                <div className="row">
                    {
                        Object.keys(summaryData).length &&(
                            <>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title={tabIndex === 1 ? "Active STPs" : "Active SWPs"}
                                        count={tabIndex === 1 ? summaryData.total_active_stp : summaryData.total_active_swp}
                                        borderColor="bg-alice-blue" 
                                        icon={require('../../../Assets/Images/Sidebar/sip-color.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title={tabIndex === 1 ? "Cancelled STPs" : "Cancelled SWPs"}
                                        count={tabIndex === 1 ? summaryData.total_cancelled_stp : summaryData.total_cancelled_swp}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/cancel-sip.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="Total amount"
                                        count={tabIndex === 1 ?summaryData.amount_active_stp && summaryData.amount_active_stp.toLocaleString('en-IN') : summaryData.amount_active_swp && summaryData.amount_active_swp.toLocaleString('en-IN')}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/total-amount.png')} />
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 pb-lg-4 pb-md-3 pb-3">
                                    <UserSummary type={1}
                                        title="AMC invested"
                                        count={summaryData.amc_counts}
                                        borderColor="bg-alice-blue"
                                        icon={require('../../../Assets/Images/sip/amc-invested.png')} />
                                </div>
                            </>
                        )
                    }

                    <div className="col-12">
                        <CustomTab type={1}
                            index={tabIndex}
                            data={TABS}
                            wrapperClass="gap-24px"
                            onSelectTab={_handleTabIndex} />
                    </div>

                    <div className="col-12 min-height-80vh">
                        {
                            tabIndex === 1 ?
                            <STPListing />
                            :
                            <SWPListing />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}