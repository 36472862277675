
/*
 *   File : preview.js
 *   Author URI : https://evoqins.com
 *   Description : File preview *   Integrations : null
 *   Version : v1.1
 */
/*import packages*/
import { Fragment, React, useState } from "react";

/*import components*/
import { Icon } from "../Icon";


const FilePreview = (props) => {
    const [showRedDeleteIcon, setShowRedDeleteIcon] = useState(false);


    const _handleDelete = (status) => {
        setShowRedDeleteIcon(status);
    }
    return (
        <Fragment>
            <div className="d-flex align-items-center mt-3">
                <p className="border-radius-8px d-flex bg-pale-baby-blue p-2 color-jett-black e-font-10 e-poppins-regular line-height-24px mb-0">
                    {props.file}
                    {
                        !props.hidePreviewIcon &&
                        <img draggable={false} src={require("../../Assets/Images/Notifications/preview.svg").default}
                            alt="preview"
                            className="ms-2" />
                    }

                </p>
                <Icon icon={showRedDeleteIcon ? "delete-red" : "delete"} alt="delete"
                    size={24}
                    className="margin-6px-left cursor-pointer"
                    onClick={() => {
                        props.clearFile()
                    }}
                    onMouseEnter={() => {
                        _handleDelete(true)
                    }}
                    onMouseLeave={() => {
                        _handleDelete(false)
                    }} />
            </div>
        </Fragment>
    )
}

FilePreview.defaultProps = {
    file: "",
    clearFile: () => { }
}

export default FilePreview;