import { all, fork } from 'redux-saga/effects'
import { _updateNavigation, _updateSidebarToggle, _getAdminProfile, _getOFFilter } from './saga'


export default function* rootSaga() {
    yield all([
        fork(
            _updateNavigation(),
            _updateSidebarToggle(),
            _getAdminProfile(),
            _getOFFilter()
        )
    ]);
}