
/*
 *   File : 404.js
 *   Author URI : https://evoqins.com
 *   Description : 404 page
 *   Integrations : null
 *   Version : v1.1
 */
import { useNavigate } from 'react-router-dom'
import { GradientButton } from '../Cta';

export default function PageNotFound() {
    const navigator = useNavigate();

    function _navigation() {
        navigator('/');
    }
    return (
        <div className='e-page-container'>
            {/* <InvestmentHeader /> */}
            <div className="h-80vh d-flex justify-content-center align-items-center">
                <div className='text-center'>
                    <img src={require('../../Assets/Images/404.png')}
                        alt="404 page"
                        width={210}
                        draggable={false}
                        className='' />
                    <h5 className='e-poppins-semi-bold e-font-18 line-height-26px color-outer-space d-block'>
                        We seem to have run into a error <br />
                        Don’t be alarmed just go back and try again.
                    </h5>
                    <GradientButton label="Back to home"
                        className='padding-12px-tb padding-24px-lr mt-4'
                        onPress={_navigation} />
                </div>

            </div>
        </div>

    )
}

