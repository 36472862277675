/*
 *   File : summary.js
 *   Author URI : https://evoqins.com
 *   Description : Summary page for mt-transcations
 *   Integrations : NA
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { Icon } from "../../../Components/Icon";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { Footer } from "../../../Components/Footer";
import { NavigationHeader } from "../../../Components/Header";
import { CustomLoader } from "../../../Components/Other";
import { CancelSTPModal } from "../../../Components/Modal";

import APIService from "../../../Services/api-service";

import style from "../../../Styles/Components/sip-detail.module.scss"


const PAGE_NAVIGATION = [
    'Dashboard',
    'Manage STP & SWP',
    'STP Summary',
];

export default function STPDetail(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);

    const fundId = location.state && location.state.fund_id;
    const customerId = location.state && location.state.customer_id;
    const [apiLoader, setApiLoader] = useState(true);
    const [detail, setDetail] = useState({});
    const [openCancelConfirmModal, setOpenCancelConfrimModal] = useState(false);
    const [cancelAPILoader, setCancelAPILoader] = useState(false);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    useEffect(() => {
        if (fundId) {
            _getDetail();
        }
        else {
            navigate(-1);
        }
    }, [fundId]);

    useEffect(() => {
        if (openCancelConfirmModal === true) {
            _openCancelModal();
        }
    }, [openCancelConfirmModal]);

    const _openCancelModal = () => {
        const modal = new Modal(document.getElementById("cancel-stp"), {});
        modal.show();
    }

    const _handleNavigate = (order_id) => {
        navigate("/orders/fund-order-summary", { state: { order_id: order_id } });
    }

    // handle cancel STP
    function _handleCancelTransaction() {
        let url = 'stp/generate-2fa';
        let payload = JSON.stringify({
            id: fundId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success(`OTP sent successfully`, {
                    type: "success",
                });
                setOpenCancelConfrimModal(true);
            } else {
                toast.error(`Failed to send OTP`, {
                    type: "error",
                });
            }
        })
    }

    function _closeConfirmModal() {
        setOpenCancelConfrimModal(false);
    }

    //  API - get detail of STP || SWP
    function _getDetail() {
        let url = 'stp/detail';
        let payload = JSON.stringify({
            id: fundId,
            customer_id: customerId,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data);
            }
            setApiLoader(false);
        });
    }

    // API - Cancel STP || SWP
    async function _cancelTransaction(otp) {
        setCancelAPILoader(true);
        let status = false;
        let url = 'stp/cancel';
        let payload = JSON.stringify({
            id: fundId,
            customer_id: customerId,
            otp
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                status = true;
                toast.success('STP cancelled', {
                    type: "success",
                });

            }
            else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setCancelAPILoader(false);
        });
        return status;
    }

    const Heading = (props) => {
        return (
            <div className={`d-flex ${props.className}`}>
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">{props.heading}&nbsp;</p>
                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{props.answer}</p>
            </div>

        )
    }

    const PaymentAndOtherActivities = (props) => {
        return (
            <div className="mt-3">
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px margin-10px-bottom e-payment-other-activities">{props.heading}</p>
                <p className="color-black e-poppins-semi-bold e-font-18 line-height-16px mb-0">{props.answer}</p>
            </div>
        )
    }

    return (
        apiLoader === true ? (
            <CustomLoader />
        )
            :
            <>
                <NavigationHeader
                    type={1}
                    title={location.state.status ? "Active STPs" : "Cancelled STPs"} />
                <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-32px-top">
                    <div className="mt-2">
                        <BreadCrumb data={PAGE_NAVIGATION} />
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-12">
                            <div className="border-radius-24px border-all border-bright-gray p-4">

                                <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between  flex-wrap">
                                    <div className="d-flex  gap-12px">
                                        <div className="border-all border-bright-gray border-radius-8px p-2 h-max-content">
                                            <img src={detail.image}
                                                alt="Quant Small Cap Fund Direct Plan Growth"
                                                width={36}
                                                height={36}
                                                className="object-fit-contain" />
                                        </div>
                                        <div className="">
                                            <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                Transfer Out
                                            </p>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{detail.name}</p>
                                            <img src={require('../../../Assets/Images/Orders/stp-arrow.png')}
                                                className="e-arrow-down my-3 w-35px" />
                                            <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                Transfer in
                                            </p>
                                            <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{detail.switch_in_fund_name}</p>
                                        </div>

                                    </div>
                                    <div>
                                        <p className="color-outer-space e-poppins-semi-bold e-font-20 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{detail.amount}
                                        </p>
                                        <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0">STP amount</p>
                                    </div>
                                </div>

                                <div className="mt-3 d-flex flex-wrap justify-content-between align-items-center">
                                    <Heading heading="Last installment:"
                                        answer={detail.last_installment} />
                                    <div className="d-flex align-items-center border-all border-primary-color border-radius-30px bg-pale-steel-blue px-3 py-2 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                        <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">Next installment &nbsp;</p>
                                        <p className="color-primary-color e-poppins-semi-bold e-font-16 mb-0">{detail.next_stp_date}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-2 flex-wrap gap-16px">
                                    <Heading heading="STP created on:"
                                        answer={detail.stp_created_on}
                                    />
                                </div>
                                <div className="border-radius-16px bg-light-grayish-white p-3 my-4 ">
                                    <div className="d-flex justify-content-between flex-wrap gap-16px">
                                        <Heading heading="Start date:"
                                            answer={detail.start_date} />
                                        <Heading heading="End date:"
                                            answer={detail.end_date}
                                        />
                                    </div>

                                </div>
                                {
                                    location.state.status &&
                                    <div className="border-top-1px border-bright-gray pt-3 d-flex justify-content-between algin-items-center flex-wrap">
                                        <div className="d-flex align-items-center gap-16px">
                                            <p className="color-primary-color e-poppins-regular e-font-16 mb-0 cursor-pointer"
                                                onClick={_handleCancelTransaction}>Cancel STP</p>
                                        </div>
                                    </div>
                                }

                            </div>

                            <p className="color-jett-black e-poppins-medium e-font-16 my-3">Payments and other activities</p>
                            <div className="border-all border-bright-gray pb-3 px-4 d-flex flex-wrap gap-24px border-radius-16px">
                                <PaymentAndOtherActivities heading="Total no. of installments"
                                    answer={detail.total_number_of_installments} />
                                <PaymentAndOtherActivities heading="Missed installments"
                                    answer={detail.missed_of_installments} />
                                <PaymentAndOtherActivities heading="Rejected installments"
                                    answer={detail.rejected_of_installments} />
                                <PaymentAndOtherActivities heading="Remaining installments"
                                    answer={detail.remaining_installments} />
                            </div>


                            {
                                detail.installments.length !== 0 &&
                                <div className="border-radius-24px border-all border-bright-gray p-4 mt-3">
                                    {
                                        detail.installments.map((item, key) =>
                                            <div key={key}
                                                className={`d-flex gap-8px  cursor-pointer ${detail.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(item.status === "Upcoming" && 'e-pointer-events-none')}`}
                                                onClick={() => {
                                                    if (item.status === "Success") {
                                                        navigate("/order-book/fund-order-detail", {
                                                            state: {
                                                                customer_id: customerId,
                                                                order_id: item.order_id
                                                            }
                                                        })
                                                    }
                                                }
                                                }>
                                                <Icon icon={item.status === "Success" ? "check-green" : item.status === "Failed" ? "cancelled" : "upcoming"}
                                                    size={24} />
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px">
                                                        <div>
                                                            <div className="d-lg-flex d-md-flex d-sm-flex d-block gap-16px align-items-center mb-2">
                                                                <p className="color-outer-space e-poppins-medium e-font-16 line-height-24px mb-0">{item.title}: {item.status === "Upcoming" ? "Not paid yet" : item.status === "Success" ? "Paid" : item.status === "Failed" ? "Failed" : item.status}</p>
                                                                {
                                                                    item.status !== "Upcoming" &&
                                                                    <p className={`position-relative e-font-14 e-poppins-regular mb-0 ms-lg-0 ms-md-0 ms-sm-0 ms-2 ${style.e_sip}`}>STP</p>
                                                                }
                                                            </div>
                                                            <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{item.transaction_date}</p>
                                                        </div>
                                                        {
                                                            item.status === "Success" ?
                                                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">Success</p>
                                                                :
                                                                item.status === "Failed" ?
                                                                    <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                                    :
                                                                    <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0">{item.status}</p>

                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            }
                        </div>
                    </div>
                    {
                        openCancelConfirmModal === true &&
                        <CancelSTPModal label="Cancel STP"
                            loading={cancelAPILoader}
                            close={_closeConfirmModal}
                            handleOTPSubmission={_cancelTransaction}
                        />
                    }
                </div>
            </>

    )
}

